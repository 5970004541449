import React from 'react';
import { withPageContext } from 'hoc';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DeliveriesList, DeliveryViewDialog } from 'components/enterprise';
import { useDialog } from 'hooks';

function PatientsDeliveriesPage() {
	const { patientId } = useParams();
	const [isOpenDeliveryDialog, onOpenDeliveryDialog, onCloseDeliveryDialog, deliverySfid] = useDialog<string>();

	return (
		<Grid item xs={12}>
			<DeliveriesList patientId={patientId || ''} onClickDelivery={onOpenDeliveryDialog} />
			<DeliveryViewDialog
				patientId={patientId || ''}
				deliverySfid={deliverySfid || ''}
				open={isOpenDeliveryDialog}
				onClose={onCloseDeliveryDialog}
			/>
		</Grid>
	);
}

export default withPageContext(PatientsDeliveriesPage);
