import { getRequest, postRequest, patchRequest, deleteRequest } from 'core/api/api-service';
import { endpoints } from 'core/api/endpoints';
import {
	CareTeamMember,
	CareTeamMemberAddParams,
	CareTeamMemberEditParams,
	CareTeamMemberViewParams,
	CareTeamMemberListParams,
	CareTeamMemberListResponse,
	CareTeamMemberDeleteParams,
	PatientAccesses,
} from './supervisor.models';
import { ISuccessResponse } from '../api-service.models';

export async function careTeamMemberList(request: CareTeamMemberListParams): Promise<CareTeamMemberListResponse> {
	const body = {
		...request,
	};

	return getRequest<CareTeamMemberListResponse>(endpoints.enterprise.supervisor.careTeamMembers, body, {});
}

export async function careTeamMemberView({ id }: CareTeamMemberViewParams): Promise<CareTeamMember> {
	return getRequest<CareTeamMember>(endpoints.enterprise.supervisor.careTeamMember(id), {}, {});
}

export async function careTeamMemberAdd(request: CareTeamMemberAddParams): Promise<CareTeamMember> {
	const body = {
		...request,
	};
	return postRequest<CareTeamMember>(endpoints.enterprise.supervisor.careTeamMembers, body, {});
}

export async function careTeamMemberEdit({ id, ...restRequest }: CareTeamMemberEditParams): Promise<CareTeamMember> {
	const body = {
		...restRequest,
	};
	return patchRequest<CareTeamMember>(endpoints.enterprise.supervisor.careTeamMember(id), body, {});
}

export async function careTeamMemberDelete({ id }: CareTeamMemberDeleteParams): Promise<ISuccessResponse> {
	return deleteRequest<ISuccessResponse>(endpoints.enterprise.supervisor.careTeamMember(id), {}, {});
}

export async function patientAccessesList(): Promise<PatientAccesses> {
	return getRequest<PatientAccesses>(endpoints.enterprise.supervisor.patientAccesses, {}, {});
}
