import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const SidebarListItemWrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	alignItems: 'center',
	justifyContent: 'space-between',
}));

export default SidebarListItemWrapper;
