export const primary = {
	100: '#f5dbff',
	200: '#ebb6ff',
	300: '#e192ff',
	400: '#d76dff',
	500: '#cd48ff',
	600: '#c324ff',
	700: '#b800ff',
	800: '#9e00da',
	900: '#8300b6',
	1000: '#690091',
};
