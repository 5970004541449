import { useQuery } from '@tanstack/react-query';
import { getCheckStatus } from 'core/api/registration/registration.api';
import { ICheckStatusSearchQuery } from 'core/api/registration/registration.models';

export function useRegisterCheckStatusQuery({ hash }: ICheckStatusSearchQuery) {
	return useQuery({
		queryKey: ['register', 'initialData'],
		queryFn: () => getCheckStatus({ hash }),
		enabled: !!hash,
		retry: false,
	});
}

export default useRegisterCheckStatusQuery;
