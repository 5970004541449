import React, { useEffect, useState } from 'react';
import { PaginationProps as MuiPaginationProps } from '@mui/material';
import { InboxNotificationListParams, InboxStatus } from 'core/api/inboxNotification';
import { ContentCard } from 'components/card';
import { InboxMessagesList, InboxMessagesTabs } from 'components/inbox';
import { useQueryClient } from '@tanstack/react-query';

export default function InboxMessagesPage() {
	const queryClient = useQueryClient();

	const [inboxState, setInboxState] = useState<InboxNotificationListParams>({
		page: 1,
		statusId: InboxStatus.Inbox,
		exists: { readAt: undefined },
	});

	const PaginationProps: MuiPaginationProps = {
		onChange: (event, page) => setInboxState((prevState) => ({ ...prevState, page })),
	};

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: ['inbox', 'list'] });
	}, [queryClient]);

	return (
		<ContentCard title="Inbox Messages" sx={{ '& .MuiCardContent-root': { gap: 0, p: 0 } }}>
			<InboxMessagesTabs
				status={inboxState.statusId}
				readAt={inboxState.exists?.readAt}
				onChange={({ status, readAt }) => setInboxState({ statusId: status, exists: { readAt }, page: 1 })}
			/>
			<InboxMessagesList {...{ ...inboxState, PaginationProps }} />
		</ContentCard>
	);
}
