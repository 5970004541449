import React from 'react';
import { Box, Container, Stack } from '@mui/material';

interface AuthLayoutProps {
	children: React.ReactNode;
}

export function AuthLayout({ children }: AuthLayoutProps) {
	return (
		<Box
			sx={{
				minHeight: '100vh',
				backgroundColor: 'hsla(278,100%,6%,1)',
				backgroundImage:
					'radial-gradient(at 76% 58%, hsla(278,100%,6%,0.81) 0px, transparent 50%), radial-gradient(at 26% 24%, hsla(278,100%,6%,0.81) 0px, transparent 50%), radial-gradient(at 35% 99%, hsla(206,80%,43%,0.62) 0px, transparent 50%), radial-gradient(at 62% 1%, hsla(283,100%,28%,0.71) 0px, transparent 50%)',
			}}
		>
			<Container maxWidth="lg" sx={{ paddingTop: '5%', paddingBottom: '5%' }}>
				<Stack sx={{ backgroundColor: 'background.default' }} alignItems="stretch" direction="row">
					{children}
				</Stack>
			</Container>
		</Box>
	);
}

export default AuthLayout;
