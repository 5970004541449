import React, { useMemo } from 'react';
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

export interface ISwitchProps extends MuiSwitchProps {
	label?: string;
	labelOff?: string;
	labelOn?: string;
}

const Switch = React.forwardRef<HTMLButtonElement, ISwitchProps>((props, ref) => {
	const { label, labelOn, labelOff, defaultChecked, checked, ...rest } = props;
	const customLabel: string | undefined = useMemo(() => {
		const isChecked = !!(defaultChecked ?? checked);
		if (labelOn || labelOff) {
			return isChecked ? labelOn : labelOff;
		}

		return undefined;
	}, [labelOn, labelOff, checked, defaultChecked]);

	if (label || customLabel) {
		return (
			<FormControlLabel
				control={<MuiSwitch ref={ref} {...rest} defaultChecked={defaultChecked} checked={checked} />}
				label={customLabel ?? label}
			/>
		);
	}
	return <MuiSwitch ref={ref} {...rest} defaultChecked={defaultChecked} checked={checked} />;
});

export default Switch;
