import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MedsList = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	width: '100%',
	gap: 24,
}));
