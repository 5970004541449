import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	recordTakenAsNeededMeds,
	editRecordTakenAsNeededMeds,
	deleteRecordTakenAsNeededMeds,
	recordTakenScheduledMeds as recordTakenScheduledMedsApi,
} from 'core/api/enterprise/patient.api';

export function useMedScheduleMutation() {
	const queryClient = useQueryClient();

	const asNeededRecordTaken = useMutation({
		mutationFn: recordTakenAsNeededMeds,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['enterprise', 'patient', variables.patientId, 'asNeededMed', 'list'],
			});
		},
	});

	const editAsNeededRecordTaken = useMutation({
		mutationFn: editRecordTakenAsNeededMeds,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['enterprise', 'patient', variables.patientId, 'asNeededMed', 'list'],
			});
		},
	});

	const deleteAsNeededRecordTaken = useMutation({
		mutationFn: deleteRecordTakenAsNeededMeds,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['enterprise', 'patient', variables.patientId, 'asNeededMed', 'list'],
			});
		},
	});

	const recordTakenScheduledMeds = useMutation({
		mutationFn: recordTakenScheduledMedsApi,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: ['enterprise', 'patient', variables.patientId, 'schedule'] });
		},
	});

	return {
		asNeededRecordTaken,
		editAsNeededRecordTaken,
		deleteAsNeededRecordTaken,
		recordTakenScheduledMeds,
	};
}

export default useMedScheduleMutation;
