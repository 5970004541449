import React, { createContext, useContext, useMemo, useState } from 'react';

export interface PageContextType {
	pageTitle: string;
	setPageTitle: (title: string) => void;
}

const PageContext = createContext<PageContextType | undefined>(undefined);

type PageProviderProps = {
	children: React.ReactNode;
};

export default function PageProvider({ children }: PageProviderProps) {
	const [pageTitle, setPageTitle] = useState('');

	const contextValue = useMemo(
		() => ({
			pageTitle,
			setPageTitle,
		}),
		[pageTitle, setPageTitle]
	);

	return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
}

export const usePageContext = (): PageContextType => {
	const context = useContext(PageContext);
	if (!context) {
		throw new Error('usePageContext must be used within a PageProvider');
	}
	return context;
};
