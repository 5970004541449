import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import routes from 'routes';
import { UserRole } from 'core/models/auth/auth.models';
import UserProvider from 'contexts/UserProvider';
import { useAuth } from 'hooks';
import { Box } from '@mui/material';
import CircularLoading from 'components/CircularLoading';
import { AuthError } from 'core/errors';

interface ProtectedRouteProps {
	children: React.ReactNode;
	roles?: UserRole[];
}

function ProtectedRoute({ children, roles = [] }: ProtectedRouteProps) {
	const { getDefaultRouteForRoles, getAccountState, userToken, logout } = useAuth();
	const { data: account, isLoading, error } = getAccountState();
	const location = useLocation();

	useEffect(() => {
		if (userToken && error && error instanceof AuthError) {
			logout.mutate(undefined);
		}
	}, [userToken, error]);

	if (!userToken) {
		return <Navigate to={routes.login} />;
	}

	if (!isLoading && account && account.roles && roles.length && !roles.some((role) => account.roles.includes(role))) {
		if (location.pathname === routes.home) {
			return <Navigate to={getDefaultRouteForRoles(account.roles)} />;
		}

		return <Navigate to={routes.errors.unauthorizedAccess} />;
	}

	if (isLoading) {
		return (
			<Box sx={{ height: '100vh' }}>
				<CircularLoading />
			</Box>
		);
	}

	return <UserProvider>{children}</UserProvider>;
}

export default ProtectedRoute;
