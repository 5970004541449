import React, { useEffect, useState } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import Switch from 'components/common/inputs/switch/Switch';
import { NotificationChannel, NotificationSettingProps } from 'core/api/userAccount/userAccount.models';

interface NotificationSettingRowProps {
	title: string;
	description: string;
	categoryId: number;
	emailEnabled: boolean;
	pushEnabled: boolean;
	isPending?: boolean;
	onChange: (props: NotificationSettingProps) => void;
}

export function NotificationSettingRow({
	title,
	description,
	categoryId,
	emailEnabled,
	pushEnabled,
	isPending = false,
	onChange,
}: NotificationSettingRowProps) {
	const [isPendingPushUpdate, setIsPendingPushUpdate] = useState(false);
	const [isPendingEmailUpdate, setIsPendingEmailUpdate] = useState(false);

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange({ categoryId, channelId: NotificationChannel.EMAIL, enabled: event.target.checked });
		setIsPendingEmailUpdate(true);
	};

	const handlePushChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange({ categoryId, channelId: NotificationChannel.PUSH, enabled: event.target.checked });
		setIsPendingPushUpdate(true);
	};

	useEffect(() => {
		if (!isPending) {
			setIsPendingPushUpdate(false);
			setIsPendingEmailUpdate(false);
		}
	}, [isPending]);

	return (
		<TableRow>
			<TableCell align="left">
				<Typography variant="small" fontWeight={700}>
					{title}
				</Typography>
				<Typography component="p" variant="caption" color="initial">
					{description}
				</Typography>
			</TableCell>
			<TableCell align="center">
				<Switch
					labelOn="On"
					labelOff="Off"
					disabled={isPendingEmailUpdate}
					onChange={handleEmailChange}
					checked={emailEnabled}
				/>
			</TableCell>
			<TableCell align="center">
				<Switch
					labelOn="On"
					labelOff="Off"
					disabled={isPendingPushUpdate}
					onChange={handlePushChange}
					checked={pushEnabled}
				/>
			</TableCell>
		</TableRow>
	);
}

export default NotificationSettingRow;
