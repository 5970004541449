import { UserRole } from 'core/models/auth/auth.models';
import { getRequest, postRequest, patchRequest, deleteRequest } from '../api-service';
import { endpoints } from '../endpoints';
import { IAddressParts, IChangePhoneRequest, IProfileInfo } from '../../models/userAccount/userAccount.models';
import {
	IChangeEmailRequest,
	IChangePasswordRequest,
	IChangeAddressRequest,
	IChangeAddressResponse,
	IDeleteChangePhoneRequest,
	IGetProfileInfoResponse,
	ISendChangePhoneRequest,
	ISendChangePhoneRequestResponse,
	ISendCodeAgainRequest,
	ISendCodeAgainRequestResponse,
	ISuccessResponse,
	IVerifyPhoneNumberRequest,
	IVerifyEmailChangedRequest,
	IChangeEmailResponse,
	IMakeAPaymentResponse,
	IAddressPartsResponse,
	NotificationSettings,
	NotificationSettingProps,
} from './userAccount.models';

function mapAddressParts(result: IAddressPartsResponse): IAddressParts {
	return {
		shippingStreet: result.shippingStreet,
		shippingCity: result.shippingCity,
		shippingStateCode: result.shippingStateCode,
		shippingPostalCode: result.shippingPostalCode,
		billingStreet: result.billingStreet,
		billingCity: result.billingCity,
		billingStateCode: result.billingStateCode,
		billingPostalCode: result.billingPostalCode,
	};
}

function mapProfileInfo(result: IGetProfileInfoResponse): IProfileInfo {
	return {
		requestedShippingAddress: result.requestedShippingAddress,
		requestedBillingAddress: result.requestedBillingAddress,
		phoneNumber: result.phoneNumber,
		pendingPhoneNumber: result.pendingPhoneNumber,
		changePhoneRequestId: result.changePhoneRequestId,
		email: result.email,
		emailValidated: result.emailValidated,
		shippingAddress: result.shippingAddress,
		billingAddress: result.billingAddress,
		addressParts: result.changeAddress ? mapAddressParts(result.changeAddress) : null,
		balance: result.balance,
		balanceFloat: result.balanceFloat,
		balanceUpdatedAt: result.balanceUpdatedAt,
		fullName: result.fullName,
		passwordExpired: result.passwordExpired,
		roles: result.roles as UserRole[],
		timeZone: result.timeZone,
		medicationRemindersEnabled: result.medicationRemindersEnabled,
		homepage: result.homepage,
		mainMenu: result.mainMenu,
		vialsPatient: result.vialsPatient,
	};
}

function mapChangePhoneRequest(result: ISendChangePhoneRequestResponse): IChangePhoneRequest {
	return {
		changePhoneRequestId: result.id,
		pendingPhoneNumber: result.phoneNumber,
	};
}

export async function getProfileInfo(): Promise<IProfileInfo> {
	const { profileInfo: profileInfoEndpoint } = endpoints.userAccount;

	const result = await getRequest<IGetProfileInfoResponse>(profileInfoEndpoint, {}, {});
	return mapProfileInfo(result);
}

export async function updateProfileInfo(request: Partial<IProfileInfo>): Promise<ISuccessResponse> {
	const body = {
		timeZone: request.timeZone,
		medicationRemindersEnabled: request.medicationRemindersEnabled,
	};

	return postRequest<ISuccessResponse>(endpoints.userAccount.updateProfileInfo, body, {});
}

export async function addChangePhoneRequest(request: ISendChangePhoneRequest): Promise<IChangePhoneRequest> {
	const { changePhoneRequestEndpoint } = endpoints.userAccount;
	const body = {
		phoneNumber: request.phoneNumber,
	};

	const result = await postRequest<ISendChangePhoneRequestResponse>(changePhoneRequestEndpoint, body, {});

	return mapChangePhoneRequest(result);
}

export function deleteChangePhoneRequest(request: IDeleteChangePhoneRequest): Promise<ISuccessResponse> {
	const { changePhoneRequestEndpoint } = endpoints.userAccount;
	const body = {
		id: request.id,
	};

	return deleteRequest<ISuccessResponse>(changePhoneRequestEndpoint, body, {});
}

export async function sendCodeAgainRequest(request: ISendCodeAgainRequest): Promise<IChangePhoneRequest> {
	const { changePhoneRequestEndpoint } = endpoints.userAccount;

	const result = await patchRequest<ISendCodeAgainRequestResponse>(
		`${changePhoneRequestEndpoint}/${request.id}`,
		{},
		{}
	);

	return mapChangePhoneRequest(result);
}

export function verifyPhoneNumberRequest(request: IVerifyPhoneNumberRequest): Promise<ISuccessResponse> {
	const { verifyPhoneNumberEndpoint } = endpoints.userAccount;
	const body = {
		phoneVerificationCode: request.phoneVerificationCode ?? '',
	};

	return postRequest<ISuccessResponse>(verifyPhoneNumberEndpoint, body, {});
}
export async function changeEmailRequest(request: IChangeEmailRequest): Promise<IChangeEmailResponse> {
	const { changeEmailEndpoint } = endpoints.userAccount;
	const body = {
		newEmail: request.newEmail,
		password: request.password,
	};
	return postRequest<IChangeEmailResponse>(changeEmailEndpoint, body, {});
}

export async function deleteAccountRequest(): Promise<ISuccessResponse> {
	const { deleteAccountEndpoint } = endpoints.userAccount;
	return postRequest<ISuccessResponse>(deleteAccountEndpoint, {}, {});
}

export async function changePasswordRequest(request: IChangePasswordRequest): Promise<ISuccessResponse> {
	const { changePasswordEndpoint } = endpoints.userAccount;
	const body = {
		password: request.password,
		newPassword: request.newPassword,
	};
	return postRequest<ISuccessResponse>(changePasswordEndpoint, body, {});
}

export async function verifyEmailChangedRequest(request: IVerifyEmailChangedRequest): Promise<ISuccessResponse> {
	const { verifyEmailChangedEndpoint } = endpoints.userAccount;
	const body = {
		hash: request.hash,
	};
	return postRequest<ISuccessResponse>(verifyEmailChangedEndpoint, body, {});
}

export async function changeAddressRequest(request: IChangeAddressRequest): Promise<IChangeAddressResponse> {
	const { changeAddressEndpoint } = endpoints.userAccount;
	const body = {
		shippingStreet: request.shippingStreet,
		shippingCity: request.shippingCity,
		shippingStateCode: request.shippingStateCode,
		shippingPostalCode: request.shippingPostalCode,
		billingStreet: request.billingStreet,
		billingCity: request.billingCity,
		billingStateCode: request.billingStateCode,
		billingPostalCode: request.billingPostalCode,
	};

	return postRequest<IChangeAddressResponse>(changeAddressEndpoint, body, {});
}

export async function makeAPaymentRequest(): Promise<IMakeAPaymentResponse> {
	const { paymentUrl: paymentUrlEndpoint } = endpoints.userAccount;

	return getRequest<IMakeAPaymentResponse>(paymentUrlEndpoint, {}, {});
}

export async function resendVerificationEmailRequest(): Promise<ISuccessResponse> {
	const { resendVerificationEmailEndpoint } = endpoints.userAccount;
	return postRequest<ISuccessResponse>(resendVerificationEmailEndpoint, {}, {});
}

export async function getNotificationSettings(): Promise<NotificationSettings> {
	return getRequest<NotificationSettings>(endpoints.userAccount.notificationSettings, {}, {});
}

export async function changeNotificationSetting(request: NotificationSettingProps): Promise<ISuccessResponse> {
	const body = {
		categoryId: request.categoryId,
		channelId: request.channelId,
		enabled: request.enabled,
	};

	return postRequest<ISuccessResponse>(endpoints.userAccount.notificationSettings, body, {});
}
