import { useQuery } from '@tanstack/react-query';
import { getInboxNotifications, InboxNotification, InboxNotificationListParams } from 'core/api/inboxNotification';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export function useInboxNotificationsQuery({ page, ...restRequest }: InboxNotificationListParams) {
	return useQuery({
		queryKey: ['inbox', 'list', restRequest, page],
		queryFn: () => getInboxNotifications({ page, ...restRequest }),
		select: normalizeHydraCollection<InboxNotification>,
		refetchInterval: 2000,
		refetchIntervalInBackground: true,
		enabled: true,
		refetchOnWindowFocus: true,
	});
}

export default useInboxNotificationsQuery;
