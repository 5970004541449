import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPatientFilter } from 'core/api/enterprise/patient.api';

export function useCreatePatientFilterMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['enterprise', 'patient', 'filter', 'create'],
		mutationFn: createPatientFilter,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['enterprise', 'patient', 'filters'] });
		},
	});
}

export default useCreatePatientFilterMutation;
