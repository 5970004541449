import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function MedicationScheduleIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 48 48" {...props}>
			<path
				d="M24.5984 44.0999C22.4984 41.9999 22.4984 38.6999 24.5984 36.5999L35.8484 25.4999C37.9484 23.3999 41.2484 23.3999 43.3484 25.4999C45.4484 27.5999 45.4484 30.8999 43.3484 32.9999L32.0984 44.0999C29.9984 46.1999 26.6984 46.1999 24.5984 44.0999Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="8"
			/>
			<path d="M30.1484 31.0499L37.6484 38.3999" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path
				d="M20.2492 45.3C9.74922 43.65 1.94922 34.5 1.94922 23.85C1.94922 11.85 11.6992 1.94995 23.8492 1.94995C35.2492 1.94995 44.8492 10.65 45.7492 22.05"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="8"
			/>
			<path d="M18.1484 23.7H23.8484V12.45" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M23.8496 5.69995V7.49995" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M14.6992 7.94995L15.5992 9.59995" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M7.94922 14.5499L9.74922 15.5999" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M5.69922 23.7H7.64922" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M7.94922 32.8499L9.74922 31.7999" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M14.6992 39.5999L15.5992 37.7999" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M39.5992 14.5499L37.9492 15.5999" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
			<path d="M32.8492 7.94995L31.9492 9.59995" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
		</SvgIcon>
	);
}
