import React from 'react';
import { Alert, Button, Box, Typography } from '@mui/material';
import { ICheckStatusResponse } from 'core/api/registration/registration.models';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';

export interface RegisterVerifyEmailCodeProps extends Partial<ICheckStatusResponse> {
	hash: string;
	onBack: () => void;
}

export function RegisterVerifyEmailCode({ hash, onBack }: RegisterVerifyEmailCodeProps) {
	const { registerPhoneNumber } = useRegisterMutation();
	const handleSendCodeAgain = () => registerPhoneNumber.mutate({ phoneNumber: null, hash });

	return (
		<Box>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Finish Signing Up
			</Typography>

			{!registerPhoneNumber.isSuccess && (
				<Typography component="p" color="text.secondary">
					Please check your email and follow the link to verify.
				</Typography>
			)}

			{registerPhoneNumber.error?.message && (
				<Alert sx={{ mt: 2 }} severity="error">
					{registerPhoneNumber.error.message}
				</Alert>
			)}

			<Box display="flex" mt={5} mb={2} sx={{ width: { xs: '100%', sm: '50%' }, flexDirection: 'column' }}>
				<Typography variant="input2" fontWeight={700}>
					Didn‘t receive the code?
				</Typography>
				<Button
					onClick={handleSendCodeAgain}
					variant="text"
					sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}
				>
					{registerPhoneNumber.isPending ? 'Loading...' : 'Send me a new email.'}
				</Button>
				<Button onClick={onBack} variant="text" sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}>
					Use phone number instead.
				</Button>
			</Box>
		</Box>
	);
}

export default RegisterVerifyEmailCode;
