import React from 'react';
import { Outlet } from 'react-router-dom';
import GoogleAnalytics from 'components/GoogleAnalytics';
import ErrorBoundaryProvider from 'contexts/ErrorBoundaryProvider';
import PageProvider from 'contexts/PageProvider';

function App() {
	return (
		<ErrorBoundaryProvider>
			<PageProvider>
				<GoogleAnalytics />
				<Outlet />
			</PageProvider>
		</ErrorBoundaryProvider>
	);
}

export default App;
