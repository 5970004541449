import React from 'react';
import { Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import AnewHealthExactCareLightLogo from 'components/logo/AnewHealthExactCareLightLogo';

interface AuthMainContentProps {
	children: React.ReactNode;
	sx?: SxProps<Theme>;
}

export function AuthMainContent({ children, sx }: AuthMainContentProps) {
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	return (
		<Stack
			width={{ sm: '100%', md: '65%' }}
			padding="3rem 2.2rem"
			direction="column"
			gap={2}
			justifyContent="center"
			sx={{
				backgroundColor: 'background.secondary',
				...sx,
			}}
		>
			{!upSmallScreen && <AnewHealthExactCareLightLogo sx={{ width: '160px', height: 'auto' }} />}
			{children}
		</Stack>
	);
}

export default AuthMainContent;
