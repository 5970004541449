import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	changePassword,
	changeDateOfBirth,
	changeEmail,
	deletePatient,
	suspendPatient,
	unblockPatient,
} from 'core/api/admin/patients.api';
import { IPatientsListRequest } from 'core/api/admin/patients.models';
import { adminPatientQueries } from 'queries/admin';

export function usePatientActions() {
	const queryClient = useQueryClient();

	const patientsListQuery = (props: IPatientsListRequest) => {
		return useQuery(adminPatientQueries.patientsList(props));
	};

	const suspendPatientMutation = useMutation({
		mutationFn: suspendPatient,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patients', 'list'],
			});
		},
	});

	const unblockPatientMutation = useMutation({
		mutationFn: unblockPatient,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patients', 'list'],
			});
		},
	});

	const deletePatientMutation = useMutation({
		mutationFn: deletePatient,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patients', 'list'],
			});
		},
	});

	const changeEmailMutation = useMutation({
		mutationFn: changeEmail,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patients', 'list'],
			});
		},
	});

	const changeDateOfBirthMutation = useMutation({
		mutationFn: changeDateOfBirth,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patients', 'list'],
			});
		},
	});

	const changePatientPasswordMutation = useMutation({
		mutationFn: changePassword,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patients', 'list'],
			});
		},
	});

	return {
		patientsListQuery,
		suspendPatientMutation,
		unblockPatientMutation,
		deletePatientMutation,
		changeEmailMutation,
		changeDateOfBirthMutation,
		changePatientPasswordMutation,
	};
}

export default usePatientActions;
