import React from 'react';
import { Stack, SxProps, Theme } from '@mui/material';
import AnewHealthExactCareLightLogo from 'components/logo/AnewHealthExactCareLightLogo';

interface AuthSidebarProps {
	children: React.ReactNode;
	sx?: SxProps<Theme>;
}

export function AuthSidebar({ children, sx }: AuthSidebarProps) {
	return (
		<Stack
			direction="column"
			alignItems="left"
			flexGrow={1}
			padding="3rem 2.2rem"
			maxWidth={{ sm: '100%', md: '35%' }}
			gap={2}
			sx={sx}
		>
			<AnewHealthExactCareLightLogo sx={{ width: '240px', height: 'auto' }} />
			{children}
		</Stack>
	);
}

export default AuthSidebar;
