import React, { useState, forwardRef } from 'react';
import { TimePicker as MuiTimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

export default forwardRef<HTMLInputElement, TimePickerProps<Dayjs>>(function TimePicker({ slotProps, ...props }, ref) {
	const [open, setOpen] = useState(false);
	return (
		<MuiTimePicker
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			slotProps={{
				...slotProps,
				textField: {
					onClick: () => setOpen(true),
					...slotProps?.textField,
				},
			}}
			inputRef={ref as React.Ref<HTMLInputElement>}
			{...props}
		/>
	);
});
