import * as Sentry from '@sentry/react';
import React from 'react';
import {
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT } = process.env;

class SentryService {
	private initialized = false;

	init() {
		if (!this.initialized) {
			Sentry.init({
				dsn: REACT_APP_SENTRY_DSN,
				environment: REACT_APP_SENTRY_ENVIRONMENT,
				integrations: [
					Sentry.browserTracingIntegration(),
					Sentry.reactRouterV6BrowserTracingIntegration({
						useEffect: React.useEffect,
						useLocation,
						useNavigationType,
						createRoutesFromChildren,
						matchRoutes,
					}),
				],
				tracesSampleRate: 1.0,
				tracePropagationTargets: ['https://demo-my.exactcarepharmacy.com'],
			});
			this.initialized = true;
		}
	}

	// eslint-disable-next-line class-methods-use-this
	createBrowserRouter(routes: any) {
		return Sentry.wrapCreateBrowserRouter(createBrowserRouter)(routes);
	}
}

const sentryService = new SentryService();
export default sentryService;
