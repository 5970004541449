import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { Tab, Tabs, styled } from '@mui/material';
import { InboxStatus } from 'core/api/inboxNotification';

const InboxMessagesTabsStyled = styled(Tabs)(({ theme }) => ({
	borderBottom: 1,
	borderColor: 'divider',
	'& .MuiTab-root': {
		'&.Mui-selected': {
			color: theme.palette.text.secondary,
		},
		color: theme.palette.text.primary,
		textTransform: 'capitalize',
		fontSize: theme.typography.h2.fontSize,
		lineHeight: theme.typography.h2.lineHeight,
		fontWeight: 600,
		textAlign: 'center',
	},
}));

interface InboxMessagesTabsProps {
	status?: InboxStatus | InboxStatus[];
	readAt?: boolean;
	onChange: (props: { status: InboxStatus | InboxStatus[]; readAt?: boolean }) => void;
}

enum TabList {
	All = 'All',
	Unread = 'Unread',
	Deleted = 'Deleted',
}

export function InboxMessagesTabs({ status, readAt, onChange }: InboxMessagesTabsProps) {
	const initialTab = useMemo(() => {
		let tab = TabList.All;

		if (status === InboxStatus.Inbox && readAt === undefined) {
			tab = TabList.All;
		} else if (status === InboxStatus.Inbox && readAt === false) {
			tab = TabList.Unread;
		} else if (status === InboxStatus.Deleted && readAt === undefined) {
			tab = TabList.Deleted;
		}

		return tab;
	}, [status, readAt]);

	const handleTabChange = useCallback(
		(event: SyntheticEvent, newTab: TabList) => {
			if (newTab === TabList.All) {
				onChange({ status: InboxStatus.Inbox, readAt: undefined });
			} else if (newTab === TabList.Unread) {
				onChange({ status: InboxStatus.Inbox, readAt: false });
			} else if (newTab === TabList.Deleted) {
				onChange({ status: InboxStatus.Deleted, readAt: undefined });
			}
		},
		[onChange]
	);

	return (
		<InboxMessagesTabsStyled value={initialTab} onChange={handleTabChange}>
			{Object.values(TabList).map((tab) => (
				<Tab key={tab} value={tab} label={tab} />
			))}
		</InboxMessagesTabsStyled>
	);
}

export default InboxMessagesTabs;
