import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function BillingIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 48 48" {...props}>
			<path
				d="M30.4512 1.5V9.6C30.4512 11.1 31.6512 12.3 33.1512 12.3H41.2512"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.5004 46.65H37.6504C39.6004 46.65 41.2504 45 41.2504 43.05V12.3L30.4504 1.5H10.5004C8.55039 1.5 6.90039 3 6.90039 5.1V43.05C6.90039 45 8.55039 46.65 10.5004 46.65Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M34.9508 15.9C35.4008 15.9 35.8508 15.9 35.8508 15.9V32.25C35.8508 32.25 35.4008 32.25 34.9508 32.25H13.2008C12.7508 32.25 12.3008 32.25 12.3008 32.25V15.9C12.3008 15.9 12.7508 15.9 13.2008 15.9H34.9508Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M35.8508 26.7H12.3008"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M35.8508 21.3H12.3008"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M28.5 21.3V32.25"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30.4512 37.65H35.8512"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.3008 37.65H24.9008"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.3008 10.5H24.9008"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.3008 6.75H21.3008"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}
