import { postRequest } from '../api-service';
import { endpoints } from '../endpoints';
import { IChangePasswordRequest, ISuccessResponse } from './resetPassword.models';

export function changePassword(request: IChangePasswordRequest): Promise<ISuccessResponse> {
	const { changePassword: changePasswordEndpoint } = endpoints.resetPassword;
	const body = {
		hash: request.hash,
		password: request.password,
	};
	return postRequest<ISuccessResponse>(changePasswordEndpoint, body, {});
}
