import React, { useEffect } from 'react';
import ContentCard from 'components/card/ContentCard';
import { Button, Box, Grid, Typography } from '@mui/material';
import Switch from 'components/common/inputs/switch/Switch';
import { TimeZoneSelect } from 'components/common/inputs';
import ReminderSettings from 'components/patient/mySchedule/ReminderSettings';
import { useMedicationRemindersMutation, useMedicationRemindersQuery } from 'hooks/patient';
import CircularLoading from 'components/CircularLoading';
import { useUserContext } from 'contexts/UserProvider';

export default function ManageMedicationRemindersPage() {
	const { updateTimeZone, updateMedicationRemindersState, updateTimeReminder, resetAllTimeReminders } =
		useMedicationRemindersMutation();
	const { medicationRemindersEnabled, timeZone, isLoading: isLoadingUserContext } = useUserContext();
	const { data: settingsData, isLoading: settingsDataIsLoading } = useMedicationRemindersQuery();
	const { items: reminders } = settingsData || {};

	const handleChangeMedicationRemindersEnabled = updateMedicationRemindersState.mutate;
	const handleChangeReminder = updateTimeReminder.mutate;

	useEffect(() => {
		if (reminders && reminders.every((reminder) => !reminder.enabled)) {
			handleChangeMedicationRemindersEnabled(false);
		}
	}, [reminders]);

	return (
		<ContentCard title="Manage Medication Reminders">
			<Typography>
				Taking your medication as prescribed is important to your health. You can set up reminders to help you remember
				to take them!
			</Typography>

			<Grid container>
				<Grid item xs={8}>
					<Typography variant="input2" fontWeight={700}>
						Do you want to receive reminders to take your medication?
					</Typography>
					<Switch
						labelOn="Yes"
						labelOff="No"
						checked={medicationRemindersEnabled ?? false}
						onChange={(event) => handleChangeMedicationRemindersEnabled(event.target.checked)}
						disabled={updateMedicationRemindersState.isPending || isLoadingUserContext}
					/>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="input2" fontWeight={700}>
						Time Zone
					</Typography>
					<TimeZoneSelect
						size="small"
						fullWidth
						onChange={(event) => {
							updateTimeZone.mutate(event.target.value);
						}}
						value={timeZone || ''}
						disabled={updateTimeZone.isPending || isLoadingUserContext}
					/>
				</Grid>
			</Grid>

			{medicationRemindersEnabled && (
				<>
					<CircularLoading isLoading={settingsDataIsLoading} />
					{!settingsDataIsLoading && <ReminderSettings reminders={reminders} onChangeReminder={handleChangeReminder} />}
					<Box>
						<Button onClick={() => resetAllTimeReminders.mutate()} variant="outlined" size="small">
							{resetAllTimeReminders.isPending ? 'Loading...' : 'Reset to Prescribed Times'}
						</Button>
					</Box>
				</>
			)}
		</ContentCard>
	);
}
