import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PersonalInfoIcon(props: SvgIconProps) {
	return (
		<SvgIcon x="0px" y="0px" viewBox="0 0 32 32" sx={{ fill: 'none', color: 'text.secondary' }} {...props}>
			<style type="text/css">
				{`
	.st2{display:inline;fill:none;stroke:currentColor;stroke-linejoin:round;stroke-miterlimit:10;}
	.st3{display:inline;fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st4{display:inline;}
	.st5{fill:none;stroke:currentColor;stroke-linejoin:round;stroke-miterlimit:10;}
	.st6{fill:none;stroke:currentColor;stroke-linejoin:bevel;stroke-miterlimit:10;}
	.st7{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        `}
			</style>
			<g>
				<path className="st7" d="M2.6,27.4c-0.9,0-1.7-0.9-1.7-1.8V7.2C0.9,6.5,1.4,6,2.1,6h2.1" />
				<path
					className="st7"
					d="M28.3,27.4c1.5,0,2.8-1.4,2.8-2.9V8.3c0-0.6-0.5-1.1-1.1-1.1l0,0H14.7c-0.2,0-0.4-0.1-0.5-0.2l-1.7-2.6
		c-0.2-0.3-0.6-0.5-0.9-0.5H5.4C4.8,3.9,4.3,4.4,4.3,5v20.7c0,0.9-0.7,1.7-1.7,1.7H28.3L28.3,27.4z"
				/>
				<path
					className="st7"
					d="M18.8,17.2c0,3.1-2.5,5.6-5.6,5.6s-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6S18.8,14.1,18.8,17.2L18.8,17.2z"
				/>
				<path
					className="st7"
					d="M15.1,16.4c0,1.1-0.9,2-1.9,2s-1.9-0.9-1.9-2s0.9-2,1.9-2C14.3,14.4,15.1,15.3,15.1,16.4L15.1,16.4z"
				/>
				<path className="st7" d="M17.3,21.1c-1.4-2.2-4.4-2.8-6.6-1.5c-0.6,0.4-1.1,0.9-1.5,1.5" />
				<line className="st7" x1="27.7" y1="17.2" x2="21" y2="17.2" />
				<line className="st7" x1="21" y1="13.9" x2="27.7" y2="13.9" />
				<line className="st7" x1="25.5" y1="20.6" x2="21" y2="20.6" />
			</g>
		</SvgIcon>
	);
}
