import { useState, useEffect, useCallback } from 'react';
import { fromEvent, merge, filter, map, startWith, Subject } from 'rxjs';

export type Token = string | null;

export const bearerTokenSubject = new Subject();

export const useBearerToken = () => {
	const [token, setToken] = useState<Token>(() => localStorage.getItem('bearer'));

	const updateToken = useCallback((newToken: Token) => {
		if (newToken) {
			window.localStorage.setItem('bearer', newToken);
		} else {
			window.localStorage.removeItem('bearer');
		}
		bearerTokenSubject.next(newToken);
	}, []);

	useEffect(() => {
		const storageEvents = fromEvent<StorageEvent>(window, 'storage').pipe(
			filter((event) => event.key === 'bearer'),
			map((event) => event.newValue)
		);

		const tokenUpdates = merge(storageEvents, bearerTokenSubject.asObservable()).pipe(
			startWith(localStorage.getItem('bearer'))
		);

		const subscription = tokenUpdates.subscribe((newToken) => {
			setToken(newToken as Token);
		});

		return () => subscription.unsubscribe();
	}, []);

	return { token, updateToken };
};

export default useBearerToken;
