import { HydraCollection, HydraContext } from 'core/api/api-service.models';
import { ScheduledMedStatus, ScheduledMedType } from 'core/models/mySchedule/mySchedule.models';

export interface IScheduledMedsRequestParams {
	day?: string; // Y-m-d
}

export interface IAsNeededMedsParams {
	day?: string; // Y-m-d
}

interface IScheduledMedication extends HydraContext {
	id: number;
	name: string;
	imageUrl: string;
	taken: boolean | null;
	skipped: boolean | null;
}

export interface IScheduledMedDetails {
	exactPackMedication: IScheduledMedication[];
	additionalMedication: IScheduledMedication[];
	id: number;
	title: string;
	type: ScheduledMedType;
	timeOfDaySuffix: number;
	scheduleInfo: string | null;
	logged: boolean;
	status: ScheduledMedStatus | null;
	message: string | null;
}

export interface IScheduledMedsResponse extends HydraCollection<IScheduledMedDetails> {}

export interface IRecordTakenRequestParams {
	takenIds: number[];
	skippedIds: number[];
}

export interface IRecordTakenResponse extends HydraContext {
	error: string | null;
}

export interface IAsNeededMedication {
	scheduledMedId: number;
	scheduledTimeId: number;
	name: string;
	imageUrl: string;
	howToUse: string;
	lastTakenAt: string;
	warnIfTakenBefore: string;
	asNeededMedHistory: {
		from: string;
		to: string;
		asNeededMedsTaken: {
			scheduledMedTakenId: number;
			date: string;
			time: string;
		}[];
	};
}

export interface IAsNeededMedsResponse extends HydraCollection<IAsNeededMedication> {}

export interface IRecordTakenAsNeededMedRequestParams {
	id?: number;
	scheduledMedId?: number | null;
	scheduledTimeId?: number | null;
	hourTaken?: number;
	minuteTaken?: number;
	meridiem?: 'AM' | 'PM';
}

export interface IRecordTakenAsNeededMedResponse extends HydraContext {
	id: number;
	lastReportedDose: string | null;
}

export interface IEditRecordTakenAsNeededMedRequestParams {
	id: number;
	hourTaken: number;
	minuteTaken: number;
	meridiem: 'AM' | 'PM';
}

export interface IDeleteRecordTakenAsNeededMedRequestParams {
	id: number;
}

export interface IScheduledTimeReminderSettings {
	id: number | null;
	scheduledTimeHour: number | null;
	scheduledTimeMinute: number | null;
	scheduledTimeMeridiem: 'AM' | 'PM';
	reminderTimeHour: number | null;
	reminderTimeMinute: number | null;
	reminderTimeMeridiem: 'AM' | 'PM';
	enabled: boolean | null;
	scheduleTimeOfDayName: string | null;
}

export interface IScheduledTimeReminderSettingsResponse extends HydraCollection<IScheduledTimeReminderSettings> {}

export interface ICreateScheduledTimeReminderSettingsParams
	extends Exclude<IScheduledTimeReminderSettings, 'id' | 'scheduleTimeOfDayName'> {}

export interface ICreateScheduledTimeReminderSettingsResponse extends HydraContext, IScheduledTimeReminderSettings {}

export interface IUpdateScheduledTimeReminderSettingsParams
	extends Pick<
		IScheduledTimeReminderSettings,
		'id' | 'reminderTimeHour' | 'reminderTimeMinute' | 'reminderTimeMeridiem' | 'enabled'
	> {}

export interface IUpdateScheduledTimeReminderSettingsResponse extends HydraContext, IScheduledTimeReminderSettings {}

export interface MedHistoryParams {
	from: string;
	to: string;
}

interface MedHistorySummary {
	title: string;
	daysLogged: number;
	daysIncomplete: number;
}

export enum MedHistoryStatus {
	Incomplete = 'incomplete',
	Logged = 'logged',
}

export interface IMedHistoryDay {
	day: string;
	status: MedHistoryStatus;
	message: 'All Logged' | 'Incomplete';
}

export interface MedHistoryResponse {
	summary: MedHistorySummary;
	days: IMedHistoryDay[];
}
