import React from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';

const ChipContainer = styled(MuiChip)<{ component: React.ElementType }>(() => ({
	fontWeight: '700',
	textTransform: 'capitalize',
}));

export interface IBooleanChipProps extends Omit<ChipProps, 'label' | 'color'> {
	value: boolean;
	colorTrue?: ChipProps['color'];
	colorFalse?: ChipProps['color'];
	labelTrue?: string;
	labelFalse?: string;
}

function BooleanChip({
	value,
	colorTrue = 'success',
	colorFalse = 'default',
	labelTrue = 'Yes',
	labelFalse = 'No',
	size = 'small',
	...rest
}: IBooleanChipProps) {
	const booleanChipProps = value ? { color: colorTrue, label: labelTrue } : { color: colorFalse, label: labelFalse };

	return <ChipContainer component="span" size={size} {...booleanChipProps} {...rest} />;
}

export default BooleanChip;
