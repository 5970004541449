import { UserRole } from 'core/models/auth/auth.models';

interface IAPIResponse {
	'@context': string;
	'@id': string;
	'@type': string;
}

export interface IAddressPartsResponse extends IAPIResponse {
	shippingStreet: string;
	shippingCity: string;
	shippingStateCode: string;
	shippingPostalCode: string;
	billingStreet: string;
	billingCity: string;
	billingStateCode: string;
	billingPostalCode: string;
}

export interface ProfileInfoHomePage {
	unreadMessages: number;
	refillAlerts: number;
	exactPack: {
		orderId: number;
		period: string;
		completePercentage: number;
	};
	medsThisWeek: {
		day: string;
		status: string;
		message: string;
	}[];
}

export interface ProfileInfoMainMenu {
	homepageBadge: number;
	medicationsBadge: number;
	deliveriesBadge: number;
}

export interface IGetProfileInfoResponse extends IAPIResponse {
	requestedShippingAddress: string | null;
	requestedBillingAddress: string | null;
	phoneNumber: string | null;
	pendingPhoneNumber: string | null;
	changePhoneRequestId: number | null;
	email: string | null;
	emailValidated: boolean | null;
	shippingAddress: string | null;
	billingAddress: string | null;
	changeAddress: IAddressPartsResponse;
	balance: string | null;
	balanceFloat: number | null;
	balanceUpdatedAt: string | null;
	fullName: string | null;
	passwordExpired: boolean | null;
	roles: UserRole[] | null;
	timeZone: string | null;
	medicationRemindersEnabled: boolean | null;
	homepage: ProfileInfoHomePage | null;
	mainMenu: ProfileInfoMainMenu | null;
	vialsPatient: boolean | null;
}

export interface ISendChangePhoneRequest {
	phoneNumber: string | null;
}

export interface ISendChangePhoneRequestResponse extends IAPIResponse {
	id: number | null;
	phoneNumber: string | null;
}

export interface IDeleteChangePhoneRequest {
	id: number | null;
}

export interface ISuccessResponse {
	success: boolean;
}

export interface ISendCodeAgainRequest {
	id: number | null;
}

export interface ISendCodeAgainRequestResponse extends ISendChangePhoneRequestResponse {}

export interface IVerifyPhoneNumberRequest {
	phoneVerificationCode: string | null;
}

export interface IChangeEmailRequest {
	newEmail: string;
	password: string;
}

export interface IVerifyEmailChangedRequest {
	hash: string;
}

export interface IChangePasswordRequest {
	password: string;
	newPassword: string;
}

export interface IChangeAddressRequest {
	shippingStreet: string;
	shippingCity: string;
	shippingStateCode: string;
	shippingPostalCode: string;
	billingStreet: string;
	billingCity: string;
	billingStateCode: string;
	billingPostalCode: string;
}

export interface IChangeAddressResponse extends IAPIResponse {
	requestedShippingAddress: string | null;
	requestedBillingAddress: string | null;
}

export interface IChangeEmailResponse extends IAPIResponse {
	jwtToken: string;
}

export interface IMakeAPaymentResponse extends IAPIResponse {
	url: string;
}

export enum NotificationChannel {
	EMAIL = 2,
	PUSH = 3,
}

export interface NotificationSettings {
	categories: {
		id: number;
		title: string;
		description: string;
	}[];
	channels: {
		id: NotificationChannel;
		title: string;
	}[];
	values: {
		categoryId: number;
		channelId: NotificationChannel;
		enabled: boolean;
	}[];
	enrolledInSMS: boolean;
	contactMobilePhone: string;
}

export interface NotificationSettingProps {
	categoryId: number;
	channelId: NotificationChannel;
	enabled: boolean;
}
