import { Theme } from '@mui/material/styles';

export default {
	root: ({ theme }: { theme: Theme }) => ({
		'& .MuiFormLabel-root': {
			color: theme.palette.text.secondary,
			fontSize: '1.25rem',
			lineHeight: '1.625rem',
			fontWeight: 700,
			position: 'relative',
			transform: 'none',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.125rem',
				lineHeight: '1.375rem',
			},

			'&.Mui-focused, &.Mui-error': {
				color: theme.palette.text.secondary,
			},
		},

		'& .MuiInputBase-root': {
			border: `2px solid ${theme.palette.divider}`,
			borderRadius: '20px',
			padding: '8px 16px 8px 16px',
			margin: '6px 0 0 0',

			'&:before, &:after': {
				border: '0 !important',
			},

			'&.Mui-focused': {
				borderColor: theme.palette.grey[500],
			},

			'&.Mui-error': {
				borderColor: theme.palette.error.light,
			},
		},

		'& .MuiInputBase-input': {
			padding: 0,
			color: theme.palette.text.secondary,
		},

		'& .MuiIconButton-root': {
			top: 0,
			right: 0,
		},
	}),
};
