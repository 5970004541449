export const error = {
	100: '#FCE8EC',
	200: '#F9D0D9',
	300: '#F2A2B3',
	400: '#E95C7B',
	500: '#DF1642',
	600: '#9C0F2E',
	700: '#59091A',
	800: '#2D040D',
	900: '#160207',
};
