import { createBreakpoints } from '@mui/system';

export default createBreakpoints({
	values: {
		xs: 0,
		sm: 1000,
		md: 1200,
		lg: 1400,
		xl: 1440,
	},
});
