import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { TextFieldStatus } from 'components/common/inputs/textField/TextField';
import TwoFAField from 'components/common/inputs/TwoFAField/TwoFAField';
import FormatPhoneNumber from 'utils/formatPhoneNumber';
import { IAppError } from 'core/errors/AppError';

const schema = Joi.object({
	phoneVerificationCode: Joi.string().min(6).max(6).required().messages({
		'any.required': 'Verification code is a required field',
	}),
});

export interface IVerifyMobileNumberForm {
	phoneVerificationCode: string;
}

interface IVerifyMobileNumberProps {
	pendingPhoneNumber: string | null;
	verifyPhoneNumberIsLoading: boolean;
	sendCodeAgainIsLoading: boolean;
	verifyPhoneNumberError: IAppError | null;
	sendCodeAgainError: IAppError | null;
	showSuccessMessage: boolean;
	onVerifyMobileNumberAction: (data: IVerifyMobileNumberForm) => void;
	onSendCodeAgainAction: () => void;
	onUseAnotherPhoneNumberAction: () => void;
}

function VerifyMobileNumber({
	pendingPhoneNumber,
	verifyPhoneNumberIsLoading,
	sendCodeAgainIsLoading,
	verifyPhoneNumberError,
	showSuccessMessage,
	onVerifyMobileNumberAction,
	onSendCodeAgainAction,
	onUseAnotherPhoneNumberAction,
}: IVerifyMobileNumberProps) {
	const {
		control,
		handleSubmit,
		formState: { isValid },
		setValue,
	} = useForm<IVerifyMobileNumberForm>({ mode: 'onChange', resolver: joiResolver(schema) });

	const formattedNewPhoneNumber = FormatPhoneNumber(pendingPhoneNumber || '');
	const [attempts, setAttempts] = useState<number>(0);

	const handleVerifyMobileNumberActionFactory = (data: IVerifyMobileNumberForm) => {
		onVerifyMobileNumberAction(data);
	};

	const handleSendCodeAgainActionFactory = () => {
		onSendCodeAgainAction();
		setValue('phoneVerificationCode', '');
	};

	useEffect(() => {
		if (isValid && attempts < 1) {
			handleSubmit(handleVerifyMobileNumberActionFactory)();
		}
	}, [isValid]);

	useEffect(() => {
		if (verifyPhoneNumberError) {
			setAttempts(attempts + 1);
		}
	}, [verifyPhoneNumberError]);

	const successMessage = (
		<Box>
			<Typography component="p">
				{formattedNewPhoneNumber}
				<br />
				<Typography component="span" color="primary" fontWeight={700}>
					Verified
				</Typography>
			</Typography>
			<Typography component="p" mt={2} color={(theme) => theme.palette.success.main}>
				Thank you for updating your mobile number.
			</Typography>
		</Box>
	);

	return (
		<Box component="form" onSubmit={handleSubmit(handleVerifyMobileNumberActionFactory)}>
			{!showSuccessMessage && (
				<>
					<Typography component="p">
						<b>Your New Mobile Number is:</b> {formattedNewPhoneNumber}
						<br />
						<Typography component="span" color="error" fontWeight={700}>
							Not Verified
						</Typography>
					</Typography>
					<Box mt={2}>
						<Typography component="p">
							Enter the 6-digit verification code sent to you at {formattedNewPhoneNumber} to verify your new phone
							number.
						</Typography>
						<Box display="flex" justifyContent="space-between" mt={2} sx={{ gap: '1rem' }}>
							<Controller
								name="phoneVerificationCode"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TwoFAField
										{...field}
										status={error ? TextFieldStatus.ERROR : undefined}
										variant="outlined"
										size="small"
									/>
								)}
							/>
						</Box>
					</Box>

					<Box mt={2}>
						{attempts > 0 && (
							<Button
								disabled={!isValid && verifyPhoneNumberIsLoading}
								variant="contained"
								color="primary"
								fullWidth
								type="submit"
							>
								{verifyPhoneNumberIsLoading ? 'Loading...' : 'Continue'}
							</Button>
						)}
					</Box>

					<Box display="flex" mt={2} mb={2} sx={{ flexDirection: 'column' }}>
						<Button onClick={handleSendCodeAgainActionFactory} variant="text">
							{sendCodeAgainIsLoading ? 'Loading...' : 'Resend Code'}
						</Button>
						<Button onClick={onUseAnotherPhoneNumberAction} variant="text">
							Use another phone number
						</Button>
					</Box>
				</>
			)}
			{showSuccessMessage && successMessage}
		</Box>
	);
}

export default VerifyMobileNumber;
