import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import {
	ScheduleList,
	ScheduleItem,
	ScheduleDetails,
	ScheduleTitle,
	ScheduleTime,
	ScheduleActions,
} from 'components/scheduleList';

function ScheduleItemLoading() {
	return (
		<ScheduleItem>
			<Grid container>
				<Grid container item xs={12} md={5} direction="row" alignItems="flex-start" gap={2}>
					<Skeleton variant="circular" width={48} height={48} />
					<ScheduleDetails>
						<ScheduleTitle>
							<Skeleton width={200} />
						</ScheduleTitle>
						<ScheduleTime>
							<Skeleton width={160} />
						</ScheduleTime>
						<Typography variant="h3">
							<Skeleton width={160} />
						</Typography>
					</ScheduleDetails>
				</Grid>
				<Grid container item xs={12} md={7} justifyContent="flex-end">
					<ScheduleActions>
						<Typography variant="h3">
							<Skeleton width={160} />
						</Typography>
						<Typography variant="h3">
							<Skeleton width={160} />
						</Typography>
					</ScheduleActions>
				</Grid>
			</Grid>
		</ScheduleItem>
	);
}

export default function Loading() {
	return (
		<ScheduleList>
			{Array.from({ length: 3 }, (_, index) => (
				<ScheduleItemLoading key={`item-${index}`} />
			))}
		</ScheduleList>
	);
}
