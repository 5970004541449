import { HydraCollection } from '../api-service.models';

export enum InboxStatus {
	Inbox = 0,
	Archived = 1,
	Deleted = 2,
}

export interface InboxNotificationParams {
	id: number;
}

export interface UpdateInboxNotificationParams extends InboxNotificationParams {
	statusId: InboxStatus;
	readAt?: boolean | null;
}

export interface InboxNotificationListParams {
	page: number;
	itemsPerPage?: number;
	statusId?: InboxStatus | InboxStatus[];
	search?: string;
	exists?: { readAt?: boolean };
}

export interface InboxNotification {
	id: number;
	subject: string;
	preview: string;
	body: string;
	statusId: InboxStatus;
	readAt: string;
	createdAt: string;
}

export interface InboxNotificationListResponse extends HydraCollection<InboxNotification> {}
