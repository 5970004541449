import { queryOptions } from '@tanstack/react-query';
import {
	getAsNeededMeds,
	getMedHistory,
	getScheduledMeds,
	getScheduledTimeReminderSettingsN,
} from 'core/api/mySchedule/mySchedule.api';
import {
	IAsNeededMedication,
	IAsNeededMedsParams,
	IScheduledMedDetails,
	IScheduledMedsRequestParams,
	IScheduledTimeReminderSettings,
	MedHistoryParams,
} from 'core/api/mySchedule/mySchedule.models';
import dayjs from 'dayjs';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export const myScheduleQueries = {
	asNeededMedsList: ({ day }: IAsNeededMedsParams) =>
		queryOptions({
			queryKey: ['asNeededMed', 'list', day],
			queryFn: () => getAsNeededMeds({ day }),
			select: normalizeHydraCollection<IAsNeededMedication>,
		}),
	medHistory: (dateRange: MedHistoryParams) =>
		queryOptions({
			queryKey: ['schedule', 'history', dateRange],
			queryFn: () => getMedHistory(dateRange),
		}),
	scheduledMedsList: ({ day }: IScheduledMedsRequestParams) =>
		queryOptions({
			queryKey: ['schedule', 'list', day || dayjs().format('YYYY-MM-DD')],
			queryFn: () => getScheduledMeds({ day }),
			select: normalizeHydraCollection<IScheduledMedDetails>,
		}),
	timeReminderSettings: () =>
		queryOptions({
			queryKey: ['schedule', 'timeReminders'],
			queryFn: () => getScheduledTimeReminderSettingsN(),
			select: normalizeHydraCollection<IScheduledTimeReminderSettings>,
		}),
};
