import React from 'react';
import { Button, Box } from '@mui/material';

interface IFiltersBarProps {
	children: React.ReactNode;
	onClearFilters?: () => void;
}

export default function FiltersBar({ children, onClearFilters }: IFiltersBarProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '10px',
				paddingBottom: '10px',
				flexWrap: 'nowrap',
				justifyContent: 'space-evenly',
			}}
		>
			{children}
			{onClearFilters && <Button onClick={onClearFilters}>Clear filters</Button>}
		</Box>
	);
}
