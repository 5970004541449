import React, { createContext, useMemo, useContext } from 'react';
import { IProfileInfo } from 'core/models/userAccount/userAccount.models';
import { useAuth } from 'hooks';

interface IUserContext
	extends Partial<
		Pick<
			IProfileInfo,
			| 'roles'
			| 'email'
			| 'emailValidated'
			| 'fullName'
			| 'medicationRemindersEnabled'
			| 'balance'
			| 'balanceUpdatedAt'
			| 'homepage'
			| 'mainMenu'
			| 'passwordExpired'
			| 'phoneNumber'
			| 'pendingPhoneNumber'
			| 'changePhoneRequestId'
			| 'timeZone'
			| 'billingAddress'
			| 'shippingAddress'
			| 'requestedShippingAddress'
			| 'requestedBillingAddress'
			| 'addressParts'
			| 'vialsPatient'
		>
	> {
	isLoading: boolean;
}

const UserContext = createContext<IUserContext | undefined>(undefined);

type UserProviderProps = {
	children: React.ReactNode;
};

export default function UserProvider({ children }: UserProviderProps) {
	const { getAccountState } = useAuth();
	const { data, isLoading } = getAccountState();

	const {
		roles,
		email,
		emailValidated,
		balance,
		balanceUpdatedAt,
		fullName,
		medicationRemindersEnabled,
		mainMenu,
		homepage,
		passwordExpired,
		phoneNumber,
		pendingPhoneNumber,
		changePhoneRequestId,
		timeZone,
		billingAddress,
		shippingAddress,
		requestedShippingAddress,
		requestedBillingAddress,
		addressParts,
		vialsPatient,
	} = data ?? {};

	const contextValue = useMemo(
		() => ({
			roles,
			email,
			emailValidated,
			balance,
			balanceUpdatedAt,
			fullName,
			medicationRemindersEnabled,
			mainMenu,
			homepage,
			passwordExpired,
			billingAddress,
			shippingAddress,
			requestedShippingAddress,
			requestedBillingAddress,
			isLoading,
			phoneNumber,
			pendingPhoneNumber,
			changePhoneRequestId,
			timeZone,
			addressParts,
			vialsPatient,
		}),
		[
			roles,
			email,
			emailValidated,
			balance,
			balanceUpdatedAt,
			fullName,
			medicationRemindersEnabled,
			homepage,
			passwordExpired,
			billingAddress,
			shippingAddress,
			requestedShippingAddress,
			requestedBillingAddress,
			isLoading,
			phoneNumber,
			pendingPhoneNumber,
			changePhoneRequestId,
			timeZone,
			addressParts,
			vialsPatient,
		]
	);

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export const useUserContext = (): IUserContext => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserProvider');
	}
	return context;
};
