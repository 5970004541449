import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	userCreate,
	registerPhoneNumber as registerPhoneNumberApi,
	getVerifyIdentity,
	registerVerifyPhoneNumber,
	reset,
} from 'core/api/registration/registration.api';
import { ICheckStatusResponse } from 'core/api/registration/registration.models';

export function useRegisterMutation() {
	const queryClient = useQueryClient();

	const verifyIdentity = useMutation({
		mutationFn: getVerifyIdentity,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['register', 'initialData'] });
		},
	});

	const createUser = useMutation({
		mutationFn: userCreate,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['register', 'initialData'] });
		},
	});

	const registerPhoneNumber = useMutation({
		mutationFn: registerPhoneNumberApi,
		onSuccess: (data, variables) => {
			const initialData: ICheckStatusResponse | undefined = queryClient.getQueryData(['register', 'initialData']);

			if (initialData) {
				queryClient.setQueryData(['register', 'initialData'], { ...initialData, phoneNumber: variables.phoneNumber });
			}
		},
	});

	const verifyPhoneNumber = useMutation({
		mutationFn: registerVerifyPhoneNumber,
	});

	const resetRegister = useMutation({
		mutationFn: reset,
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['register', 'initialData'] });
		},
	});

	return {
		verifyIdentity,
		createUser,
		registerPhoneNumber,
		verifyPhoneNumber,
		resetRegister,
	};
}

export default useRegisterMutation;
