import React from 'react';
import { Button, ButtonProps, Link } from '@mui/material';
import Dialog from 'components/dialog';
import { useDisclosure } from 'hooks';
import { DeliveryTrackingAction, ITrackingButton } from 'core/models/delivery/delivery.models';
import eventService from 'services/eventService';

interface DeliveryTrackingButtonProps extends Omit<ButtonProps, 'children'> {
	trackingButton: ITrackingButton;
}

export function DeliveryTrackingButton({ trackingButton, ...buttonArgs }: DeliveryTrackingButtonProps) {
	const {
		isOpen: isOpenTrackingDialog,
		onOpen: onOpenTrackingDialog,
		onClose: onCloseTrackingDialog,
	} = useDisclosure();

	const deliveryTrackingLinkProps = {
		component: Link,
		href: trackingButton?.link || undefined,
		target: '_blank',
		onClick: () => {
			eventService.emitEvent({ category: 'button', action: 'deliveryTrackingButton' });
		},
	};

	const deliveryTrackingButtonProps = {
		onClick: () => {
			onOpenTrackingDialog();
			eventService.emitEvent({ category: 'button', action: 'deliveryTrackingButton' });
		},
	};

	return (
		<>
			<Button
				{...(trackingButton.action === DeliveryTrackingAction.LINK
					? deliveryTrackingLinkProps
					: deliveryTrackingButtonProps)}
				variant="contained"
				disabled={!trackingButton.action}
				{...buttonArgs}
			>
				{trackingButton.label}
			</Button>
			{trackingButton && trackingButton.action === DeliveryTrackingAction.LOCAL_DELIVERY && (
				<Dialog maxWidth="xs" title={trackingButton?.label} open={isOpenTrackingDialog} onClose={onCloseTrackingDialog}>
					Your medication will be delivered by a local courier. Please call ExactCare if you have questions about your
					delivery status. <br />
					1-877-355-7225
				</Dialog>
			)}
		</>
	);
}

export default DeliveryTrackingButton;
