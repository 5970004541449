import React from 'react';
import { Typography, Breadcrumbs as MuiBreadcrumbs, Link } from '@mui/material';
import { UIMatch, useMatches, Link as RouterLink } from 'react-router-dom';

export function Breadcrumbs() {
	const matches = useMatches() as UIMatch<
		unknown,
		{
			crumb: (data: unknown) => {
				name: string;
				to?: string;
			};
		}
	>[];

	const crumbs = matches
		.filter((match) => Boolean(match.handle?.crumb))
		.map((match) => match.handle?.crumb(match.data));

	return (
		<MuiBreadcrumbs>
			{crumbs.map((crumb) =>
				crumb?.to ? (
					<Link
						key={crumb.name}
						underline="hover"
						color="inherit"
						component={RouterLink}
						to={crumb.to}
						variant="caption"
					>
						{crumb.name}
					</Link>
				) : (
					<Typography key={crumb.name} color="text.secondary" variant="caption">
						{crumb.name}
					</Typography>
				)
			)}
		</MuiBreadcrumbs>
	);
}

export default Breadcrumbs;
