import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMedsProcessingJobs } from 'core/api/admin/patients.api';

export function useMedsProcessingJobsCreateMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['admin', 'patient', 'createMedsProcessingJobs'],
		mutationFn: createMedsProcessingJobs,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patient', 'view', variables.patientId, 'medsProcessingJobs'],
			});
		},
	});
}

export default useMedsProcessingJobsCreateMutation;
