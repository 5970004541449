import React from 'react';
import {
	ListItem,
	ListItemButton,
	Pagination,
	PaginationProps as MuiPaginationProps,
	Stack,
	styled,
	Chip,
} from '@mui/material';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { useInboxNotificationsQuery } from 'hooks';
import { InboxNotificationListParams } from 'core/api/inboxNotification';
import relativeDateFormat from 'utils/relativeDateFormat';
import routes from 'routes';
import CircularLoading from 'components/CircularLoading';
import MessageSummary from '../MessageSummary';

const NoMessages = styled(ListItem)(({ theme }) => ({
	alignItems: 'stretch',
	gap: '15px',
	borderTop: '1px solid',
	borderColor: theme.palette.divider,
}));

const MessageItemButton = styled(ListItemButton)<{ component: React.ElementType; to?: string }>(({ theme }) => ({
	alignItems: 'stretch',
	gap: '15px',
	padding: '12px 16px',
	borderTop: '1px solid',
	borderColor: theme.palette.divider,
}));

const MessageTime = styled(Stack)(({ theme }) => ({
	color: theme.palette.grey[600],
	fontWeight: 400,
	fontSize: theme.typography.body1.fontSize,
	lineHeight: theme.typography.body1.lineHeight,
	textAlign: 'left',
	justifyContent: 'center',
}));

interface InboxMessagesListProps extends InboxNotificationListParams {
	PaginationProps?: MuiPaginationProps;
}

export function InboxMessagesList({ PaginationProps, ...request }: InboxMessagesListProps) {
	const { data, isLoading } = useInboxNotificationsQuery(request);
	const { items, totalItems, pagination } = data ?? {};

	if (isLoading) {
		return <CircularLoading />;
	}

	if (items && totalItems) {
		return (
			<Stack gap={2} sx={{ background: (theme) => theme.palette.background.default, paddingBottom: '24px' }}>
				<Stack>
					{items.map(({ id, readAt, subject, preview, createdAt }) => (
						<MessageItemButton
							component={RouterLink}
							key={id}
							to={generatePath(routes.inbox.item, {
								messageId: id,
							})}
						>
							<MessageSummary primary={subject} secondary={preview} />
							<Stack gap={1}>
								<MessageTime>{relativeDateFormat(createdAt)}</MessageTime>
								{!readAt && <Chip label="New" color="primary" size="small" />}
								<Chip label="New" color="primary" size="small" />
							</Stack>
						</MessageItemButton>
					))}
				</Stack>
				{pagination?.lastPage && (
					<Pagination shape="rounded" {...PaginationProps} count={pagination.lastPage} page={request.page} />
				)}
			</Stack>
		);
	}

	return <NoMessages>You have no messages at this time.</NoMessages>;
}

export default InboxMessagesList;
