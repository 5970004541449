import React, { useEffect, useState, useRef } from 'react';
import { Alert, Button, Box, Typography } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import IMask from 'imask';
import TwoFAField from 'components/common/inputs/TwoFAField';
import { TextFieldStatus } from 'components/common/inputs/textField/TextField';
import { PHONE_NUMBER_MASK_OPTIONS } from 'components/common/inputs/textField/TextFieldMask';
import {
	ICheckStatusResponse,
	IRegisterVerifyPhoneNumberRequestParams,
	IRegisterVerifyPhoneNumberResponse,
} from 'core/api/registration/registration.models';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';

const schema = Joi.object({
	phoneVerificationCode: Joi.string().min(6).max(6).required().messages({
		'any.required': 'Verification code is a required field',
	}),
});

export interface RegisterVerifyPhoneCodeProps extends Partial<ICheckStatusResponse> {
	hash: string;
	onSuccess: (props: IRegisterVerifyPhoneNumberResponse) => void;
	onBack: () => void;
}

export function RegisterVerifyPhoneCode({ hash, phoneNumber, onSuccess, onBack }: RegisterVerifyPhoneCodeProps) {
	const { registerPhoneNumber, verifyPhoneNumber } = useRegisterMutation();

	const handleSendCodeAgain = () => registerPhoneNumber.mutate({ phoneNumber: phoneNumber ?? '', hash });

	const formRef = useRef<HTMLFormElement>(null);
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<IRegisterVerifyPhoneNumberRequestParams>({ mode: 'onChange', resolver: joiResolver(schema) });

	const [attempts, setAttempts] = useState<number>(0);

	const phoneNumberMask = IMask.createMask(PHONE_NUMBER_MASK_OPTIONS);
	phoneNumberMask.resolve(phoneNumber || '');
	const formattedPhoneNumber = phoneNumberMask.value;

	const handleActionFactory = ({ phoneVerificationCode }: IRegisterVerifyPhoneNumberRequestParams) =>
		verifyPhoneNumber.mutate(
			{ phoneVerificationCode, hash },
			{
				onSuccess,
			}
		);

	useEffect(() => {
		if (verifyPhoneNumber.isError) {
			setAttempts(attempts + 1);
		}
	}, [verifyPhoneNumber.isError]);

	useEffect(() => {
		if (isValid && attempts < 1) {
			handleSubmit(handleActionFactory)();
		}
	}, [isValid]);

	return (
		<Box ref={formRef} component="form" onSubmit={handleSubmit(handleActionFactory)}>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Finish Signing Up
			</Typography>

			<Box mt={5}>
				<Typography variant="input2" fontWeight={700}>
					<>Enter the 6-digit code sent to you at {formattedPhoneNumber}.</>
				</Typography>
				<Box
					display="flex"
					justifyContent="space-between"
					mt={1}
					sx={{ width: { xs: '100%', sm: '50%' }, gap: '1rem' }}
				>
					<Controller
						name="phoneVerificationCode"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<TwoFAField
								{...field}
								status={error ? TextFieldStatus.ERROR : undefined}
								variant="outlined"
								size="small"
							/>
						)}
					/>
				</Box>
			</Box>

			{verifyPhoneNumber.error?.message && (
				<Alert sx={{ mt: 2 }} severity="error">
					{verifyPhoneNumber.error.message}
				</Alert>
			)}
			{registerPhoneNumber.error?.message && (
				<Alert sx={{ mt: 2 }} severity="error">
					{registerPhoneNumber.error.message}
				</Alert>
			)}

			<Box mt={5} sx={{ width: { xs: '100%', sm: '50%' } }}>
				{attempts > 0 && (
					<Button
						disabled={!isValid && verifyPhoneNumber.isPending}
						variant="outlined"
						color="primary"
						fullWidth
						type="submit"
					>
						{verifyPhoneNumber.isPending ? 'Loading...' : 'Complete Your Sign Up'}
					</Button>
				)}
			</Box>

			<Box display="flex" mt={5} mb={2} sx={{ width: { xs: '100%', sm: '50%' }, flexDirection: 'column' }}>
				<Typography variant="input2" fontWeight={700}>
					Didn‘t receive the code?
				</Typography>
				<Button
					onClick={handleSendCodeAgain}
					variant="text"
					sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}
				>
					{registerPhoneNumber.isPending ? 'Loading...' : 'Send code again'}
				</Button>
				<Button onClick={onBack} variant="text" sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}>
					Change phone number
				</Button>
			</Box>
		</Box>
	);
}

export default RegisterVerifyPhoneCode;
