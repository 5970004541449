import { endpoints } from 'core/api/endpoints';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'core/api/api-service';
import { IAdminsList, ICreateAdminsResponse, ICreateAdminsRequestActionPayload } from 'core/models/admin/admins.models';
import {
	IAdminsListRequest,
	IAdminsListResponse,
	IChangePasswordAdminRequest,
	IDeleteAdminRequest,
	IUpdateAdminRequest,
} from './admins.models';

function mapAdminsList(result: IAdminsListResponse): IAdminsList {
	const admins = result['hydra:member'].map((admin) => {
		return {
			id: admin.id,
			email: admin.email,
			phoneNumber: admin.phoneNumber,
			createdAt: admin.createdAt,
			updatedAt: admin.updatedAt,
		};
	});

	const total = result['hydra:totalItems'];

	return { admins, total };
}

export async function getAdminsList(request: IAdminsListRequest): Promise<IAdminsList> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		search: request?.search || '',
	};

	const result = await getRequest<IAdminsListResponse>(endpoints.admin.admins.list, body, {});

	return mapAdminsList(result);
}

export async function getAdminsListN(request: IAdminsListRequest): Promise<IAdminsListResponse> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		search: request?.search || '',
	};

	return getRequest<IAdminsListResponse>(endpoints.admin.admins.list, body, {});
}

export function createAdminsRequest(request: ICreateAdminsRequestActionPayload): Promise<ICreateAdminsResponse> {
	const body = {
		email: request.email,
		password: request.password,
		phoneNumber: request.phoneNumber,
	};
	return postRequest<ICreateAdminsResponse>(endpoints.admin.admins.create, body, {});
}

export async function deleteAdmin(request: IDeleteAdminRequest): Promise<void> {
	await deleteRequest<void>(endpoints.admin.admins.delete(request.userId), {}, {});
}

export async function changePassword(request: IChangePasswordAdminRequest): Promise<void> {
	const body = {
		password: request.newPassword,
	};
	await patchRequest<void>(endpoints.admin.admins.changePassword(request.userId), body, {});
}

export async function updateAdmin(request: IUpdateAdminRequest): Promise<void> {
	const body = {
		email: request.email,
		phoneNumber: request.phoneNumber,
	};
	await patchRequest<void>(endpoints.admin.admins.update(request.userId), body, {});
}
