import React from 'react';
import InfoCardWidget from 'components/InfoCardWidget';
import { Typography, Link } from '@mui/material';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import routes from 'routes';
import { usePatientDetailsQuery } from 'hooks/enterprise';
import { Paper } from 'components/dashboardLayout';

interface RefillAlertsWidgetProps {
	patientId?: string;
}

export function RefillAlertsWidget({ patientId }: RefillAlertsWidgetProps) {
	const cardTitle = 'Refill Alerts';

	const { data } = usePatientDetailsQuery({ id: patientId });
	const refillAlerts = data?.homepage?.refillAlerts || 0;
	const link = generatePath(routes.enterprise.patients.patient.medications, {
		patientId,
	});

	if (refillAlerts > 0) {
		return (
			<Link to={link} component={RouterLink} sx={{ textDecoration: 'none' }}>
				<Paper>
					<InfoCardWidget
						title={cardTitle}
						icon={<ErrorOutlineOutlinedIcon color="error" />}
						sx={{
							marginTop: 0,
							marginBottom: 0,
						}}
					>
						<Typography variant="body1" color="error.main">
							Action Required
						</Typography>
					</InfoCardWidget>
				</Paper>
			</Link>
		);
	}

	return (
		<Link to={link} component={RouterLink} sx={{ textDecoration: 'none' }}>
			<Paper>
				<InfoCardWidget
					title={cardTitle}
					sx={{
						marginTop: 0,
						marginBottom: 0,
					}}
				>
					<Typography variant="body1" color="success.main">
						None
					</Typography>
				</InfoCardWidget>
			</Paper>
		</Link>
	);
}

export default RefillAlertsWidget;
