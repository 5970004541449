import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import Switch from 'components/common/inputs/switch/Switch';
import TimePicker from 'components/common/inputs/TimePicker';
import { IScheduledTimeReminderSettings } from 'core/api/mySchedule/mySchedule.models';

interface ReminderSettingsProps {
	reminders?: IScheduledTimeReminderSettings[];
	onChangeReminder?: (reminder: Partial<IScheduledTimeReminderSettings>) => void;
}

export function ReminderSettings({ reminders, onChangeReminder }: ReminderSettingsProps) {
	const convertToDateTime = (hours: number, minutes: number, meridiem: 'AM' | 'PM'): Dayjs => {
		let adjustedHours = hours;

		adjustedHours = Math.max(1, Math.min(12, adjustedHours));

		if (meridiem === 'PM' && hours < 12) {
			adjustedHours += 12;
		} else if (meridiem === 'AM' && hours === 12) {
			adjustedHours = 0;
		}

		return dayjs().hour(adjustedHours).minute(minutes).second(0);
	};

	const getReminderInterval = ({
		scheduledTimeHour,
		scheduledTimeMinute,
		scheduledTimeMeridiem,
	}: IScheduledTimeReminderSettings) =>
		convertToDateTime(scheduledTimeHour ?? 0, scheduledTimeMinute ?? 0, scheduledTimeMeridiem).format('hh:mm A');

	const handleChangeEnabled = (reminder: IScheduledTimeReminderSettings, isEnabled: boolean) => {
		if (onChangeReminder) {
			onChangeReminder({
				...reminder,
				enabled: isEnabled,
			});
		}
	};

	const handleTimeChange = (reminder: IScheduledTimeReminderSettings) => (newTime: Dayjs | null) => {
		if (newTime) {
			let hours = newTime.hour();
			const minutes = newTime.minute();
			const meridiem = hours >= 12 ? 'PM' : 'AM';
			hours %= 12;
			hours = hours === 0 ? 12 : hours;

			if (onChangeReminder) {
				onChangeReminder({
					...reminder,
					reminderTimeHour: hours,
					reminderTimeMinute: minutes,
					reminderTimeMeridiem: meridiem,
				});
			}
		}
	};

	return (
		<List
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '12px',
			}}
		>
			{reminders &&
				reminders.map((reminder) => (
					<ListItem
						key={getReminderInterval(reminder)}
						sx={{
							padding: '15px 24px',
							gap: '24px',
							borderRadius: '10px',
							background: (theme) => theme.palette.background.secondary,
						}}
					>
						<ListItemText
							primary={
								<Typography
									fontWeight={700}
									sx={{
										fontSize: 20,
										lineHeight: '26px',
									}}
									component="b"
								>
									{reminder.scheduleTimeOfDayName}
								</Typography>
							}
							secondary={
								<Typography
									sx={{
										fontSize: 16,
										lineHeight: '24px',
									}}
								>
									Prescribed Time to Take: {getReminderInterval(reminder)}
								</Typography>
							}
						/>
						{!!reminder.enabled && (
							<TimePicker
								label="Current Reminder Time"
								value={convertToDateTime(
									reminder.reminderTimeHour ?? 0,
									reminder.reminderTimeMinute ?? 0,
									reminder.reminderTimeMeridiem
								)}
								onChange={handleTimeChange(reminder)}
								slotProps={{
									textField: {
										size: 'small',
									},
									field: {
										readOnly: true,
									},
									desktopPaper: {
										elevation: 3,
										sx: {
											background: (theme) => theme.palette.background.default,
											border: '2px solid',
											borderColor: 'primary.main',
										},
									},
								}}
							/>
						)}
						<Switch
							labelOn="On"
							labelOff="Off"
							checked={reminder.enabled || false}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								handleChangeEnabled(reminder, event.target.checked)
							}
						/>
					</ListItem>
				))}
		</List>
	);
}

export default ReminderSettings;
