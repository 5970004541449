import { Theme } from '@mui/material/styles';

export default {
	root: ({ theme }: { theme: Theme }) => ({
		borderRadius: 100,
		fontWeight: 700,
		fontSize: '1rem',
		textTransform: 'none',
		...theme.typography.button1,

		'&.MuiButton-outlinedInherit': {
			boxShadow: '0px 0px 4px 0px rgba(117, 117, 117, 0.6)',
			borderColor: 'transparent',
		},
	}),
	text: () => ({
		textTransform: 'inherit',
		margin: 0,
		padding: 0,
		textDecoration: 'underline',
		textDecorationColor: 'rgba(34, 34, 34, 0.4)',
		justifyContent: 'center',
		verticalAlign: 'baseline',
		minWidth: 'inherit',
		width: 'fit-content',
		':hover': {
			backgroundColor: 'transparent',
			textDecoration: 'underline',
			opacity: 1,
		},
		'& .MuiTouchRipple-root': {
			display: 'none',
		},
	}),
	outlined: ({ theme }: { theme: Theme }) => ({
		background: theme.palette.background.default,
	}),
};
