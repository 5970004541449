import React, { useState } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

export interface IDatePickerProps extends MuiDatePickerProps<Dayjs> {
	label?: string;
	hideDatePickerHeader?: boolean;
}

const DatePicker = React.forwardRef<HTMLInputElement, IDatePickerProps>(({ ...rest }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const existingTheme = useTheme();
	const newTheme = createTheme({
		...existingTheme,
		components: {
			...existingTheme.components,
			MuiTextField: {
				styleOverrides: {
					root: {
						'& .MuiInputBase-root': {
							background: existingTheme.palette.background.default,
						},
					},
				},
			},
			MuiPopper: {
				defaultProps: {
					sx: {
						'& .MuiPickersCalendarHeader-root': {
							display: rest?.hideDatePickerHeader ? 'none' : 'flex',
						},
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={newTheme}>
			<MuiDatePicker
				open={isOpen}
				onClose={() => setIsOpen(false)}
				ref={ref}
				{...rest}
				slotProps={{
					...rest?.slotProps,
					textField: {
						onClick: () => {
							if (typeof rest.onOpen === 'function') {
								rest.onOpen();
							}
							setIsOpen(true);
						},
						InputProps: {
							endAdornment: null,
						},
						...rest?.slotProps?.textField,
					},
				}}
			/>
		</ThemeProvider>
	);
});

export default DatePicker;
