import { useMutation, useQuery } from '@tanstack/react-query';
import { IGetDeliveriesSummaryRequestParams } from 'core/api/delivery/delivery.models';
import { requestRefill } from 'core/api/medications/medications.api';
import { medicationQueries } from 'queries/patient/medicationQueries';

export function useMedications() {
	const getMedicationsQuery = () => {
		return useQuery(medicationQueries.getMedications());
	};

	const getMedicationQuery = (props: Partial<IGetDeliveriesSummaryRequestParams>) => {
		return useQuery(medicationQueries.getMedication(props));
	};

	const requestRefillMutation = useMutation({
		mutationFn: requestRefill,
	});

	return {
		getMedicationsQuery,
		getMedicationQuery,
		requestRefillMutation,
	};
}

export default useMedications;
