import React from 'react';
import { Button, Divider, IconButton, List, ListItem, Stack, TextField, Typography } from '@mui/material';
import ConditionInputFilter from 'components/common/inputs/ConditionInputFilter';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CreatePatientFilterParams, PatientFilter, PatientFilterQuery } from 'core/api/enterprise/patient.models';
import { useCreatePatientFilterMutation, usePatientFilterSchema } from 'hooks';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import dayjs from 'dayjs';
import { filterPatientQuery } from 'core/api/enterprise/patient.api';
import { withValidation } from 'hoc';

interface PatientListFiltersProps extends Partial<PatientFilterQuery> {
	onFilter: (params: Partial<PatientFilter>) => void;
	onSaveFilter: (params: Partial<PatientFilter>) => void;
	onClose: () => void;
}

export function PatientListFilters({ onFilter, onSaveFilter, onClose, ...restProps }: PatientListFiltersProps) {
	const { mutate: mutateCreatePatientFilter, isPending } = useCreatePatientFilterMutation();

	const filterCriterion = [
		{
			value: 'eq',
			label: 'Equals',
		},
		{
			value: 'neq',
			label: 'Not Equals',
		},
		{
			value: 'startswith',
			label: 'Starts With',
		},
		{
			value: 'endswith',
			label: 'Ends With',
		},
		{
			value: 'contains',
			label: 'Contains',
		},
	];

	const medsWithoutRefillsFilterCriterion = [
		{
			value: 'eq',
			label: 'Equals',
		},
		{
			value: 'neq',
			label: 'Not Equals',
		},
		{
			value: 'gt',
			label: 'Greater Than',
		},
		{
			value: 'lt',
			label: 'Less Than',
		},
	];

	const dobFilterCriterion = [
		{
			value: 'eq',
			label: 'Equals',
		},
		{
			value: 'gte',
			label: 'Greater Than or Equals',
		},
		{
			value: 'lte',
			label: 'Less Than or Equals',
		},
	];

	const schema = usePatientFilterSchema();

	const formMethods = useForm<Partial<CreatePatientFilterParams>>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: filterPatientQuery(restProps as PatientFilterQuery),
	});
	const { handleSubmit, getValues, setError, control, reset, clearErrors } = formMethods;

	const handleClickFilter = handleSubmit(onFilter);

	const handleClickSaveFilter = () => {
		const values = getValues();
		const isValidName = values?.name && values.name.length > 0;

		if (!isValidName) {
			setError('name', {
				type: 'custom',
				message: 'Name is required.',
			});
			return;
		}

		clearErrors('name');

		handleSubmit((data) => {
			mutateCreatePatientFilter(data as CreatePatientFilterParams, {
				onSuccess: (filterData) => {
					onSaveFilter(filterData);
				},
			});
		})();
	};

	const handleClickResetForm = () => {
		reset({}, { keepIsSubmitted: false, keepSubmitCount: false });
	};

	const ValidatedConditionInputFilter = withValidation(ConditionInputFilter);
	const ValidatedTextField = withValidation(TextField);

	return (
		<FormProvider {...formMethods}>
			<List
				subheader={
					<Stack direction="row" alignItems="center" justifyContent="space-between" p="16px 8px 8px 20px">
						<Typography variant="h6" sx={{ flexGrow: 1 }}>
							Filters
						</Typography>
						<IconButton onClick={handleClickResetForm} aria-label="Reset">
							<RefreshIcon />
						</IconButton>
						<IconButton onClick={onClose} aria-label="Close">
							<CloseIcon />
						</IconButton>
					</Stack>
				}
			>
				<ListItem>
					<ValidatedConditionInputFilter name="locationName.operator" label="Location" conditions={filterCriterion}>
						<ValidatedTextField name="locationName.value" variant="outlined" size="small" fullWidth />
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem>
					<ValidatedConditionInputFilter name="territory.operator" label="Territory" conditions={filterCriterion}>
						<ValidatedTextField name="territory.value" variant="outlined" size="small" fullWidth />
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem>
					<ValidatedConditionInputFilter name="state.operator" label="State" conditions={filterCriterion}>
						<ValidatedTextField name="state.value" variant="outlined" size="small" fullWidth />
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem>
					<ValidatedConditionInputFilter name="status.operator" label="Status" conditions={filterCriterion}>
						<ValidatedTextField name="status.value" variant="outlined" size="small" fullWidth />
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem>
					<ValidatedConditionInputFilter
						name="onboardingStatus.operator"
						label="Onboarding Status"
						conditions={filterCriterion}
					>
						<ValidatedTextField name="onboardingStatus.value" variant="outlined" size="small" fullWidth />
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem>
					<ValidatedConditionInputFilter name="postalCode.operator" label="Postal Code" conditions={filterCriterion}>
						<ValidatedTextField name="postalCode.value" variant="outlined" size="small" fullWidth />
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem>
					<ValidatedConditionInputFilter
						name="medsWithoutRefills.operator"
						label="Meds Without Refills"
						conditions={medsWithoutRefillsFilterCriterion}
					>
						<ValidatedTextField name="medsWithoutRefills.value" variant="outlined" size="small" fullWidth />
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem>
					<ValidatedConditionInputFilter
						name="dateOfBirth.operator"
						label="Date Of Birth"
						conditions={dobFilterCriterion}
					>
						<Controller
							name="dateOfBirth.value"
							control={control}
							render={({ field: { onChange, value, ...fieldProps } }) => (
								<DatePicker
									views={['year', 'month', 'day']}
									value={value ? dayjs(value) : null}
									onChange={(newValue) => {
										onChange(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
									}}
									slotProps={{ textField: { size: 'small' } }}
									{...fieldProps}
								/>
							)}
						/>
					</ValidatedConditionInputFilter>
				</ListItem>
				<ListItem sx={{ mt: 2 }}>
					<Button onClick={handleClickFilter} variant="contained" fullWidth>
						Filter
					</Button>
				</ListItem>
				<Divider>OR</Divider>
				<ListItem sx={{ justifyContent: 'center' }}>
					<Stack gap={2} direction="row">
						<ValidatedTextField name="name" label="Filter name" variant="outlined" size="small" sx={{ flex: 1 }} />
						<Button onClick={handleClickSaveFilter} size="small" variant="text" disabled={isPending}>
							Save & Filter
						</Button>
					</Stack>
				</ListItem>
			</List>
		</FormProvider>
	);
}

export default PatientListFilters;
