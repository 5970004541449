import { Theme } from '@mui/material/styles';

export default {
	tooltip: ({ theme }: { theme: Theme }) => ({
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.secondary,
		boxShadow: theme.shadows[3],
		borderRadius: 4,
	}),
	arrow: ({ theme }: { theme: Theme }) => ({
		color: theme.palette.background.default,
	}),
};
