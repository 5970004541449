import React from 'react';
import { Box, Typography } from '@mui/material';
import { IAsNeededMedication } from 'core/api/mySchedule/mySchedule.models';

export function AsNeededMedDirections({ howToUse }: IAsNeededMedication) {
	return (
		<Box pb={2}>
			<Typography component="b" fontWeight={700}>
				How to use
			</Typography>
			<Typography>{howToUse}</Typography>
		</Box>
	);
}

export default AsNeededMedDirections;
