import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function MailIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 26 20" {...props}>
			<path
				d="M2.58344 20C2.02094 20 1.53302 19.7934 1.11969 19.3803C0.706563 18.967 0.5 18.4791 0.5 17.9166V2.08344C0.5 1.52094 0.706563 1.03302 1.11969 0.619688C1.53302 0.206563 2.02094 0 2.58344 0H23.4166C23.9791 0 24.467 0.206563 24.8803 0.619688C25.2934 1.03302 25.5 1.52094 25.5 2.08344V17.9166C25.5 18.4791 25.2934 18.967 24.8803 19.3803C24.467 19.7934 23.9791 20 23.4166 20H2.58344ZM13 10.7916L2.58344 4.0625V17.9166H23.4166V4.0625L13 10.7916ZM13 8.70844L23.3334 2.08344H2.6875L13 8.70844ZM2.58344 4.0625V2.08344V17.9166V4.0625Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
