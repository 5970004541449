import { matchPath, PathPattern } from 'react-router-dom';

type Pattern = string | PathPattern;

const isActiveRoute = (pathname: string, patterns?: Pattern[]): boolean => {
	if (!patterns) return false;

	return patterns.some((pattern) => matchPath(pattern, pathname) !== null);
};

export default isActiveRoute;
