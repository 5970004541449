import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ReviewMyHistoryIcon(props: SvgIconProps) {
	return (
		<SvgIcon sx={{ fill: 'none', color: 'text.secondary' }} viewBox="0 0 46 46" {...props}>
			<g id="Group">
				<path
					id="Vector"
					d="M12.5 22.1H6.80005"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_2"
					d="M14.45 16.55H6.80005"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_3"
					d="M13.55 27.9501H6.80005"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_4"
					d="M16.4 33.65H6.80005"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_5"
					d="M10.7 2V9.65C10.7 10.25 10.25 10.55 9.79998 10.55H1.84998"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_6"
					d="M35.6 38.3V44C35.6 44.45 35.15 44.9 34.7 44.9H2.15C1.55 44.9 1.25 44.45 1.25 44V10.85L10.85 1.40002H34.7C35.3 1.40002 35.6 1.85002 35.6 2.30002V8.00002"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_7"
					d="M44.9 39.2L37.4 31.85"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_8"
					d="M41.15 23.1501C41.15 30.0501 35.6 35.6001 28.7 35.6001C21.8 35.6001 16.25 30.0501 16.25 23.1501C16.25 16.2501 21.8 10.7001 28.7 10.7001C35.6 10.7001 41.15 16.2501 41.15 23.1501Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_9"
					d="M32.45 19.25C32.45 21.35 30.8 23 28.7 23C26.6 23 24.95 21.35 24.95 19.25C24.95 17.15 26.6 15.5 28.7 15.5C30.8 15.5 32.45 17.3 32.45 19.25Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_10"
					d="M35.6 28.85C35.6 25.55 32.6 24.05 29 24.05C25.4 24.05 22.4 25.7 22.4 28.85"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</SvgIcon>
	);
}
