import React, { useEffect } from 'react';
import { PageContextType, usePageContext } from 'contexts/PageProvider';

export const withPageContext = <P extends object>(Component: React.ComponentType<P>) => {
	return function WrappedComponent({ pageTitle, ...props }: Partial<PageContextType>) {
		const { setPageTitle } = usePageContext();

		useEffect(() => {
			if (pageTitle) {
				setPageTitle(pageTitle);
			}
		}, [pageTitle]);

		return <Component {...(props as P)} />;
	};
};

export default withPageContext;
