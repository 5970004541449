import React from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IAsNeededMedication } from 'core/api/mySchedule/mySchedule.models';

interface AsNeededMedTakeProps extends IAsNeededMedication {
	onCancel: () => void;
	onRecordTaken: (asNeededMedication: IAsNeededMedication) => void;
}

export function AsNeededMedTake({ onCancel, onRecordTaken, ...asNeededMedication }: AsNeededMedTakeProps) {
	return (
		<>
			<Box pb={2}>
				<Typography component="b" fontWeight={700}>
					Last Reported Dose:
				</Typography>{' '}
				{asNeededMedication.lastTakenAt}
			</Box>
			<Box pb={2}>
				<Typography component="b" fontWeight={700}>
					<ErrorOutlineIcon sx={{ verticalAlign: 'text-bottom' }} fontSize="small" color="warning" /> Less than 1 hour
					since your last reported dose.
				</Typography>
			</Box>
			<Stack spacing={2} direction="row">
				<Button
					onClick={() => {
						onRecordTaken(asNeededMedication);
						onCancel();
					}}
					size="small"
					variant="contained"
					color="primary"
				>
					Take
				</Button>
				<Button onClick={onCancel} size="small" variant="outlined" color="primary">
					Cancel
				</Button>
			</Stack>
		</>
	);
}

export default AsNeededMedTake;
