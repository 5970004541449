import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

interface ISearchFilterProps {
	sx?: SystemStyleObject;
	name: string;
	label: string;
	value?: string;
	onChange?: (value: string) => void;
}

export default function SearchFilter({ sx, name, label, value, onChange }: ISearchFilterProps) {
	const [searchValue, setSearchValue] = useState<string>(value || '');

	useEffect(() => {
		if (searchValue && searchValue.length >= 3) {
			onChange?.(searchValue);
		}
	}, [searchValue]);

	useEffect(() => {
		if (!value) {
			setSearchValue('');
		}
	}, [value]);

	return (
		<TextField
			label={label}
			variant="outlined"
			sx={sx}
			size="small"
			value={searchValue}
			onChange={(event) => setSearchValue(event.target.value)}
			InputProps={{
				name,
				startAdornment: (
					<InputAdornment position="start">
						<SearchOutlinedIcon />
					</InputAdornment>
				),
			}}
		/>
	);
}
