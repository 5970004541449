import React, { Suspense, ReactNode } from 'react';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface IDialogProps extends Omit<MuiDialogProps, 'title'> {
	title?: string | React.ReactNode;
	children: ReactNode;
	useSuspense?: boolean;
	suspenseFallback?: React.ReactNode;
	onClose?: () => void;
}
function Dialog({ title, children, onClose, useSuspense = false, suspenseFallback = null, ...rest }: IDialogProps) {
	const dialogContent = useSuspense ? <Suspense fallback={suspenseFallback}>{children}</Suspense> : children;

	return (
		<MuiDialog onClose={onClose} {...rest}>
			<IconButton
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			{title && (
				<DialogTitle fontWeight={700} color="text.secondary" variant="h1">
					{title}
				</DialogTitle>
			)}
			<DialogContent sx={!title ? { pt: '24px' } : undefined}>{dialogContent}</DialogContent>
		</MuiDialog>
	);
}

export default Dialog;
