import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import isActiveRoute from 'utils/isActiveRoute';
import { LoaderFunctionArgs, useLocation, Link as RouterLink, generatePath, useParams } from 'react-router-dom';
import routes from 'routes';
import { QueryClient } from '@tanstack/react-query';
import { enterprisePatientQueries } from 'queries/enterprise';

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		const { patientId } = params;
		const query = enterprisePatientQueries.patient({ id: patientId });
		return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
	};

interface EnterprisePatientLayoutProps {
	children: React.ReactNode;
}

export default function EnterprisePatientLayout({ children }: EnterprisePatientLayoutProps) {
	const location = useLocation();
	const { patientId } = useParams();

	const navItems: { to: string; activeRoutes: string[]; label: string }[] = [
		{
			to: generatePath(routes.enterprise.patients.patient.profile, {
				patientId,
			}),
			activeRoutes: [routes.enterprise.patients.patient.profile],
			label: 'Profile',
		},
		{
			to: generatePath(routes.enterprise.patients.patient.inbox, {
				patientId,
			}),
			activeRoutes: [routes.enterprise.patients.patient.inbox],
			label: 'Inbox',
		},
		{
			to: generatePath(routes.enterprise.patients.patient.mySchedule, {
				patientId,
			}),
			activeRoutes: [routes.enterprise.patients.patient.mySchedule],
			label: 'My Schedule',
		},
		{
			to: generatePath(routes.enterprise.patients.patient.medications, {
				patientId,
			}),
			activeRoutes: [routes.enterprise.patients.patient.medications],
			label: 'Medications',
		},
		{
			to: generatePath(routes.enterprise.patients.patient.deliveries, {
				patientId,
			}),
			activeRoutes: [routes.enterprise.patients.patient.deliveries],
			label: 'Deliveries',
		},
	];

	return (
		<Grid item container xs={12} spacing={2} alignItems="flex-start" sx={{ marginTop: 0, paddingTop: '0 !important' }}>
			<Grid item xs={12}>
				<Stack direction="row" gap="12px">
					{navItems.map(({ to, activeRoutes, label }) => (
						<Button
							component={RouterLink}
							to={to}
							key={label}
							variant={isActiveRoute(location.pathname, activeRoutes) ? 'contained' : 'outlined'}
						>
							{label}
						</Button>
					))}
				</Stack>
			</Grid>

			{children}
		</Grid>
	);
}
