import React from 'react';
import { RequestRefillButton } from 'components/patient/medication';
import { ButtonProps } from '@mui/material';
import { useRequestRefillMutation } from 'hooks/enterprise';

interface EnterpriseRequestRefillButtonProps extends Omit<ButtonProps, 'children'> {
	sfid: string;
	patientId: string;
}

export function EnterpriseRequestRefillButton({ sfid, patientId, ...buttonArgs }: EnterpriseRequestRefillButtonProps) {
	const { mutate, isSuccess, isPending, reset } = useRequestRefillMutation();
	const onRequestRefillFromExactCare = () => mutate({ patientId, sfid });

	return (
		<RequestRefillButton
			isSuccessMessage={isSuccess}
			isLoading={isPending}
			reset={reset}
			onRequestRefill={onRequestRefillFromExactCare}
			{...buttonArgs}
		/>
	);
}

export default EnterpriseRequestRefillButton;
