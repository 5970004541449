import { queryOptions } from '@tanstack/react-query';
import { getAdminsListN } from 'core/api/admin/admins.api';
import { IAdminsListRequest, IAdmin } from 'core/api/admin/admins.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export const adminQueries = {
	adminsList: ({ page, ...restRequest }: IAdminsListRequest) =>
		queryOptions({
			queryKey: ['admin', 'admins', 'list', restRequest, page],
			queryFn: () => getAdminsListN({ page, ...restRequest }),
			select: normalizeHydraCollection<IAdmin>,
		}),
};
