import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import routes from 'routes';
import { NotificationChannel } from 'core/api/userAccount/userAccount.models';
import { useNotificationSettingMutation, useNotificationSettingsQuery } from 'hooks';
import {
	NotificationSettingRow,
	NotificationSettingRowLoading,
} from 'components/patient/account/NotificationSettingRow';
import { ContentCard } from 'components/card';
import TextMessageEnrollment from 'components/patient/account/TextMessageEnrollment';

export default function NotificationsPreferencesPage() {
	const { data: notificationSettings, isLoading: isLoadingNotificationSettings } = useNotificationSettingsQuery();
	const { mutate: notificationSettingMutate, isPending: isPendingUpdateNotification } =
		useNotificationSettingMutation();
	const isNotificationEnabled = useCallback(
		(categoryId: NotificationChannel, channelId: number) => {
			return (
				notificationSettings?.values.find((value) => value.categoryId === categoryId && value.channelId === channelId)
					?.enabled ?? false
			);
		},
		[notificationSettings]
	);

	return (
		<ContentCard title="Notifications Preferences">
			<Table
				sx={{
					'& .MuiTableCell-root': {
						borderBottom: 0,
					},
					'& .MuiTableCell-body': {
						borderTop: '1px solid',
						borderColor: 'divider',
					},
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell align="left">
							<Typography variant="caption" color="initial">
								Choose how you want to receive notifications when you have a new message in your inbox.
							</Typography>
						</TableCell>
						<TableCell align="center">Email Message</TableCell>
						<TableCell align="center">App Notification</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoadingNotificationSettings && <NotificationSettingRowLoading />}
					{!isLoadingNotificationSettings &&
						notificationSettings?.categories.map(({ id: categoryId, title, description }) => (
							<NotificationSettingRow
								key={categoryId}
								emailEnabled={isNotificationEnabled(categoryId, NotificationChannel.EMAIL)}
								pushEnabled={isNotificationEnabled(categoryId, NotificationChannel.PUSH)}
								onChange={notificationSettingMutate}
								isPending={isPendingUpdateNotification}
								{...{ title, description, categoryId }}
							/>
						))}
					<TableRow>
						<TableCell align="left" colSpan={3}>
							<TextMessageEnrollment
								isEnrolled={notificationSettings?.enrolledInSMS}
								phoneNumber={notificationSettings?.contactMobilePhone}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="left" colSpan={3}>
							<Typography variant="small" fontWeight={700}>
								Medication Reminders
							</Typography>
							<Typography component="p" variant="caption" color="initial">
								<Link to={routes.mySchedule.manageMedicationReminders} component={RouterLink}>
									Click here
								</Link>{' '}
								to manage reminders sent to your phone when it&apos;s time to take your medications.
							</Typography>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</ContentCard>
	);
}
