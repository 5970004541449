import React from 'react';
import {
	Button,
	ListItemText,
	Stack,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Avatar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageWithPreview from 'components/imageWithPreview/imageWithPreview';
import { IAsNeededMedication } from 'core/api/mySchedule/mySchedule.models';
import { AsNeededMedHistory } from '../AsNeededMedHistory';
import { AsNeededMedDirections } from '../AsNeededMedDirections';

interface AsNeededMedListItemMobileProps extends IAsNeededMedication {
	showUpdateMessage: boolean;
	onRecordTaken: (asNeededMedication: IAsNeededMedication) => void;
}

function AsNeededMedListItemMobile({
	onRecordTaken,
	showUpdateMessage,
	...asNeededMedication
}: AsNeededMedListItemMobileProps) {
	const { name, imageUrl, lastTakenAt } = asNeededMedication;

	return (
		<Stack
			gap="24px"
			sx={{
				backgroundColor: (theme) => theme.palette.background.secondary,
				borderRadius: '10px',
				padding: '24px 12px',
			}}
		>
			<Stack direction="row" gap="12px">
				{imageUrl && (
					<ImageWithPreview source={imageUrl} title={name || ''}>
						<Avatar src={imageUrl} alt={name || ''} variant="rounded" sx={{ width: 60, height: 60 }} />
					</ImageWithPreview>
				)}
				<Stack gap="6px">
					<Typography variant="h4" color="text.secondary" fontWeight={700}>
						{name}
					</Typography>
					{showUpdateMessage && (
						<Typography color="success.main" fontWeight={700}>
							As-Needed Medication Dose Logged.
						</Typography>
					)}
					<ListItemText
						primary="Last Reported Dose:"
						primaryTypographyProps={{ sx: { fontWeight: 700 } }}
						secondary={lastTakenAt || 'None'}
					/>
				</Stack>
			</Stack>

			<Button onClick={() => onRecordTaken(asNeededMedication)} size="small" variant="contained" color="primary">
				Take
			</Button>

			<Stack>
				<Accordion disableGutters elevation={0} square>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>View Usage History</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<AsNeededMedHistory {...asNeededMedication} />
					</AccordionDetails>
				</Accordion>

				<Accordion disableGutters elevation={0} square>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>How to Use</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<AsNeededMedDirections {...asNeededMedication} />
					</AccordionDetails>
				</Accordion>
			</Stack>
		</Stack>
	);
}

export default AsNeededMedListItemMobile;
