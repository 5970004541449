import { queryOptions } from '@tanstack/react-query';
import { getPatientsListN, patientDetails } from 'core/api/admin/patients.api';
import { IPatientResponse, IPatientsListRequest, PatientDetailsParams } from 'core/api/admin/patients.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export const adminPatientQueries = {
	patientsList: ({ page, ...restRequest }: IPatientsListRequest) =>
		queryOptions({
			queryKey: ['admin', 'patients', 'list', restRequest, page],
			queryFn: () => getPatientsListN({ page, ...restRequest }),
			select: normalizeHydraCollection<IPatientResponse>,
		}),
	patient: ({ id }: Partial<PatientDetailsParams>) =>
		queryOptions({
			queryKey: ['admin', 'patient', 'view', id],
			queryFn: () => patientDetails({ id: id || '' }),
			enabled: !!id,
		}),
};
