import React, { forwardRef, useMemo } from 'react';
import { InputLabel, MenuItem, Select, Stack, SelectProps, FormHelperText } from '@mui/material';

type Condition = string | { value: string; label: string };

export interface ConditionInputFilterProps extends SelectProps {
	label?: string;
	value?: string;
	conditions: Condition[];
	error?: boolean;
	helperText?: string;
	children: React.ReactNode;
}

export const ConditionInputFilter = forwardRef(
	({ label, conditions, children, helperText, ...restProps }: ConditionInputFilterProps, ref) => {
		const menuItems = useMemo(() => {
			return conditions.map((condition) => {
				if (typeof condition === 'string') {
					return (
						<MenuItem key={condition} value={condition}>
							{condition}
						</MenuItem>
					);
				}

				return (
					<MenuItem key={condition.value} value={condition.value}>
						{condition.label}
					</MenuItem>
				);
			});
		}, [conditions]);

		return (
			<Stack gap={1} sx={{ width: '100%' }}>
				{label && <InputLabel>{label}</InputLabel>}
				<Stack direction="row" gap={1}>
					<Select size="small" sx={{ flex: 1, minWidth: 200 }} ref={ref} {...restProps}>
						<MenuItem value="">None</MenuItem>
						{menuItems}
					</Select>
					{children}
				</Stack>
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
			</Stack>
		);
	}
);

export default ConditionInputFilter;
