import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function BedtimeIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M10.125 3.07505C10.05 4.35005 10.275 5.55005 10.575 6.82505C11.1 9.37505 12.6 11.175 14.85 12.375C16.95 13.5 19.2 14.025 21.525 13.95C21.75 13.95 21.9 13.95 22.125 13.95C22.875 14.1 23.25 14.7 23.025 15.45C22.875 15.975 22.575 16.5 22.35 16.95C20.625 19.95 18.225 22.05 14.85 22.875C8.69998 24.375 2.47498 20.475 1.04998 14.325C-0.225023 8.62505 2.92498 2.85005 8.39998 0.975046C9.52498 0.600046 10.125 0.975046 10.125 2.17505C10.125 2.40005 10.125 2.77505 10.125 3.07505ZM7.79998 3.60005C4.64998 4.87505 2.47498 9.07505 2.92498 12.825C3.44998 16.95 6.74998 20.325 10.725 20.925C14.55 21.525 18.825 19.35 20.175 16.2C19.95 16.05 19.65 16.05 19.35 15.975C17.625 15.825 15.975 15.375 14.475 14.625C11.775 13.35 9.67498 11.4 8.69998 8.40005C8.24998 7.12505 8.02498 5.77505 7.94998 4.42505C8.02498 4.12505 8.09998 3.82505 7.79998 3.60005Z"
				fill="currentColor"
			/>
			<path
				d="M18.225 7.20002C17.925 6.22502 18.3 5.17502 17.925 4.20002C17.625 4.20002 17.4 4.42502 17.175 4.50002C16.95 4.57502 16.725 4.65002 16.575 4.35002C16.5 4.12502 16.5 3.97502 16.725 3.82502C17.325 3.45002 17.85 3.07502 18.6 2.77502C19.2 3.82502 18.675 4.95002 18.975 6.00002C19.35 6.00002 19.575 5.62502 19.95 5.55002C20.1 5.47502 20.325 5.55002 20.4 5.70002C20.55 5.85002 20.55 6.07502 20.325 6.22502C19.725 6.60002 19.2 7.12502 18.45 7.27502C18.45 7.27502 18.3 7.20002 18.225 7.20002Z"
				fill="currentColor"
			/>
			<path
				d="M22.2 4.57499C22.6501 4.57499 22.8751 4.12499 23.3251 4.42499C23.4751 4.64999 23.325 4.79999 23.175 4.94999C23.025 5.09999 22.8001 5.17499 22.5751 5.32499C21.75 5.77499 21.525 5.69999 21.525 4.72499C21.525 4.27499 21.525 3.89999 21.525 3.52499C21.15 3.44999 20.85 3.89999 20.55 3.52499C20.7 3.07499 21.525 2.39999 21.9001 2.47499C22.2001 2.54999 22.2751 2.69999 22.2751 2.92499C22.2751 3.22499 22.2751 3.44999 22.2751 3.74999C22.2001 3.97499 22.2 4.27499 22.2 4.57499Z"
				fill="currentColor"
			/>
			<path
				d="M15.825 8.25002C16.125 8.25002 16.35 7.95002 16.65 8.02502C16.95 8.17502 16.95 8.40002 16.725 8.62502C16.425 8.85002 16.05 9.07502 15.75 9.22502C15.375 9.37502 15.15 9.22502 15.15 8.85002C15.15 8.32502 15.15 7.72502 15.15 7.20002C14.775 6.97502 14.4 7.80002 14.025 7.05002C14.4 6.52502 14.925 6.30002 15.525 6.15002C16.05 6.75002 15.6 7.57502 15.825 8.25002Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
