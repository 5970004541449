import React, { useMemo } from 'react';
import { CardProps, Link, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
	IGetDeliveriesListResponse,
	IGetDeliveriesSummaryResponse,
	SFOrderStatus,
} from 'core/api/delivery/delivery.models';
import { useUserContext } from 'contexts/UserProvider';
import { ContentCard } from 'components/card';
import { DeliveryRecordType } from 'core/models/delivery/delivery.models';

interface Message {
	condition: boolean;
	title?: string;
	message?: React.ReactNode;
	icon?: React.ReactNode;
}

interface DeliveriesMessageProps extends Omit<CardProps, 'children' | 'title'> {
	deliveriesList: IGetDeliveriesListResponse;
	deliverySummary?: IGetDeliveriesSummaryResponse;
}

export function DeliveriesMessage({ deliveriesList, deliverySummary, ...cardProps }: DeliveriesMessageProps) {
	const { shippingAddress, firstExactPackReceived, anyDeliveryReceived } = deliveriesList;
	const { vialsPatient = null } = useUserContext();

	const messages: Message[] = useMemo(
		() => [
			// DEL-1
			{
				condition: !deliverySummary && firstExactPackReceived === true && anyDeliveryReceived === true,
				title: 'Deliveries',
				message: (
					<>
						<Typography variant="body1" fontWeight="bold">
							Delivery Address
						</Typography>
						<Typography variant="body1">This is where you will receive your medication deliveries:</Typography>
						<Typography variant="body1">{shippingAddress}</Typography>
						<Typography variant="body1">Please call us right away if this is incorrect. 1-877-355-7225</Typography>
					</>
				),
			},
			// DEL-2
			{
				condition: !deliverySummary && firstExactPackReceived === false && anyDeliveryReceived === false,
				title: 'We Are Preparing Your First Deliveries',
				message: (
					<>
						<Typography variant="body1">
							You recently completed your Health and Medication Review with ExactCare!
						</Typography>
						<Typography variant="body1" fontWeight={700}>
							Here is what will happen next:
						</Typography>
						<Typography variant="body1">
							<ul>
								<li>We will contact your doctors to get your prescriptions.</li>
								<li>
									We will line up your refill dates so that all of your prescriptions can be sent to you at the same
									time.
								</li>
								<li>
									We will call you with an important update about your upcoming deliveries and copayments, if you have
									any.
								</li>
							</ul>
						</Typography>
						<Typography variant="body1">Please call us if you have any questions!</Typography>
						<Typography variant="body1" fontWeight={700}>
							1-877-355-7225
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-3
			{
				condition:
					!deliverySummary &&
					firstExactPackReceived === false &&
					anyDeliveryReceived === true &&
					vialsPatient === false,
				title: 'You Are On Your Way To Your First ExactPack',
				message: (
					<>
						<Typography variant="body1">
							We are working to get your prescriptions from your doctors and line up your refill dates.
						</Typography>
						<Typography variant="body1">
							<ul>
								<li>This lets us fill your medications together every month.</li>
								<li>That is how we can package your doses by day and time in the ExactPack.</li>
								<li>You will likely receive some medications in vials until this is complete.</li>
								<li>There may also be less than a 30-day supply of medications.</li>
							</ul>
						</Typography>
						<Typography variant="body1">
							Don’t worry! This is a normal part of the process. Please call us if you have concerns.
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-4
			{
				condition:
					!deliverySummary && firstExactPackReceived === false && anyDeliveryReceived === true && vialsPatient === true,
				title: 'You Are On Your Way To Your First Monthly Order',
				message: (
					<>
						<Typography variant="body1">
							We are working to get your prescriptions from your doctors and line up your refill dates.
						</Typography>
						<Typography variant="body1">
							<ul>
								<li>This lets us fill your medications together every month.</li>
								<li>There may also be less than a 30-day supply of medications.</li>
							</ul>
						</Typography>
						<Typography variant="body1">
							Don’t worry! This is a normal part of the process. Please call us if you have concerns.
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-A
			{
				condition: !!deliverySummary && firstExactPackReceived === true,
			},
			// DEL-B
			{
				condition: !!deliverySummary && deliverySummary.recordType !== DeliveryRecordType.ROUTINE,
			},
			// DEL-C
			{
				condition:
					!!deliverySummary &&
					firstExactPackReceived === false &&
					deliverySummary.recordType !== DeliveryRecordType.ROUTINE,
				title: 'There May Be Some Bottles In This Delivery',
				message: (
					<>
						<p>Don’t worry! This is a normal part of the process. Here’s why.</p>
						<p>
							<b>We are lining up your refill dates.</b>
							<br />
							This is so all your medications can be filled at the same time every month.
						</p>
						<p>
							<b>You may also get less than a 30-day supply of some medications.</b>
							<br />
							This is how we line up your refills. You get a small supply now so that we can send a full supply of all
							your medications together on the same date.
						</p>
						<p>
							<b>Please call us if you do not understand your delivery.</b>
						</p>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-D
			{
				condition: !!deliverySummary && firstExactPackReceived === false && deliverySummary.firstExactPack === true,
				title: 'Congratulations On Your First ExactPack',
				message: (
					<>
						<p>If we are still aligning your refill dates, your order may include some bottles</p>
						<p>Always call us if you have any questions!</p>
						<p>
							<Link
								href="https://www.exactcarepharmacy.com/help-with-my-medications/welcome-new-exactcare-patient/#how-to"
								target="_blank"
							>
								Click here
							</Link>{' '}
							to read more about using the ExactPack.
						</p>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-F
			{
				condition: !!deliverySummary && deliverySummary.orderStatus === SFOrderStatus.RETURNED,
				title: 'Returned Order',
				message: (
					<p>
						This order was returned to ExactCare. There are many reasons this could have happened. If you have not
						already talked to ExactCare about this order, please call us. We may need info from you to deliver your
						medications.
					</p>
				),
				icon: <ErrorOutlineRoundedIcon color="error" />,
			},
			// DEL-V
			{
				condition:
					!!deliverySummary &&
					firstExactPackReceived === false &&
					deliverySummary.recordType === DeliveryRecordType.ROUTINE &&
					vialsPatient === true,
				title: 'Congratulations On Your First Monthly Delivery',
				message: (
					<>
						<p>This delivery contains your first monthly order! </p>
						<p>We may be still aligning your refills. There may be less than a 30-day supply of medications. </p>
						<p>Always call us if you have any questions!</p>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
		],
		[firstExactPackReceived, anyDeliveryReceived, shippingAddress, vialsPatient, deliverySummary]
	);

	return (
		<>
			{messages
				.filter(({ condition, title }) => condition && !!title)
				.map(({ title, icon, message }) => (
					<ContentCard key={title} title={title} icon={icon} {...cardProps}>
						{message}
					</ContentCard>
				))}
		</>
	);
}

export default DeliveriesMessage;
