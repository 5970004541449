import React from 'react';
import { Alert, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { useUserContext } from 'contexts/UserProvider';

export function EmailVerifiedAlert() {
	const { emailValidated } = useUserContext();

	if (emailValidated) {
		return null;
	}

	return (
		<Alert variant="filled" severity="error" sx={{ justifyContent: 'center', borderRadius: 0 }}>
			Your email is not verified.{' '}
			<Link to={routes.account.personalInformation} component={RouterLink} color="error.contrastText">
				Review your email settings.
			</Link>
		</Alert>
	);
}

export default EmailVerifiedAlert;
