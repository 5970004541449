import React from 'react';
import { Navigate } from 'react-router-dom';
import routes from 'routes';
import { useBearerToken } from 'hooks';

interface GuestRouteProps {
	children: JSX.Element;
}

function GuestRoute({ children }: GuestRouteProps): JSX.Element {
	const { token: userToken } = useBearerToken();

	if (userToken) {
		return <Navigate to={routes.home} replace />;
	}

	return children;
}

export default GuestRoute;
