import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	createCareTeamMember as createCareTeamMemberApi,
	updateCareTeamMember as updateCareTeamMemberApi,
	deleteCareTeamMember as deleteCareTeamMemberApi,
} from 'core/api/admin/enterprise.api';

export function useCareTeamMemberMutation() {
	const queryClient = useQueryClient();

	const createCareTeamMember = useMutation({
		mutationFn: createCareTeamMemberApi,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'careTeamMember'],
			});
		},
	});

	const updateCareTeamMember = useMutation({
		mutationFn: updateCareTeamMemberApi,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'careTeamMember'],
			});
		},
	});

	const deleteCareTeamMember = useMutation({
		mutationFn: deleteCareTeamMemberApi,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'careTeamMember'],
			});
		},
	});

	return {
		createCareTeamMember,
		updateCareTeamMember,
		deleteCareTeamMember,
	};
}

export default useCareTeamMemberMutation;
