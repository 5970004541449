import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ContractEditMobIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 21 20" {...props}>
			<path
				d="M6.10001 7V5H15.1V7H6.10001ZM6.10001 10V8H15.1V10H6.10001ZM9.10001 20H3.10001C2.26667 20 1.55834 19.7083 0.975006 19.125C0.391673 18.5417 0.100006 17.8333 0.100006 17V14H3.10001V0H18.1V9.025C17.7667 8.99167 17.4292 9.00417 17.0875 9.0625C16.7458 9.12083 16.4167 9.225 16.1 9.375V2H5.10001V14H11.1L9.10001 16H2.10001V17C2.10001 17.2833 2.19584 17.5208 2.38751 17.7125C2.57917 17.9042 2.81667 18 3.10001 18H9.10001V20ZM11.1 20V16.925L16.625 11.425C16.775 11.275 16.9417 11.1667 17.125 11.1C17.3083 11.0333 17.4917 11 17.675 11C17.875 11 18.0667 11.0375 18.25 11.1125C18.4333 11.1875 18.6 11.3 18.75 11.45L19.675 12.375C19.8083 12.525 19.9125 12.6917 19.9875 12.875C20.0625 13.0583 20.1 13.2417 20.1 13.425C20.1 13.6083 20.0667 13.7958 20 13.9875C19.9333 14.1792 19.825 14.35 19.675 14.5L14.175 20H11.1ZM12.6 18.5H13.55L16.575 15.45L16.125 14.975L15.65 14.525L12.6 17.55V18.5ZM16.125 14.975L15.65 14.525L16.575 15.45L16.125 14.975Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
