import { useCallback, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(weekday);
dayjs.extend(isToday);

export interface IDayDetails {
	day: Dayjs;
	today: boolean;
}

export const useWeekDays = (weekStart = 0): IDayDetails[] => {
	const getWeekDays = useCallback((startOfWeek: number): IDayDetails[] => {
		const start = dayjs().weekday(startOfWeek).startOf('week');
		return Array.from({ length: 7 }).map((_, index) => {
			const day = start.add(index, 'day');
			return {
				day,
				today: day.isToday(),
			};
		});
	}, []);

	const weekDays = useMemo(() => getWeekDays(weekStart), [getWeekDays, weekStart]);

	return weekDays;
};

export default useWeekDays;
