import React, { ChangeEvent, useState } from 'react';
import {
	InputAdornment,
	Typography,
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Tooltip,
	TextField,
	TextFieldProps,
} from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Joi from 'joi';
import { useTheme } from '@mui/material/styles';
import { useDisclosure } from 'hooks';

export type PasswordFieldProps = {
	disableTooltip?: boolean;
} & TextFieldProps;

type ActiveErrors = (keyof typeof errorMessages)[];
type ValidatePassword = (value: string) => ActiveErrors | undefined;

interface IErrorMessagesProps {
	messages: typeof errorMessages;
	activeErrors?: ActiveErrors;
}

const errorMessages = {
	minLength: 'Minimum of 13 characters',
	hasUppercase: 'Include a capital letter',
	hasSpecialOrDigit: 'Include a special character (@,#,$,%,&) or a number',
};

export const schema = {
	minLength: Joi.string().min(13).message(errorMessages.minLength),
	hasUppercase: Joi.string()
		.regex(/^(?=.*[A-Z])/)
		.message(errorMessages.hasUppercase),
	hasSpecialOrDigit: Joi.string()
		.regex(/(?=.*[\d]|.*[\W])/)
		.message(errorMessages.hasSpecialOrDigit),
};

function ErrorMessagesBox({ messages, activeErrors }: IErrorMessagesProps) {
	const existingTheme = useTheme();

	return (
		<Box sx={{ padding: '10px' }}>
			<Typography variant="input2" fontWeight={700}>
				Password Requirements:
			</Typography>

			<List dense>
				{Object.keys(messages).map((key) => {
					const isActive = activeErrors?.includes(key as keyof IErrorMessagesProps['activeErrors']) ?? true;
					const colorTextEl = isActive ? existingTheme.palette.text.secondary : existingTheme.palette.success.main;
					return (
						<ListItem key={key} sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon sx={{ minWidth: '30px' }}>
								{!isActive && <RadioButtonCheckedIcon color="success" />}
								{isActive && <RadioButtonUncheckedIcon color="error" />}
							</ListItemIcon>
							<ListItemText sx={{ color: colorTextEl }}>{messages[key as keyof typeof messages]}</ListItemText>
						</ListItem>
					);
				})}
			</List>
		</Box>
	);
}

const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>(
	({ onChange, disableTooltip, ...props }, ref) => {
		const [activeErrors, setActiveErrors] = useState<ActiveErrors | undefined>(undefined);
		const { isOpen: isShowTooltip, onOpen: onOpenTooltip, onClose: onCloseTooltip } = useDisclosure();
		const { isOpen: isShowPassword, onToggle: toggleShowPassword } = useDisclosure();

		const validatePassword: ValidatePassword = (value) => {
			const validateObject = Object.keys(schema).reduce(
				(acc, key) => ({
					...acc,
					[key]: value,
				}),
				{} as Record<string, string>
			);

			const { error } = Joi.object(schema).validate(validateObject, { abortEarly: false });

			if (!error) return [];

			return error.details.map((detail) => detail.context?.key).filter(Boolean) as (keyof typeof errorMessages)[];
		};

		const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
			const inputValue = event.target.value;
			setActiveErrors(validatePassword(inputValue));

			// Call the original onChange provided in props, if it exists
			if (onChange) {
				onChange(event);
			}
		};

		return (
			<Tooltip
				title={<ErrorMessagesBox messages={errorMessages} activeErrors={activeErrors} />}
				arrow
				placement="right"
				slotProps={{
					tooltip: {
						sx: { lineHeight: '16px' },
					},
				}}
				open={!disableTooltip && isShowTooltip}
			>
				<Box>
					<TextField
						onChange={handleInputChange}
						type={isShowPassword ? 'text' : 'password'}
						InputProps={{
							onFocus: onOpenTooltip,
							onBlur: onCloseTooltip,
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={toggleShowPassword} sx={{ padding: 0 }}>
										{isShowPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
									</IconButton>
								</InputAdornment>
							),
						}}
						ref={ref}
						{...props}
					/>
				</Box>
			</Tooltip>
		);
	}
);

export default PasswordField;
