import React from 'react';
import {
	Avatar,
	Collapse,
	TableContainer as MuiTableContainer,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	Table,
	List,
	ListItem,
	ListItemText,
	Box,
	Typography,
	Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
	DeliveryNotIncludedActions,
	DeliveryRecordType,
	IDeliveryMedication,
} from 'core/models/delivery/delivery.models';
import RefillsStatusChip from 'components/refillsStatusChip/RefillsStatusChip';
import ImageWithPreview from 'components/imageWithPreview/imageWithPreview';
import eventService from 'services/eventService';
import { Theme } from '@mui/system';

const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
	borderRadius: '10px',
	border: '1px solid rgba(0, 0, 0, 0.12)',

	[theme.breakpoints.down('sm')]: {
		borderRadius: 0,
		border: 'none',
	},

	'& .MuiTableHead-root': {
		background: theme.palette.background.secondary,
	},
	'& .MuiTableCell-root': {
		padding: '12px',
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			borderRight: 'none',
		},
	},
	'& .MuiTableRow-root:last-child .MuiTableCell-root': {
		borderBottom: 0,
	},
	'& .MuiTableCell-head': {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.body1.fontSize,
		lineHeight: theme.typography.body1.lineHeight,
		fontStyle: 'normal',
		fontWeight: '700',
		'&:first-of-type': {
			fontSize: theme.typography.h2.fontSize,
			lineHeight: theme.typography.h2.lineHeight,
			fontStyle: 'normal',
			fontWeight: '700',
		},
	},

	'& .MuiTableBody-root': {
		background: theme.palette.background.default,

		'& th.MuiTableCell-root': {
			color: theme.palette.text.secondary,
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.5px',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
			alignItems: 'center',
			gap: '10px',

			'& .MuiBox-root': {
				display: 'flex',
				flexDirection: 'column',

				'& span': {
					fontWeight: '400',
					letterSpacing: '0.5px',
					display: 'block',
				},
			},
		},
	},

	'& .collapseBody': {
		padding: 0,

		'& .MuiListItem-root': {
			padding: '0 16px',
		},

		'& .MuiListItemText-root': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
			gap: '10px',
			margin: 0,

			'&.error .MuiListItemText-secondary': {
				color: theme.palette.error.main,
			},
		},

		'& .MuiListItemText-primary': {
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.5px',
		},
		'& .MuiListItemText-secondary': {
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			fontStyle: 'normal',
			fontWeight: '400',
			letterSpacing: '0.5px',
			color: theme.palette.text.secondary,
		},
	},

	'& .collapseButton': {
		display: 'flex',
		background: theme.palette.background.secondary,
		alignItems: 'center',
		color: theme.palette.text.secondary,
		fontSize: theme.typography.caption.fontSize,
		lineHeight: theme.typography.caption.lineHeight,
		fontWeight: '700',
		letterSpacing: '0.5px',
		padding: '5px 10px',
		gap: '5px',
		cursor: 'pointer',
	},
}));

interface MedRowProps extends IDeliveryMedication {
	recordType: DeliveryRecordType | null;
	showRefrigerateAlert?: boolean;
}

function MedRow({
	name,
	imageUrl,
	dispensed,
	rxNumber,
	reason,
	action,
	prescriberName,
	prescriberPhone,
	recordType,
	showRefrigerateAlert,
	refillsStatus,
	refillsStatusAlertLevel,
}: MedRowProps) {
	const [open, setOpen] = React.useState(false);
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	const actionTypographyProps =
		action === DeliveryNotIncludedActions.CALL_YOUR_PRESCRIBER_WITH_QUESTIONS ? { style: { fontWeight: 'bold' } } : {};

	return (
		<>
			<TableRow key={`${name}_short_info`}>
				<TableCell component="th" scope="row">
					{imageUrl && name && (
						<ImageWithPreview
							source={imageUrl}
							title={name}
							onClick={() =>
								eventService.emitEvent({ category: 'imageWithPreview', action: 'deliveryZoomOnMedicationPicture' })
							}
						>
							<Avatar alt={name} src={imageUrl} sx={{ width: 36, height: 36 }} variant="square" />
						</ImageWithPreview>
					)}
					<Box>
						<b>{name}</b>
						{!upSmallScreen && (
							<Typography component="span" variant="body1">
								{rxNumber}
							</Typography>
						)}
						{showRefrigerateAlert && (
							<Typography component="span" variant="caption">
								<b>Refrigerate immediately.</b> Medication may be under packaging materials.
							</Typography>
						)}
					</Box>
				</TableCell>
				<TableCell align="center">
					{recordType === DeliveryRecordType.NON_ROUTINE || recordType === DeliveryRecordType.SHORT_SUPPLY ? (
						dispensed
					) : (
						<RefillsStatusChip refillsStatus={refillsStatus} refillsStatusAlertLevel={refillsStatusAlertLevel} />
					)}
				</TableCell>
				{upSmallScreen && <TableCell align="center">{rxNumber}</TableCell>}
			</TableRow>
			{(reason || action || prescriberName || prescriberPhone) && (
				<TableRow key={`${name}_collapse`}>
					<TableCell colSpan={upSmallScreen ? 3 : 2} className="collapseBody">
						<Box
							className="collapseButton"
							onClick={() => {
								setOpen(!open);
								eventService.emitEvent({ category: 'button', action: 'deliveryMedWhyIsThisNotIncluded' });
							}}
						>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							Why is this not included?
						</Box>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<List>
								{reason && (
									<ListItem>
										<ListItemText className="error" primary="Reason:" secondary={reason} />
									</ListItem>
								)}
								{action && (
									<ListItem>
										<ListItemText
											primary="Action:"
											secondary={action}
											secondaryTypographyProps={actionTypographyProps}
										/>
									</ListItem>
								)}
								{prescriberName && (
									<ListItem>
										<ListItemText primary="Prescriber:" secondary={prescriberName} />
									</ListItem>
								)}
								{prescriberPhone && (
									<ListItem>
										<ListItemText primary="Prescriber Phone:" secondary={prescriberPhone} />
									</ListItem>
								)}
								<ListItem>
									<ListItemText primary="ExactCare Phone:" secondary="(877) 355-7225" />
								</ListItem>
							</List>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}

interface DeliveryMedsProps {
	tableName: string;
	status?: 'error' | string;
	showRefrigerateAlert?: boolean;
	rows: Omit<MedRowProps, 'recordType'>[];
	recordType: DeliveryRecordType | null;
}

export function DeliveryMeds({ tableName, status, recordType, rows, showRefrigerateAlert }: DeliveryMedsProps) {
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
	const cellRefillsHeading = upSmallScreen ? (
		<>
			Refills Remaining
			<Tooltip
				onOpen={() => eventService.emitEvent({ category: 'tooltip', action: 'deliveryMedRefillsTooltip' })}
				arrow
				placement="top"
				title="Tells you if there are refills left for ExactCare to fill."
			>
				<InfoOutlinedIcon sx={{ verticalAlign: 'middle', marginLeft: 0.5 }} fontSize="small" />
			</Tooltip>
		</>
	) : (
		'Refills'
	);

	const cellQuantityRefillsHeading =
		recordType === DeliveryRecordType.NON_ROUTINE || recordType === DeliveryRecordType.SHORT_SUPPLY
			? 'Quantity'
			: cellRefillsHeading;

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell sx={{ display: 'flex', gap: '6px', alignItems: 'center' }} align="left">
							{status === 'error' && <ErrorIcon color="error" />} {rows.length} {tableName}
						</TableCell>
						<TableCell sx={{ minWidth: 75, width: '1px', whiteSpace: 'nowrap' }} align="center">
							{cellQuantityRefillsHeading}
						</TableCell>
						{upSmallScreen && (
							<TableCell sx={{ minWidth: 75, width: '1px', whiteSpace: 'nowrap' }} align="center">
								Rx Number
							</TableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<MedRow key={row.sfid} {...row} recordType={recordType} showRefrigerateAlert={showRefrigerateAlert} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default DeliveryMeds;
