import { deleteRequest, getRequest, postRequest } from '../api-service';
import { endpoints } from '../endpoints';
import {
	IVerifyIdentityResponse,
	IVerifyIdentitySearchQuery,
	ICheckStatusSearchQuery,
	ICheckStatusResponse,
	ICreateUserRequestParams,
	IRegisterPhoneNumberRequestParams,
	IRegisterVerifyPhoneNumberRequestParams,
	IRegisterVerifyPhoneNumberResponse,
} from './registration.models';

export function getVerifyIdentity(request: IVerifyIdentitySearchQuery): Promise<IVerifyIdentityResponse> {
	const { verifyIdentity } = endpoints.registration;
	const body = {
		dateOfBirth: request.dateOfBirth,
		lastFourSsn: request.lastFourSsn,
		zipCode: request.zipCode,
		recaptchaToken: request.recaptchaToken,
	};
	return postRequest<IVerifyIdentityResponse>(verifyIdentity, body, {});
}

export function getCheckStatus(request: ICheckStatusSearchQuery): Promise<ICheckStatusResponse> {
	return getRequest<ICheckStatusResponse>(endpoints.registration.checkStatus(request.hash), {}, {});
}

export function reset(request: ICheckStatusSearchQuery): Promise<ICheckStatusResponse> {
	return deleteRequest<ICheckStatusResponse>(endpoints.registration.reset(request.hash), {}, {});
}

export function userCreate(request: ICreateUserRequestParams): Promise<string> {
	const { createUser } = endpoints.registration;
	const body = {
		hash: request.hash,
		email: request.email,
		password: request.password,
	};

	return postRequest<string>(createUser, body, {});
}

export function registerPhoneNumber(request: IRegisterPhoneNumberRequestParams): Promise<string> {
	const body = {
		hash: request.hash,
		phoneNumber: request.phoneNumber,
	};

	return postRequest<string>(endpoints.registration.registerPhoneNumber, body, {});
}

export function registerVerifyPhoneNumber(
	request: IRegisterVerifyPhoneNumberRequestParams
): Promise<IRegisterVerifyPhoneNumberResponse> {
	const body = {
		hash: request.hash,
		phoneVerificationCode: request.phoneVerificationCode,
	};

	return postRequest<IRegisterVerifyPhoneNumberResponse>(endpoints.registration.verifyPhoneNumber, body, {});
}
