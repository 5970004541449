interface IApiResponse<HydraMember> {
	'hydra:member': HydraMember[];
	'hydra:totalItems': number;
	'hydra:view': {
		'@id': string;
		'@type': string;
		'hydra:first': string;
		'hydra:last': string;
		'hydra:previous': string;
		'hydra:next': string;
	};
	'hydra:search': {
		'@type': string;
		'hydra:template': string;
		'hydra:variableRepresentation': string;
		'hydra:mapping': {
			'@type': string;
			variable: string;
			property: string;
			required: boolean;
		}[];
	};
}

export enum MedicationAction {
	GET_PRESCRIBER_REFILL_REQUEST_FORM = 'GET_PRESCRIBER_REFILL_REQUEST_FORM',
	REQUEST_REFILL_FROM_ECP = 'REQUEST_REFILL_FROM_ECP',
}

export enum MedicationRefillAlert {
	NO_REFILLS = 'NO_REFILLS',
	REFILL_DENIED = 'REFILL_DENIED',
}

export enum MedicationOnboardingAlert {
	REFILL_TOO_SOON = 'Refill Too Soon',
	VERIFY_IF_PATIENT_TAKING_MED = 'Verify if Patient Taking Med',
	DRUG_NOT_COVERED = 'Drug Not Covered',
}

export enum MedicationRefillsStatus {
	REFILLS_STATUS_NONE = 'None',
	REFILLS_STATUS_LOW = 'Low',
	REFILLS_STATUS_YES = 'Yes',
}

export enum MedicationRefillsStatusAlertLevel {
	REFILLS_ALERT_LEVEL_DANGER = 'danger',
	REFILLS_ALERT_LEVEL_WARNING = 'warning',
}

export interface IMedication {
	'@id': string;
	'@type': string;
	id: number | null;
	sfid: string | null;
	name: string | null;
	imageUrl: string | null;
	pack: string | null;
	refillsRemaining: number | null;
	actions: MedicationAction[] | null;
	refillAlert: MedicationRefillAlert | null;
	onboardingAlerts: MedicationOnboardingAlert[] | null;
	rxNumber: string | null;
	prescriberName: string | null;
	prescriberPhone: string | null;
	prescriber: string | null;
}

interface IAPIResponse {
	'@context': string;
	'@id': string;
	'@type': string;
}

export interface IAPIMedicationSummaryResponse extends IAPIResponse {
	id: number | null;
	sfid: string | null;
	name: string | null;
	imageUrl: string | null;
	pack: string | null;
	refillsRemaining: number | null;
	actions: MedicationAction[] | null;
	refillAlert: MedicationRefillAlert | null;
	onboardingAlerts: MedicationOnboardingAlert[] | null;
	rxNumber: string | null;
	rxStatus: string | null;
	refillsStatus: string | null;
	refillsStatusAlertLevel: string | null;
	prescriberName: string | null;
	prescriberPhone: string | null;
	prescriber: string | null;
	commonUses: string | null;
	howToUse: string | null;
	sideEffects: string | null;
	precautions: string | null;
	directions: string | null;
	whenToTake: string | null;
}

export interface IGetMedicationsResponse extends IApiResponse<IMedication> {}

export interface IGetRefillRequestFormPdfRequestParams {
	prescriberSfid: string;
	currentMedicationSfid?: string;
}

export interface IGetMedicationSummaryRequestParams {
	sfid: string;
}

export interface IGetMedicationSummaryResponse extends IAPIMedicationSummaryResponse {}

export interface IRequestRefillParams {
	sfid: string;
}
