import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useAuth } from 'hooks';

export function LoggedInAsUserBar() {
	const auth = useAuth();

	const userData = localStorage.getItem('userData');
	const { userFullName = null } = userData ? JSON.parse(userData) : {};

	const handleSwitchToAdmin = () => auth.restoreToAdmin();

	if (!userFullName) {
		return null;
	}

	return (
		<Stack
			p={2}
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			direction="row"
			sx={{ backgroundColor: 'primary.main' }}
		>
			<Typography fontSize={18} color="primary.contrastText" fontWeight={700}>
				You Are Logged In As {userFullName}.{' '}
				<Button
					onClick={handleSwitchToAdmin}
					variant="text"
					sx={{ color: '#fff', fontWeight: 700, fontSize: 18, display: 'contents' }}
				>
					Switch Back To Your User
				</Button>
			</Typography>
		</Stack>
	);
}

export default LoggedInAsUserBar;
