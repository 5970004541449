import React from 'react';
import { CircularProgress, Box } from '@mui/material';

interface CircularLoadingProps {
	isLoading?: boolean;
}

export default function CircularLoading({ isLoading = true }: CircularLoadingProps) {
	if (!isLoading) {
		return null;
	}

	return (
		<Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
			<CircularProgress />
		</Box>
	);
}
