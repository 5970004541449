import { DisplayOrderStatus } from '../../models/delivery/delivery.models';
import { getBlobRequest, getRequest } from '../api-service';
import { endpoints } from '../endpoints';
import {
	IGetDeliveriesListResponse,
	IGetDeliveriesSummaryRequestParams,
	IGetDeliveriesSummaryResponse,
	SFOrderStatus,
} from './delivery.models';

export function mapDeliveryStatus(sfOrderStatus: SFOrderStatus): DisplayOrderStatus {
	const sfStatusToDisplayStatusMap = {
		[SFOrderStatus.SUBMITTED_TO_PRODUCTION]: DisplayOrderStatus.PREPARING_YOUR_SHIPMENT,
		[SFOrderStatus.IN_PRODUCTION]: DisplayOrderStatus.PREPARING_YOUR_SHIPMENT,
		[SFOrderStatus.AWAITING_DRIVER]: DisplayOrderStatus.ON_ITS_WAY,
		[SFOrderStatus.IN_TRANSIT]: DisplayOrderStatus.ON_ITS_WAY,
		[SFOrderStatus.DELIVERED]: DisplayOrderStatus.DELIVERED,
		[SFOrderStatus.RETURNED]: DisplayOrderStatus.POSSIBLE_DELIVERY_ISSUE,
	};

	return sfStatusToDisplayStatusMap[sfOrderStatus] || DisplayOrderStatus.NA;
}

export function getDeliveryList(): Promise<IGetDeliveriesListResponse> {
	return getRequest<IGetDeliveriesListResponse>(endpoints.delivery.list, {}, {});
}

export function getDeliverySummary(
	request: IGetDeliveriesSummaryRequestParams
): Promise<IGetDeliveriesSummaryResponse> {
	const body = {
		sfid: request.sfid,
	};

	return getRequest<IGetDeliveriesSummaryResponse>(endpoints.delivery.summary, body, {});
}

export function getPdfDeliverySummary(request: IGetDeliveriesSummaryRequestParams): Promise<Blob> {
	const { pdfDownload } = endpoints.delivery;

	return getBlobRequest(pdfDownload(request.sfid), {}, {});
}
