import React from 'react';
import { Box, Stack, Typography, styled, Theme } from '@mui/material';
import { PaletteColor, PaletteOptions } from '@mui/material/styles/createPalette';

const Bullet = styled(Box)<{
	color?: keyof PaletteOptions;
}>`
	width: 14px;
	min-width: auto;
	height: 14px;
	border-radius: 50%;
	background-color: ${({ theme, color }: { theme: Theme; color?: keyof PaletteOptions; today?: boolean }) => {
		if (color && theme.palette?.[color]) return (theme.palette[color] as PaletteColor)?.main;
		return theme.palette.grey[200];
	}};
`;

interface ICalendarWeekDayProps {
	items: {
		label: string;
		color: keyof PaletteOptions;
	}[];
}

export function CalendarWeekLegend({ items }: ICalendarWeekDayProps) {
	return (
		<Stack direction="row" gap="12px" justifyContent="center">
			{items.map(({ label, color }) => (
				<Stack direction="row" alignItems="center" gap="6px" key={label}>
					<Bullet color={color} />
					<Typography fontSize={12} fontWeight={400} color="grey.600">
						{label}
					</Typography>
				</Stack>
			))}
		</Stack>
	);
}
