import React from 'react';
import { Dayjs } from 'dayjs';
import { PickersDayProps } from '@mui/x-date-pickers';
import { Avatar, Stack, Typography } from '@mui/material';
import { PaletteColor, PaletteOptions, styled, useTheme } from '@mui/material/styles';
import { MedHistoryStatus } from 'core/api/mySchedule/mySchedule.models';

const SchedulerDayStyled = styled(Stack)(({ theme }) => ({
	padding: '12px',
	border: 0,
	margin: 0,
	width: '14.28%',
	overflow: 'hidden',
	minHeight: 120,
	justifyContent: 'space-between',
	borderRadius: '10px',
	height: '100%',
	boxShadow: '0px 0px 4px 0px rgba(158, 158, 158, 0.35)',
	[theme.breakpoints.down('sm')]: {
		boxShadow: 'none',
		padding: 0,
		minHeight: 'auto',
	},
}));

interface SchedulerDayProps extends PickersDayProps<Dayjs> {
	status: MedHistoryStatus;
	message: string;
}

export function SchedulerDay({ today, outsideCurrentMonth, day, status, message, onDaySelect }: SchedulerDayProps) {
	const theme = useTheme();
	const statusColors: {
		[key in MedHistoryStatus]: string;
	} = {
		[MedHistoryStatus.Incomplete]: 'warning',
		[MedHistoryStatus.Logged]: 'success',
	};

	const dayColor = (statusColors[status] as keyof PaletteOptions) || undefined;

	return (
		<SchedulerDayStyled
			onClick={status || today ? () => onDaySelect(day) : undefined}
			sx={{
				background: {
					xs: 'transparent',
					sm: outsideCurrentMonth ? theme.palette.background.secondary : theme.palette.background.default,
				},
				cursor: status || today ? 'pointer' : 'unset',
			}}
		>
			{!outsideCurrentMonth && (
				<>
					<Avatar
						sx={{
							fontSize: '20px',
							width: { xs: '100%', sm: '36px' },
							aspectRatio: '1 / 1',
							height: 'auto',
							color: today
								? theme.palette.primary.contrastText
								: (theme.palette[dayColor] as PaletteColor)?.contrastText || theme.palette.text.primary,
							backgroundColor: today
								? theme.palette.primary.main
								: (theme.palette[dayColor] as PaletteColor)?.main || 'transparent',
						}}
					>
						{day.date()}
					</Avatar>
					<Stack gap={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
						{today && <Typography variant="caption">Log Today</Typography>}

						{!today && status === MedHistoryStatus.Logged && <Typography variant="caption">{message}</Typography>}

						{!today && status === MedHistoryStatus.Incomplete && (
							<>
								<Typography variant="caption" component="span" color="warning.main" fontWeight={600}>
									{message}
								</Typography>
								<Typography variant="caption" component="span" color="text.primary">
									Click to log
								</Typography>
							</>
						)}
					</Stack>
				</>
			)}
		</SchedulerDayStyled>
	);
}

export default SchedulerDay;
