import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { usePatientDetailsQuery } from 'hooks/enterprise';
import { stringAvatar, stringToColor } from 'utils';

interface PatientProfileWidgetProps {
	patientId?: string;
}

export function PatientProfileWidget({ patientId }: PatientProfileWidgetProps) {
	const { data } = usePatientDetailsQuery({ id: patientId });
	const { fullName, address, age, dateOfBirth, ssn } = data?.patientProfile || {};

	return (
		<Stack direction="column" spacing={2} alignItems="center">
			{fullName && (
				<Avatar sx={{ width: 100, height: 100, bgcolor: stringToColor(fullName) }}>{stringAvatar(fullName)}</Avatar>
			)}
			<Stack alignItems="center">
				<Typography variant="h6" textAlign="center">
					{fullName}
				</Typography>
				<Typography variant="caption" textAlign="center">
					{address}
				</Typography>
			</Stack>
			<Stack direction="column">
				<Typography component="span" variant="caption">
					<b>DOB:</b> {dateOfBirth} ({age} years)
				</Typography>
				<Typography component="span" variant="caption">
					<b>Last 4 SSN:</b> {ssn}
				</Typography>
			</Stack>
		</Stack>
	);
}

export default PatientProfileWidget;
