import { PatientStatus } from 'core/models/admin/patients.models';
import { HydraCollection } from '../api-service.models';

export interface IPatientsListRequest {
	page: number;
	itemsPerPage: number;
	search?: string;
	dob?: string;
	status?: string;
}

interface IApiResponse<HydraMember> {
	'hydra:member': HydraMember[];
	'hydra:totalItems': number;
	'hydra:view': {
		'@id': string;
		'@type': string;
		'hydra:first': string;
		'hydra:last': string;
		'hydra:previous': string;
		'hydra:next': string;
	};
	'hydra:search': {
		'@type': string;
		'hydra:template': string;
		'hydra:variableRepresentation': string;
		'hydra:mapping': {
			'@type': string;
			variable: string;
			property: string;
			required: boolean;
		}[];
	};
}

export interface IPatientResponse {
	'@id': string;
	'@type': string;
	userId: number | null;
	fullName: string | null;
	dateOfBirth: string | null;
	email: string;
	phone: string | null;
	createdAt: string;
	lastLoginAt: string;
	status: PatientStatus;
}

export interface IGetPatientsListResponse extends IApiResponse<IPatientResponse> {}

export interface IGetPatientRequest {
	userId: string;
}

export interface IDeletePatientRequest extends IGetPatientRequest {}

export interface IUnblockPatientRequest extends IGetPatientRequest {}

export interface ISuspendPatientRequest extends IGetPatientRequest {
	suspend: boolean;
}

export interface IChangeEmailPatientRequest extends IGetPatientRequest {
	email: string;
}

export interface IChangeDateOfBirthPatientRequest extends IGetPatientRequest {
	dateOfBirth: string | null;
}

export interface IChangePasswordPatientRequest extends IGetPatientRequest {
	newPassword: string | null;
}

export interface UserLoginAsParams {
	id: string;
}

export interface UserLoginAs {
	adminEmail: string;
	userFullName: string;
	userToken: string;
}

export interface PatientDetailsParams {
	id: string;
}

export interface PatientDetails {
	userId: number;
	fullName: string;
	dateOfBirth: string;
	email: string;
	phone: string;
	createdAt: string;
	lastLoginAt: string;
	status: PatientStatus;
}

export enum PatientNotificationStatus {
	DISPATCHED = 0,
	SENT = 1,
	ERROR = 2,
	EXPIRED = 4,
}

export enum PatientNotificationChannel {
	SMS = 'sms',
	EMAIL = 'email',
	PUSH = 'push',
}

export interface PatientNotificationsParams {
	patientId?: string;
	page?: number;
	itemsPerPage?: number;
	orderBy?: 'id' | 'statusId';
	orderDirection?: 'ASC' | 'DESC';
}

export interface PatientNotification {
	id: number;
	channel: PatientNotificationChannel;
	code: string;
	sentAt: string;
	statusId: PatientNotificationStatus;
	createdAt: string;
	updatedAt: string;
}

export interface PatientNotificationsResponse extends HydraCollection<PatientNotification> {}

export interface MedsProcessingJobParams {
	patientId?: string;
	page?: number;
	itemsPerPage?: number;
	orderBy?: 'id' | 'status' | 'createdAt' | 'updatedAt';
	orderDirection?: 'ASC' | 'DESC';
}

export enum MedsProcessingJobStatus {
	PENDING = 0,
	PROCESSING = 1,
	SUCCESS = 2,
	ERROR = 3,
}

export interface MedsProcessingJob {
	id: number;
	triggerType: string;
	notes: string;
	from: string;
	to: string;
	status: MedsProcessingJobStatus;
	createdAt: string;
	updatedAt: string;
}

export interface MedsProcessingJobsResponse extends HydraCollection<MedsProcessingJob> {}

export interface CreateMedsProcessingJobsParams {
	patientId: string;
	from: string;
	to: string;
}

export interface CreateMedsProcessingJobsResponse {
	from: string;
	to: string;
}
