import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	addChangePhoneRequest,
	deleteChangePhoneRequest as deleteChangePhoneRequestApi,
	sendCodeAgainRequest,
	verifyPhoneNumberRequest,
} from 'core/api/userAccount/userAccount.api';

export function usePatientPhoneMutation() {
	const queryClient = useQueryClient();

	const createChangePhoneRequest = useMutation({
		mutationFn: addChangePhoneRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['user'],
			});
		},
	});

	const resendVerificationCode = useMutation({
		mutationFn: sendCodeAgainRequest,
	});

	const deleteChangePhoneRequest = useMutation({
		mutationFn: deleteChangePhoneRequestApi,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['user'],
			});
		},
	});

	const verifyPhoneNumber = useMutation({
		mutationFn: verifyPhoneNumberRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['user'],
			});
		},
	});

	return {
		createChangePhoneRequest,
		deleteChangePhoneRequest,
		resendVerificationCode,
		verifyPhoneNumber,
	};
}

export default usePatientPhoneMutation;
