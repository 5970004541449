import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LateAfternoonIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M6.79995 17.6999C6.29995 17.2499 5.79995 16.7499 5.29995 16.2999C5.14995 16.1499 4.99995 16.0499 4.89995 15.8999C4.54995 15.4499 4.54995 14.8999 4.94995 14.5499C5.29995 14.1999 5.84995 14.1999 6.24995 14.5499C6.69995 14.9999 7.14995 15.4499 7.59995 15.8999C7.79995 16.0999 7.94995 16.2999 8.24995 16.4499C8.99995 15.8999 9.89995 15.5499 10.8 15.3499C11 15.0999 10.9 14.7999 10.9 14.5499C10.95 13.8499 10.85 13.1999 10.95 12.4999C11.05 11.8999 11.45 11.5999 12 11.5999C12.55 11.6499 12.9 11.9999 12.9 12.5999C12.9 13.4499 12.9 14.3499 12.9 15.2499C14 15.4999 15 15.8499 15.85 16.4999C16.2 16.3999 16.3499 16.0999 16.5499 15.8999C17 15.4999 17.4 15.0499 17.7999 14.6499C18.1999 14.2499 18.7999 14.2999 19.15 14.6999C19.5 15.0999 19.5499 15.6499 19.15 16.0499C18.5499 16.6999 17.9 17.2999 17.2999 17.8499C17.4 18.3999 17.6999 18.4999 18.15 18.4999C19.4499 18.4999 20.8 18.4999 22.1 18.4999C22.35 18.4999 22.65 18.4999 22.9 18.5499C23.4 18.6499 23.7 19.0999 23.75 19.5999C23.75 20.1499 23.45 20.5499 22.95 20.6999C22.7 20.7499 22.4499 20.7999 22.15 20.7999C15.4 20.7999 8.59995 20.7999 1.84995 20.7999C1.59995 20.7999 1.29995 20.7999 1.04995 20.6999C0.549951 20.5499 0.199951 20.0999 0.199951 19.5999C0.199951 19.0499 0.549951 18.6499 1.09995 18.5499C1.34995 18.4999 1.64995 18.4999 1.89995 18.4999C3.14995 18.4999 4.39995 18.4999 5.64995 18.4999C6.39995 18.4999 6.39995 18.4999 6.79995 17.6999ZM15.1 18.4999C13.15 16.4999 9.89995 17.1499 9.04995 18.4999H15.1Z"
				fill="currentColor"
			/>
			<path
				d="M17.8 12.65C17.7499 12.65 17.7 12.65 17.65 12.65C15.25 12.65 12.4 10.9 11.85 7.64995C11.35 4.64995 12.9 1.94995 15.75 0.849951C16 0.749951 16.2 0.699951 16.35 0.699951C16.6 0.699951 17.3 0.799951 17.35 1.89995C17.35 2.09995 17.35 2.29995 17.35 2.49995C17.35 2.94995 17.35 3.34995 17.45 3.74995C17.8 5.54995 18.95 6.64995 20.85 6.99995C21.35 7.09995 21.85 7.14995 22.35 7.14995C22.9 7.14995 23.25 7.29995 23.45 7.59995C23.65 7.89995 23.65 8.29995 23.45 8.79995C22.5 11.05 20.25 12.65 17.95 12.65C17.95 12.65 17.85 12.65 17.8 12.65ZM15.3 3.49995C14.3 4.19995 13.7 5.64995 13.85 7.14995C14 8.74995 15.45 10.25 17.1 10.55C17.3 10.6 17.55 10.6 17.8 10.6C19.05 10.6 20.15 10.05 20.9 9.14995C19.35 8.84995 18.1 8.29995 17.1 7.34995C15.9 6.24995 15.5 4.89995 15.3 3.49995Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
