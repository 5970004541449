import { useState, useCallback } from 'react';

type UseDialogReturn<IDialogProps = undefined> = [
	boolean,
	(dialogProps?: IDialogProps) => void,
	() => void,
	IDialogProps,
];

export function useDialog<IDialogProps = undefined>(): UseDialogReturn<IDialogProps> {
	const [isOpen, setIsOpen] = useState(false);
	const [dialogData, setDialogData] = useState<IDialogProps | undefined>();

	const openDialog = useCallback((dialogProps?: IDialogProps) => {
		setIsOpen(true);

		if (dialogProps) {
			setDialogData(dialogProps);
		}
	}, []);
	const closeDialog = useCallback(() => {
		setIsOpen(false);
		setDialogData(undefined);
	}, []);

	return [isOpen, openDialog, closeDialog, dialogData as IDialogProps];
}

export default useDialog;
