import React, { useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Alert, Snackbar } from '@mui/material';
import { AuthError, InternalServerError } from 'core/errors';
import { Navigate } from 'react-router-dom';
import routes from 'routes';
import useAuth from 'hooks/useAuth';

function ErrorAlert({ error, resetErrorBoundary }: FallbackProps) {
	const { logout, userToken } = useAuth();

	useEffect(() => {
		if (error instanceof AuthError || error instanceof InternalServerError) {
			resetErrorBoundary();
		}
	}, [error, resetErrorBoundary]);

	useEffect(() => {
		if (userToken && error && error instanceof AuthError) {
			logout.mutate();
		}
	}, [error, logout, userToken]);

	if (error && error instanceof InternalServerError) {
		return <Navigate to={routes.errors.unauthorizedAccess} />;
	}

	return (
		<Snackbar open autoHideDuration={6000} onClose={resetErrorBoundary}>
			<Alert onClose={resetErrorBoundary} severity="error">
				{error.message || 'Something went wrong'}
			</Alert>
		</Snackbar>
	);
}

export function ErrorBoundaryProvider({ children }: React.PropsWithChildren) {
	const logError = (
		error: Error,
		info: {
			componentStack?: string | null;
			digest?: string | null;
		}
	) => {
		console.error('ErrorBoundary', error, info);
	};

	return (
		<ErrorBoundary FallbackComponent={ErrorAlert} onError={logError}>
			{children}
		</ErrorBoundary>
	);
}

export default ErrorBoundaryProvider;
