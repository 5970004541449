import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Paper, PaperTitle } from 'components/dashboardLayout';
import StatusChip, { StatusChipLegend } from 'components/StatusChip';
import DataGrid from 'components/dataGrid';
import { PatientDetails, PatientNotificationChannel, PatientNotificationStatus } from 'core/api/admin/patients.models';
import { usePatientNotificationListQuery } from 'hooks/admin';

interface PatientNotificationListProps extends Pick<PatientDetails, 'userId'> {}

export function PatientNotificationList({ userId }: PatientNotificationListProps) {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<{
		field: 'id' | 'statusId';
		sort: 'ASC' | 'DESC';
	}>({
		field: 'id',
		sort: 'DESC',
	});

	const { data, isLoading } = usePatientNotificationListQuery({
		patientId: userId.toString(),
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
		orderBy: sortModel.field,
		orderDirection: sortModel.sort,
	});
	const { items: notificationsData, totalItems: totalNotifications } = data ?? {};

	const notificationStatusLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: PatientNotificationStatus.DISPATCHED,
				label: 'Dispatched',
				color: 'info',
			},
			{
				id: PatientNotificationStatus.SENT,
				label: 'Sent',
				color: 'success',
			},
			{
				id: PatientNotificationStatus.ERROR,
				label: 'Error',
				color: 'error',
			},
			{
				id: PatientNotificationStatus.EXPIRED,
				label: 'Expired',
				color: 'warning',
			},
		],
		[]
	);

	const notificationChannelLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: PatientNotificationChannel.SMS,
				label: 'SMS',
				icon: <SmsIcon />,
			},
			{
				id: PatientNotificationChannel.EMAIL,
				label: 'Email',
				icon: <EmailIcon />,
			},
			{
				id: PatientNotificationChannel.PUSH,
				label: 'Push',
				icon: <NotificationsIcon />,
			},
		],
		[]
	);

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'id', headerName: 'ID', sortable: true, flex: 1 },
			{
				field: 'channel',
				headerName: 'Channel',
				sortable: false,
				flex: 1,
				renderCell: ({ value }) => <StatusChip legend={notificationChannelLegend} statusId={value} />,
			},
			{
				field: 'code',
				headerName: 'Code',
				sortable: false,
				flex: 1,
				renderCell: ({ value }) => (
					<Typography component="span" fontWeight={700}>
						{value}
					</Typography>
				),
			},
			{ field: 'sentAt', headerName: 'Sent at', sortable: false, flex: 2 },
			{
				field: 'statusId',
				headerName: 'Status',
				sortable: true,
				flex: 1,
				renderCell: ({ value }) => <StatusChip legend={notificationStatusLegend} statusId={value} />,
			},
			{ field: 'createdAt', headerName: 'Created at', sortable: false, flex: 2 },
			{ field: 'updatedAt', headerName: 'Updated at', sortable: false, flex: 2 },
		],
		[notificationChannelLegend, notificationStatusLegend]
	);

	const handleSortModelChange = useCallback((gridSortModel: GridSortModel) => {
		const sort = gridSortModel[0]?.sort?.toUpperCase() as 'ASC' | 'DESC';
		const field = gridSortModel[0]?.field as 'id' | 'statusId';

		setSortModel({
			field,
			sort,
		});
	}, []);

	return (
		<Paper>
			<PaperTitle>Notifications</PaperTitle>
			<DataGrid
				autoHeight
				rows={notificationsData || []}
				columns={columns}
				loading={isLoading}
				rowCount={totalNotifications || 0}
				pageSizeOptions={[5, 10, 25]}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortModelChange}
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
				getRowClassName={(params) => `row-status--${params.row.status}`}
			/>
		</Paper>
	);
}

export default PatientNotificationList;
