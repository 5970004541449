import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LocalShippingMobIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 23 16" {...props}>
			<path
				d="M5.90002 16C5.06669 16 4.35836 15.7083 3.77502 15.125C3.19169 14.5417 2.90002 13.8333 2.90002 13H0.900024V2C0.900024 1.45 1.09586 0.979167 1.48752 0.5875C1.87919 0.195833 2.35002 0 2.90002 0H16.9V4H19.9L22.9 8V13H20.9C20.9 13.8333 20.6084 14.5417 20.025 15.125C19.4417 15.7083 18.7334 16 17.9 16C17.0667 16 16.3584 15.7083 15.775 15.125C15.1917 14.5417 14.9 13.8333 14.9 13H8.90002C8.90002 13.8333 8.60836 14.5417 8.02502 15.125C7.44169 15.7083 6.73336 16 5.90002 16ZM5.90002 14C6.18336 14 6.42086 13.9042 6.61252 13.7125C6.80419 13.5208 6.90002 13.2833 6.90002 13C6.90002 12.7167 6.80419 12.4792 6.61252 12.2875C6.42086 12.0958 6.18336 12 5.90002 12C5.61669 12 5.37919 12.0958 5.18752 12.2875C4.99586 12.4792 4.90002 12.7167 4.90002 13C4.90002 13.2833 4.99586 13.5208 5.18752 13.7125C5.37919 13.9042 5.61669 14 5.90002 14ZM2.90002 11H3.70002C3.98336 10.7 4.30836 10.4583 4.67502 10.275C5.04169 10.0917 5.45002 10 5.90002 10C6.35002 10 6.75836 10.0917 7.12502 10.275C7.49169 10.4583 7.81669 10.7 8.10002 11H14.9V2H2.90002V11ZM17.9 14C18.1834 14 18.4209 13.9042 18.6125 13.7125C18.8042 13.5208 18.9 13.2833 18.9 13C18.9 12.7167 18.8042 12.4792 18.6125 12.2875C18.4209 12.0958 18.1834 12 17.9 12C17.6167 12 17.3792 12.0958 17.1875 12.2875C16.9959 12.4792 16.9 12.7167 16.9 13C16.9 13.2833 16.9959 13.5208 17.1875 13.7125C17.3792 13.9042 17.6167 14 17.9 14ZM16.9 9H21.15L18.9 6H16.9V9Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
