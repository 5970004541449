import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patientFilterDelete } from 'core/api/enterprise/patient.api';

export function usePatientFiltersDeleteMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['enterprise', 'patient', 'filter', 'delete'],
		mutationFn: patientFilterDelete,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['enterprise', 'patient', 'filters'] });
		},
	});
}

export default usePatientFiltersDeleteMutation;
