import { useMutation, useQueryClient } from '@tanstack/react-query';
import { careTeamMemberDelete } from 'core/api/enterprise/supervisor.api';

export function useCareTeamMemberDeleteMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['enterprise', 'careTeamMember', 'delete'],
		mutationFn: careTeamMemberDelete,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: ['enterprise', 'careTeamMember', 'view', variables.id] });
			queryClient.invalidateQueries({ queryKey: ['enterprise', 'careTeamMember', 'list'] });
		},
	});
}

export default useCareTeamMemberDeleteMutation;
