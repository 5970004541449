import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import InfoCardWidget from 'components/InfoCardWidget';
import isActiveRoute from 'utils/isActiveRoute';
import SidebarListItem from '../sidebarList/SidebarListItem';
import SidebarList from '../sidebarList/SidebarList';

interface MenuItem {
	label: string;
	to: string;
	state?: any;
	icon?: React.ReactNode;
	activeRoutes?: string[];
}

interface SidebarMenuProps {
	menuItems: MenuItem[];
}

function SidebarMenu({ menuItems }: SidebarMenuProps) {
	const location = useLocation();

	return (
		<SidebarList>
			{menuItems.map(({ label, to, icon, activeRoutes, state }) => (
				<SidebarListItem
					key={label}
					component={RouterLink}
					to={to}
					state={state}
					className={isActiveRoute(location.pathname, activeRoutes) ? 'active' : ''}
				>
					<InfoCardWidget icon={icon} title={label} />
				</SidebarListItem>
			))}
		</SidebarList>
	);
}

export default SidebarMenu;
