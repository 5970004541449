import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import { UserCredentials } from 'core/api/login/login.models';
import TwoFAField from 'components/common/inputs/TwoFAField';
import useAuth from 'hooks/useAuth';

const twoFASchema = Joi.object({
	authCode: Joi.string().required(),
});

interface TwoFACodeFormProps extends UserCredentials {
	methodId: number;
	methodName: string;
	onResendCode: () => void;
}

export function TwoFACodeForm({ email, password, methodName, onResendCode }: TwoFACodeFormProps) {
	const {
		watch,
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<{ authCode: string }>({
		mode: 'onChange',
		resolver: joiResolver(twoFASchema),
	});
	const { verifyAuthenticationCode } = useAuth();
	const { mutate: onVerifyCode, isPending, isError, error: apiError } = verifyAuthenticationCode;
	const chosenMethodTitle = methodName.split(' ').slice(3, 4)[0] === 'SMS' ? 'Phone' : 'Email';
	const chosenMethodText = methodName.split(' ').slice(2).join(' ');
	const watchAuthCode = watch('authCode');
	const [showResubmitBtn, setShowResubmitBtn] = useState(false);

	const handleVerify = useCallback(
		({ authCode }: { authCode: string }) => {
			onVerifyCode(
				{
					email,
					password,
					authCode,
				},
				{
					onError: () => {
						setShowResubmitBtn(true);
					},
				}
			);
		},
		[email, password, onVerifyCode, setShowResubmitBtn]
	);
	const handleCodeChange = useCallback(
		(authCode: string) => {
			if (authCode && authCode.length === 6 && !isError && !isPending) {
				handleSubmit(handleVerify)();
			}
		},
		[isError, isPending]
	);

	useEffect(() => {
		handleCodeChange(watchAuthCode);
	}, [watchAuthCode]);

	return (
		<>
			<Typography component="h1" variant="h1" fontWeight="bold">
				Check your {chosenMethodTitle}!
			</Typography>
			<Box sx={{ mt: 5, mb: 2 }}>
				<Typography component="span" color="text.secondary" variant="small">
					We sent your six-digit code {chosenMethodText} This code will expire in 5 minutes. Enter it here to access
					your account:
				</Typography>
			</Box>
			<Stack direction="column" gap={2} component="form" onSubmit={handleSubmit(handleVerify)}>
				{isError && <Alert severity="error">{apiError?.message || 'Something went wrong'}</Alert>}
				<Controller
					name="authCode"
					control={control}
					render={({ field: { ref, ...restField }, fieldState: { error } }) => (
						<TwoFAField variant="outlined" {...restField} error={!!error} disabled={isPending} />
					)}
				/>
				{showResubmitBtn && (
					<Button
						type="submit"
						sx={{ mt: 2 }}
						fullWidth
						variant="contained"
						color="primary"
						disabled={isPending || !isValid}
					>
						Resubmit
					</Button>
				)}
			</Stack>
			<Box display="flex" mt={5} sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				<Typography variant="input2" fontWeight={700}>
					Didn‘t receive the code?
				</Typography>
				<Button color="primary" onClick={onResendCode} sx={{ fontWeight: '400', fontSize: '14px' }}>
					Send a new code
				</Button>
			</Box>
		</>
	);
}

export default TwoFACodeForm;
