import { HydraCollection } from 'core/api/api-service.models';
import extractQueryParam from 'utils/extractQueryParam';

export interface Pagination {
	firstPage?: number;
	lastPage?: number;
	nextPage?: number;
	previousPage?: number;
}

export interface NormalizedHydraCollection<D> {
	items: D[];
	totalItems: number;
	pagination?: Pagination;
}

export default function normalizeHydraCollection<D>(data: HydraCollection<D>): NormalizedHydraCollection<D> {
	let pagination;

	if (data['hydra:view'] && data['hydra:view']['@type'] === 'hydra:PartialCollectionView') {
		pagination = {
			firstPage: Number(extractQueryParam(data['hydra:view']?.['hydra:first'], 'page')) || undefined,
			lastPage: Number(extractQueryParam(data['hydra:view']?.['hydra:last'], 'page')) || undefined,
			nextPage: Number(extractQueryParam(data['hydra:view']?.['hydra:next'], 'page')) || undefined,
			previousPage: Number(extractQueryParam(data['hydra:view']?.['hydra:previous'], 'page')) || undefined,
		};
	}

	return {
		items: data['hydra:member'],
		totalItems: data['hydra:totalItems'],
		pagination,
	};
}
