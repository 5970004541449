import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem as MuiMenuItem, Select } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { styled } from '@mui/material/styles';

const MenuItem = styled(MuiMenuItem)(() => ({
	textTransform: 'capitalize',
}));

interface ISelectFilterProps {
	sx?: SystemStyleObject;
	name: string;
	label: string;
	value?: string;
	onChange?: (value: string | undefined) => void;
	items: { value: string; label: string }[];
	placeholder?: string;
}

export default function SelectFilter({ sx, name, label, value, onChange, items, placeholder }: ISelectFilterProps) {
	const [selectedValue, setSelectedValue] = useState<string>(placeholder || '');
	useEffect(() => {
		if (value) {
			setSelectedValue(value);
		} else if (placeholder) {
			setSelectedValue(placeholder);
		}
	}, [value, placeholder]);

	useEffect(() => {
		onChange?.(selectedValue === placeholder ? undefined : selectedValue);
	}, [selectedValue]);

	return (
		<FormControl fullWidth sx={sx}>
			<InputLabel id={name}>{label}</InputLabel>
			<Select
				labelId={name}
				value={selectedValue}
				label={label}
				size="small"
				onChange={(event) => {
					setSelectedValue(event.target.value);
				}}
			>
				{placeholder && (
					<MenuItem key={placeholder} value={placeholder}>
						{placeholder}
					</MenuItem>
				)}
				{items.map((item) => (
					<MenuItem key={item.value} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
