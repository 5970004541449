export interface IResetPasswordRequestActionPayload {
	email: string;
}

export interface IChangePasswordActionPayload {
	password: string;
	hash: string;
}

export enum UserRole {
	SUPERADMIN = 'ROLE_SUPERADMIN',
	ADMIN = 'ROLE_ADMIN',
	ENTERPRISE_CARE_TEAM_MEMBER = 'ROLE_ENTERPRISE_CARE_TEAM_MEMBER',
	ENTERPRISE_SUPERVISOR = 'ROLE_ENTERPRISE_SUPERVISOR',
	PATIENT = 'ROLE_PATIENT',
}
