import { useQuery } from '@tanstack/react-query';
import { getNotificationSettings } from 'core/api/userAccount/userAccount.api';

export function useNotificationSettingsQuery() {
	return useQuery({
		queryKey: ['notificationSetting', 'list'],
		queryFn: getNotificationSettings,
	});
}

export default useNotificationSettingsQuery;
