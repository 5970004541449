import React from 'react';
import { Link, Typography } from '@mui/material';

interface TextMessageEnrollmentProps {
	isEnrolled?: boolean;
	phoneNumber?: string;
}

export function TextMessageEnrollment({ isEnrolled, phoneNumber }: TextMessageEnrollmentProps) {
	return (
		<>
			<Typography variant="small" fontWeight={700}>
				Text Messages
			</Typography>
			<Typography component="p" mb={2}>
				<Typography component="span" variant="body1">
					<b>Enrolled in text messages:</b>{' '}
					{!isEnrolled && (
						<Typography component="span" color="error">
							No
						</Typography>
					)}
					{isEnrolled && (
						<Typography component="span" color="success">
							Yes
						</Typography>
					)}
				</Typography>
				<br />
				<Typography component="span" variant="body1">
					<b>Mobile number on file:</b> {phoneNumber || 'N/A'}
				</Typography>
			</Typography>
			<Typography component="p" variant="caption" color="initial">
				Do you want text messages from ExactCare? Call 1-877-355-7225 to enroll.
				<br />
				<Link
					href="https://www.exactcarepharmacy.com/text-messages/?utm_campaign=Twilio&utm_medium=Clicked-Link&utm_source=APP"
					target="_blank"
				>
					Learn about the benefits of enrolling in text messages.
				</Link>
			</Typography>
		</>
	);
}

export default TextMessageEnrollment;
