import { useMemo, useCallback } from 'react';
import { useMatches, useLocation, UIMatch } from 'react-router-dom';

type UIMatchHandle = {
	showSidebarNavigation: boolean;
};

export const useShowSidebarNavigation = () => {
	const matches = useMatches() as UIMatch<unknown, UIMatchHandle>[];
	const location = useLocation();

	const getShowSidebarNavigationFromMatches = useCallback(() => {
		return (
			matches.find((match) => Boolean(match.handle?.showSidebarNavigation))?.handle?.showSidebarNavigation || false
		);
	}, [matches]);

	const showSidebarNavigation = useMemo(() => {
		return location.state?.showSidebarNavigation ?? getShowSidebarNavigationFromMatches();
	}, [location.state?.showSidebarNavigation, getShowSidebarNavigationFromMatches]);

	return showSidebarNavigation;
};

export default useShowSidebarNavigation;
