import { useMutation, useQueryClient } from '@tanstack/react-query';
import { recordTakenAsNeededMed } from 'core/api/mySchedule/mySchedule.api';

export function useTakeAsNeededMedMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['asNeededMed', 'take'],
		mutationFn: recordTakenAsNeededMed,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['asNeededMed', 'list'] });
		},
	});
}

export default useTakeAsNeededMedMutation;
