import { useRef, useState } from 'react';

type CallbackFunction = () => void;

export function useConfirmation() {
	const [isOpen, setOpen] = useState(false);
	const callbackRef = useRef<CallbackFunction | null>(null);

	const onConfirm = () => {
		setOpen(false);

		return callbackRef?.current ? callbackRef.current() : null;
	};

	const onOpen = (callback: CallbackFunction) => {
		callbackRef.current = callback;
		setOpen(true);
	};

	const onCancel = () => {
		setOpen(false);
	};

	return { isOpen, onCancel, onOpen, onConfirm };
}

export default useConfirmation;
