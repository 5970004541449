import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import eventService from 'services/eventService';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { IMedsGrouped } from '../../../../core/models/medications/medications.models';
import { getRefillRequestFormPdf } from '../../../../core/api/medications/medications.api';
import { MedicationRefillAlert } from '../../../../core/api/medications/medications.models';

const PrescriberList = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	gap: 15,
}));

const PrescriberListItem = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
}));

const PrescriberListItemName = styled(Box)(() => ({}));

interface IRefillRequestFormProps {
	medsGroupedByAlerts: IMedsGrouped | null;
	onDownloadPdfError?: (error: string) => void;
}

interface IPrescriber {
	prescriberSfid: string | null;
	prescriberName: string | null;
}

export function RefillRequestForm({ medsGroupedByAlerts, onDownloadPdfError }: IRefillRequestFormProps) {
	const [downloadPdfIsLoading, setDownloadPdfIsLoading] = useState<Map<string, boolean>>(new Map());

	const addDownloadPdfIsLoading = (prescriberSfid: string | null, state: boolean) => {
		if (prescriberSfid) {
			setDownloadPdfIsLoading((prevMap) => {
				const newMap = new Map(prevMap);
				newMap.set(prescriberSfid, state);
				return newMap;
			});
		}
	};

	const uniquePrescribersList: IPrescriber[] =
		(medsGroupedByAlerts?.[MedicationRefillAlert.NO_REFILLS] &&
			medsGroupedByAlerts[MedicationRefillAlert.NO_REFILLS].reduce((uniquePrescribers: IPrescriber[], med) => {
				if (med.prescriber && med.prescriberName) {
					const isPrescriberUnique = !uniquePrescribers.some(
						(uniquePrescriber) => uniquePrescriber.prescriberSfid === med.prescriber
					);
					if (isPrescriberUnique) {
						uniquePrescribers.push({ prescriberSfid: med.prescriber, prescriberName: med.prescriberName });
					}
				}
				return uniquePrescribers;
			}, [])) ||
		[];

	const handleDownloadPdf = getRefillRequestFormPdf;

	return (
		<Box>
			<Typography mb={2}>
				This will generate a PDF that you can save or print and share with your prescriber(s).
			</Typography>
			<Typography mb={2}>
				If more than one refill is needed from a prescriber, they will all be listed on the form.
			</Typography>
			<Typography component="h3" variant="h3" fontWeight="bold" mb={2}>
				Choose your prescriber(s)
			</Typography>
			<PrescriberList>
				{uniquePrescribersList.map(({ prescriberSfid, prescriberName }) => (
					<PrescriberListItem key={prescriberSfid}>
						<PrescriberListItemName>{prescriberName}</PrescriberListItemName>
						<DownloadPdfButton
							onDownloadPdf={() => {
								eventService.emitEvent({ category: 'button', action: 'refillRequestFormDownloadPdf' });
								return handleDownloadPdf({ prescriberSfid: prescriberSfid || '' });
							}}
							onError={onDownloadPdfError}
							onIsLoading={(state: boolean) => addDownloadPdfIsLoading(prescriberSfid, state)}
							variant="outlined"
							color="primary"
							size="small"
							sx={{ fontSize: '14px' }}
						>
							{prescriberSfid && !downloadPdfIsLoading.get(prescriberSfid) ? 'Create Forms' : 'Loading...'}
						</DownloadPdfButton>
					</PrescriberListItem>
				))}
			</PrescriberList>
		</Box>
	);
}

export default RefillRequestForm;
