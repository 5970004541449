import React from 'react';

import { Box, Card, CardProps, CardContent as MuiCardContent, Stack, styled, Typography } from '@mui/material';

interface IContentCardProps extends Omit<CardProps, 'children' | 'title'> {
	children: React.ReactNode;
	icon?: React.ReactNode | string;
	title?: React.ReactNode | string;
	actions?: React.ReactNode | string;
	disableTitleTypography?: boolean;
}

export const CardBox = styled(Card)(({ theme }) => ({
	boxShadow: 'none',
	borderRadius: '20px',
	padding: '24px 24px 0 24px',
	background: theme.palette.background.default,

	[theme.breakpoints.down('sm')]: {
		background: 'transparent',
		padding: 0,
		borderRadius: 0,
	},
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
	fontWeight: 700,
	flex: '1 1 auto',
}));

const CardHeader = styled(Box)(({ theme }) => ({
	paddingBottom: '24px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'flex-start',

	'&  svg': {
		fontSize: '2.4rem',
	},

	[theme.breakpoints.down('sm')]: {
		padding: '6px 12px 12px 12px',
		flexDirection: 'column',
		alignItems: 'stretch',
		gap: '6px',
	},
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	gap: '12px',
	padding: 0,
	[theme.breakpoints.down('sm')]: {
		padding: '12px',
	},
}));

export default function ContentCard({
	children,
	icon,
	title,
	disableTitleTypography,
	actions,
	...restCardProps
}: IContentCardProps) {
	return (
		<CardBox {...restCardProps}>
			{title && (
				<CardHeader>
					<Stack direction="row" gap="16px" alignItems="center">
						{icon}
						{disableTitleTypography && title}
						{!disableTitleTypography && <CardTitle variant="h1">{title}</CardTitle>}
					</Stack>
					{actions}
				</CardHeader>
			)}
			<CardContent>{children}</CardContent>
		</CardBox>
	);
}
