import React from 'react';
import { ListItem } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const SidebarListItem = styled(ListItem)<{ component?: React.ElementType; to?: string; state?: any }>(({ theme }) => ({
	padding: '12px',
	gap: '8px',
	boxShadow: '0px 0px 3px 0px rgba(18, 18, 18, 0.2)',
	border: '1px solid transparent',
	borderColor: 'transparent',
	borderRadius: '10px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	backgroundColor: 'transparent',
	cursor: 'pointer',

	[theme.breakpoints.down('sm')]: {
		backgroundColor: alpha(theme.palette.background.paper, 0.7),
	},

	'&.active': {
		cursor: 'default',
	},

	'&:hover, &.active': {
		boxShadow: '0px 0px 12px 0px rgba(18, 18, 18, 0.1)',
		borderColor: theme.palette.grey[600],
	},
}));

export default SidebarListItem;
