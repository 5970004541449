const API_URL = process.env.REACT_APP_API_URL;

export const endpoints = {
	registration: {
		checkStatus: (hash: string) => `${API_URL}/registrations/${hash}`,
		reset: (hash: string) => `${API_URL}/registrations/${hash}`,
		verifyIdentity: `${API_URL}/registration/verify_identity`,
		createUser: `${API_URL}/registration/create_user`,
		registerPhoneNumber: `${API_URL}/registration/register_phone_number`,
		verifyPhoneNumber: `${API_URL}/registration/verify_phone_number`,
	},
	login: {
		loginMethod: `${API_URL}/login/2fa_method`,
		sendAuthenticationCode: `${API_URL}/login/send_authentication_code`,
		verifyAuthenticationCode: `${API_URL}/login/verify_authentication_code`,
	},
	logout: `${API_URL}/logout`,
	resetPassword: {
		changePassword: `${API_URL}/reset_password/change_password`,
		sendEmail: `${API_URL}/reset_password/send_email`,
	},
	delivery: {
		list: `${API_URL}/delivery/list`,
		summary: `${API_URL}/delivery/summary`,
		pdfDownload: (sfid: string) => `${API_URL}/delivery/summary/${sfid}/pdf_download`,
	},
	userAccount: {
		profileInfo: `${API_URL}/user_account/profile_info`,
		updateProfileInfo: `${API_URL}/user_account/profile_info`,
		changePhoneRequestEndpoint: `${API_URL}/user_account/change_phone_request`,
		verifyPhoneNumberEndpoint: `${API_URL}/user_account/verify_phone_number`,
		changeEmailEndpoint: `${API_URL}/user_account/change_email`,
		deleteAccountEndpoint: `${API_URL}/user_account/delete_account`,
		changePasswordEndpoint: `${API_URL}/user_account/change_password`,
		changeAddressEndpoint: `${API_URL}/user_account/change_address`,
		verifyEmailChangedEndpoint: `${API_URL}/user_account/verify_email`,
		paymentUrl: `${API_URL}/user_account/payment_url`,
		resendVerificationEmailEndpoint: `${API_URL}/user_account/resend_change_email_confirmation`,
		notificationSettings: `${API_URL}/user_account/notification_settings`,
	},
	medications: {
		list: `${API_URL}/medications`,
		summary: `${API_URL}/medications`,
		listPdfDownload: `${API_URL}/medications/list/pdf_download`,
		refillRequestFormPdfDownload: `${API_URL}/medications/refill_request_form/pdf_download`,
		requestRefill: `${API_URL}/medications/request_refill`,
	},
	mySchedule: {
		scheduledMeds: `${API_URL}/my_schedule/scheduled_meds`,
		recordTaken: `${API_URL}/my_schedule/scheduled_meds/record_taken`,
		scheduledMedsPdf: `${API_URL}/my_schedule/scheduled_meds/pdf_download`,
		asNeededMeds: `${API_URL}/my_schedule/as_needed_meds`,
		recordTakenAsNeededMed: `${API_URL}/my_schedule/as_needed_meds/record_taken`,
		editRecordTakenAsNeededMed: (id: string) => `${API_URL}/my_schedule/as_needed_meds/record_taken/${id}`,
		deleteRecordTakenAsNeededMed: (id: string) => `${API_URL}/my_schedule/as_needed_meds/record_taken/${id}`,
		asNeededMedsUsageHistoryPdf: (scheduledMedId: number) =>
			`${API_URL}/my_schedule/as_needed_meds/${scheduledMedId}/usage_history/pdf_download`,
		getScheduledTimeReminderSettings: `${API_URL}/my_schedule/scheduled_time_reminder_settings`,
		createScheduledTimeReminderSettings: `${API_URL}/my_schedule/scheduled_time_reminder_settings`,
		updateScheduledTimeReminderSettings: (id: string) =>
			`${API_URL}/my_schedule/scheduled_time_reminder_settings/${id}`,
		resetScheduledTimeReminderSettings: `${API_URL}/my_schedule/scheduled_time_reminder_settings_reset`,
		medHistory: `${API_URL}/my_schedule/med_history`,
	},
	inboxNotifications: {
		list: `${API_URL}/inbox_notifications`,
		view: (id: number) => `${API_URL}/inbox_notifications/${id}`,
		update: (id: number) => `${API_URL}/inbox_notifications/${id}`,
	},
	admin: {
		admins: {
			list: `${API_URL}/admin/admins`,
			create: `${API_URL}/admin/admins`,
			update: (id: string) => `${API_URL}/admin/admins/${id}`,
			delete: (id: string) => `${API_URL}/admin/admins/${id}`,
			changePassword: (id: string) => `${API_URL}/admin/admins/${id}`,
		},
		patients: {
			list: `${API_URL}/admin/patients`,
			details: (id: string) => `${API_URL}/admin/patients/${id}`,
			delete: (id: string) => `${API_URL}/admin/patients/${id}`,
			unblock: (id: string) => `${API_URL}/admin/patients/${id}/unblock`,
			suspend: (id: string) => `${API_URL}/admin/patients/${id}/suspend`,
			changeEmail: (id: string) => `${API_URL}/admin/patients/${id}/change_email`,
			changeDateOfBirth: (id: string) => `${API_URL}/admin/patients/${id}/change_date_of_birth`,
			changePassword: (id: string) => `${API_URL}/admin/patients/${id}/change_password`,
			loginAs: (id: string) => `${API_URL}/admin/patients/${id}/login_as`,
			notifications: (id: string) => `${API_URL}/admin/patients/${id}/notifications`,
			medsProcessingJobs: (id: string) => `${API_URL}/admin/patients/${id}/meds_processing_jobs`,
			createMedsProcessingJobs: (id: string) => `${API_URL}/admin/patients/${id}/meds_processing_jobs`,
		},
		enterprise: {
			list: `${API_URL}/admin/enterprise/enterprises`,
			updateEnterprise: (id: string) => `${API_URL}/admin/enterprise/enterprises/${id}`,
			patientAccesses: (id: string) => `${API_URL}/admin/enterprise/enterprises/${id}/patient_accesses`,
			deleteEnterprise: (id: string) => `${API_URL}/admin/enterprise/enterprises/${id}`,
			careTeamMembers: {
				list: `${API_URL}/admin/enterprise/care_team_members`,
				create: `${API_URL}/admin/enterprise/care_team_members`,
				update: (id: string) => `${API_URL}/admin/enterprise/care_team_members/${id}`,
				get: (id: string) => `${API_URL}/admin/enterprise/care_team_members/${id}`,
				delete: (id: string) => `${API_URL}/admin/enterprise/care_team_members/${id}`,
				loginAs: (id: string) => `${API_URL}/admin/enterprise/care_team_members/${id}/login_as`,
			},
		},
	},
	enterprise: {
		supervisor: {
			careTeamMembers: `${API_URL}/enterprise/supervisor/care_team_members`,
			careTeamMember: (id: string) => `${API_URL}/enterprise/supervisor/care_team_members/${id}`,
			patientAccesses: `${API_URL}/enterprise/supervisor/patient_accesses`,
		},
		patient: {
			patients: `${API_URL}/enterprise/patient/patients`,
			patientFilters: `${API_URL}/enterprise/patient/patient_filters`,
			patientFilter: (id: string) => `${API_URL}/enterprise/patient/patient_filters/${id}`,
			details: (id: string) => `${API_URL}/enterprise/patient/patients/${id}`,
			medications: {
				list: (id: string) => `${API_URL}/enterprise/patient/patients/${id}/medications`,
				listPdf: (id: string) => `${API_URL}/enterprise/patient/patients/${id}/medications/pdf_download`,
				refillRequestFormPdf: (patientId: string, prescriberSfid: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/medications/refill_request_form/pdf_download/${prescriberSfid}`,
				requestRefill: (id: string) => `${API_URL}/enterprise/patient/patients/${id}/medications/request_refill`,
				details: (patientId: string, sfid: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/medications/${sfid}`,
			},
			deliveries: {
				list: (patientId: string) => `${API_URL}/enterprise/patient/patients/${patientId}/deliveries`,
				details: (patientId: string, sfid: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/deliveries/${sfid}`,
				pdfDownload: (patientId: string, sfid: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/deliveries/${sfid}/pdf_download`,
			},
			inbox: {
				list: (patientId: string) => `${API_URL}/enterprise/patient/patients/${patientId}/inbox_notifications`,
				details: (patientId: string, notificationId: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/inbox_notifications/${notificationId}`,
			},
			asNeededMeds: {
				list: (patientId: string) => `${API_URL}/enterprise/patient/patients/${patientId}/as_needed_meds`,
				recordTaken: (patientId: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/as_needed_meds/record_taken`,
				editRecordTaken: (patientId: string, id: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/as_needed_meds/record_taken/${id}`,
				deleteRecordTaken: (patientId: string, id: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/as_needed_meds/record_taken/${id}`,
			},
			medHistory: (patientId: string) => `${API_URL}/enterprise/patient/patients/${patientId}/med_history`,
			scheduledMeds: {
				list: (patientId: string) => `${API_URL}/enterprise/patient/patients/${patientId}/scheduled_meds`,
				recordTaken: (patientId: string) =>
					`${API_URL}/enterprise/patient/patients/${patientId}/scheduled_meds/record_taken`,
			},
		},
	},
};
