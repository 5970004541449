import React from 'react';
import { Alert, Button, Box, Typography } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import TextField, { TextFieldStatus } from 'components/common/inputs/textField/TextField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { IAppError } from 'core/errors/AppError';
import { IChangePasswordAdminRequest } from 'core/api/admin/admins.models';

const schema = Joi.object({
	newPassword: buildPasswordSchema(),
	repeatNewPassword: Joi.string().required().valid(Joi.ref('newPassword')).messages({
		'string.empty': 'Repeat new password cannot be empty',
		'any.required': 'Repeat new password is required',
		'any.only': 'New password and repeat new password must match',
	}),
});
export interface IAdminChangePasswordForm {
	newPassword: string;
	repeatNewPassword: string;
}

interface AdminChangePasswordProps {
	userId: string;
	onSubmit: (data: IChangePasswordAdminRequest) => void;
	isLoading: boolean;
	error?: IAppError | null;
}

export function AdminChangePassword({
	userId,
	onSubmit,
	isLoading,
	error: changePasswordError,
}: AdminChangePasswordProps) {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<IAdminChangePasswordForm>({ mode: 'onChange', resolver: joiResolver(schema) });

	const handleSubmitChangePassword = (data: IAdminChangePasswordForm) => {
		onSubmit({ userId, ...data });
	};

	return (
		<Box component="form" onSubmit={handleSubmit(handleSubmitChangePassword)}>
			{changePasswordError?.message && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{changePasswordError.message}
				</Alert>
			)}
			<Box>
				<Typography sx={{ display: 'block' }} variant="input2" fontWeight={700}>
					Enter New Password
				</Typography>
				<Box>
					<Controller
						name="newPassword"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<PasswordField
								{...field}
								value={field.value || ''}
								label="New password"
								variant="outlined"
								size="small"
								fullWidth
								helperText={error ? error.message : null}
								error={!!error}
								InputProps={{ endAdornment: null }}
							/>
						)}
					/>
				</Box>
			</Box>
			<Box mt={2}>
				<Typography sx={{ display: 'block' }} variant="input2" fontWeight={700}>
					Repeat New Password
				</Typography>
				<Box>
					<Controller
						name="repeatNewPassword"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								value={field.value || ''}
								label="Repeat new password"
								helperText={error ? error.message : null}
								status={error ? TextFieldStatus.ERROR : undefined}
								variant="outlined"
								size="small"
								type="password"
								fullWidth
							/>
						)}
					/>
				</Box>
			</Box>
			<Box mt={2} mb={2}>
				<Button disabled={!isValid && isLoading} variant="outlined" color="primary" fullWidth type="submit">
					{isLoading ? 'Loading...' : 'Save'}
				</Button>
			</Box>
		</Box>
	);
}
export default AdminChangePassword;
