import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import Suspense from 'components/Suspense';
import { MedicationSummary, RefillAlerts, RefillRequestForm } from 'components/patient/medication';
import { useMedications } from 'hooks/patient/useMedications';
import Dialog from 'components/dialog';
import { useDisclosure } from 'hooks';
import { medsGroupedByAlerts } from 'core/api/medications/medications.api';

function MedicationPage() {
	const { sfid } = useParams();
	const { getMedicationsQuery, getMedicationQuery, requestRefillMutation } = useMedications();
	const { data: medicationSummaryData, isLoading: medicationSummaryIsLoading } = getMedicationQuery({ sfid });
	const { data: medicationList, isLoading: medicationListIsLoading } = getMedicationsQuery();
	const {
		isOpen: isOpenRefillRequestFormDialog,
		onOpen: onOpenRefillRequestFormDialog,
		onClose: onCloseRefillRequestFormDialog,
	} = useDisclosure();

	const handleRequestRefillFromExactCare = () => sfid && requestRefillMutation.mutate({ sfid });

	const medsGroupedByAlertsData = useMemo(() => {
		return medicationList?.items ? medsGroupedByAlerts(medicationList.items) : null;
	}, [medicationList]);
	const totalAlerts = useMemo(() => {
		return medicationList?.items ? medicationList.items.filter((med) => med.refillAlert).length : 0;
	}, [medicationList]);

	if (!sfid) {
		return (
			<Suspense fallback={<CircularLoading />} isLoading={medicationListIsLoading}>
				<RefillAlerts
					onOpenRefillRequestForm={onOpenRefillRequestFormDialog}
					medsGroupedByAlerts={medsGroupedByAlertsData}
					totalAlerts={totalAlerts}
				/>
				<Dialog
					title="Refill Request Form"
					scroll="paper"
					fullWidth
					maxWidth="xs"
					open={isOpenRefillRequestFormDialog}
					onClose={onCloseRefillRequestFormDialog}
				>
					<RefillRequestForm medsGroupedByAlerts={medsGroupedByAlertsData} />
				</Dialog>
			</Suspense>
		);
	}

	return (
		<Suspense fallback={<CircularLoading />} isLoading={medicationSummaryIsLoading && !medicationSummaryData}>
			{medicationSummaryData && (
				<MedicationSummary
					onRequestRefillFromExactCare={handleRequestRefillFromExactCare}
					requestRefillFromExactCareIsSuccess={requestRefillMutation.isSuccess}
					requestRefillFromExactCareIsLoading={requestRefillMutation.isPending}
					resetRequestRefillFromExactCare={requestRefillMutation.reset}
					medicationSummaryData={medicationSummaryData}
				/>
			)}
		</Suspense>
	);
}

export default MedicationPage;
