import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, Box, Typography, Link } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import TextField, { TextFieldStatus } from 'components/common/inputs/textField/TextField';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { ICheckStatusResponse, ICreateUserRequestParams } from 'core/api/registration/registration.models';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';

const schema = Joi.object({
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required(),
	password: buildPasswordSchema(),
	confirmPassword: Joi.string().valid(Joi.ref('password')).required().messages({
		'any.only': 'Passwords do not match',
	}),
});

interface FormFields extends ICreateUserRequestParams {
	confirmPassword: string;
}

export interface RegisterCreateYourAccountProps extends Partial<ICheckStatusResponse> {
	hash: string;
}

export function RegisterCreateYourAccount({ firstName, patientEmail, hash }: RegisterCreateYourAccountProps) {
	const { createUser, resetRegister } = useRegisterMutation();

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<FormFields>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			email: patientEmail || '',
		},
	});

	const handleCreateUser = handleSubmit(({ email, password }) => createUser.mutate({ email, password, hash }));

	return (
		<Box component="form" onSubmit={handleCreateUser}>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				{firstName}, Create Your Account
			</Typography>
			<Typography component="p" fontWeight="400" variant="caption" color="text.secondary">
				Not you?{' '}
				<Button
					onClick={() => resetRegister.mutate({ hash })}
					disabled={resetRegister.isPending}
					color="inherit"
					variant="text"
				>
					{resetRegister.isPending ? 'Loading...' : 'Switch Account'}
				</Button>
			</Typography>

			<Box mt={5}>
				<Typography variant="input2" fontWeight={700}>
					Email
				</Typography>
				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Controller
						name="email"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								status={error ? TextFieldStatus.ERROR : undefined}
								label="Email"
								variant="outlined"
								size="small"
								autoComplete="username"
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<AlternateEmailIcon />
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
				</Box>
			</Box>

			<Box mt={5}>
				<Typography variant="input2" fontWeight={700}>
					Choose Your Password
				</Typography>
				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Controller
						name="password"
						control={control}
						render={({ field: { value, ...restField }, fieldState: { error } }) => (
							<PasswordField
								{...restField}
								value={value || ''}
								label="Password"
								variant="outlined"
								size="small"
								autoComplete="new-password"
								fullWidth
								error={!!error}
							/>
						)}
					/>
				</Box>
			</Box>

			<Box mt={5}>
				<Typography variant="input2" fontWeight={700} mb={1}>
					Type Your Password Again
				</Typography>
				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Controller
						name="confirmPassword"
						control={control}
						render={({ field: { value, ...restField }, fieldState: { error } }) => (
							<PasswordField
								{...restField}
								value={value || ''}
								autoComplete="new-password"
								helperText={error ? error.message : null}
								error={!!error}
								label="Password"
								variant="outlined"
								size="small"
								disableTooltip
								fullWidth
							/>
						)}
					/>
				</Box>
			</Box>

			{createUser.error?.message && (
				<Alert sx={{ mt: 2 }} severity="error">
					{createUser.error.message}
				</Alert>
			)}

			<Box mt={5} mb={2} sx={{ width: { xs: '100%', sm: '50%' } }}>
				<Button disabled={!isValid || createUser.isPending} variant="outlined" color="primary" fullWidth type="submit">
					{createUser.isPending ? 'Loading...' : 'Create Your Account'}
				</Button>
			</Box>

			<Box mb={2}>
				<Typography fontWeight="400" variant="caption" color="text.secondary">
					By clicking &apos;Create Your Account&apos; you agree to the{' '}
					<Link href="https://anewhealthrx.com/terms-of-use/" fontSize="14px" textTransform="none" target="_blank">
						Terms and Conditions
					</Link>
					.
				</Typography>
			</Box>
		</Box>
	);
}

export default RegisterCreateYourAccount;
