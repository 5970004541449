import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MedsItemImage = styled(Box)(() => ({
	'.imagePreview': {
		height: 'fit-content',
		maxWidth: 150,
		borderRadius: 5,
		overflow: 'hidden',
		display: 'flex',
		'& img': {
			minWidth: '100%',
			minHeight: '100%',
			objectFit: 'cover',
		},
	},
}));
