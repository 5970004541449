import { deleteRequest, getBlobRequest, getRequest, postRequest } from 'core/api/api-service';
import { endpoints } from 'core/api/endpoints';
import {
	CreatePatientFilterParams,
	EntPatientAsNeededMedsParams,
	EntPatientAsNeededMedsResponse,
	EntPatientDeleteRecordTakenParams,
	EntPatientEditRecordTakenParams,
	EntPatientMedHistoryParams,
	EntPatientMedHistoryResponse,
	EntPatientRecordTakenParams,
	EntPatientRecordTakenResponse,
	EntPatientScheduledMedRecordTakenParams,
	EntPatientScheduledMedsParams,
	EntPatientScheduledMedsResponse,
	PatientDeliveriesList,
	PatientDeliveriesListParams,
	PatientDelivery,
	PatientDeliveryDetailsParams,
	PatientDetailsParams,
	PatientDetailsResponse,
	PatientFilter,
	PatientFilterListResponse,
	PatientFilterParams,
	PatientFilterQuery,
	PatientInboxNotificationParams,
	PatientInboxParams,
	PatientListParams,
	PatientListResponse,
	PatientMedication,
	PatientMedicationParams,
	PatientMedicationsParams,
	PatientMedicationsResponse,
	PatientRefillRequestFormPdfParams,
	RequestRefillParams,
} from './patient.models';
import { ISuccessResponse } from '../api-service.models';
import { InboxNotification, InboxNotificationListResponse } from '../inboxNotification';

export async function patientDetails({ id }: PatientDetailsParams): Promise<PatientDetailsResponse> {
	return getRequest(endpoints.enterprise.patient.details(id), {}, {});
}

export async function patientMedications({ patientId }: PatientMedicationsParams): Promise<PatientMedicationsResponse> {
	return getRequest(endpoints.enterprise.patient.medications.list(patientId), {}, {});
}

export function patientMedicationsPdf({ patientId }: PatientMedicationsParams): Promise<Blob> {
	return getBlobRequest(endpoints.enterprise.patient.medications.listPdf(patientId), {}, {});
}

export function patientInbox({ patientId }: PatientInboxParams): Promise<InboxNotificationListResponse> {
	return getRequest(endpoints.enterprise.patient.inbox.list(patientId), {}, {});
}

export function patientInboxNotification({
	patientId,
	notificationId,
}: PatientInboxNotificationParams): Promise<InboxNotification> {
	return getRequest(endpoints.enterprise.patient.inbox.details(patientId, notificationId), {}, {});
}

export function refillRequestFormPdf({
	patientId,
	prescriberSfid,
	currentMedicationSfid,
}: PatientRefillRequestFormPdfParams): Promise<Blob> {
	const body = { currentMedicationSfid };

	return getBlobRequest(
		endpoints.enterprise.patient.medications.refillRequestFormPdf(patientId, prescriberSfid),
		body,
		{}
	);
}

export function requestRefill({ patientId, sfid }: RequestRefillParams): Promise<ISuccessResponse> {
	const body = { sfid };
	return postRequest(endpoints.enterprise.patient.medications.requestRefill(patientId), body, {});
}

export function patientDeliveriesList({ patientId }: PatientDeliveriesListParams): Promise<PatientDeliveriesList> {
	return getRequest<PatientDeliveriesList>(endpoints.enterprise.patient.deliveries.list(patientId), {}, {});
}

export function patientDeliveryDetails({ patientId, sfid }: PatientDeliveryDetailsParams): Promise<PatientDelivery> {
	return getRequest(endpoints.enterprise.patient.deliveries.details(patientId, sfid), {}, {});
}

export function patientDeliveryDetailsPdf({ patientId, sfid }: PatientDeliveryDetailsParams): Promise<Blob> {
	return getBlobRequest(endpoints.enterprise.patient.deliveries.pdfDownload(patientId, sfid), {}, {});
}

export function patientMedicationDetails({ patientId, sfid }: PatientMedicationParams): Promise<PatientMedication> {
	return getRequest(endpoints.enterprise.patient.medications.details(patientId, sfid), {}, {});
}

export function filterPatientQuery(request: PatientFilterQuery): Partial<PatientFilterQuery> {
	const filter: Partial<PatientFilterQuery> = {};

	if (request?.locationName?.operator && request?.locationName?.value) {
		filter.locationName = {
			operator: request.locationName.operator,
			value: request.locationName.value,
		};
	}

	if (request?.territory?.operator && request?.territory?.value) {
		filter.territory = {
			operator: request.territory.operator,
			value: request.territory.value,
		};
	}

	if (request?.state?.operator && request?.state?.value) {
		filter.state = {
			operator: request.state.operator,
			value: request.state.value,
		};
	}

	if (request?.status?.operator && request?.status?.value) {
		filter.status = {
			operator: request.status.operator,
			value: request.status.value,
		};
	}

	if (request?.onboardingStatus?.operator && request?.onboardingStatus?.value) {
		filter.onboardingStatus = {
			operator: request.onboardingStatus.operator,
			value: request.onboardingStatus.value,
		};
	}

	if (request?.postalCode?.operator && request?.postalCode?.value) {
		filter.postalCode = {
			operator: request.postalCode.operator,
			value: request.postalCode.value,
		};
	}

	if (request?.medsWithoutRefills?.operator && request?.medsWithoutRefills?.value) {
		filter.medsWithoutRefills = {
			operator: request.medsWithoutRefills.operator,
			value: request.medsWithoutRefills.value,
		};
	}

	if (request?.dateOfBirth?.operator && request?.dateOfBirth?.value) {
		filter.dateOfBirth = {
			operator: request.dateOfBirth.operator,
			value: request.dateOfBirth.value,
		};
	}

	return filter;
}

export async function patientList({
	page,
	itemsPerPage,
	filterId,
	...restRequest
}: PatientListParams): Promise<PatientListResponse> {
	const filter: PatientListParams = {
		page,
		itemsPerPage,
		...filterPatientQuery(restRequest as PatientFilter),
	};

	if (filterId) {
		filter.filterId = filterId;
	}

	const body = { ...filter };

	return getRequest<PatientListResponse>(endpoints.enterprise.patient.patients, body, {});
}

export async function patientFilterList(): Promise<PatientFilterListResponse> {
	return getRequest<PatientFilterListResponse>(endpoints.enterprise.patient.patientFilters, {}, {});
}

export async function patientFilterDelete({ id }: PatientFilterParams): Promise<ISuccessResponse> {
	return deleteRequest<ISuccessResponse>(endpoints.enterprise.patient.patientFilter(id), {}, {});
}

export function createPatientFilter(request: CreatePatientFilterParams): Promise<PatientFilter> {
	const filter: CreatePatientFilterParams = {
		name: request.name,
		...filterPatientQuery(request as PatientFilter),
	};

	const body = { ...filter };

	return postRequest(endpoints.enterprise.patient.patientFilters, body, {});
}

export async function asNeededMedsList(request: EntPatientAsNeededMedsParams): Promise<EntPatientAsNeededMedsResponse> {
	const body = {
		day: request.day,
	};

	return getRequest<EntPatientAsNeededMedsResponse>(
		endpoints.enterprise.patient.asNeededMeds.list(request.patientId),
		body,
		{}
	);
}

export function recordTakenAsNeededMeds({
	patientId,
	...restRequest
}: EntPatientRecordTakenParams): Promise<EntPatientRecordTakenResponse> {
	const body = {
		scheduledMedId: restRequest.scheduledMedId,
		scheduledTimeId: restRequest.scheduledTimeId,
		hourTaken: restRequest.hourTaken,
		minuteTaken: restRequest.minuteTaken,
		meridiem: restRequest.meridiem,
	};

	return postRequest<EntPatientRecordTakenResponse>(
		endpoints.enterprise.patient.asNeededMeds.recordTaken(patientId),
		body,
		{}
	);
}

export function editRecordTakenAsNeededMeds({
	patientId,
	id,
	...restRequest
}: EntPatientEditRecordTakenParams): Promise<ISuccessResponse> {
	const body = { ...restRequest };

	return postRequest<ISuccessResponse>(
		endpoints.enterprise.patient.asNeededMeds.editRecordTaken(patientId, id),
		body,
		{}
	);
}

export function deleteRecordTakenAsNeededMeds({
	patientId,
	id,
}: EntPatientDeleteRecordTakenParams): Promise<ISuccessResponse> {
	return deleteRequest<ISuccessResponse>(
		endpoints.enterprise.patient.asNeededMeds.deleteRecordTaken(patientId, id.toString()),
		{},
		{}
	);
}

export async function patientMedHistory({
	patientId,
	...restRequest
}: EntPatientMedHistoryParams): Promise<EntPatientMedHistoryResponse> {
	const body = {
		...restRequest,
	};
	return getRequest<EntPatientMedHistoryResponse>(endpoints.enterprise.patient.medHistory(patientId), body, {});
}

export async function scheduledMedsList({
	patientId,
	...restRequest
}: EntPatientScheduledMedsParams): Promise<EntPatientScheduledMedsResponse> {
	const body = {
		...restRequest,
	};
	return getRequest<EntPatientScheduledMedsResponse>(
		endpoints.enterprise.patient.scheduledMeds.list(patientId),
		body,
		{}
	);
}

export function recordTakenScheduledMeds({
	patientId,
	...restRequest
}: EntPatientScheduledMedRecordTakenParams): Promise<ISuccessResponse> {
	const body = { ...restRequest };

	return postRequest<ISuccessResponse>(endpoints.enterprise.patient.scheduledMeds.recordTaken(patientId), body, {});
}
