import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export default function relativeDateFormat(utcDate: string): string | undefined {
	const localInputDate = dayjs.utc(utcDate).local();

	if (!localInputDate.isValid()) {
		return undefined;
	}

	let relativeDate = localInputDate.format('M/D/YY');

	if (localInputDate.isToday()) {
		relativeDate = localInputDate.format('h:mm A');
	} else if (localInputDate.isYesterday()) {
		relativeDate = 'Yesterday';
	} else if (localInputDate.isAfter(dayjs().subtract(7, 'day'))) {
		relativeDate = localInputDate.format('dddd');
	}

	return relativeDate;
}
