import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useRegisterHash() {
	const [hash, setHash] = useState(localStorage.getItem('register') || '');
	const params = useParams();

	useEffect(() => {
		if (hash) {
			localStorage.setItem('register', hash);
		} else {
			localStorage.removeItem('register');
		}
	}, [hash]);

	useEffect(() => {
		const hashValue: string | null = params?.hash || null;

		if (hashValue) {
			setHash(hashValue);
		}
	}, [params.hash]);

	return { hash, setHash };
}

export default useRegisterHash;
