import React from 'react';

interface SuspenseProps {
	isLoading?: boolean; // Controlled Suspense
	fallback: React.ReactNode;
	children: React.ReactNode;
}

export default function Suspense({ isLoading = false, fallback, children }: SuspenseProps) {
	if (isLoading) {
		return fallback;
	}

	return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
}
