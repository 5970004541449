import React from 'react';
import { Alert, Button, Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import TextField, { TextFieldStatus } from 'components/common/inputs/textField/TextField';
import { PhoneNumberMaskInput } from 'components/common/inputs/textField/TextFieldMask';
import { ICheckStatusResponse, IRegisterPhoneNumberRequestParams } from 'core/api/registration/registration.models';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';

const schema = Joi.object({
	phoneNumber: Joi.string().required().messages({
		'any.required': 'Phone number is a required field',
	}),
});

export interface RegisterWithPhoneNumberProps extends Partial<ICheckStatusResponse> {
	hash: string;
	onSuccess: () => void;
}

export function RegisterWithPhoneNumber({ hash, onSuccess }: RegisterWithPhoneNumberProps) {
	const { registerPhoneNumber } = useRegisterMutation();
	const { mutate: phoneNumberMutate, isPending, error: phoneNumberError } = registerPhoneNumber;

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<IRegisterPhoneNumberRequestParams>({ mode: 'onChange', resolver: joiResolver(schema) });

	const handleVerifyWithPhoneNumber = handleSubmit(({ phoneNumber }) =>
		phoneNumberMutate({ phoneNumber, hash }, { onSuccess })
	);

	const handleVerifyWithEmail = () => phoneNumberMutate({ phoneNumber: null, hash }, { onSuccess });

	return (
		<Box component="form" onSubmit={handleVerifyWithPhoneNumber}>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Confirm Your Mobile Phone Number
			</Typography>
			<Box mt={5}>
				<Typography sx={{ display: 'block' }} variant="input2" fontWeight={700}>
					Enter your mobile phone number to activate your account.
				</Typography>
				<Typography component="span" variant="small">
					This phone number will be used for service notifications and text messages from ExactCare. We only message you
					about your service.
					<br />
					<br />
				</Typography>
				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Controller
						name="phoneNumber"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								status={error ? TextFieldStatus.ERROR : undefined}
								variant="outlined"
								size="small"
								InputProps={{ inputComponent: PhoneNumberMaskInput as any }}
								fullWidth
							/>
						)}
					/>
				</Box>
			</Box>

			{phoneNumberError?.message && (
				<Alert sx={{ mt: 2 }} severity="error">
					{phoneNumberError.message}
				</Alert>
			)}

			<Box mt={5} mb={2} sx={{ width: { xs: '100%', sm: '50%' } }}>
				<Button disabled={!isValid && isPending} variant="outlined" color="primary" fullWidth type="submit">
					{isPending ? 'Loading...' : 'Send Your Verification Code'}
				</Button>
			</Box>

			<Box display="flex" mt={5} mb={2} sx={{ width: { xs: '100%', sm: '50%' }, flexDirection: 'column' }}>
				<Typography variant="input2" fontWeight={700}>
					Prefer not to share your phone number?
				</Typography>
				<Button
					onClick={handleVerifyWithEmail}
					variant="text"
					sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}
				>
					Verify with your email
				</Button>
			</Box>
		</Box>
	);
}

export default RegisterWithPhoneNumber;
