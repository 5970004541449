import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changeNotificationSetting } from 'core/api/userAccount/userAccount.api';

export function useNotificationSettingMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['notificationSetting', 'update'],
		mutationFn: changeNotificationSetting,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['notificationSetting', 'list'] });
		},
	});
}

export default useNotificationSettingMutation;
