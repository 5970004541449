import React from 'react';
import AccessibleIcon from '@mui/icons-material/Accessible';
import BusinessIcon from '@mui/icons-material/Business';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import routes from 'routes';
import { usePageContext } from 'contexts/PageProvider';
import { DashboardLayout } from 'components/dashboardLayout';

interface AdminLayoutProps {
	children: React.ReactNode;
}

export function AdminLayout({ children }: AdminLayoutProps) {
	const { pageTitle } = usePageContext();

	const mainMenu = [
		{
			label: 'Patients',
			icon: <AccessibleIcon />,
			to: routes.admin.patients.list,
			activeRoutes: [routes.admin.patients.list, routes.admin.patients.create, routes.admin.patients.view],
		},
		{
			label: 'Admins',
			icon: <SupervisedUserCircleIcon />,
			to: routes.admin.admins.list,
			activeRoutes: [routes.admin.admins.list],
		},
		{
			label: 'Enterprises',
			icon: <BusinessIcon />,
			to: routes.admin.enterprise.list,
			activeRoutes: [routes.admin.enterprise.list, routes.admin.enterprise.caregiver.list],
		},
	];

	return (
		<DashboardLayout {...{ pageTitle, mainMenu }} className="EcaLayout-admin">
			{children}
		</DashboardLayout>
	);
}

export default AdminLayout;
