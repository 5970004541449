import React from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Theme, useMediaQuery } from '@mui/material';
import useShowSidebarNavigation from 'hooks/useShowSidebarNavigation';
import DeliveriesSidebar from '../DeliveriesSidebar';

interface DeliveriesLayoutProps {
	children: React.ReactNode;
}

export function DeliveriesLayout({ children }: DeliveriesLayoutProps) {
	const xsScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'));
	const showSidebarNavigation = useShowSidebarNavigation();

	if (xsScreen) {
		if (showSidebarNavigation) {
			return (
				<Grid xs={12}>
					<DeliveriesSidebar />
				</Grid>
			);
		}

		return <Grid xs={12}>{children}</Grid>;
	}

	return (
		<>
			<Grid sm={4} xs={12}>
				<DeliveriesSidebar />
			</Grid>
			<Grid sm={8} xs={12}>
				{children}
			</Grid>
		</>
	);
}

export default DeliveriesLayout;
