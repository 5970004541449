import React, { useEffect } from 'react';
import Joi from 'joi';
import {
	Button,
	Box,
	FormControl,
	FormHelperText,
	InputLabel,
	Link,
	ListSubheader,
	MenuItem,
	Select,
} from '@mui/material';
import { IAppError } from 'core/errors/AppError';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import TextField, { TextFieldStatus } from 'components/common/inputs/textField/TextField';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Switch from 'components/common/inputs/switch/Switch';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface IDynamicFormData {
	[key: string]: string | string[] | number | number[] | boolean | undefined;
}

interface IDynamicFormSelect {
	label: string;
	value: string | number | undefined;
}

export interface IDynamicFormAction {
	label: string;
	value: string;
}

export interface IDynamicFormElement {
	label?: string;
	type: string;
	validation: Joi.AnySchema;
	value?: string | string[] | number | number[] | boolean;
	required?: boolean;
	options?: IDynamicFormSelect[] | Record<string, IDynamicFormSelect[]>;
	disabled?: boolean | ((watch: UseFormWatch<FieldValues>) => boolean);
}

export interface IDynamicFormProps {
	formElements: Map<string, IDynamicFormElement>;
	formActions?: IDynamicFormAction[];
	onSubmitFormHandler: (data: any) => void;
	formRequestIsLoading: boolean;
	formRequestError: IAppError | null;
}

const DynamicForm = React.forwardRef<HTMLElement, IDynamicFormProps>((props, ref) => {
	const { formElements, formActions, formRequestError, formRequestIsLoading, onSubmitFormHandler } = props;
	const validationRules: { [key: string]: Joi.Schema } = {};
	const initialValues: IDynamicFormData = {};
	formElements.forEach((element: IDynamicFormElement, key: string) => {
		validationRules[key] = element.validation;
		initialValues[key] = element?.value;
	});
	const {
		control,
		handleSubmit,
		setError,
		watch,
		formState: { isValid },
	} = useForm<IDynamicFormData>({
		mode: 'onChange',
		defaultValues: initialValues as IDynamicFormData,
		resolver: joiResolver(Joi.object(validationRules)),
	});

	const isDisabled = (element: IDynamicFormElement): boolean => {
		return (typeof element.disabled === 'function' ? element.disabled(watch) : element.disabled) || false;
	};

	const isGroupedList = (list: IDynamicFormSelect[] | Record<string, IDynamicFormSelect[]>): boolean => {
		if (typeof list === 'object' && list !== null) {
			return Object.values(list).every(Array.isArray);
		}
		return false;
	};

	const isMultiSelect = (type: string) => type === 'multi-select';

	const setServerValidationErrors = (serverErrors: IAppError['validationErrors']) => {
		if (serverErrors) {
			Object.entries(serverErrors).forEach(([key, message]) => {
				setError(key as string, {
					type: 'manual',
					message,
				});
			});
		}
	};

	useEffect(() => {
		if (formRequestError?.validationErrors) {
			setServerValidationErrors(formRequestError.validationErrors);
		}
	}, [formRequestError]);

	return (
		<Box component="form" py={2} onSubmit={handleSubmit(onSubmitFormHandler)} ref={ref}>
			{Array.from(formElements.entries()).map(([key, element]) => (
				<Box key={key} mb={2}>
					{['select', 'multi-select'].includes(element.type) && (
						<FormControl fullWidth variant="outlined" size="small">
							<InputLabel>{element.label}</InputLabel>
							<Controller
								name={key}
								control={control}
								render={({ field, fieldState: { error } }) => (
									<>
										<Select
											{...field}
											label={element.label}
											value={field.value || (isMultiSelect(element.type) ? [] : '')}
											multiple={isMultiSelect(element.type)}
											disabled={isDisabled(element)}
										>
											{element?.options &&
												isGroupedList(element.options) &&
												Object.entries(element.options).flatMap(([group, options]) => [
													<ListSubheader key={group}>{group}</ListSubheader>,
													...options.map((option: IDynamicFormSelect) => (
														<MenuItem key={option.value} value={option.value}>
															{option.label}
														</MenuItem>
													)),
												])}
											{element?.options &&
												Array.isArray(element.options) &&
												element.options.map((option: IDynamicFormSelect) => (
													<MenuItem key={option.label} value={option.value}>
														{option.label}
													</MenuItem>
												))}
										</Select>
										<FormHelperText error>{error ? error.message : null}</FormHelperText>
									</>
								)}
							/>
						</FormControl>
					)}
					{element.type === 'switch' && (
						<>
							<InputLabel>{element.label}</InputLabel>
							<Controller
								name={key}
								control={control}
								render={({ field, fieldState: { error } }) => (
									<>
										<Switch {...field} checked={(field.value as boolean) || false} disabled={isDisabled(element)} />
										<FormHelperText error>{error ? error.message : null}</FormHelperText>
									</>
								)}
							/>
						</>
					)}
					{['text', 'password', 'email'].includes(element.type) && (
						<Controller
							name={key}
							control={control}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									value={field.value || ''}
									label={element.label}
									type={element.type}
									helperText={error ? error.message : null}
									status={error ? TextFieldStatus.ERROR : undefined}
									variant="outlined"
									size="small"
									disabled={isDisabled(element)}
									fullWidth
								/>
							)}
						/>
					)}
				</Box>
			))}
			<Box mt={2} mb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Typography
					component="span"
					color="text.secondary"
					variant="body1"
					noWrap
					sx={{ flexGrow: 1, marginTop: '5px' }}
				>
					{formActions &&
						formActions.map((action: IDynamicFormAction) => (
							<Link
								key={action.label}
								component={RouterLink}
								sx={{ marginRight: '15px' }}
								to={action.value}
								variant="caption"
								fontWeight="bold"
							>
								{action.label}
							</Link>
						))}
				</Typography>
				<Button disabled={!isValid && formRequestIsLoading} variant="outlined" color="primary" type="submit">
					{formRequestIsLoading ? 'Loading...' : 'Save'}
				</Button>
			</Box>
		</Box>
	);
});

export default DynamicForm;
