import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MedsItem = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	borderTop: '1px solid rgba(0, 0, 0, 0.12)',
	gap: 24,
	paddingTop: 24,

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
	},
}));
