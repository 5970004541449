import React, { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';

const ChipContainer = styled(MuiChip)<{ component: React.ElementType }>(() => ({
	fontWeight: '700',
	textTransform: 'capitalize',
}));

export interface StatusChipLegend {
	id: string | number;
	label?: string;
	icon?: React.ReactElement;
	color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export interface StatusChipProps extends Partial<ChipProps> {
	legend: StatusChipLegend[];
	statusId: string | number;
}

export function StatusChip({ legend, statusId, ...chipProps }: StatusChipProps) {
	const getStatus = useCallback(
		(id: string | number) => {
			return legend.find((item) => item.id === id);
		},
		[legend]
	);

	const { id, ...statusChipProps } = useMemo(() => getStatus(statusId), [getStatus, statusId]) || {};

	if (id == null) {
		return null;
	}

	return <ChipContainer component="span" size="small" {...statusChipProps} {...chipProps} />;
}

export default StatusChip;
