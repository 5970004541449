import React from 'react';
import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import routes from 'routes';
import { useUserContext } from 'contexts/UserProvider';
import LoggedInAsUserBar from 'components/LoggedInAsUserBar';
import EmailVerifiedAlert from 'components/EmailVerifiedAlert';
import ExpiredPasswordScreen from 'components/ExpiredPasswordScreen';
import { HomeMobIcon } from 'components/icons/HomeMobIcon';
import { ContractEditMobIcon } from 'components/icons/ContractEditMobIcon';
import { MedsMobIcon } from 'components/icons/MedsMobIcon';
import { LocalShippingMobIcon } from 'components/icons/LocalShippingMobIcon';
import useAuth from 'hooks/useAuth';
import { generatePath } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

interface IAppLayoutContainerProps {
	children: React.ReactNode;
}

const Content = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	[theme.breakpoints.down('sm')]: {
		margin: '0 -16px',
	},
}));

const AppLayoutBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.secondary,
	backgroundImage:
		'radial-gradient(at 27% 99%, hsla(206, 80%, 43%, 0.63) 0px, transparent 50%), radial-gradient(at 58% 46%, hsla(283, 100%, 28%, 0.34) 0px, transparent 50%)',
	minHeight: '100vh',

	[theme.breakpoints.down('sm')]: {
		backgroundImage:
			'radial-gradient(at 51% 3%, hsla(240,11%,96%,0.59) 0px, transparent 50%), radial-gradient(at 24% 72%, hsla(206,80%,43%,0.5) 0px, transparent 50%), radial-gradient(at 77% 99%, hsla(283,100%,28%,0.4) 0px, transparent 50%)',
	},

	'& .MuiContainer-root': {
		gap: '24px',
		display: 'flex',
		minHeight: '100vh',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		minWidth: '1180px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
			minWidth: 'auto',
			gap: '0',
		},
	},
}));

export default function AppLayout({ children }: IAppLayoutContainerProps) {
	const theme = useTheme();
	const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
	const { fullName: accountName, mainMenu: mainMenuBadge, balance: accountBalance, passwordExpired } = useUserContext();
	const { logout } = useAuth();
	const gridSpacing = upSmallScreen ? 3 : 0;

	const handleLogout = () => {
		logout.mutate();
	};

	const mainMenu = [
		{
			label: 'Home',
			icon: <HomeMobIcon />,
			to: routes.home,
			notifications: mainMenuBadge?.homepageBadge ?? 0,
			activeRoutes: [routes.inbox.list, routes.inbox.item],
		},
		{
			label: 'My Schedule',
			icon: <ContractEditMobIcon />,
			to: routes.mySchedule.logTodaysMed,
			activeRoutes: [
				routes.mySchedule.logTodaysMed,
				routes.mySchedule.todaysMedDetails,
				routes.mySchedule.reviewMyHistory.calendar,
				routes.mySchedule.reviewMyHistory.day,
				routes.mySchedule.manageMedicationReminders,
				routes.mySchedule.logAsNeededMed,
			],
		},
		{
			label: upSmallScreen ? 'Medication' : 'Meds',
			icon: <MedsMobIcon />,
			to: generatePath(routes.medication),
			notifications: mainMenuBadge?.medicationsBadge ?? 0,
			activeRoutes: [routes.medication],
		},
		{
			label: 'Deliveries',
			icon: <LocalShippingMobIcon />,
			to: generatePath(routes.delivery),
			notifications: mainMenuBadge?.deliveriesBadge ?? 0,
			activeRoutes: [routes.delivery],
		},
		{
			label: 'Help',
			to: 'https://exactcare.helpjuice.com/',
			target: '_blank',
		},
	];

	if (passwordExpired) {
		return <ExpiredPasswordScreen />;
	}

	return (
		<>
			<EmailVerifiedAlert />
			<AppLayoutBox className="EcaLayout-app">
				<LoggedInAsUserBar />
				<Container maxWidth="xl">
					<Header
						{...{
							mainMenu,
							accountBalance: accountBalance || null,
							accountName: accountName || null,
						}}
						onLogout={handleLogout}
					/>
					<Content>
						<Grid container spacing={gridSpacing}>
							{children}
						</Grid>
					</Content>
					<Footer />
				</Container>
			</AppLayoutBox>
		</>
	);
}
