import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function RefillIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 48 48" {...props}>
			<g clipPath="url(#clip0_3499_24250)">
				<path
					d="M13 36.8999H7V42.8999"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M43.1 15.2999C44.3 17.9999 45 20.8999 45 23.7999C45 29.1999 42.9 34.5999 38.8 38.6999C34.7 42.7999 29.4 44.7999 24 44.7999C18.1 44.8999 13.8 43.0999 10 39.7999C9.3 39.1999 7 36.7999 7 36.7999"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M4.9 32.4C3.7 29.7 3 26.8 3 23.9C3 18.4999 5.1 13.1 9.2 8.99995C13.3 4.89995 18.6 2.89995 24 2.89995C29.9 2.79995 34.2 4.59995 38 7.89995C38.7 8.49995 41 10.9 41 10.9"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M35 10.8999H41V4.8999"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M34.2992 24.1L24.3992 14.2C23.0992 12.9 21.3992 12.2 19.6992 12.2C17.9992 12.2 16.2992 12.9 15.0992 14.1C13.8992 15.3 13.1992 17 13.1992 18.7C13.1992 20.4 13.8992 22.1 15.0992 23.3L15.4992 23.7C14.0992 24.9 13.1992 26.8 13.1992 28.7C13.1992 32.3 16.0992 35.2 19.6992 35.2C21.5992 35.2 23.3992 34.4 24.6992 32.9L25.0992 33.2999C26.2992 34.5 27.9992 35.2 29.6992 35.2C31.3992 35.2 33.0992 34.5 34.2992 33.2999C35.4992 32.0999 36.1992 30.4 36.1992 28.7C36.1992 27 35.4992 25.3 34.2992 24.1ZM32.8992 31.9C31.9992 32.8 30.8992 33.2 29.6992 33.2C28.4992 33.2 27.2992 32.7 26.4992 31.9L25.7992 31.2C26.0992 30.4 26.2992 29.5 26.2992 28.7C26.2992 27.3 25.8992 25.9 24.9992 24.8L28.5992 21.1L32.8992 25.4C33.7992 26.3 34.1992 27.4 34.1992 28.6C34.1992 29.9 33.7992 31.1 32.8992 31.9ZM19.6992 22.2C18.7992 22.2 17.9992 22.4 17.1992 22.7L16.4992 22C15.5992 21.1 15.0992 20 15.0992 18.7C15.0992 17.4 15.5992 16.3 16.4992 15.5C17.3992 14.6 18.4992 14.2 19.6992 14.2C20.8992 14.2 22.0992 14.7 22.8992 15.5L27.1992 19.8L23.5992 23.4C22.3992 22.6 21.0992 22.2 19.6992 22.2ZM20.6992 33.2V24.2C22.7992 24.7 24.2992 26.5 24.2992 28.7C24.2992 30.9 22.6992 32.7 20.6992 33.2ZM15.0992 28.7C15.0992 26.5 16.5992 24.7 18.6992 24.2V33.2C16.6992 32.7 15.0992 30.8 15.0992 28.7Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3499_24250">
					<rect width="48" height="48" fill="white" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
}
