import { postRequest } from '../api-service';
import { endpoints } from '../endpoints';
import {
	LoginParams,
	TwoFactorAuthOptions,
	SendAuthCodeParams,
	ISendEmailRequest,
	ISuccessResponse,
	VerifyAuthCodeParams,
	VerifyAuthCodeResponse,
} from './login.models';

export function getLoginMethod(request: LoginParams): Promise<TwoFactorAuthOptions> {
	const body = {
		email: request.email,
		password: request.password,
		recaptchaToken: request.recaptchaToken,
	};
	return postRequest<TwoFactorAuthOptions>(endpoints.login.loginMethod, body, {});
}

export function sendAuthCode(request: SendAuthCodeParams): Promise<ISuccessResponse> {
	const { sendAuthenticationCode } = endpoints.login;
	const body = {
		email: request.email,
		password: request.password,
		method: request.method,
	};
	return postRequest<ISuccessResponse>(sendAuthenticationCode, body, {});
}

export function verifyAuthCode(request: VerifyAuthCodeParams): Promise<VerifyAuthCodeResponse> {
	const { verifyAuthenticationCode } = endpoints.login;
	const body = {
		email: request.email,
		password: request.password,
		authCode: request.authCode,
	};
	return postRequest<VerifyAuthCodeResponse>(verifyAuthenticationCode, body, {});
}

export function sendEmail(request: ISendEmailRequest): Promise<ISuccessResponse> {
	const endpoint = endpoints.resetPassword.sendEmail;
	const body = {
		email: request.email,
	};
	return postRequest<ISuccessResponse>(endpoint, body, {});
}

export function logoutCall(): Promise<ISuccessResponse> {
	return postRequest<ISuccessResponse>(endpoints.logout, {}, {});
}
