import React, { useCallback } from 'react';
import CircularLoading from 'components/CircularLoading';
import DynamicForm, { IDynamicFormElement } from 'components/common/inputs/dynamicForm/DynamicForm';
import { PaperTitle } from 'components/dashboardLayout';
import {
	CareTeamMember,
	CareTeamMemberSaveParams,
	CareTeamMemberViewParams,
} from 'core/api/enterprise/supervisor.models';
import { useCareTeamMemberQuery, usePatientAccessesListQuery, useCareTeamMemberSaveMutation } from 'hooks';
import Joi from 'joi';
import { FieldValues, UseFormWatch } from 'react-hook-form';

interface CareTeamMemberPatientsAccessProps extends Partial<CareTeamMemberViewParams> {
	onSaveSuccess: (data: CareTeamMember) => void;
}

export function CareTeamMemberPatientsAccess({ id, onSaveSuccess }: CareTeamMemberPatientsAccessProps) {
	const { data: patientAccesses, isLoading: isLoadingPatientAccesses } = usePatientAccessesListQuery();
	const { data: careTeamMember, isLoading: isLoadingCareTeamMember } = useCareTeamMemberQuery({ id });
	const { mutate: careTeamMemberSaveMutate, isPending, error } = useCareTeamMemberSaveMutation();
	const handleCareTeamMemberSave = (props: CareTeamMemberSaveParams) => {
		careTeamMemberSaveMutate(
			{ id, phoneNumber: careTeamMember?.phoneNumber, type: careTeamMember?.type, ...props },
			{
				onSuccess: onSaveSuccess,
			}
		);
	};

	const disabledAllExcept = useCallback((watch: UseFormWatch<FieldValues>, elementName: string): boolean => {
		const fields = {
			patientsAccessIDs: watch('patientsAccessIDs')?.length > 0,
			patientsAccessLocationSfid: !!watch('patientsAccessLocationSfid'),
			patientsAccessStateSfid: !!watch('patientsAccessStateSfid'),
			patientsAccessAll: watch('patientsAccessAll'),
		};

		return Object.entries(fields).some(([key, value]) => key !== elementName && value);
	}, []);

	const formElements: Map<string, IDynamicFormElement> = new Map();
	formElements
		.set('patientsAccessAll', {
			label: 'Patients Access All',
			type: 'switch',
			value: careTeamMember?.patientsAccessAll || false,
			validation: Joi.boolean(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessAll'),
		})
		.set('patientsAccessStateSfid', {
			label: 'Patients Access State Sfid',
			type: 'select',
			value: careTeamMember?.patientsAccessStateSfid || '',
			options: [
				...(Array.isArray(patientAccesses?.states)
					? patientAccesses.states.map(({ sfid, name }) => ({
							value: sfid,
							label: name,
						}))
					: []),
				{ label: 'N/A', value: '' },
			],
			validation: Joi.string().allow('').optional(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessStateSfid'),
		})
		.set('patientsAccessLocationSfid', {
			label: 'Patients Access Location Sfid',
			type: 'select',
			value: careTeamMember?.patientsAccessLocationSfid || '',
			options: patientAccesses?.locations
				? {
						...patientAccesses.locations.reduce(
							(accumulator, locationGroup) => {
								// eslint-disable-next-line no-param-reassign
								accumulator[locationGroup.label] = locationGroup.options.map(({ sfid, name }) => ({
									value: sfid,
									label: name,
								})) as { label: string; value: string }[];
								return accumulator;
							},
							{} as Record<string, { label: string; value: string }[]>
						),
						'-': [{ label: 'N/A', value: '' }],
					}
				: undefined,
			validation: Joi.string().allow('').optional(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessLocationSfid'),
		})
		.set('patientsAccessIDs', {
			label: 'Patients Access IDs',
			type: 'multi-select',
			value: careTeamMember?.patientsAccessIDs || [],
			options:
				(patientAccesses?.patients &&
					patientAccesses.patients.reduce(
						(accumulator, patientGroup) => {
							// eslint-disable-next-line no-param-reassign
							accumulator[patientGroup.label] = patientGroup.options.map((patient) => ({
								label: patient.name,
								value: patient.id,
							})) as { label: string; value: number }[];
							return accumulator;
						},
						{} as Record<string, { label: string; value: number }[]>
					)) ||
				undefined,
			validation: Joi.array().optional(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessIDs'),
		});

	return (
		<>
			<PaperTitle>Assign Patients</PaperTitle>
			{!isLoadingCareTeamMember && !isLoadingPatientAccesses && (
				<DynamicForm
					formElements={formElements}
					formRequestIsLoading={isPending}
					formRequestError={error}
					onSubmitFormHandler={handleCareTeamMemberSave}
				/>
			)}
			<CircularLoading isLoading={isLoadingCareTeamMember && isLoadingPatientAccesses} />
		</>
	);
}

export default CareTeamMemberPatientsAccess;
