import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import routes from 'routes';
import ContentCard from 'components/card/ContentCard';
import SidebarMenu from 'components/sidebarMenu/SidebarMenu';
import LogTodaysMedIcon from 'components/icons/LogTodaysMedIcon';
import LogAsNeededMedIcon from 'components/icons/LogAsNeededMedIcon';
import ManageMedicationRemindersIcon from 'components/icons/ManageMedicationRemindersIcon';
import ReviewMyHistoryIcon from 'components/icons/ReviewMyHistoryIcon';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import useShowSidebarNavigation from 'hooks/useShowSidebarNavigation';

interface MyScheduleLayoutProps {
	children: React.ReactNode;
}

export function MyScheduleLayout({ children }: MyScheduleLayoutProps) {
	const menuItems = [
		{
			label: 'Today’s Medication',
			icon: <LogTodaysMedIcon fontSize="large" />,
			to: routes.mySchedule.logTodaysMed,
			state: { showSidebarNavigation: false },
			activeRoutes: [routes.mySchedule.logTodaysMed, routes.mySchedule.todaysMedDetails],
		},
		{
			label: 'Log As-Needed Medication',
			icon: <LogAsNeededMedIcon fontSize="large" />,
			to: routes.mySchedule.logAsNeededMed,
			activeRoutes: [routes.mySchedule.logAsNeededMed],
		},
		{
			label: 'Manage Medication Reminders',
			icon: <ManageMedicationRemindersIcon fontSize="large" />,
			to: routes.mySchedule.manageMedicationReminders,
			activeRoutes: [routes.mySchedule.manageMedicationReminders],
		},
		{
			label: 'Review My History',
			icon: <ReviewMyHistoryIcon fontSize="large" />,
			to: routes.mySchedule.reviewMyHistory.calendar,
			activeRoutes: [routes.mySchedule.reviewMyHistory.calendar, routes.mySchedule.reviewMyHistory.day],
		},
	];

	const xsScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'));
	const showSidebarNavigation = useShowSidebarNavigation();

	if (xsScreen) {
		if (showSidebarNavigation) {
			return (
				<Grid xs={12}>
					<ContentCard>
						<Typography variant="h1" fontWeight="bold" color="text.secondary">
							My Schedule
						</Typography>
						<Typography variant="body1" color="text.primary">
							Your medication schedule is based on feedback from your prescribers, pharmacists and you. Use this section
							to track if you have taken your medication. This does not replace consultation from your pharmacist or
							provider. Please call us if you have questions or concerns.
						</Typography>
					</ContentCard>
					<ContentCard>
						<SidebarMenu menuItems={menuItems} />
					</ContentCard>
				</Grid>
			);
		}

		return <Grid xs={12}>{children}</Grid>;
	}

	return (
		<>
			<Grid sm={4}>
				<ContentCard>
					<SidebarMenu menuItems={menuItems} />
				</ContentCard>
			</Grid>
			<Grid sm={8}>{children}</Grid>
		</>
	);
}

export default MyScheduleLayout;
