import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Alert, Stack } from '@mui/material';
import { usePatientPhoneMutation } from 'hooks/patient';
import { useUserContext } from 'contexts/UserProvider';
import { ISendChangePhoneRequest } from 'core/api/userAccount/userAccount.models';
import NewMobileNumber from './components/NewMobileNumber';
import VerifyMobileNumber, { IVerifyMobileNumberForm } from './components/VerifyMobileNumber';

export function UpdateMobileNumber() {
	const { createChangePhoneRequest, deleteChangePhoneRequest, resendVerificationCode, verifyPhoneNumber } =
		usePatientPhoneMutation();
	const { phoneNumber = null, pendingPhoneNumber = null, changePhoneRequestId = null } = useUserContext();
	const [showVerifyMobileNumber, setShowVerifyMobileNumber] = useState(!!pendingPhoneNumber);
	const [initialPendingPhoneNumber, setInitialPendingPhoneNumber] = useState<string | null>(null);

	useEffect(() => {
		if (initialPendingPhoneNumber === null && pendingPhoneNumber !== null) {
			setInitialPendingPhoneNumber(pendingPhoneNumber);
		}
	}, [pendingPhoneNumber, initialPendingPhoneNumber]);

	const handleNewMobileNumberAction = useCallback(
		(params: ISendChangePhoneRequest) => {
			createChangePhoneRequest.mutate(params, {
				onSuccess: () => {
					setShowVerifyMobileNumber(true);
					setInitialPendingPhoneNumber(params.phoneNumber);
				},
			});
		},
		[createChangePhoneRequest]
	);

	const handleVerifyMobileNumberAction = useCallback(
		(data: IVerifyMobileNumberForm) => {
			verifyPhoneNumber.mutate(data);
		},
		[verifyPhoneNumber]
	);

	const handleSendCodeAgainAction = useCallback(() => {
		resendVerificationCode.mutate({ id: changePhoneRequestId });
	}, [resendVerificationCode, changePhoneRequestId]);

	const handleUseAnotherPhoneNumberAction = useCallback(() => {
		deleteChangePhoneRequest.mutate(
			{ id: changePhoneRequestId },
			{
				onSuccess: () => {
					setShowVerifyMobileNumber(false);
					setInitialPendingPhoneNumber(null);
				},
			}
		);
	}, [deleteChangePhoneRequest, changePhoneRequestId]);

	const createChangePhoneRequestError = useMemo(
		() => createChangePhoneRequest.error?.message,
		[createChangePhoneRequest.error]
	);
	const resendVerificationCodeError = useMemo(
		() => resendVerificationCode.error?.message,
		[resendVerificationCode.error]
	);
	const verifyPhoneNumberError = useMemo(() => verifyPhoneNumber.error?.message, [verifyPhoneNumber.error]);
	const deleteChangePhoneRequestError = useMemo(
		() => deleteChangePhoneRequest.error?.message,
		[deleteChangePhoneRequest.error]
	);

	return (
		<Stack direction="column" gap={2}>
			{createChangePhoneRequestError && <Alert severity="error">{createChangePhoneRequestError}</Alert>}
			{resendVerificationCodeError && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{resendVerificationCodeError}
				</Alert>
			)}
			{verifyPhoneNumberError && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{verifyPhoneNumberError}
				</Alert>
			)}
			{deleteChangePhoneRequestError && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{deleteChangePhoneRequestError}
				</Alert>
			)}
			{!showVerifyMobileNumber && (
				<NewMobileNumber
					currentPhoneNumber={phoneNumber}
					createChangePhoneRequestIsLoading={createChangePhoneRequest.isPending}
					onNewMobileNumberAction={handleNewMobileNumberAction}
				/>
			)}
			{showVerifyMobileNumber && (
				<VerifyMobileNumber
					pendingPhoneNumber={initialPendingPhoneNumber}
					verifyPhoneNumberIsLoading={verifyPhoneNumber.isPending}
					sendCodeAgainIsLoading={resendVerificationCode.isPending}
					verifyPhoneNumberError={verifyPhoneNumber.error}
					sendCodeAgainError={resendVerificationCode.error}
					showSuccessMessage={verifyPhoneNumber.isSuccess}
					onVerifyMobileNumberAction={handleVerifyMobileNumberAction}
					onSendCodeAgainAction={handleSendCodeAgainAction}
					onUseAnotherPhoneNumberAction={handleUseAnotherPhoneNumberAction}
				/>
			)}
		</Stack>
	);
}

export default UpdateMobileNumber;
