import React from 'react';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

export enum TextFieldStatus {
	ERROR = 'error',
	SUCCESS = 'success',
	INFO = 'info',
	DISABLED = 'disabled',
	NORMAL = 'normal',
}

export interface ITextFieldProps extends MuiTextFieldProps<'outlined'> {
	status?: TextFieldStatus;
	ref?: React.Ref<any>;
}

const TextField = React.forwardRef<HTMLInputElement, ITextFieldProps>(({ ...rest }, ref) => {
	const existingTheme = useTheme();
	const newTheme = createTheme({
		...existingTheme,
		components: {
			...existingTheme.components,
			MuiTextField: {
				styleOverrides: {
					root: {
						'& input[type=number]': {
							MozAppearance: 'textfield',
						},
						'& input[type=number]::-webkit-outer-spin-button': {
							WebkitAppearance: 'none',
							margin: 0,
						},
						'& input[type=number]::-webkit-inner-spin-button': {
							WebkitAppearance: 'none',
							margin: 0,
						},
						'& .MuiInputBase-root': {
							background: existingTheme.palette.background.default,
						},
						'& .MuiSvgIcon-root': {
							color: existingTheme.palette.primary.main,
						},
						'&.Mui-success': {
							color: existingTheme.palette.success.main,
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: existingTheme.palette.success.main,
							},
							'& .MuiSvgIcon-root': {
								color: existingTheme.palette.success.main,
							},
							'& .MuiFormHelperText-root': {
								color: existingTheme.palette.success.main,
							},
						},
						'&.Mui-info': {
							color: 'green',
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: existingTheme.palette.info.main,
							},
							'& .MuiSvgIcon-root': {
								color: existingTheme.palette.info.main,
							},
						},
						'& .Mui-error': {
							'& .MuiSvgIcon-root': {
								color: existingTheme.palette.error.main,
							},
						},
						'& .Mui-disabled': {
							'& .MuiSvgIcon-root': {
								color: existingTheme.palette.grey[500],
							},
						},
					},
				},
			},
		},
	});

	const textFieldStatus = (status: TextFieldStatus) => {
		switch (status) {
			case TextFieldStatus.ERROR:
				return { error: true };
			case TextFieldStatus.SUCCESS:
				return { className: 'Mui-success' };
			case TextFieldStatus.INFO:
				return { className: 'Mui-info' };
			case TextFieldStatus.DISABLED:
				return { disabled: true };
			default:
				return {};
		}
	};

	return (
		<ThemeProvider theme={newTheme}>
			<MuiTextField ref={ref} {...textFieldStatus(rest?.status || TextFieldStatus.NORMAL)} {...rest} />
		</ThemeProvider>
	);
});

export default TextField;
