import React from 'react';

import { CssBaseline, createTheme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { lightTheme } from './mode';
import components from './components';
import breakpoints from './breakpoints';
import shadows from './shadows';
import typography from './typography';

interface IThemeProviderProps {
	children: React.ReactNode;
}

export const currentTheme = lightTheme;

const theme = createTheme(currentTheme, {
	typography,
	shadows,
	breakpoints,
	components,
});

export default function ThemeProvider({ children }: IThemeProviderProps) {
	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</MuiThemeProvider>
	);
}
