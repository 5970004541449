import React, { useState } from 'react';
import ImageCover from 'components/ImageCover';
import { AuthSidebar, LoginForm, TwoFACodeForm, TwoFAMethods } from 'components/auth';
import { UserCredentials, MethodsMap } from 'core/api/login/login.models';

export default function SignInPage() {
	const [userCredentials, setUserCredentials] = useState<UserCredentials | null>(null);
	const [methods, setMethods] = useState<MethodsMap | null>(null);
	const [method, setMethod] = useState<{ methodId: number; methodName: string } | null>(null);
	const isAuthenticated = userCredentials !== null;

	const handleLoginSuccess = (data: UserCredentials & { methods: MethodsMap }) => {
		const { email, password } = data;
		setUserCredentials({ email, password });
		setMethods(data.methods);
	};
	const handleMethodSelect = (methodId: number, methodName: string) => {
		setMethod({ methodId, methodName });
	};
	const handleResetSignInProcess = () => {
		setUserCredentials(null);
		setMethod(null);
	};

	const handleResendCode = () => {
		setMethod(null);
	};

	return (
		<>
			<AuthSidebar>
				{!isAuthenticated && <LoginForm onLoginSuccess={handleLoginSuccess} />}
				{isAuthenticated && methods && !method && (
					<TwoFAMethods
						{...userCredentials}
						methods={methods}
						onSelect={handleMethodSelect}
						onResetSignInProcess={handleResetSignInProcess}
					/>
				)}
				{isAuthenticated && method && (
					<TwoFACodeForm onResendCode={handleResendCode} {...userCredentials} {...method} />
				)}
			</AuthSidebar>
			<ImageCover
				src="/images/auth-seniors.png"
				sx={{
					display: { sm: 'none', md: 'flex' },
					flexGrow: 2,
				}}
			/>
		</>
	);
}
