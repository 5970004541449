import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LogTodaysMedIcon(props: SvgIconProps) {
	return (
		<SvgIcon sx={{ fill: 'none', color: 'text.secondary' }} viewBox="0 0 47 47" {...props}>
			<g id="Group">
				<path
					id="Vector"
					d="M24.5999 44.1C22.4999 42 22.4999 38.7 24.5999 36.6L35.8499 25.5C37.9499 23.4 41.2499 23.4 43.3499 25.5C45.4499 27.6 45.4499 30.9 43.3499 33L32.0999 44.1C29.9999 46.2 26.6999 46.2 24.5999 44.1Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path
					id="Vector_2"
					d="M30.1499 31.05L37.6499 38.4"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path
					id="Vector_3"
					d="M20.25 45.3C9.74995 43.65 1.94995 34.5 1.94995 23.85C1.94995 11.85 11.7 1.95001 23.85 1.95001C35.25 1.95001 44.85 10.65 45.75 22.05"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path
					id="Vector_4"
					d="M18.1499 23.7H23.8499V12.45"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path id="Vector_5" d="M23.85 5.70001V7.50001" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path id="Vector_6" d="M14.7 7.95001L15.6 9.60001" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path
					id="Vector_7"
					d="M7.94995 14.55L9.74995 15.6"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path id="Vector_8" d="M5.69995 23.7H7.64995" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path
					id="Vector_9"
					d="M7.94995 32.85L9.74995 31.8"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path id="Vector_10" d="M14.7 39.6L15.6 37.8" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path id="Vector_11" d="M39.6 14.55L37.95 15.6" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path
					id="Vector_12"
					d="M32.85 7.95001L31.95 9.60001"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
			</g>
		</SvgIcon>
	);
}
