export enum PatientStatus {
	ACTIVE = 'active',
	PRE_REGISTER = 'pre-register',
	SUSPENDED = 'suspended',
	DELETED = 'deleted',
	BLOCKED = 'blocked',
}

export interface IPatient {
	userId: number | null;
	fullName: string | null;
	dateOfBirth: string | null;
	email: string;
	phone: string | null;
	createdAt: string;
	status: PatientStatus;
}

export interface IPatientsList {
	patients: IPatient[];
	total: number;
}

export interface IPatientsRequestActionPayload {
	page: number;
	pageSize: number;
	search?: string;
	dob?: string;
	status?: string;
}

interface IGetPatientRequestActionPayload {
	userId: number;
}

export interface IDeletePatientRequestActionPayload extends IGetPatientRequestActionPayload {}

export interface IUnblockPatientRequestActionPayload extends IGetPatientRequestActionPayload {}

export interface ISuspendPatientRequestActionPayload extends IGetPatientRequestActionPayload {
	suspend: boolean;
}

export interface IChangeEmailPatientRequestActionPayload extends IGetPatientRequestActionPayload {
	email: string;
}

export interface IChangeDateOfBirthPatientRequestActionPayload extends IGetPatientRequestActionPayload {
	dateOfBirth: string | null;
}

export interface IChangePasswordPatientRequestActionPayload extends IGetPatientRequestActionPayload {
	newPassword: string;
	repeatNewPassword: string;
}
