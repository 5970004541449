export const warning = {
	100: '#FFF8EB',
	200: '#FFF1D7',
	300: '#FFE4AF',
	400: '#FFCF74',
	500: '#FFBB38',
	600: '#B38327',
	700: '#664B16',
	800: '#33250B',
	900: '#191306',
};
