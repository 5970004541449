import { Box, BoxProps, styled } from '@mui/material';

interface ImageCoverProps extends BoxProps {
	src: string;
}

export const ImageCover = styled(Box)<ImageCoverProps>(({ src }) => ({
	backgroundImage: `url(${src})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
}));

export default ImageCover;
