export const success = {
	100: '#E8F6EE',
	200: '#D1EEDD',
	300: '#A3DDBC',
	400: '#5DC389',
	500: '#18A957',
	600: '#11763D',
	700: '#0A4423',
	800: '#052211',
	900: '#021109',
};
