import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Stack } from '@mui/material';
import { IAsNeededMed, IAsNeededMedsTaken } from 'core/models/mySchedule/mySchedule.models';
import { NormalizedHydraCollection } from 'utils';
import {
	IAsNeededMedication,
	IDeleteRecordTakenAsNeededMedRequestParams,
	IRecordTakenAsNeededMedRequestParams,
} from 'core/api/mySchedule/mySchedule.models';
import { UseMutateFunction } from '@tanstack/react-query';
import { ISuccessResponse } from 'core/api/api-service.models';
import { AsNeededMedByDayListItem } from './AsNeededMedByDayListItem';

interface AsNeededMedByDayListProps {
	daySelected: Dayjs;
	data?: NormalizedHydraCollection<IAsNeededMedication>;
	onTakeAsNeededMed: UseMutateFunction<ISuccessResponse, Error, IRecordTakenAsNeededMedRequestParams, unknown>;
	onDeleteTakeAsNeededMed: UseMutateFunction<
		ISuccessResponse,
		Error,
		IDeleteRecordTakenAsNeededMedRequestParams,
		unknown
	>;
}

export function AsNeededMedByDayList({
	daySelected,
	data,
	onTakeAsNeededMed,
	onDeleteTakeAsNeededMed,
}: AsNeededMedByDayListProps) {
	const { items: asNeededMedList } = data ?? {};

	const handleChangeTime =
		({ scheduledMedId, scheduledTimeId }: Partial<IAsNeededMed>) =>
		({ scheduledMedTakenId, time }: IAsNeededMedsTaken) => {
			const timeObj = dayjs(time, 'hh:mm A');
			const hourTaken = Number(timeObj.format('h'));
			const minuteTaken = timeObj.minute();
			const meridiem = timeObj.format('A') as 'AM' | 'PM';

			onTakeAsNeededMed({
				id: scheduledMedTakenId,
				scheduledMedId,
				scheduledTimeId,
				hourTaken,
				minuteTaken,
				meridiem,
			});
		};
	const handleDeleteTime = ({ scheduledMedTakenId }: IAsNeededMedsTaken) => {
		if (scheduledMedTakenId > 0) {
			onDeleteTakeAsNeededMed({ id: scheduledMedTakenId });
		}
	};

	return (
		<Stack useFlexGap spacing={2} direction="row" flexWrap="wrap">
			{asNeededMedList &&
				asNeededMedList.map(({ name, scheduledMedId, scheduledTimeId, asNeededMedHistory }) => (
					<AsNeededMedByDayListItem
						key={name}
						name={name}
						date={daySelected.format('MM/DD/YYYY')}
						asNeededMedsTaken={asNeededMedHistory.asNeededMedsTaken}
						onChangeTime={handleChangeTime({ scheduledMedId, scheduledTimeId })}
						onDeleteTime={handleDeleteTime}
					/>
				))}
		</Stack>
	);
}

export default AsNeededMedByDayList;
