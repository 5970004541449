interface IEnterpriseState {
	sfid: string | null;
	name: string | null;
}

export interface IEnterpriseLocation {
	sfid: string | null;
	name: string | null;
}

export interface IEnterprisePatient {
	id: number | null;
	name: string | null;
}

export interface IEnterprise {
	id: number | null;
	sfid: string | null;
	name: string | null;
	states: IEnterpriseState[];
	locations: Record<string, IEnterpriseLocation[]>;
	patients: Record<string, IEnterprisePatient[]>;
}

export interface IEnterprisesList {
	enterprises: Pick<IEnterprise, 'id' | 'sfid' | 'name'>[];
	total: number;
}

export interface IEnterprisesRequestActionPayload {
	page: number;
	pageSize: number;
	name?: string;
}

export interface IGetEnterpriseRequestActionPayload {
	id: number;
}

export enum CareTeamMemberStatus {
	PENDING = 0,
	APPROVED = 1,
	REGISTERED = 2,
}

export interface ICareTeamMember {
	id: number | null;
	enterpriseId: number | null;
	email: string | null;
	name: string | null;
	supervisor: boolean | null;
	status: CareTeamMemberStatus | null;
	patientsAccessAll: boolean | null;
	patientsAccessStateSfid: string | null;
	patientsAccessLocationSfid: string | null;
	patientsAccessIDs: number[] | null;
	phone: string | null;
	lastUpdated: string | null;
}

export interface ICareTeamMembersList {
	careTeamMembers: ICareTeamMember[];
	total: number;
}

export interface ICareTeamMembersRequestActionPayload {
	page: number;
	pageSize: number;
	enterpriseId: number;
	name?: string;
}

export interface IDeleteCareTeamMemberRequestActionPayload {
	id: number;
}

export interface IUpdateEnterpriseRequestActionPayload {
	id: number;
	name: string;
}

export interface IDeleteEnterpriseRequestActionPayload {
	id: number;
}

export interface IEnterpriseRequestActionPayload {
	id: number;
}

export interface ICareTeamMemberRequestActionPayload {
	id: number;
}

export interface ICreateCareTeamMemberRequestActionPayload {
	enterpriseId: number;
	email: string;
	name: string;
	supervisor: boolean;
	status: CareTeamMemberStatus;
	patientsAccessAll: boolean;
	patientsAccessStateSfid: string;
	patientsAccessLocationSfid: string;
	patientsAccessIDs: number[];
}

export interface IUpdateCareTeamMemberRequestActionPayload
	extends ICareTeamMemberRequestActionPayload,
		ICreateCareTeamMemberRequestActionPayload {}

export interface ICreateCareTeamMemberResponse extends ICareTeamMember {}
export interface IUpdateCareTeamMemberResponse extends ICareTeamMember {}
