import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Stack, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import Stepper from 'components/stepper/Stepper';
import {
	RegisterWithPhoneNumber,
	RegisterCreateYourAccount,
	RegisterCurrentStep,
	RegisterSlotComponentProps,
	RegisterSuccess,
	RegisterVerifyPhoneCode,
	RegisterVerifyYourInfo,
	RegisterVerifyEmailCode,
	RegisterSlot,
	AuthSidebar,
	AuthMainContent,
} from 'components/auth';
import { useRegisterCheckStatusQuery, useRegisterHash } from 'hooks/auth';
import CircularLoading from 'components/CircularLoading';
import { getStepByStatus, RegistrationStep } from 'utils/auth.utils';

interface StepContent {
	introText: string;
	extraIntroText: string;
	stepText?: string;
	slot: RegisterSlot;
	slotProps?: RegisterSlotComponentProps;
}

export function RegisterPage() {
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
	const { hash, setHash } = useRegisterHash();
	const { data: initialData, isLoading: statusIsLoading, isError } = useRegisterCheckStatusQuery({ hash });
	const [currentStepIndex, setCurrentStepIndex] = useState<number | null>(null);
	const [userToken, setUserToken] = useState<string | null>('sssss');

	const stepsContent: StepContent[] = useMemo(
		() => [
			{
				introText: 'Let‘s Get Started!',
				extraIntroText: 'Create your account in just a few short steps.',
				stepText: 'Verify Your Information',
				slotProps: { onGetHash: setHash },
				slot: () => RegisterVerifyYourInfo,
			},
			{
				introText: 'You‘re Halfway There!',
				extraIntroText: 'Create your account in just a few short steps.',
				stepText: 'Create Your Account',
				slotProps: { hash },
				slot: () => RegisterCreateYourAccount,
			},
			{
				introText: 'This Step Is Quick!',
				extraIntroText: 'Create your account in just a few short steps.',
				stepText: 'Confirm Your Phone Number',
				slotProps: {
					hash,
					onSuccess: () => setCurrentStepIndex(RegistrationStep.Step4),
				},
				slot: () => RegisterWithPhoneNumber,
			},
			{
				introText: 'Almost Done!',
				extraIntroText: 'Create your account in just a few short steps.',
				stepText: 'Finish Signing Up',
				slotProps: {
					hash,
					onSuccess: ({ jwtToken }) => {
						setUserToken(jwtToken);
						setCurrentStepIndex(RegistrationStep.Finish);
					},
					onBack: () => setCurrentStepIndex(RegistrationStep.Step3),
				},
				slot: ({ phoneNumber }) => (phoneNumber ? RegisterVerifyPhoneCode : RegisterVerifyEmailCode),
			},
			{
				introText: 'Finished!',
				extraIntroText: 'Create your account in just a few short steps.',
				slotProps: { jwtToken: userToken },
				slot: () => RegisterSuccess,
			},
		],
		[hash, userToken]
	);

	const getCurrentStepContent = (stepIndex: number | null): StepContent =>
		stepsContent[stepIndex || RegistrationStep.Step1];

	const stepTitles = useMemo(
		() => stepsContent.map((step) => step?.stepText ?? '').filter((stepText) => stepText),
		[stepsContent]
	);

	useEffect(() => {
		const stepIndex = getStepByStatus(initialData?.status ?? null);

		setCurrentStepIndex(stepIndex);
	}, [initialData?.status]);

	useEffect(() => {
		if (isError) {
			setHash('');
			setCurrentStepIndex(RegistrationStep.Step1);
		}
	}, [isError]);

	return (
		<>
			{upSmallScreen && (
				<AuthSidebar>
					<Stack>
						<Typography component="h1" variant="h1" fontWeight="bold">
							{getCurrentStepContent(currentStepIndex)?.introText}
						</Typography>
						<Typography component="span" color="text.secondary" variant="small">
							{getCurrentStepContent(currentStepIndex)?.extraIntroText}
						</Typography>
					</Stack>
					<Stepper
						steps={stepTitles}
						orientation="vertical"
						activeStep={currentStepIndex || undefined}
						sx={{ padding: '3rem 0' }}
					/>
				</AuthSidebar>
			)}
			<AuthMainContent>
				{upSmallScreen && !!currentStepIndex && (
					<Typography component="h2" variant="h2" fontWeight="bold" color="primary">
						Step {currentStepIndex + 1}
					</Typography>
				)}
				{!upSmallScreen && (
					<>
						<Typography component="h1" variant="h1" fontWeight="bold">
							{getCurrentStepContent(currentStepIndex)?.introText}
						</Typography>
						<Typography component="span" color="text.secondary" variant="small">
							{getCurrentStepContent(currentStepIndex)?.extraIntroText}
						</Typography>
						{!!currentStepIndex && (
							<Typography mt={2} component="h2" variant="h4" fontWeight="bold" color="primary">
								Step {currentStepIndex + 1} of {stepTitles.length}
							</Typography>
						)}
					</>
				)}

				<CircularLoading isLoading={statusIsLoading} />
				{!statusIsLoading && getCurrentStepContent(currentStepIndex) && (
					<RegisterCurrentStep initialData={{ ...initialData }} {...getCurrentStepContent(currentStepIndex)} />
				)}
			</AuthMainContent>
		</>
	);
}

export default RegisterPage;
