import { createTheme } from '@mui/material';
import { error, grey, primary, success, tertiary, warning, white } from '../colors';

export default createTheme({
	palette: {
		mode: 'light',
		background: {
			default: white[100],
			secondary: grey[100],
			paper: grey[100],
		},
		text: {
			primary: grey[600],
			secondary: grey[900],
		},
		divider: grey[300],
		/**
		 * Base colors
		 */
		primary: {
			main: primary[1000],
			contrastText: white[100],
		},
		secondary: {
			main: tertiary[1000],
			contrastText: grey[900],
		},
		/**
		 * Alert colors
		 */
		success: {
			main: success[500],
			contrastText: white[100],
		},
		warning: {
			light: warning[200],
			main: warning[500],
			contrastText: white[100],
		},
		error: {
			light: error[200],
			main: error[500],
			contrastText: white[100],
		},
		/**
		 * Grey swatch
		 */
		grey,
	},
});
