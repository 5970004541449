import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AlertsIcon(props: SvgIconProps) {
	return (
		<SvgIcon x="0px" y="0px" viewBox="0 0 32 32" sx={{ fill: 'none', color: 'text.secondary' }} {...props}>
			<style type="text/css">
				{`
	.st2{display:inline;fill:none;stroke:currentColor;stroke-linejoin:round;stroke-miterlimit:10;}
	.st3{display:inline;fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st4{display:inline;}
	.st5{fill:none;stroke:currentColor;stroke-linejoin:round;stroke-miterlimit:10;}
	.st6{fill:none;stroke:currentColor;stroke-linejoin:bevel;stroke-miterlimit:10;}
	.st7{fill:none;stroke:currentColor;stroke-miterlimit:8;}
           `}
			</style>
			<g>
				<path
					className="st7"
					d="M7.9,9.3C7.3,7.5,4.9,7,4.3,7.4c-0.7,0.4,0.9,3.2-0.9,8.5c-0.7,2.1-1.4,4.2-1.5,6C1.4,28.8,8,32.2,12.4,30.4
		l6.4-2.8"
				/>
				<path
					className="st7"
					d="M24.7,19.4c-0.2-0.6-0.8-1-1.4-1c-0.2,0-0.4,0-0.6,0.1L21,19.3c-0.8,0.4-1.2,1.3-0.8,2c0.2,0.6,0.8,1,1.4,1
		c0.2,0,0.4,0,0.6-0.1l1.7-0.7C24.6,21.1,25,20.2,24.7,19.4L24.7,19.4z"
				/>
				<path
					className="st7"
					d="M22.2,25.8l1.5-0.6c0.7-0.3,1-1.1,0.7-1.8c-0.2-0.5-0.7-0.9-1.3-0.9c-0.2,0-0.5,0.1-0.5,0.1l-1.5,0.6
		c-0.7,0.3-1,1.1-0.7,1.8c0.2,0.5,0.7,0.9,1.3,0.9C21.9,25.9,22.2,25.8,22.2,25.8L22.2,25.8z"
				/>
				<path
					className="st7"
					d="M20.3,17.4c0.2,0.6,0.8,1,1.4,0.9c0,0,0.4,0,0.6-0.1l1.1-0.5c0.8-0.3,1.1-1.3,0.8-2c-0.2-0.6-0.9-1.1-1.5-1
		c0,0-0.3,0.1-0.5,0.2l-1.1,0.5C20.3,15.7,19.9,16.6,20.3,17.4L20.3,17.4z"
				/>
				<line className="st7" x1="13.1" y1="25.4" x2="16.4" y2="25.4" />
				<line className="st7" x1="7.8" y1="23.4" x2="20.6" y2="23.4" />
				<line className="st7" x1="7.8" y1="7.3" x2="12.8" y2="7.3" />
				<path
					className="st7"
					d="M19,15c0.4-0.1,11-2.4,11-7.9c0-1.3-0.5-2.5-1.5-3.5C26.9,2,24.2,1,21.4,1c-4.7,0-8.6,2.7-8.6,6.1
		c0,0.9,0.3,3.1,3.3,3.8c2.3,0.5,3,1.3,3.1,1.8c0.2,0.8-0.6,1.6-0.6,1.6c-0.1,0.1-0.2,0.4-0.1,0.5C18.6,14.9,18.8,15,19,15L19,15z"
				/>
				<line className="st7" x1="16.9" y1="5.3" x2="25.7" y2="5.3" />
				<line className="st7" x1="16.9" y1="8.3" x2="25.7" y2="8.3" />
				<path
					className="st7"
					d="M14.2,3.8c-1.3,0-3.3,0.1-4.8,0.3C8.5,4.2,7.9,5,7.9,5.9v19.7c0,0.9,0.7,1.7,1.5,1.8c1.9,0.2,3.8,0.3,5.7,0.3
		c1.6,0,3.3-0.1,4.9-0.2c0.9-0.1,1.8-0.7,1.8-1.6"
				/>
			</g>
		</SvgIcon>
	);
}
