import React, { useCallback, useState } from 'react';
import { IAsNeededMedication } from 'core/api/mySchedule/mySchedule.models';
import { useDialog } from 'hooks';
import { useAsNeededMedListQuery, useTakeAsNeededMedMutation } from 'hooks/patient';
import { MedsList } from 'components/medsList';
import Dialog from 'components/dialog';
import { AsNeededMedDirections, AsNeededMedHistory, AsNeededMedTake } from 'components/patient/mySchedule';
import { Theme, useMediaQuery } from '@mui/material';
import { AsNeededMedListItem } from './AsNeededMedListItem';
import AsNeededMedListItemMobile from './AsNeededMedListItemMobile';

export function AsNeededMedList() {
	const { mutate: takeAsNeededMed } = useTakeAsNeededMedMutation();
	const { data } = useAsNeededMedListQuery({});
	const { items: asNeededMedList } = data ?? {};
	const [scheduledMedIdUpdated, setScheduledMedIdUpdated] = useState<number | null>(null);
	const [
		isOpenAsNeededMedHistoryDialog,
		openAsNeededMedHistoryDialog,
		closeAsNeededMedHistoryDialog,
		asNeededMedHistory,
	] = useDialog<IAsNeededMedication>();
	const [
		isOpenAsNeededMedDirectionsDialog,
		openAsNeededMedDirectionsDialog,
		closeAsNeededMedDirectionsDialog,
		asNeededMedDirections,
	] = useDialog<IAsNeededMedication>();
	const [isOpenAsNeededMedTakeDialog, openAsNeededMedTakeDialog, closeAsNeededMedTakeDialog, asNeededMedTakeData] =
		useDialog<IAsNeededMedication>();

	const handleRecordTaken = useCallback(
		(force = false) =>
			(asNeededMedData: IAsNeededMedication) => {
				setScheduledMedIdUpdated(null);

				const takeMedication = () => {
					takeAsNeededMed(
						{
							scheduledMedId: asNeededMedData.scheduledMedId,
							scheduledTimeId: asNeededMedData.scheduledTimeId,
						},
						{
							onSuccess: () => setScheduledMedIdUpdated(asNeededMedData.scheduledMedId),
							onError: () => setScheduledMedIdUpdated(null),
						}
					);
				};

				if (force) {
					takeMedication();
					return;
				}

				const warnTime = new Date(asNeededMedData.warnIfTakenBefore);
				const currentTime = new Date();
				const timeDifference = currentTime.getTime() - warnTime.getTime();

				// 1 hour = 3600000 milliseconds
				if (timeDifference < 3600000) {
					openAsNeededMedTakeDialog(asNeededMedData);
				} else {
					takeMedication();
				}
			},
		[takeAsNeededMed, setScheduledMedIdUpdated, openAsNeededMedTakeDialog]
	);

	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	return (
		<>
			<MedsList>
				{asNeededMedList?.map((asNeededMedication) =>
					upSmallScreen ? (
						<AsNeededMedListItem
							key={asNeededMedication.name}
							onRecordTaken={handleRecordTaken()}
							onViewDirections={openAsNeededMedDirectionsDialog}
							onViewUsageHistory={openAsNeededMedHistoryDialog}
							showUpdateMessage={scheduledMedIdUpdated === asNeededMedication.scheduledMedId}
							{...asNeededMedication}
						/>
					) : (
						<AsNeededMedListItemMobile
							key={asNeededMedication.name}
							onRecordTaken={handleRecordTaken()}
							showUpdateMessage={scheduledMedIdUpdated === asNeededMedication.scheduledMedId}
							{...asNeededMedication}
						/>
					)
				)}
			</MedsList>

			<Dialog
				fullWidth
				maxWidth="xs"
				title={asNeededMedHistory?.name}
				open={isOpenAsNeededMedHistoryDialog}
				onClose={closeAsNeededMedHistoryDialog}
			>
				{asNeededMedHistory && <AsNeededMedHistory {...asNeededMedHistory} />}
			</Dialog>

			<Dialog
				fullWidth
				maxWidth="xs"
				title={asNeededMedDirections?.name}
				open={isOpenAsNeededMedDirectionsDialog}
				onClose={closeAsNeededMedDirectionsDialog}
			>
				{asNeededMedDirections && <AsNeededMedDirections {...asNeededMedDirections} />}
			</Dialog>

			<Dialog
				fullWidth
				maxWidth="xs"
				title={asNeededMedTakeData?.name}
				open={isOpenAsNeededMedTakeDialog}
				onClose={closeAsNeededMedTakeDialog}
			>
				{asNeededMedTakeData && (
					<AsNeededMedTake
						onRecordTaken={handleRecordTaken(true)}
						onCancel={closeAsNeededMedTakeDialog}
						{...asNeededMedTakeData}
					/>
				)}
			</Dialog>
		</>
	);
}

export default AsNeededMedList;
