import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Box, Divider, ListItemText, ListItemIcon, Typography, Stack } from '@mui/material';
import { ArrowIcon } from 'components/icons/ArrowIcon';
import routes from 'routes';
import { PillBottleIcon } from 'components/icons/PillBottleIcon';
import { PillExactPackIcon } from 'components/icons/PillExactPackIcon';
import { DisplayOrderStatus, DeliveryRecordType } from 'core/models/delivery/delivery.models';
import SidebarListItemWrapper from 'components/sidebarList/SidebarListItemWrapper';
import SidebarListItem from 'components/sidebarList/SidebarListItem';
import SidebarList from 'components/sidebarList/SidebarList';
import { IAPIPastDelivery, IAPIUpcomingDelivery } from 'core/api/delivery/delivery.models';

interface IOrderListItemProps {
	orderSfid: string;
	orderName: string | null;
	orderNumber: string | null;
	orderStatus: string | null;
	displayStatus: string | null;
	orderType: string | null;
	activeOrder?: string;
}

function OrderListItem({
	orderStatus,
	orderName,
	orderNumber,
	displayStatus,
	orderSfid,
	orderType,
	activeOrder,
}: IOrderListItemProps) {
	const className = activeOrder === orderSfid ? 'active' : '';

	return (
		<SidebarListItem
			component={RouterLink}
			to={generatePath(routes.delivery, {
				sfid: orderSfid,
			})}
			state={{ showSidebarNavigation: false }}
			className={className}
		>
			<SidebarListItemWrapper
				gap="12px"
				sx={{
					minHeight: { xs: '34px', sm: '50px' },
					justifyContent: 'center',
				}}
			>
				{orderType && orderName && (
					<ListItemIcon
						sx={{
							minWidth: 'unset',
							'& .MuiSvgIcon-root': {
								fontSize: '3rem',
								fill: 'transparent',
								color: (theme) => (activeOrder === orderSfid ? theme.palette.primary.main : theme.palette.text.primary),
							},
						}}
					>
						{orderType === DeliveryRecordType.ROUTINE ? <PillExactPackIcon /> : <PillBottleIcon />}
					</ListItemIcon>
				)}
				<ListItemText
					sx={{
						display: 'flex',
						flexDirection: 'column',
						margin: 0,
					}}
					disableTypography
					primary={
						<Typography variant="h2" fontWeight={600} color="text.secondary">
							{orderName}
						</Typography>
					}
					secondary={
						<Typography variant="body1" color="grey.600">
							Order: {orderNumber}
						</Typography>
					}
				/>
				<ListItemIcon sx={{ minWidth: 'unset' }}>
					<ArrowIcon />
				</ListItemIcon>
			</SidebarListItemWrapper>
			<Divider />
			<Stack direction="row" gap="6px">
				<Typography fontWeight={700} variant="body1" component="b" color="text.secondary">
					Status:
				</Typography>
				<Typography
					component="span"
					variant="body1"
					sx={{
						color: (theme) =>
							orderStatus === DisplayOrderStatus.POSSIBLE_DELIVERY_ISSUE
								? theme.palette.error.main
								: theme.palette.text.secondary,
					}}
				>
					{displayStatus}
				</Typography>
			</Stack>
		</SidebarListItem>
	);
}

interface IDeliveriesProps {
	hasOrderItems: boolean;
	title: string;
	placeholder?: string;
	items?: IAPIUpcomingDelivery[] | IAPIPastDelivery[];
	activeOrder?: string;
}

export function DeliveriesList({ hasOrderItems, title, items, activeOrder, placeholder = '' }: IDeliveriesProps) {
	return (
		<Stack gap="12px">
			<Typography component="h2" variant="h1" fontWeight="bold" color="text.secondary">
				{title}
			</Typography>
			<Box>
				{!hasOrderItems ? (
					<Typography sx={{ p: '15px' }}>{placeholder}</Typography>
				) : (
					<SidebarList>
						{items?.map(
							(order) =>
								order.sfid && (
									<OrderListItem
										key={order.sfid}
										orderSfid={order.sfid}
										orderName={order.orderName}
										orderNumber={order.orderNumber}
										orderStatus={order.orderStatus}
										displayStatus={order.displayStatus}
										activeOrder={activeOrder}
										orderType={order.recordType}
									/>
								)
						)}
					</SidebarList>
				)}
			</Box>
		</Stack>
	);
}

export default DeliveriesList;
