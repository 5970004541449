import { bearerTokenSubject } from 'hooks/useBearerToken';

// Hash
export function setUserHashCache(token: string): void {
	localStorage.setItem('userHash', token);
}
export function getUserHashCache(): string | null {
	return localStorage.getItem('userHash');
}
export function deleteUserHashCache(): void {
	localStorage.removeItem('userHash');
}

// Bearer
export function isBearerCache(): boolean {
	return !!localStorage.getItem('bearer');
}

export function setBearerCache(token: string): void {
	localStorage.setItem('bearer', token);
	bearerTokenSubject.next(token);
}

export function getBearerCache(): string | null {
	return localStorage.getItem('bearer');
}

export function deleteBearerCache(): void {
	localStorage.removeItem('bearer');
	bearerTokenSubject.next(null);
}
