import React, { useState } from 'react';
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Eg:
// 1000 * 1 = 1 second
// 1000 * 60 * 1 = 1 minute
const STALE_TIME = 1000 * 60 * 1;
const REFETCH_INTERVAL = 1000 * 60 * 5;

export const queryClientConfig: QueryClientConfig = {
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			staleTime: STALE_TIME,
			refetchInterval: REFETCH_INTERVAL,
			retry: 0,
		},
		mutations: {
			onError: (error: Error, variables, context) => {
				console.error(error.message, variables, context);
			},
		},
	},
};

export function QueryProvider({ children }: React.PropsWithChildren) {
	const [queryClient] = useState(() => new QueryClient(queryClientConfig));

	return (
		<QueryClientProvider client={queryClient}>
			{children}
			{process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
		</QueryClientProvider>
	);
}

export default QueryProvider;
