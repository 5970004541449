import React from 'react';
import { SidebarList, SidebarListItem } from 'components/sidebarList';
import { ListItem, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useUserContext } from 'contexts/UserProvider';
import InfoCardWidget from 'components/InfoCardWidget';
import { MailIcon } from 'components/icons/MailIcon';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { CurrentBalanceWidget } from '../CurrentBalanceWidget';
import { MedicationLoggedThisWeekWidget } from '../MedicationLoggedThisWeekWidget';
import { RefillAlertsWidget } from '../RefillAlertsWidget';
import LogTodaysMedicationWidget from '../LogTodaysMedicationWidget';

export function InboxPageSidebar() {
	const { fullName, homepage } = useUserContext();
	const currentDate = dayjs().format('dddd, MMMM D');

	const widgets = [RefillAlertsWidget, LogTodaysMedicationWidget, MedicationLoggedThisWeekWidget, CurrentBalanceWidget];

	return (
		<SidebarList>
			<ListItem sx={{ p: '3px 0px 12px 0px', justifyContent: 'space-between' }}>
				<Typography variant="h3" fontWeight="bold" color="text.secondary">
					Welcome, {fullName}
				</Typography>
				<Typography variant="body1">{currentDate}</Typography>
			</ListItem>
			<SidebarListItem
				to={routes.inbox.list}
				state={{ showSidebarNavigation: false }}
				component={RouterLink}
				sx={{ display: { sm: 'none' } }}
			>
				<InfoCardWidget title="Messages" icon={<MailIcon />} />
			</SidebarListItem>
			{homepage && widgets.map((Widget) => <Widget key={Widget.name} />)}
		</SidebarList>
	);
}

export default InboxPageSidebar;
