import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAccountRequest } from 'core/api/userAccount/userAccount.api';

export function useDeleteAccountMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteAccountRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
	});
}

export default useDeleteAccountMutation;
