import { useMutation, useQueryClient } from '@tanstack/react-query';
import { recordTaken } from 'core/api/mySchedule/mySchedule.api';

export function useTakeMedMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['schedule', 'take'],
		mutationFn: recordTaken,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['schedule'] });
		},
	});
}

export default useTakeMedMutation;
