import React, { useRef, useEffect } from 'react';
import TextField, { ITextFieldProps } from '../textField/TextField';

export interface ICodeFieldProps extends Omit<ITextFieldProps, 'onFocus' | 'onPaste' | 'onChange'> {
	index: number;
	value: string;
	onChange: (index: number, value: string) => void;
	onPaste: (pastedValue: string) => void;
	onBackspace: () => void;
	isFocused: boolean;
	onFocus: (index: number) => void;
}

function CodeField({
	index,
	value,
	onChange,
	onPaste,
	onBackspace,
	isFocused,
	onFocus,
	...textFieldProps
}: ICodeFieldProps) {
	const ref = useRef<HTMLInputElement | null>();

	useEffect(() => {
		if (ref.current && ref.current !== document.activeElement && isFocused) {
			ref.current.focus();
		}
	}, [isFocused]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		const isNumeric = /^[0-9]+$/.test(inputValue);

		if (isNumeric) {
			onChange(index, inputValue);
		}
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		onPaste(event.clipboardData.getData('text'));
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Backspace') {
			event.preventDefault();
			onBackspace();
		}
	};

	const handleFocus = () => {
		onFocus(index);
	};

	return (
		<TextField
			inputRef={ref}
			key={index}
			value={value}
			onChange={handleChange}
			onPaste={handlePaste}
			onKeyDown={handleKeyDown}
			inputProps={{ type: 'number', maxLength: 1, autoComplete: 'off', pattern: '[0-9]*', inputMode: 'numeric' }}
			onFocus={handleFocus}
			{...textFieldProps}
		/>
	);
}

export default CodeField;
