import { List } from '@mui/material';
import { styled } from '@mui/material/styles';

const SidebarList = styled(List)(() => ({
	padding: 0,
	gap: '12px',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
}));

export default SidebarList;
