import { useMutation } from '@tanstack/react-query';
import { sendEmail } from 'core/api/login/login.api';

export function useRequestResetPasswordMutation() {
	return useMutation({
		mutationKey: ['requestResetPassword'],
		mutationFn: sendEmail,
	});
}

export default useRequestResetPasswordMutation;
