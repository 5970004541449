import { useQuery } from '@tanstack/react-query';
import { patientNotificationList } from 'core/api/admin/patients.api';
import { PatientNotification, PatientNotificationsParams } from 'core/api/admin/patients.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export function usePatientNotificationListQuery({ page, patientId, ...restRequest }: PatientNotificationsParams) {
	return useQuery({
		queryKey: ['admin', 'patient', 'view', patientId, 'notification', 'list', restRequest, page],
		queryFn: () => patientNotificationList({ page, patientId, ...restRequest }),
		select: normalizeHydraCollection<PatientNotification>,
		enabled: !!patientId,
	});
}

export default usePatientNotificationListQuery;
