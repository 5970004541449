import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ContentCard from 'components/card/ContentCard';
import useShowSidebarNavigation from 'hooks/useShowSidebarNavigation';
import { InboxPageSidebar } from '../InboxPageSidebar';

interface InboxLayoutProps {
	children: React.ReactNode;
}

export function InboxLayout({ children }: InboxLayoutProps) {
	const theme = useTheme();
	const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
	const showSidebarNavigation = useShowSidebarNavigation();

	if (xsScreen) {
		if (showSidebarNavigation) {
			return (
				<Grid xs={12}>
					<ContentCard>
						<InboxPageSidebar />
					</ContentCard>
				</Grid>
			);
		}

		return <Grid xs={12}>{children}</Grid>;
	}

	return (
		<>
			<Grid sm={4}>
				<ContentCard>
					<InboxPageSidebar />
				</ContentCard>
			</Grid>
			<Grid sm={8}>{children}</Grid>
		</>
	);
}

export default InboxLayout;
