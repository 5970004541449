import {
	muiButtonStyle,
	muiTooltipStyle,
	muiAccordionStyle,
	muiDialogStyle,
	muiListItemTextStyle,
	muiTextFieldStyle,
} from './componentStyles';

export default {
	MuiButton: {
		styleOverrides: muiButtonStyle,
	},
	MuiTooltip: {
		styleOverrides: muiTooltipStyle,
	},
	MuiAccordion: {
		styleOverrides: muiAccordionStyle,
	},
	MuiDialog: {
		styleOverrides: muiDialogStyle,
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				padding: '0 24px 32px 24px',
			},
		},
	},
	MuiListItemText: {
		styleOverrides: muiListItemTextStyle,
	},
	MuiTextField: {
		styleOverrides: muiTextFieldStyle,
	},
};
