import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface ICofirmationDialogProps {
	open: boolean;
	isLoading?: boolean;
	title: string;
	content?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
}
function ConfirmationDialog({ open, isLoading, title, content, onConfirm, onCancel }: ICofirmationDialogProps) {
	return (
		<Dialog open={open} onClose={onCancel}>
			<DialogTitle>{title}</DialogTitle>
			{content && (
				<DialogContent>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
			)}
			<DialogActions sx={{ gap: 2 }}>
				<Button onClick={onCancel} color="primary" variant="text">
					No
				</Button>
				<Button disabled={isLoading} onClick={onConfirm} color="primary" variant="contained" autoFocus>
					{isLoading ? 'Loading...' : 'Yes'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmationDialog;
