import { useQuery } from '@tanstack/react-query';
import { IGetDeliveriesSummaryRequestParams } from 'core/api/delivery/delivery.models';
import { deliveryQueries } from 'queries/patient';

export function useDeliveries() {
	const getDeliveriesQuery = () => {
		return useQuery(deliveryQueries.getDeliveries());
	};

	const getDeliveryQuery = (props: Partial<IGetDeliveriesSummaryRequestParams>) => {
		return useQuery(deliveryQueries.getDelivery(props));
	};

	return {
		getDeliveriesQuery,
		getDeliveryQuery,
	};
}

export default useDeliveries;
