import { queryOptions } from '@tanstack/react-query';
import { getDeliveryList, getDeliverySummary } from 'core/api/delivery/delivery.api';
import { IGetDeliveriesSummaryRequestParams } from 'core/api/delivery/delivery.models';

export const deliveryQueries = {
	getDeliveries: () =>
		queryOptions({
			queryKey: ['deliveries', 'list'],
			queryFn: getDeliveryList,
		}),
	getDelivery: ({ sfid }: Partial<IGetDeliveriesSummaryRequestParams>) =>
		queryOptions({
			queryKey: ['deliveries', 'item', sfid],
			queryFn: () => getDeliverySummary({ sfid: sfid || '' }),
			enabled: !!sfid,
		}),
};
