import { useQuery } from '@tanstack/react-query';
import { InboxNotificationParams, getInboxNotification } from 'core/api/inboxNotification';

export function useInboxNotificationQuery({ id }: InboxNotificationParams) {
	return useQuery({
		queryKey: ['inbox', 'message', id],
		queryFn: () => getInboxNotification({ id }),
		enabled: !!id,
	});
}

export default useInboxNotificationQuery;
