import { useQuery } from '@tanstack/react-query';
import { careTeamMemberView } from 'core/api/enterprise/supervisor.api';
import { CareTeamMemberViewParams } from 'core/api/enterprise/supervisor.models';

export function useCareTeamMemberQuery({ id }: Partial<CareTeamMemberViewParams>) {
	return useQuery({
		queryKey: ['enterprise', 'careTeamMember', 'view', id],
		queryFn: () => careTeamMemberView({ id } as CareTeamMemberViewParams),
		enabled: !!id,
	});
}

export default useCareTeamMemberQuery;
