import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteRecordTakenAsNeededMed } from 'core/api/mySchedule/mySchedule.api';

export function useDeleteTakeAsNeededMedMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['asNeededMed', 'delete'],
		mutationFn: deleteRecordTakenAsNeededMed,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['asNeededMed', 'list'] });
		},
	});
}

export default useDeleteTakeAsNeededMedMutation;
