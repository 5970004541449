import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface PaperTitleProps extends TypographyProps {
	children: React.ReactNode;
}

export function PaperTitle({ children, ...restProps }: PaperTitleProps) {
	return (
		<Typography variant="h6" component="h2" sx={{ mb: 2 }} {...restProps}>
			{children}
		</Typography>
	);
}

export default PaperTitle;
