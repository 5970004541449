import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar, PickersDayProps } from '@mui/x-date-pickers';
import { IMedHistoryDay } from 'core/api/mySchedule/mySchedule.models';
import { SchedulerDay } from './SchedulerDay';

interface SchedulerProps {
	data?: IMedHistoryDay[];
	isLoading: boolean;
	onDateClick: (date: Dayjs | null) => void;
	onMonthChange: (date: Dayjs | null) => void;
}

export function Scheduler({ data, isLoading, onDateClick, onMonthChange }: SchedulerProps) {
	const minDate = dayjs().startOf('month').subtract(2, 'month');
	const maxDate = dayjs();

	const getDataForDay = (day: string) => data?.filter((item) => item.day === day)[0];

	return (
		<DateCalendar
			onChange={onDateClick}
			onMonthChange={onMonthChange}
			dayOfWeekFormatter={(day: string, date: Dayjs) => date.format('ddd').toUpperCase()}
			minDate={minDate}
			maxDate={maxDate}
			reduceAnimations
			loading={isLoading}
			views={['day']}
			slots={{
				day: SchedulerDay as React.ComponentType<PickersDayProps<Dayjs>>,
			}}
			slotProps={{
				day: (date) => ({
					...date,
					...getDataForDay(date.day.format('YYYY-MM-DD')),
				}),
			}}
			sx={{
				width: '100%',
				maxHeight: 'none',
				height: 'auto',
				overflow: 'visible',
				'& .MuiDayCalendar-header': {
					gap: '12px',
				},
				'& .MuiPickersCalendarHeader-root': {
					padding: 0,
				},
				'& .MuiPickersCalendarHeader-labelContainer': {
					color: 'text.secondary',
					fontSize: (theme) => theme.typography.h2.fontSize,
					lineHeight: (theme) => theme.typography.h2.lineHeight,
				},
				'& .MuiDayCalendar-weekDayLabel': {
					flexGrow: 1,
					width: '14.28%',
					overflow: 'hidden',
					color: 'text.primary',
				},
				'& .MuiDayCalendar-weekContainer': {
					gap: '12px',
					margin: 0,
				},
				'& .MuiDayCalendar-monthContainer': {
					overflow: 'visible',
					gap: '12px',
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		/>
	);
}

export default Scheduler;
