import React from 'react';
import { Grid } from '@mui/material';
import { CareTeamMemberList } from 'components/enterprise';
import { withPageContext } from 'hoc';

function CareTeamMembersPage() {
	return (
		<Grid item xs={12}>
			<CareTeamMemberList />
		</Grid>
	);
}

export default withPageContext(CareTeamMembersPage);
