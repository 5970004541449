export function stringAvatar(name: string) {
	return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

	// {
	// 	sx: {
	// 		bgcolor: stringToColor(name),
	// 	},
	// 	children: ,
	// };
}
