import React from 'react';
import { Box, styled, Typography } from '@mui/material';

const FooterApp = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	borderRadius: '20px 20px 0px 0px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	padding: '10px 20px',
	textAlign: 'right',
	[theme.breakpoints.down('sm')]: {
		marginBottom: '100px',
		boxShadow: 'none',
		backgroundColor: 'transparent',
		textAlign: 'center',
	},
}));

export default function Footer() {
	const currentYear = new Date().getFullYear();

	return (
		<FooterApp>
			<Typography variant="body1">Copyright &copy;{currentYear} ExactCare Pharmacy</Typography>
		</FooterApp>
	);
}
