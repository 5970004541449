import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export interface IImageWithPreviewProps {
	children: React.ReactNode;
	source: string | null;
	title?: string | null;
	onClick?: () => void;
}

function ImageWithPreview({ children, source, title, onClick }: IImageWithPreviewProps) {
	const [showImagePreview, setShowImagePreview] = useState<boolean>(false);
	const handleOpen = () => {
		setShowImagePreview(true);
		if (onClick) {
			onClick();
		}
	};
	const handleClose = () => {
		setShowImagePreview(false);
	};

	if (!source) {
		return null;
	}

	return (
		<>
			<Box className="imagePreview" component="div" onClick={handleOpen} sx={{ cursor: 'zoom-in' }}>
				{children}
			</Box>
			<Dialog open={showImagePreview} onClose={handleClose} scroll="paper" maxWidth="md">
				<DialogTitle color="primary" fontWeight={700}>
					{title || ''}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<Box
						component="img"
						src={source}
						alt={title || ''}
						sx={{ maxWidth: '100%', maxHeight: 'calc(100vh - 64px)' }}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default ImageWithPreview;
