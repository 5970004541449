import React from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import routes from 'routes';
import ContentCard from 'components/card/ContentCard';
import SidebarMenu from 'components/sidebarMenu/SidebarMenu';
import PersonalInfoIcon from 'components/icons/PersonalInfoIcon';
import AlertsIcon from 'components/icons/AlertsIcon';
import StatementIcon from 'components/icons/StatementIcon';
import { Theme, useMediaQuery } from '@mui/material';
import useShowSidebarNavigation from 'hooks/useShowSidebarNavigation';

interface AccountLayoutProps {
	children: React.ReactNode;
}

export function AccountLayout({ children }: AccountLayoutProps) {
	const menuItems = [
		{
			label: 'Personal Information',
			icon: <PersonalInfoIcon fontSize="large" />,
			to: routes.account.personalInformation,
			state: { showSidebarNavigation: false },
			activeRoutes: [routes.account.personalInformation],
		},
		{
			label: 'Notifications Preferences',
			icon: <AlertsIcon fontSize="large" />,
			to: routes.account.notificationsPreferences,
			activeRoutes: [routes.account.notificationsPreferences],
		},
		{
			label: 'Payments and Billing',
			icon: <StatementIcon fontSize="large" />,
			to: routes.account.paymentsAndBilling,
			activeRoutes: [routes.account.paymentsAndBilling],
		},
	];

	const xsScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'));
	const showSidebarNavigation = useShowSidebarNavigation();

	if (xsScreen) {
		if (showSidebarNavigation) {
			return (
				<Grid xs={12}>
					<ContentCard title="Account">
						<SidebarMenu menuItems={menuItems} />
					</ContentCard>
				</Grid>
			);
		}

		return <Grid xs={12}>{children}</Grid>;
	}

	return (
		<>
			<Grid sm={4} xs={12}>
				<ContentCard title="Account">
					<SidebarMenu menuItems={menuItems} />
				</ContentCard>
			</Grid>
			<Grid sm={8} xs={12}>
				{children}
			</Grid>
		</>
	);
}

export default AccountLayout;
