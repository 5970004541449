import React, { useEffect, useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useBearerToken } from 'hooks';

export interface RegisterSuccessProps {
	jwtToken: string | null;
}

export function RegisterSuccess({ jwtToken }: RegisterSuccessProps) {
	const { updateToken } = useBearerToken();
	const redirectSec = 10;
	const [redirectCountDown, setRedirectCountDown] = useState(redirectSec);
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	const handleLogin = () => {
		updateToken(jwtToken);
		setRedirectCountDown(0);
	};

	useEffect(() => {
		if (redirectCountDown > 0) {
			const timer = setTimeout(() => setRedirectCountDown(redirectCountDown - 1), 1000);
			return () => clearTimeout(timer);
		}

		handleLogin();

		return () => {};
	}, [redirectCountDown]);

	return (
		<>
			<Typography component="h2" variant={upSmallScreen ? 'h2' : 'h4'} fontWeight="bold" color="primary">
				Thank You
			</Typography>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Your Account Has Been Created.
			</Typography>
			<Box mt="20px">
				<Typography component="span" variant="input2" color="text.secondary">
					{redirectCountDown > 0
						? `You will be redirected to your ExactCare account in ${redirectCountDown} seconds`
						: `Redirecting now...`}
					, or{' '}
					<Button onClick={handleLogin} variant="text">
						click here
					</Button>{' '}
					to go there now.
				</Typography>
			</Box>
		</>
	);
}

export default RegisterSuccess;
