import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function EveningIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M10.3 2.95002C10.3 4.75002 10.6 6.45002 11.2 8.15002C11.95 10.2 13.5 11.5 15.4 12.5C17.35 13.55 19.4 14 21.6 14.05C21.95 14.05 22.3 14.05 22.65 14.05C23.5 14.1 23.9 14.7 23.75 15.5C23.7 15.65 23.65 15.8 23.6 15.9C21.75 20.4 17.5 23.7 12.3 23.65C6.15001 23.6 1.50001 19 0.650014 13.6C-0.199986 8.15002 2.85001 2.65002 8.10001 0.600016C8.35001 0.500016 8.65001 0.400016 8.95001 0.350016C9.70001 0.200016 10.25 0.650016 10.3 1.35002C10.35 1.90002 10.3 2.40002 10.3 2.95002ZM7.95001 3.20002C4.65001 4.80002 1.85001 8.90002 3.05001 14.05C4.10001 18.5 8.25001 21.55 12.8 21.35C16.2 21.2 20.3 18.55 20.65 16.3C18.95 16.2 17.35 15.8 15.75 15.2C11.85 13.65 9.20001 10.95 8.40001 6.70002C8.15001 5.55002 8.15001 4.40002 7.95001 3.20002Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
