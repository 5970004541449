import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { changePassword, createAdminsRequest, deleteAdmin, updateAdmin } from 'core/api/admin/admins.api';
import { IAdminsListRequest } from 'core/api/admin/admins.models';
import { adminQueries } from 'queries/admin/adminQueries';

export function useAdminActions() {
	const queryClient = useQueryClient();

	const adminsListQuery = (props: IAdminsListRequest) => {
		return useQuery(adminQueries.adminsList(props));
	};

	const createAdminMutation = useMutation({
		mutationFn: createAdminsRequest,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'admins', 'list'],
			});
		},
	});

	const updateAdminMutation = useMutation({
		mutationFn: updateAdmin,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'admins', 'list'],
			});
		},
	});

	const deleteAdminMutation = useMutation({
		mutationFn: deleteAdmin,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'admins', 'list'],
			});
		},
	});

	const changeAdminPasswordMutation = useMutation({
		mutationFn: changePassword,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'admins', 'list'],
			});
		},
	});

	return {
		adminsListQuery,
		createAdminMutation,
		updateAdminMutation,
		deleteAdminMutation,
		changeAdminPasswordMutation,
	};
}

export default useAdminActions;
