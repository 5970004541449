import React, { useEffect } from 'react';
import { Alert, Button, Box, Link, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Link as RouterLink, useParams } from 'react-router-dom';
import routes from 'routes';
import { useResetPasswordMutation } from 'hooks/auth';
import { AuthSidebar } from 'components/auth';
import ImageCover from 'components/ImageCover';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { IChangePasswordActionPayload } from 'core/models/auth/auth.models';

const schema = Joi.object({
	hash: Joi.string().required(),
	password: buildPasswordSchema(),
	confirmPassword: Joi.string().valid(Joi.ref('password')).required().messages({
		'any.only': 'Passwords do not match',
	}),
});

interface FormFields extends IChangePasswordActionPayload {
	confirmPassword: string;
}

export default function ChangePasswordPage() {
	const { mutate, isPending, isSuccess, error: generalError } = useResetPasswordMutation();
	const {
		setValue,
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<FormFields>({ mode: 'onChange', resolver: joiResolver(schema) });
	const { hash } = useParams();

	const handleResetPassword = (data: FormFields) =>
		mutate({
			hash: data.hash,
			password: data.password,
		});

	useEffect(() => {
		if (hash) {
			setValue('hash', hash);
		}
	}, [hash]);

	return (
		<>
			<AuthSidebar>
				{!isSuccess && (
					<>
						<Typography component="h1" variant="h1" fontWeight="bold">
							Change Your Password
						</Typography>
						<Box component="form" sx={{ mt: 5, mb: 5 }} onSubmit={handleSubmit(handleResetPassword)}>
							{generalError?.message && (
								<Alert severity="error" sx={{ mb: 2 }}>
									{generalError.message}
								</Alert>
							)}
							<Controller name="hash" control={control} render={({ field }) => <input type="hidden" {...field} />} />
							<Controller
								name="password"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<PasswordField
										{...field}
										value={field.value || ''}
										label="Create Your New Password"
										autoComplete="new-password"
										margin="normal"
										variant="outlined"
										size="small"
										fullWidth
										error={!!error}
									/>
								)}
							/>
							<Controller
								name="confirmPassword"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<PasswordField
										{...field}
										value={field.value || ''}
										label="Enter Your New Password Again"
										autoComplete="new-password"
										variant="outlined"
										size="small"
										margin="normal"
										fullWidth
										error={!!error}
										disableTooltip
									/>
								)}
							/>
							<Button
								disabled={!isValid || isPending}
								type="submit"
								fullWidth
								variant="outlined"
								color="primary"
								sx={{ mt: 3, mb: 2 }}
							>
								{isPending ? 'Save...' : 'Save'}
							</Button>
						</Box>
					</>
				)}
				{isSuccess && (
					<Box mt={10} mb={10}>
						<Typography component="h1" variant="h1" fontWeight="bold" mb={2}>
							Your Password Has Been Updated
						</Typography>
						<Typography component="span" color="text.secondary" variant="body1">
							<Link component={RouterLink} to={routes.login}>
								Return to Login
							</Link>
						</Typography>
					</Box>
				)}
			</AuthSidebar>
			<ImageCover
				src="/images/auth-seniors.png"
				sx={{
					display: { sm: 'none', md: 'flex' },
					flexGrow: 2,
				}}
			/>
		</>
	);
}
