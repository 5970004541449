import React from 'react';
import {
	RegisterCreateYourAccountProps,
	RegisterVerifyYourInfoProps,
	RegisterWithPhoneNumberProps,
	RegisterVerifyPhoneCodeProps,
	RegisterSuccessProps,
} from 'components/auth';
import { ICheckStatusResponse } from 'core/api/registration/registration.models';

export type RegisterSlotComponent =
	| React.ElementType<RegisterVerifyYourInfoProps>
	| React.ElementType<RegisterCreateYourAccountProps>
	| React.ElementType<RegisterWithPhoneNumberProps>
	| React.ElementType<RegisterVerifyPhoneCodeProps>
	| React.ElementType<RegisterSuccessProps>
	| React.ElementType;

export type RegisterSlotComponentProps =
	| RegisterVerifyYourInfoProps
	| RegisterCreateYourAccountProps
	| RegisterWithPhoneNumberProps
	| RegisterVerifyPhoneCodeProps
	| RegisterSuccessProps;

export type RegisterSlot = (data: Partial<ICheckStatusResponse>) => RegisterSlotComponent;

interface RegisterCurrentStepProps {
	slot: RegisterSlot;
	slotProps?: RegisterSlotComponentProps;
	initialData: Partial<ICheckStatusResponse>;
}

export function RegisterCurrentStep({ slot, slotProps, initialData }: RegisterCurrentStepProps) {
	const Component = slot(initialData);
	return <Component {...slotProps} {...initialData} />;
}

export default RegisterCurrentStep;
