import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function StatementIcon(props: SvgIconProps) {
	return (
		<SvgIcon x="0px" y="0px" viewBox="0 0 32 32" sx={{ fill: 'none', color: 'text.secondary' }} {...props}>
			<style type="text/css">
				{`
	.st2{display:inline;fill:none;stroke:currentColor;stroke-linejoin:round;stroke-miterlimit:10;}
	.st3{display:inline;fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st4{display:inline;fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:8;}
	.st5{display:inline;}
	.st6{fill:none;stroke:currentColor;stroke-linejoin:round;stroke-miterlimit:10;}
	.st7{fill:none;stroke:currentColor;stroke-linejoin:bevel;stroke-miterlimit:10;}
	
		.st8{display:none;fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#222222;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st9{display:inline;fill:none;stroke:#222222;stroke-width:0.9932;stroke-miterlimit:8;}
	.st10{display:inline;fill:none;stroke:#222222;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st11{display:inline;fill:none;stroke:#222222;stroke-linejoin:round;stroke-miterlimit:10;}
	.st12{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
           `}
			</style>
			<polyline className="st8" points="-21.6,5 -12.5,16 -21.6,27 " />
			<g>
				<path className="st12" d="M20.7,1.2v5.3c0,1,0.8,1.7,1.8,1.7h5.3" />
				<path
					className="st12"
					d="M7.7,30.6h17.7c1.3,0,2.4-1.1,2.4-2.3v-20l-7.1-7h-13c-1.3,0-2.4,1.1-2.4,2.3v24.7
		C5.4,29.6,6.4,30.6,7.7,30.6L7.7,30.6z"
				/>
				<path
					className="st12"
					d="M23.6,10.6c0.3,0,0.6,0,0.6,0v10.6c0,0-0.3,0-0.6,0H9.4c-0.3,0-0.6,0-0.6,0V10.6c0,0,0.3,0,0.6,0H23.6z"
				/>
				<line className="st12" x1="24.1" y1="17.7" x2="8.8" y2="17.7" />
				<line className="st12" x1="24.1" y1="14.2" x2="8.8" y2="14.2" />
				<line className="st12" x1="19.4" y1="14.2" x2="19.4" y2="21.2" />
				<line className="st12" x1="20.6" y1="24.8" x2="24.1" y2="24.8" />
				<line className="st12" x1="8.8" y1="24.8" x2="17.1" y2="24.8" />
				<line className="st12" x1="8.8" y1="7.1" x2="17.1" y2="7.1" />
				<line className="st12" x1="8.8" y1="4.7" x2="14.7" y2="4.7" />
			</g>
		</SvgIcon>
	);
}
