import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LogAsNeededMedIcon(props: SvgIconProps) {
	return (
		<SvgIcon sx={{ fill: 'none', color: 'text.secondary' }} viewBox="0 0 42 48" {...props}>
			<g id="Group">
				<g id="Group_2">
					<path
						id="Vector"
						d="M27.25 26.25L28.3 28.2L29.35 30.15L30.55 28.2L31.75 26.25C32.5 26.1 33.25 25.8 34 25.5C34.75 25.2 35.5 24.75 36.1 24.15C38.35 22.35 39.7 19.8 40 17.1C40.3 14.4 39.55 11.55 37.75 9.30004C35.95 7.05004 33.4 5.70004 30.7 5.40004C28 5.10004 25.15 5.85004 22.9 7.65004C20.65 9.45004 19.3 12 19 14.7C19 15.3 18.85 15.9 19 16.35"
						stroke="currentColor"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="Group_3">
						<path
							id="Vector_2"
							d="M29.65 11.85V14.85V18"
							stroke="currentColor"
							strokeWidth="2"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							id="Vector_3"
							d="M29.65 20.55V20.7V21"
							stroke="currentColor"
							strokeWidth="2"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</g>
				<g id="Group_4">
					<path
						id="Vector_4"
						d="M19.75 1.65002V4.65002"
						stroke="currentColor"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_5"
						d="M15.25 9.30005H12.25"
						stroke="currentColor"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_6"
						d="M14.2 3.75L16.3 5.85"
						stroke="currentColor"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<path
					id="Vector_7"
					d="M15.4 35.55C19.625 35.55 23.05 32.125 23.05 27.9C23.05 23.675 19.625 20.25 15.4 20.25C11.175 20.25 7.75 23.675 7.75 27.9C7.75 32.125 11.175 35.55 15.4 35.55Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_8"
					d="M29.05 46.35V41.1C29.05 39.3 27.85 37.8 25.9 37.05L19.75 34.5H19.3C18.25 35.1 16.9 35.55 15.55 35.55C14.2 35.55 12.85 35.25 11.8 34.5H11.2L4.9 37.05C2.95 37.65 1.75 39.3 1.75 41.1V46.35H29.05Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</SvgIcon>
	);
}
