import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Alert, Box, Button, Typography } from '@mui/material';
import Joi from 'joi';
import TextField, { TextFieldStatus } from 'components/common/inputs/textField/TextField';
import DateOfBirthField from 'components/common/inputs/DateOfBirthField';
import { ICheckStatusResponse, IVerifyIdentitySearchQuery } from 'core/api/registration/registration.models';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';

const schema = Joi.object({
	dateOfBirth: Joi.string().required(),
	lastFourSsn: Joi.string().min(4).max(4).required(),
	zipCode: Joi.string().min(5).max(5).required(),
});

export interface RegisterVerifyYourInfoProps extends Partial<ICheckStatusResponse> {
	onGetHash: (hash: string) => void;
}

export function RegisterVerifyYourInfo({ onGetHash }: RegisterVerifyYourInfoProps) {
	const { verifyIdentity } = useRegisterMutation();
	const { mutate: verifyIdentityMutate, isPending, error: verifyIdentityError } = verifyIdentity;
	const [generalErrors, setGeneralErrors] = useState<string | null>(null);

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<IVerifyIdentitySearchQuery>({ mode: 'onChange', resolver: joiResolver(schema) });

	const successCallback = (token: string | null) =>
		handleSubmit((data) =>
			verifyIdentityMutate(
				{
					...data,
					recaptchaToken: token || '',
				},
				{
					onSuccess(identityData) {
						if (identityData?.error) {
							setGeneralErrors(identityData.error || null);
						} else {
							onGetHash(identityData?.hash || '');
						}
					},
				}
			)
		)();

	const recaptchaRef = useRef<ReCAPTCHA>(null);

	const executeCaptcha = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		if (recaptchaRef?.current) {
			recaptchaRef.current.reset();
			recaptchaRef.current.execute();
		}
	};

	useEffect(() => {
		if (verifyIdentityError) {
			setGeneralErrors(verifyIdentityError?.message || 'Something went wrong');
		}
	}, [verifyIdentityError]);

	return (
		<Box component="form" onSubmit={executeCaptcha}>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Verify Your Information
			</Typography>
			<Box mt={4}>
				<Typography variant="input2" fontWeight={700}>
					What is your date of birth?
				</Typography>
				<Box display="flex" justifyContent="space-between" mt={1} gap={1}>
					<Controller name="dateOfBirth" control={control} render={({ field }) => <DateOfBirthField {...field} />} />
				</Box>
			</Box>

			<Box mt={5}>
				<Typography variant="input2" fontWeight={700}>
					What are the last four digits of your Social Security Number?
				</Typography>

				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Controller
						name="lastFourSsn"
						control={control}
						render={({ field: { value, ...restField }, fieldState: { error } }) => (
							<TextField
								{...restField}
								value={value || ''}
								status={error ? TextFieldStatus.ERROR : undefined}
								label="Last four digits of your SSN"
								variant="outlined"
								size="small"
								inputProps={{
									maxLength: 4,
								}}
								fullWidth
							/>
						)}
					/>
				</Box>
			</Box>

			<Box mt={5}>
				<Typography variant="input2" fontWeight={700}>
					What is the ZIP code of the address where your medications are delivered?
				</Typography>
				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Controller
						name="zipCode"
						control={control}
						render={({ field: { value, ...restField }, fieldState: { error } }) => (
							<TextField
								{...restField}
								value={value || ''}
								status={error ? TextFieldStatus.ERROR : undefined}
								label="ZIP Code"
								variant="outlined"
								size="small"
								inputProps={{
									maxLength: 5,
								}}
								fullWidth
							/>
						)}
					/>
				</Box>
			</Box>

			{generalErrors && (
				<Alert sx={{ mt: 2 }} severity="error">
					{generalErrors}
				</Alert>
			)}

			<Box mt={5} mb={2} sx={{ width: { xs: '100%', sm: '50%' } }}>
				<Button disabled={!isValid || isPending} variant="outlined" color="primary" fullWidth type="submit">
					{isPending ? 'Verifying...' : 'Verify Your Identity'}
				</Button>
			</Box>

			<ReCAPTCHA
				ref={recaptchaRef}
				sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
				onChange={successCallback}
				size="invisible"
			/>
		</Box>
	);
}

export default RegisterVerifyYourInfo;
