import React from 'react';
import { Skeleton, TableCell, TableRow, Typography } from '@mui/material';

export function NotificationSettingRowLoading() {
	const rowTemplate = (
		<TableRow>
			<TableCell align="left">
				<Typography variant="small" fontWeight={700} color="primary">
					<Skeleton variant="text" width={130} />
				</Typography>
				<Typography component="p" variant="caption" color="initial">
					<Skeleton variant="text" width={230} />
				</Typography>
			</TableCell>
			<TableCell align="center">
				<Skeleton width={70} height={30} />
			</TableCell>
			<TableCell align="center">
				<Skeleton width={70} height={30} />
			</TableCell>
		</TableRow>
	);

	return (
		<>
			{rowTemplate}
			{rowTemplate}
			{rowTemplate}
		</>
	);
}

export default NotificationSettingRowLoading;
