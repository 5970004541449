import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker from 'components/common/inputs/datePicker/DatePicker';

interface DateOfBirthFieldProps {
	onChange: (value: string) => void;
	value?: string;
}

export const DateOfBirthField = React.forwardRef<HTMLInputElement, DateOfBirthFieldProps>(
	({ onChange, value }, ref) => {
		const defaultDate = '1964-01-01';
		const [month, setMonth] = useState<dayjs.Dayjs | null>(null);
		const [day, setDay] = useState<dayjs.Dayjs | null>(null);
		const [year, setYear] = useState<dayjs.Dayjs | null>(null);
		const [dateOfBirth, setDateOfBirth] = useState<dayjs.Dayjs | null>(null);
		const [maxDate, setMaxDate] = useState<dayjs.Dayjs | undefined>(undefined);

		const handleDateChange = (type: 'month' | 'day' | 'year') => (newValue: Dayjs | null) => {
			if (type === 'month') {
				setMonth(newValue);
			} else if (type === 'day') {
				setDay(newValue);
			} else if (type === 'year') {
				setYear(newValue);
			}
			onChange(newValue?.format('YYYY-MM-DD') ?? '');
		};

		const generateMaxDate = (selectedDate: dayjs.Dayjs) => {
			const currentDate = dayjs();
			const newSelectedDate = selectedDate.set('year', currentDate.year());

			if (newSelectedDate.isAfter(currentDate)) {
				return dayjs()
					.set('year', currentDate.year() - 1)
					.set('month', 11)
					.set('date', 31);
			}

			return currentDate;
		};

		useEffect(() => {
			setDateOfBirth(dayjs(value || defaultDate));
			setMaxDate(generateMaxDate(dayjs(value || defaultDate)));
		}, [value]);

		return (
			<>
				<DatePicker
					label="Month"
					value={month}
					onChange={handleDateChange('month')}
					format="MM"
					views={['month']}
					openTo="month"
					onOpen={() => {
						const handleMonthChange = handleDateChange('month');
						handleMonthChange(dateOfBirth);
					}}
					slotProps={{ textField: { size: 'small' } }}
					hideDatePickerHeader
					maxDate={maxDate}
					ref={ref}
				/>
				<DatePicker
					label="Day"
					value={day}
					onChange={handleDateChange('day')}
					format="DD"
					views={['day']}
					openTo="day"
					onOpen={() => {
						const handleDayChange = handleDateChange('day');
						handleDayChange(dateOfBirth);
					}}
					slotProps={{ textField: { size: 'small' } }}
					hideDatePickerHeader
					maxDate={maxDate}
					ref={ref}
				/>
				<DatePicker
					label="Year"
					value={year}
					onChange={handleDateChange('year')}
					format="YYYY"
					views={['year']}
					openTo="year"
					onOpen={() => {
						const handleYearChange = handleDateChange('year');
						handleYearChange(dateOfBirth);
					}}
					slotProps={{ textField: { size: 'small' } }}
					maxDate={maxDate}
					ref={ref}
				/>
			</>
		);
	}
);

export default DateOfBirthField;
