import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AnewHealthExactCareLightLogo(props: SvgIconProps) {
	return (
		<SvgIcon x="0px" y="0px" viewBox="0 0 855.7 200" {...props}>
			<style type="text/css">
				{`
	.Drop_x0020_Shadow{fill:none;}
	.Outer_x0020_Glow_x0020_5_x0020_pt{fill:none;}
	.Blue_x0020_Neon{fill:none;stroke:#8AACDA;stroke-width:7;stroke-linecap:round;stroke-linejoin:round;}
	.Chrome_x0020_Highlight{fill:url(#SVGID_1_);stroke:#FFFFFF;stroke-width:0.3629;stroke-miterlimit:1;}
	.Jive_GS{fill:#FFDD00;}
	.Alyssa_GS{fill:#A6D0E4;}
	.st0{fill:#160022;}
	.st1{fill:url(#SVGID_00000080884668500844511490000013133235986025678518_);}
        `}
			</style>
			<linearGradient
				id="SVGID_1_"
				gradientUnits="userSpaceOnUse"
				x1="-58.8816"
				y1="-72.5393"
				x2="-58.8816"
				y2="-73.5393"
			>
				<stop offset="0" style={{ stopColor: '#656565' }} />
				<stop offset="0.618" style={{ stopColor: '#1B1B1B' }} />
				<stop offset="0.6292" style={{ stopColor: '#545454' }} />
				<stop offset="0.9831" style={{ stopColor: '#3E3E3E' }} />
			</linearGradient>
			<g>
				<path
					className="st0"
					d="M214.9,46.5c-5.9-3.3-12.8-4.9-20.8-4.9c-7.8,0-14.7,1.6-20.7,4.9c-6,3.2-10.7,7.8-14.1,13.6
		c-3.4,5.8-5.1,12.5-5.1,20.2c0,7.5,1.7,14.2,5,20c3.3,5.8,8,10.4,14,13.7c6,3.3,13,4.9,20.9,4.9c5.9,0,11.1-0.7,15.7-2.1
		c4.6-1.4,8.5-3.3,11.7-5.7c3.2-2.4,5.7-5.1,7.5-8.1c1.8-3,2.8-6,3.1-9.1h-18.4c-0.1,1.2-0.6,2.4-1.4,3.6c-0.8,1.2-1.9,2.3-3.4,3.4
		c-1.5,1.1-3.5,1.9-5.8,2.5c-2.4,0.6-5.2,0.9-8.5,0.9c-4.8,0-8.9-1-12.2-2.9c-3.3-1.9-5.9-4.7-7.6-8.4c-1-2.1-1.7-4.4-2.1-6.9h60.2
		c0.1-0.5,0.2-1.3,0.2-2.5c0-1.1,0.1-2.2,0.1-3.2c0-7.7-1.6-14.5-4.8-20.3C225.3,54.3,220.7,49.7,214.9,46.5z M175,67
		c1.8-3.5,4.3-6.3,7.6-8.2c3.3-1.9,7.2-2.8,11.7-2.8c4.5,0,8.3,0.9,11.2,2.6c2.9,1.8,5.1,4,6.6,6.9c1.2,2.3,1.9,4.8,2.1,7.4h-41.2
		C173.4,70.7,174,68.8,175,67z"
				/>
				<polygon
					className="st0"
					points="310.2,42.8 290.4,42.8 273.5,67.8 256.2,42.8 236.1,42.8 263.3,80.8 235,117.2 254.7,117.2 
		272.9,92.3 290.4,117.2 310.6,117.2 283.6,79.2 	"
				/>
				<path
					className="st0"
					d="M387.6,111.3c-0.4-1.4-0.6-2.9-0.7-4.6c-0.1-1.7-0.2-3.4-0.2-5.2v-28c0-1,0-2,0-2.9s0-2,0-2.9
		c0-5.8-1.6-10.6-4.8-14.5c-3.2-3.9-7.5-6.8-12.8-8.7c-5.3-1.9-11.2-2.9-17.6-2.9c-6.9,0-13,1.1-18.4,3.2c-5.4,2.1-9.7,5.2-12.8,9.2
		c-3.1,4-4.7,9-4.7,14.8h18.2c0-4.3,1.5-7.4,4.5-9.4c3-2,7.3-3,13-3c5.4,0,9.5,0.9,12.5,2.7c2.9,1.8,4.4,4.3,4.4,7.6
		c0,0,0,0.1,0,0.1c0,1.7-1.7,3-5.1,3.7c-3.4,0.8-9.3,1.6-17.8,2.4c-5.7,0.5-11,1.7-15.7,3.5c-4.7,1.8-8.5,4.3-11.2,7.6
		c-2.8,3.3-4.2,7.5-4.2,12.6c0,4.7,1.2,8.7,3.6,12c2.4,3.3,5.7,5.8,9.9,7.6c4.2,1.8,9.1,2.6,14.6,2.6c4.7,0,9-0.6,13-1.8
		c4-1.2,7.4-2.8,10.3-4.9c1.3-1,2.4-2,3.4-3.1c0.2,1,0.4,2,0.8,3c0.5,1.3,1,2.4,1.6,3.3s1,1.6,1.4,1.9h18.2
		c-0.4-0.4-0.9-1.1-1.5-2.1C388.7,114.1,388.1,112.8,387.6,111.3z M365.4,96.2c-2,2.7-4.6,4.8-7.9,6.1c-3.3,1.3-6.8,2-10.6,2
		c-4.7,0-8.2-0.8-10.6-2.3c-2.3-1.5-3.5-3.9-3.5-6.9c0-3.2,1.4-5.5,4.2-6.9c2.8-1.4,6.9-2.4,12.4-2.9c4.3-0.4,8.5-1,12.7-1.7
		c2.3-0.4,4.4-0.8,6.3-1.4v3.5C368.4,90,367.4,93.5,365.4,96.2z"
				/>
				<path
					className="st0"
					d="M465.3,51.1c-3.1-2.9-6.9-5.2-11.5-6.9c-4.5-1.7-9.7-2.6-15.6-2.6c-7.8,0-14.8,1.6-20.8,4.7
		c-6,3.1-10.7,7.5-14.1,13.3c-3.4,5.7-5.1,12.5-5.1,20.3c0,7.8,1.7,14.7,5.1,20.5c3.4,5.8,8.1,10.4,14.1,13.6s13,4.8,20.8,4.8
		c6,0,11.2-0.9,15.8-2.6c4.6-1.7,8.4-4,11.5-6.9c3.1-2.9,5.4-6.1,7-9.6c1.6-3.5,2.4-6.9,2.4-10.4h-18.4c-0.2,2.6-1,4.9-2.3,7.1
		c-1.4,2.2-3.4,3.9-6,5.2c-2.6,1.3-6,1.9-10.2,1.9c-3.9,0-7.5-0.9-10.7-2.8c-3.2-1.8-5.7-4.5-7.6-8.1c-1.9-3.5-2.8-7.8-2.8-12.9
		c0-5.1,0.9-9.3,2.8-12.7c1.9-3.4,4.4-6,7.6-7.7c3.2-1.7,6.7-2.6,10.6-2.6c3.6,0,6.7,0.6,9.3,1.8c2.6,1.2,4.6,2.7,6.2,4.7
		c1.5,1.9,2.5,4.1,2.9,6.5c0,0.3,0.1,0.6,0.1,0.8c0,0.3,0.1,0.5,0.1,0.8h18.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3
		c0-3.4-0.8-6.8-2.5-10.3C470.8,57.1,468.4,53.9,465.3,51.1z"
				/>
				<path
					className="st0"
					d="M516.1,18.3h-18.5v11.8c0,3-0.1,5.4-0.3,7.1c-0.2,1.7-0.6,3-1.3,3.7c-0.7,0.8-1.7,1.3-3.1,1.5
		c-1.4,0.2-3.3,0.3-5.7,0.3h-7.1v14.7h17.6v34.2c0,1.1,0,2.2,0,3.3s0,2.1,0,3.1c0,4.4,0.7,8,2.1,10.7c1.4,2.7,3.2,4.8,5.5,6.2
		c2.3,1.4,4.8,2.4,7.6,2.9c2.8,0.5,5.5,0.8,8.1,0.8c2.1,0,4.1-0.2,5.7-0.5c1.7-0.3,3-0.7,3.9-1v-14.4c-0.7,0.3-1.6,0.6-3,0.8
		c-1.3,0.3-2.7,0.4-4.2,0.4c-3.1,0-5.1-1-6-3.2s-1.3-4.9-1.3-8.3c0-0.8,0-1.6,0-2.5c0-0.9,0-1.7,0-2.6V57.5h22.1V42.8h-22.1V18.3z"
				/>
				<path
					className="st0"
					d="M610.6,51.1c-3.1-2.9-6.9-5.2-11.5-6.9c-4.5-1.7-9.7-2.6-15.6-2.6c-7.8,0-14.8,1.6-20.8,4.7
		c-6,3.1-10.7,7.5-14.1,13.3c-3.4,5.7-5.1,12.5-5.1,20.3c0,7.8,1.7,14.7,5.1,20.5c3.4,5.8,8.1,10.4,14.1,13.6s13,4.8,20.8,4.8
		c6,0,11.2-0.9,15.8-2.6c4.6-1.7,8.4-4,11.5-6.9c3.1-2.9,5.4-6.1,7-9.6c1.6-3.5,2.4-6.9,2.4-10.4h-18.4c-0.2,2.6-1,4.9-2.3,7.1
		c-1.4,2.2-3.4,3.9-6,5.2c-2.6,1.3-6,1.9-10.2,1.9c-3.9,0-7.5-0.9-10.7-2.8c-3.2-1.8-5.7-4.5-7.6-8.1c-1.9-3.5-2.8-7.8-2.8-12.9
		c0-5.1,0.9-9.3,2.8-12.7c1.9-3.4,4.4-6,7.6-7.7c3.2-1.7,6.7-2.6,10.6-2.6c3.6,0,6.7,0.6,9.3,1.8c2.6,1.2,4.6,2.7,6.2,4.7
		c1.5,1.9,2.5,4.1,2.9,6.5c0,0.3,0.1,0.6,0.1,0.8c0,0.3,0.1,0.5,0.1,0.8h18.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3
		c0-3.4-0.8-6.8-2.5-10.3C616.1,57.1,613.7,53.9,610.6,51.1z"
				/>
				<path
					className="st0"
					d="M703.2,111.3c-0.4-1.4-0.6-2.9-0.7-4.6c-0.1-1.7-0.2-3.4-0.2-5.2v-28c0-1,0-2,0-2.9s0-2,0-2.9
		c0-5.8-1.6-10.6-4.8-14.5c-3.2-3.9-7.5-6.8-12.8-8.7c-5.3-1.9-11.2-2.9-17.6-2.9c-6.9,0-13,1.1-18.4,3.2c-5.4,2.1-9.7,5.2-12.8,9.2
		c-3.1,4-4.7,9-4.7,14.8h18.2c0-4.3,1.5-7.4,4.5-9.4c3-2,7.3-3,13-3c5.4,0,9.5,0.9,12.5,2.7c2.9,1.8,4.4,4.3,4.4,7.6
		c0,0,0,0.1,0,0.1c0,1.7-1.7,3-5.1,3.7c-3.4,0.8-9.3,1.6-17.8,2.4c-5.7,0.5-11,1.7-15.7,3.5c-4.7,1.8-8.5,4.3-11.2,7.6
		c-2.8,3.3-4.2,7.5-4.2,12.6c0,4.7,1.2,8.7,3.6,12c2.4,3.3,5.7,5.8,9.9,7.6c4.2,1.8,9.1,2.6,14.6,2.6c4.7,0,9-0.6,13-1.8
		c4-1.2,7.4-2.8,10.3-4.9c1.3-1,2.4-2,3.4-3.1c0.2,1,0.4,2,0.8,3c0.5,1.3,1,2.4,1.6,3.3s1,1.6,1.4,1.9h18.2
		c-0.4-0.4-0.9-1.1-1.5-2.1C704.2,114.1,703.6,112.8,703.2,111.3z M680.9,96.2c-2,2.7-4.6,4.8-7.9,6.1c-3.3,1.3-6.8,2-10.6,2
		c-4.7,0-8.2-0.8-10.6-2.3c-2.3-1.5-3.5-3.9-3.5-6.9c0-3.2,1.4-5.5,4.2-6.9c2.8-1.4,6.9-2.4,12.4-2.9c4.3-0.4,8.5-1,12.7-1.7
		c2.3-0.4,4.4-0.8,6.3-1.4v3.5C683.9,90,682.9,93.5,680.9,96.2z"
				/>
				<path
					className="st0"
					d="M758.1,41.6c-3.8,0-7.2,0.6-10.2,1.8c-3,1.2-5.5,2.9-7.6,5.1c-1.3,1.3-2.4,2.8-3.4,4.4v-10h-18.4v74.4h18.4
		v-37c0-2.6,0.3-5.1,1-7.7c0.7-2.6,1.7-5,3.2-7.1c1.5-2.2,3.5-3.9,6.1-5.2c2.5-1.3,5.7-1.9,9.5-1.9c2.4,0,4.3,0.2,5.7,0.7
		c1.4,0.5,2.4,0.9,2.8,1.2l3.4-16.9c-0.4-0.3-1.5-0.6-3.3-1.1C763.6,41.8,761.2,41.6,758.1,41.6z"
				/>
				<path
					className="st0"
					d="M846.1,60.1c-3.2-5.8-7.7-10.4-13.6-13.6c-5.9-3.3-12.8-4.9-20.8-4.9c-7.8,0-14.7,1.6-20.7,4.9
		c-6,3.2-10.7,7.8-14.1,13.6c-3.4,5.8-5.1,12.5-5.1,20.2c0,7.5,1.7,14.2,5,20c3.3,5.8,8,10.4,14,13.7c6,3.3,13,4.9,20.9,4.9
		c5.9,0,11.1-0.7,15.7-2.1c4.6-1.4,8.5-3.3,11.7-5.7c3.2-2.4,5.7-5.1,7.5-8.1c1.8-3,2.8-6,3.1-9.1h-18.4c-0.1,1.2-0.6,2.4-1.4,3.6
		c-0.8,1.2-1.9,2.3-3.4,3.4c-1.5,1.1-3.5,1.9-5.8,2.5c-2.4,0.6-5.2,0.9-8.5,0.9c-4.8,0-8.9-1-12.2-2.9c-3.3-1.9-5.9-4.7-7.6-8.4
		c-1-2.1-1.7-4.4-2.1-6.9h60.2c0.1-0.5,0.2-1.3,0.2-2.5c0-1.1,0.1-2.2,0.1-3.2C850.9,72.7,849.3,65.9,846.1,60.1z M792.6,67
		c1.8-3.5,4.3-6.3,7.6-8.2c3.3-1.9,7.2-2.8,11.7-2.8c4.5,0,8.3,0.9,11.2,2.6c2.9,1.8,5.1,4,6.6,6.9c1.2,2.3,1.9,4.8,2.1,7.4h-41.2
		C791,70.7,791.7,68.8,792.6,67z"
				/>
			</g>
			<g>
				<path
					className="st0"
					d="M179.9,174.8c-0.1-0.3-0.2-0.7-0.2-1.2c0-0.4-0.1-0.9-0.1-1.4v-8.1c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8
		c0-1.5-0.4-2.8-1.2-3.8s-1.9-1.8-3.3-2.3s-2.9-0.8-4.6-0.8c-1.8,0-3.5,0.3-4.9,0.9c-1.4,0.6-2.5,1.4-3.4,2.5
		c-0.8,1.1-1.2,2.4-1.2,4h4.1c0-1.3,0.5-2.3,1.4-2.9c0.9-0.6,2.3-0.9,4-0.9c1.6,0,2.9,0.3,3.8,0.8c0.9,0.5,1.3,1.3,1.3,2.4
		c0,0,0,0,0,0c0,0.5-0.5,0.9-1.5,1.1s-2.7,0.5-5.1,0.7c-1.6,0.2-3.1,0.5-4.3,1c-1.3,0.5-2.3,1.2-3,2.1c-0.7,0.9-1.1,2-1.1,3.4
		c0,1.3,0.3,2.4,1,3.3c0.6,0.9,1.5,1.6,2.7,2.1s2.5,0.7,4,0.7c1.3,0,2.5-0.2,3.6-0.5c1.1-0.3,2-0.7,2.8-1.3c0.4-0.3,0.8-0.6,1.1-1
		c0.1,0.4,0.1,0.7,0.2,1c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.3,0.4,0.3,0.5h4.1c-0.1-0.1-0.2-0.3-0.4-0.5
		C180.1,175.6,180,175.2,179.9,174.8z M172.3,172.7c-1,0.4-2.1,0.6-3.2,0.6c-1.5,0-2.6-0.2-3.3-0.7c-0.7-0.5-1.1-1.2-1.1-2.2
		c0-1,0.4-1.8,1.3-2.2c0.9-0.4,2.2-0.7,3.9-0.9c1.2-0.1,2.5-0.3,3.6-0.5c0.7-0.1,1.4-0.3,2-0.5v1.8c0,1.2-0.3,2.1-0.9,2.9
		C174,171.8,173.2,172.4,172.3,172.7z"
				/>
				<path
					className="st0"
					d="M199.7,156.6c-1.1-0.6-2.5-0.9-4.1-0.9c-1.6,0-3,0.4-4.3,1.1c-1,0.6-1.8,1.3-2.5,2.2v-3h-4.2v20.3h4.2v-11.1
		c0-1.1,0.2-2.1,0.7-3c0.5-0.9,1.2-1.6,2-2.1c0.8-0.5,1.8-0.8,2.8-0.8c1.2,0,2.1,0.2,2.8,0.6s1.2,1,1.5,1.8c0.3,0.8,0.5,1.6,0.5,2.6
		c0,0.3,0,0.7,0,1.1s0,0.8,0,1.4v9.4h4.2v-9c0-0.7,0-1.4,0-2.1c0-0.7,0-1.4,0-2c0-1.6-0.3-2.9-0.9-4.1
		C201.7,158.1,200.9,157.2,199.7,156.6z"
				/>
				<path
					className="st0"
					d="M223.2,157c-1.5-0.9-3.4-1.3-5.5-1.3c-2.1,0-3.9,0.4-5.5,1.3c-1.6,0.9-2.9,2.1-3.8,3.7
		c-0.9,1.6-1.4,3.4-1.4,5.5c0,2.1,0.4,3.9,1.3,5.5s2.1,2.8,3.7,3.7c1.6,0.9,3.5,1.3,5.6,1.3c1.6,0,3-0.2,4.2-0.6
		c1.2-0.4,2.3-0.9,3.1-1.6c0.8-0.7,1.5-1.4,2-2.2c0.5-0.8,0.7-1.6,0.8-2.5h-4.1c0,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.6,0.8-1.1,1.1
		c-0.5,0.3-1.1,0.6-1.8,0.8c-0.7,0.2-1.6,0.3-2.6,0.3c-1.4,0-2.6-0.3-3.6-0.9c-1-0.6-1.8-1.4-2.3-2.5c-0.3-0.7-0.6-1.5-0.7-2.3H228
		c0-0.1,0-0.3,0.1-0.7c0-0.3,0-0.6,0-0.8c0-2-0.4-3.8-1.3-5.4C225.9,159.2,224.7,157.9,223.2,157z M214.2,160c1-0.6,2.2-0.9,3.5-0.9
		c1.3,0,2.4,0.3,3.3,0.8s1.6,1.2,2,2.1c0.4,0.7,0.6,1.5,0.6,2.4h-12.5c0.1-0.7,0.4-1.4,0.7-2C212.5,161.4,213.2,160.6,214.2,160z"
				/>
				<polygon
					className="st0"
					points="251.3,173.2 246.8,156 245.6,156 243.4,156 242.3,156 237.7,173.2 234.7,156 230.4,156 234.7,176.3 
		240.5,176.3 244.5,160.4 248.6,176.3 254.3,176.3 258.6,156 254.3,156 	"
				/>
				<path
					className="st0"
					d="M275,155.7c-1.7,0-3.2,0.4-4.5,1.1c-0.9,0.5-1.6,1.1-2.2,1.8v-12.1h-4.2v29.9h4.2v-11.7c0-1,0.2-1.9,0.7-2.7
		c0.5-0.8,1.1-1.4,1.9-1.8c0.8-0.4,1.8-0.7,2.9-0.7c1.7,0,2.9,0.5,3.7,1.4c0.7,0.9,1.1,2.3,1.1,4.2c0,0.3,0,0.7,0,1.2s0,1,0,1.4
		s0,0.9,0,1.2v7.4h4.2v-7.1c0-1.1,0-2,0-2.9s0-1.7,0-2.3c0-2.6-0.7-4.6-2-6.1S277.4,155.7,275,155.7z"
				/>
				<path
					className="st0"
					d="M302.9,157c-1.5-0.9-3.4-1.3-5.5-1.3c-2.1,0-3.9,0.4-5.5,1.3c-1.6,0.9-2.9,2.1-3.8,3.7
		c-0.9,1.6-1.4,3.4-1.4,5.5c0,2.1,0.4,3.9,1.3,5.5s2.1,2.8,3.7,3.7c1.6,0.9,3.5,1.3,5.6,1.3c1.6,0,3-0.2,4.2-0.6
		c1.2-0.4,2.3-0.9,3.1-1.6c0.8-0.7,1.5-1.4,2-2.2c0.5-0.8,0.7-1.6,0.8-2.5h-4.1c0,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.6,0.8-1.1,1.1
		c-0.5,0.3-1.1,0.6-1.8,0.8c-0.7,0.2-1.6,0.3-2.6,0.3c-1.4,0-2.6-0.3-3.6-0.9c-1-0.6-1.8-1.4-2.3-2.5c-0.3-0.7-0.6-1.5-0.7-2.3h16.8
		c0-0.1,0-0.3,0.1-0.7c0-0.3,0-0.6,0-0.8c0-2-0.4-3.8-1.3-5.4C305.6,159.2,304.4,157.9,302.9,157z M293.9,160c1-0.6,2.2-0.9,3.5-0.9
		c1.3,0,2.4,0.3,3.3,0.8s1.6,1.2,2,2.1c0.4,0.7,0.6,1.5,0.6,2.4h-12.5c0.1-0.7,0.4-1.4,0.7-2C292.2,161.4,292.9,160.6,293.9,160z"
				/>
				<path
					className="st0"
					d="M330.5,174.8c-0.1-0.3-0.2-0.7-0.2-1.2c0-0.4-0.1-0.9-0.1-1.4v-8.1c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8
		c0-1.5-0.4-2.8-1.2-3.8s-1.9-1.8-3.3-2.3s-2.9-0.8-4.6-0.8c-1.8,0-3.5,0.3-4.9,0.9c-1.4,0.6-2.5,1.4-3.4,2.5
		c-0.8,1.1-1.2,2.4-1.2,4h4.1c0-1.3,0.5-2.3,1.4-2.9c0.9-0.6,2.3-0.9,4-0.9c1.6,0,2.9,0.3,3.8,0.8c0.9,0.5,1.3,1.3,1.3,2.4
		c0,0,0,0,0,0c0,0.5-0.5,0.9-1.5,1.1s-2.7,0.5-5.1,0.7c-1.6,0.2-3.1,0.5-4.3,1c-1.3,0.5-2.3,1.2-3,2.1c-0.7,0.9-1.1,2-1.1,3.4
		c0,1.3,0.3,2.4,1,3.3c0.6,0.9,1.5,1.6,2.7,2.1s2.5,0.7,4,0.7c1.3,0,2.5-0.2,3.6-0.5c1.1-0.3,2-0.7,2.8-1.3c0.4-0.3,0.8-0.6,1.1-1
		c0.1,0.4,0.1,0.7,0.2,1c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.3,0.4,0.3,0.5h4.1c-0.1-0.1-0.2-0.3-0.4-0.5
		C330.7,175.6,330.6,175.2,330.5,174.8z M322.9,172.7c-1,0.4-2.1,0.6-3.2,0.6c-1.5,0-2.6-0.2-3.3-0.7c-0.7-0.5-1.1-1.2-1.1-2.2
		c0-1,0.4-1.8,1.3-2.2c0.9-0.4,2.2-0.7,3.9-0.9c1.2-0.1,2.5-0.3,3.6-0.5c0.7-0.1,1.4-0.3,2-0.5v1.8c0,1.2-0.3,2.1-0.9,2.9
		C324.6,171.8,323.8,172.4,322.9,172.7z"
				/>
				<rect x="335.2" y="146.4" className="st0" width="4.1" height="29.9" />
				<path
					className="st0"
					d="M351.6,149.3h-4.2v3.9c0,0.7,0,1.2-0.1,1.6c0,0.4-0.1,0.7-0.3,0.8s-0.4,0.3-0.7,0.3s-0.7,0.1-1.3,0.1h-2.6v3.4
		h4.9v10.1c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.8c0,1.1,0.2,2.1,0.5,2.8c0.3,0.7,0.8,1.2,1.3,1.6c0.6,0.4,1.2,0.6,1.8,0.8
		c0.7,0.1,1.3,0.2,2,0.2c0.5,0,1,0,1.5-0.1s0.7-0.2,1-0.2v-3.4c-0.2,0.1-0.4,0.1-0.8,0.2c-0.3,0.1-0.7,0.1-1.1,0.1
		c-0.9,0-1.5-0.3-1.7-0.9s-0.4-1.4-0.4-2.4c0-0.2,0-0.5,0-0.7c0-0.3,0-0.5,0-0.8v-9h6.2V156h-6.2V149.3z"
				/>
				<path
					className="st0"
					d="M372.2,155.7c-1.7,0-3.2,0.4-4.5,1.1c-0.9,0.5-1.6,1.1-2.2,1.8v-12.1h-4.2v29.9h4.2v-11.7c0-1,0.2-1.9,0.7-2.7
		c0.5-0.8,1.1-1.4,1.9-1.8c0.8-0.4,1.8-0.7,2.9-0.7c1.7,0,2.9,0.5,3.7,1.4c0.7,0.9,1.1,2.3,1.1,4.2c0,0.3,0,0.7,0,1.2s0,1,0,1.4
		s0,0.9,0,1.2v7.4h4.2v-7.1c0-1.1,0-2,0-2.9s0-1.7,0-2.3c0-2.6-0.7-4.6-2-6.1S374.6,155.7,372.2,155.7z"
				/>
				<path
					className="st0"
					d="M413.4,156.9c-1.4-0.8-2.9-1.2-4.6-1.2c-1.6,0-3,0.4-4.4,1.1c-0.8,0.5-1.5,1-2.1,1.7V156h-4.2v28.7h4.2v-10.9
		c0.6,0.7,1.3,1.3,2.1,1.8c1.4,0.8,2.8,1.2,4.4,1.2c1.7,0,3.2-0.4,4.6-1.2c1.4-0.8,2.5-2,3.4-3.6c0.8-1.6,1.3-3.5,1.3-5.8
		c0-2.2-0.4-4.1-1.3-5.7C416,158.9,414.8,157.7,413.4,156.9z M412.2,171.3c-1.1,1.1-2.5,1.7-4.1,1.7c-1,0-1.9-0.2-2.8-0.7
		c-0.9-0.5-1.6-1.1-2.1-2c-0.5-0.8-0.8-1.8-0.8-2.8c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0,0v-2.4l0,0c0-1.1,0.3-2,0.8-2.8
		c0.5-0.8,1.2-1.5,2.1-2s1.8-0.7,2.8-0.7c1.6,0,3,0.6,4.1,1.7c1.1,1.1,1.7,2.8,1.7,5.2C413.9,168.5,413.3,170.2,412.2,171.3z"
				/>
				<path
					className="st0"
					d="M433.3,155.7c-1.7,0-3.2,0.4-4.5,1.1c-0.9,0.5-1.6,1.1-2.2,1.8v-12.1h-4.2v29.9h4.2v-11.7c0-1,0.2-1.9,0.7-2.7
		c0.5-0.8,1.1-1.4,1.9-1.8c0.8-0.4,1.8-0.7,2.9-0.7c1.7,0,2.9,0.5,3.7,1.4c0.7,0.9,1.1,2.3,1.1,4.2c0,0.3,0,0.7,0,1.2s0,1,0,1.4
		s0,0.9,0,1.2v7.4h4.2v-7.1c0-1.1,0-2,0-2.9s0-1.7,0-2.3c0-2.6-0.7-4.6-2-6.1S435.7,155.7,433.3,155.7z"
				/>
				<path
					className="st0"
					d="M464.6,174.8c-0.1-0.3-0.2-0.7-0.2-1.2c0-0.4-0.1-0.9-0.1-1.4v-8.1c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8
		c0-1.5-0.4-2.8-1.2-3.8s-1.9-1.8-3.3-2.3s-2.9-0.8-4.6-0.8c-1.8,0-3.5,0.3-4.9,0.9c-1.4,0.6-2.5,1.4-3.4,2.5
		c-0.8,1.1-1.2,2.4-1.2,4h4.1c0-1.3,0.5-2.3,1.4-2.9c0.9-0.6,2.3-0.9,4-0.9c1.6,0,2.9,0.3,3.8,0.8c0.9,0.5,1.3,1.3,1.3,2.4
		c0,0,0,0,0,0c0,0.5-0.5,0.9-1.5,1.1s-2.7,0.5-5.1,0.7c-1.6,0.2-3.1,0.5-4.3,1c-1.3,0.5-2.3,1.2-3,2.1c-0.7,0.9-1.1,2-1.1,3.4
		c0,1.3,0.3,2.4,1,3.3c0.6,0.9,1.5,1.6,2.7,2.1s2.5,0.7,4,0.7c1.3,0,2.5-0.2,3.6-0.5c1.1-0.3,2-0.7,2.8-1.3c0.4-0.3,0.8-0.6,1.1-1
		c0.1,0.4,0.1,0.7,0.2,1c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.3,0.4,0.3,0.5h4.1c-0.1-0.1-0.2-0.3-0.4-0.5
		C464.9,175.6,464.7,175.2,464.6,174.8z M457,172.7c-1,0.4-2.1,0.6-3.2,0.6c-1.5,0-2.6-0.2-3.3-0.7c-0.7-0.5-1.1-1.2-1.1-2.2
		c0-1,0.4-1.8,1.3-2.2c0.9-0.4,2.2-0.7,3.9-0.9c1.2-0.1,2.5-0.3,3.6-0.5c0.7-0.1,1.4-0.3,2-0.5v1.8c0,1.2-0.3,2.1-0.9,2.9
		C458.7,171.8,458,172.4,457,172.7z"
				/>
				<path
					className="st0"
					d="M479.3,155.7c-1.1,0-2,0.2-2.8,0.5c-0.8,0.3-1.5,0.8-2.1,1.4c-0.3,0.3-0.6,0.7-0.9,1.1V156h-4.2v20.3h4.2
		v-10.6c0-0.7,0.1-1.5,0.3-2.2c0.2-0.7,0.5-1.4,0.9-2s1-1.1,1.6-1.5c0.7-0.4,1.6-0.6,2.6-0.6c0.7,0,1.2,0.1,1.6,0.2
		c0.4,0.1,0.6,0.3,0.8,0.3l0.9-3.9c-0.1-0.1-0.4-0.2-0.9-0.3C480.7,155.8,480.1,155.7,479.3,155.7z"
				/>
				<path
					className="st0"
					d="M511.7,156.6c-1-0.6-2.2-0.9-3.8-0.9c-1.6,0-2.9,0.4-4.1,1.2c-1,0.7-1.8,1.6-2.4,2.7c0-0.1,0-0.1-0.1-0.2
		c-0.4-1.1-1.1-2-2.1-2.7c-1-0.7-2.2-1-3.8-1c-1.6,0-2.9,0.4-4.1,1.2c-0.7,0.5-1.3,1.1-1.9,1.8V156h-4.2v20.3h4.2v-11
		c0-0.7,0.1-1.4,0.2-2.1c0.2-0.7,0.4-1.3,0.8-1.9c0.4-0.6,0.9-1,1.5-1.4c0.6-0.3,1.3-0.5,2.2-0.5c0.9,0,1.5,0.2,2,0.5
		c0.5,0.3,0.9,0.7,1.1,1.2c0.2,0.5,0.4,1,0.5,1.6c0.1,0.6,0.1,1.1,0.1,1.6c0,0.4,0,0.9,0,1.4c0,0.6,0,1.2,0,2v8.6h4.1v-8.2
		c0-1.1,0-2.1,0-2.9c0-0.1,0-0.1,0-0.2c0-0.7,0.1-1.3,0.2-2c0.2-0.7,0.4-1.3,0.8-1.8c0.4-0.6,0.8-1,1.4-1.3c0.6-0.3,1.3-0.5,2.2-0.5
		c0.9,0,1.6,0.1,2.1,0.4s0.9,0.7,1.1,1.2c0.2,0.5,0.4,1,0.5,1.5c0.1,0.6,0.1,1.1,0.1,1.6c0,0.4,0,0.9,0,1.4s0,1.3,0,2.1v8.6h4.1
		v-8.5c0-1.1,0-2.1,0-2.9c0-0.8,0-1.5,0-2.1c0-1.4-0.2-2.7-0.7-3.8S512.7,157.2,511.7,156.6z"
				/>
				<path
					className="st0"
					d="M538.2,174.8c-0.1-0.3-0.2-0.7-0.2-1.2c0-0.4-0.1-0.9-0.1-1.4v-8.1c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8
		c0-1.5-0.4-2.8-1.2-3.8s-1.9-1.8-3.3-2.3s-2.9-0.8-4.6-0.8c-1.8,0-3.5,0.3-4.9,0.9c-1.4,0.6-2.5,1.4-3.4,2.5
		c-0.8,1.1-1.2,2.4-1.2,4h4.1c0-1.3,0.5-2.3,1.4-2.9c0.9-0.6,2.3-0.9,4-0.9c1.6,0,2.9,0.3,3.8,0.8c0.9,0.5,1.3,1.3,1.3,2.4
		c0,0,0,0,0,0c0,0.5-0.5,0.9-1.5,1.1s-2.7,0.5-5.1,0.7c-1.6,0.2-3.1,0.5-4.3,1c-1.3,0.5-2.3,1.2-3,2.1c-0.7,0.9-1.1,2-1.1,3.4
		c0,1.3,0.3,2.4,1,3.3c0.6,0.9,1.5,1.6,2.7,2.1s2.5,0.7,4,0.7c1.3,0,2.5-0.2,3.6-0.5c1.1-0.3,2-0.7,2.8-1.3c0.4-0.3,0.8-0.6,1.1-1
		c0.1,0.4,0.1,0.7,0.2,1c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.3,0.4,0.3,0.5h4.1c-0.1-0.1-0.2-0.3-0.4-0.5
		C538.5,175.6,538.3,175.2,538.2,174.8z M530.6,172.7c-1,0.4-2.1,0.6-3.2,0.6c-1.5,0-2.6-0.2-3.3-0.7c-0.7-0.5-1.1-1.2-1.1-2.2
		c0-1,0.4-1.8,1.3-2.2c0.9-0.4,2.2-0.7,3.9-0.9c1.2-0.1,2.5-0.3,3.6-0.5c0.7-0.1,1.4-0.3,2-0.5v1.8c0,1.2-0.3,2.1-0.9,2.9
		C532.4,171.8,531.6,172.4,530.6,172.7z"
				/>
				<path
					className="st0"
					d="M559.6,158.2c-0.8-0.8-1.8-1.4-3-1.8c-1.2-0.5-2.6-0.7-4.1-0.7c-2,0-3.9,0.4-5.5,1.3c-1.6,0.8-2.9,2-3.8,3.6
		s-1.4,3.4-1.4,5.6c0,2.2,0.5,4,1.4,5.6c0.9,1.6,2.2,2.8,3.8,3.7s3.4,1.3,5.5,1.3c1.6,0,3-0.2,4.2-0.7c1.2-0.5,2.2-1.1,3-1.8
		s1.4-1.6,1.9-2.6s0.6-1.9,0.6-2.8H558c-0.1,0.8-0.3,1.5-0.7,2.1c-0.4,0.7-1,1.2-1.8,1.6c-0.8,0.4-1.8,0.6-3.1,0.6
		c-1.2,0-2.2-0.3-3.2-0.8c-1-0.5-1.8-1.3-2.3-2.4c-0.6-1-0.9-2.3-0.9-3.9s0.3-2.8,0.9-3.8s1.4-1.8,2.3-2.3c1-0.5,2-0.8,3.2-0.8
		c1.1,0,2,0.2,2.8,0.5c0.8,0.3,1.4,0.8,1.9,1.4c0.5,0.6,0.8,1.3,0.9,2c0,0.1,0,0.2,0,0.2s0,0.2,0,0.2h4.1c0,0,0-0.1,0-0.1s0,0,0-0.1
		c0-0.9-0.2-1.8-0.7-2.8C561.1,159.9,560.4,159,559.6,158.2z"
				/>
				<path
					className="st0"
					d="M578.2,156l-4.8,16.3l-5-16.3h-4.3l7,20.5l-0.5,1.5c-0.2,0.7-0.5,1.3-0.9,1.8c-0.4,0.5-0.8,1-1.4,1.3
		c-0.5,0.3-1.2,0.5-1.9,0.5c-0.4,0-0.8,0-1.2-0.1c-0.4-0.1-0.7-0.1-0.8-0.2v3.4c0.1,0.1,0.4,0.1,0.8,0.2c0.5,0.1,1,0.1,1.5,0.1
		c1.8,0,3.2-0.3,4.3-0.9s2-1.5,2.6-2.6s1.2-2.3,1.6-3.7l7.3-21.8H578.2z"
				/>
			</g>
			<linearGradient
				id="SVGID_00000038381610138333466260000013704006903028699827_"
				gradientUnits="userSpaceOnUse"
				x1="2.9948"
				y1="79.8103"
				x2="125.1608"
				y2="79.8103"
			>
				<stop offset="0" style={{ stopColor: '#009AD8' }} />
				<stop offset="0.1493" style={{ stopColor: '#167AC9' }} />
				<stop offset="0.4737" style={{ stopColor: '#4338AB' }} />
				<stop offset="0.71" style={{ stopColor: '#5E1098' }} />
				<stop offset="0.8316" style={{ stopColor: '#690091' }} />
			</linearGradient>
			<path
				style={{ fill: 'url(#SVGID_00000038381610138333466260000013704006903028699827_)' }}
				d="M87.5,63.8l-34.1,59.1
	c-7.5,12.9-24,17.3-36.9,9.9c-12.9-7.5-17.3-24-9.9-36.9l34.1-59.1c7.5-12.9,24-17.3,36.9-9.9C90.5,34.4,94.9,50.9,87.5,63.8z
	 M74.8,122.8c7.5,12.9,24,17.3,36.9,9.9c12.9-7.5,17.3-24,9.9-36.9c-7.5-12.9-24-17.3-36.9-9.9S67.3,109.9,74.8,122.8z"
			/>
		</SvgIcon>
	);
}
