import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function MedsMobIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M21.2 12.2L11.3 2.30005C10 1.00005 8.30001 0.300049 6.60001 0.300049C4.90001 0.300049 3.20001 1.00005 2.00001 2.20005C0.800006 3.40005 0.100006 5.10005 0.100006 6.80005C0.100006 8.50005 0.800006 10.2 2.00001 11.4L2.40001 11.8C1.00001 13 0.100006 14.9 0.100006 16.8C0.100006 20.4 3.00001 23.3 6.60001 23.3C8.50001 23.3 10.3 22.5 11.6 21L12 21.4C13.2 22.6 14.9 23.3 16.6 23.3C18.3 23.3 20 22.6 21.2 21.4C22.4 20.2 23.1 18.5 23.1 16.8C23.1 15.1 22.4 13.4 21.2 12.2ZM19.8 20C18.9 20.9 17.8 21.3 16.6 21.3C15.4 21.3 14.2 20.8 13.4 20L12.7 19.3C13 18.5 13.2 17.6 13.2 16.8C13.2 15.4 12.8 14 11.9 12.9L15.5 9.20005L19.8 13.5C20.7 14.4 21.1 15.5001 21.1 16.7001C21.1 18 20.7 19.2001 19.8 20ZM6.60001 10.3C5.70001 10.3 4.90001 10.5 4.10001 10.8L3.40001 10.1C2.50001 9.20005 2.00001 8.10005 2.00001 6.80005C2.00001 5.50005 2.50001 4.40005 3.40001 3.60005C4.30001 2.70005 5.40001 2.30005 6.60001 2.30005C7.80001 2.30005 9.00001 2.80005 9.80001 3.60005L14.1 7.90005L10.5 11.5C9.30001 10.7 8.00001 10.3 6.60001 10.3ZM7.60001 21.3V12.3C9.70001 12.8 11.2 14.6 11.2 16.8C11.2 19 9.60001 20.8 7.60001 21.3ZM2.00001 16.8C2.00001 14.6 3.50001 12.8 5.60001 12.3V21.3C3.60001 20.8 2.00001 18.9 2.00001 16.8Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
