import React from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { UserCredentials, MethodsMap } from 'core/api/login/login.models';
import useAuth from 'hooks/useAuth';

interface TwoFAMethodsProps extends UserCredentials {
	methods: MethodsMap;
	onSelect: (key: number, value: string) => void;
	onResetSignInProcess: () => void;
}

export function TwoFAMethods({ email, password, methods, onSelect, onResetSignInProcess }: TwoFAMethodsProps) {
	const { sendAuthenticationCode } = useAuth();
	const { mutate, isPending, isError, error: apiError } = sendAuthenticationCode;

	const handleSelectMethod = (key: number, value: string) => {
		mutate(
			{ email, password, method: key },
			{
				onSuccess: () => {
					onSelect(key, value);
				},
			}
		);
	};

	return (
		<>
			<Typography component="h1" variant="h1" fontWeight="bold">
				Welcome Back!
			</Typography>
			<Typography component="span" variant="small" color="text.secondary">
				Keeping your information private safe and secure is important to us! Let&apos;s verify your identity before
				logging in. It will only take a minute.
			</Typography>
			<Typography color="text.secondary" variant="small" mt={2}>
				Choose how you would like to receive your verification code.
			</Typography>
			{isError && <Alert severity="error">{apiError?.message || 'Something went wrong'}</Alert>}
			{Object.entries(methods).map(([key, value]: [string, string], index: number, array: [string, string][]) => (
				<React.Fragment key={key}>
					<Button
						fullWidth
						disabled={isPending}
						onClick={() => handleSelectMethod(Number(key), value)}
						variant="outlined"
						color="primary"
						sx={{ borderRadius: '50px' }}
					>
						{value}
					</Button>
					{index !== array.length - 1 && (
						<Box
							display="flex"
							alignItems="center"
							sx={{
								'&::before, &::after': {
									content: '""',
									flex: 1,
									borderBottom: '1px solid',
									borderColor: 'grey.400',
									margin: '0 20px',
								},
							}}
						>
							<Typography variant="input2">Or</Typography>
						</Box>
					)}
				</React.Fragment>
			))}
			<Button variant="text" onClick={onResetSignInProcess}>
				Not the correct mobile or email?
			</Button>
		</>
	);
}

export default TwoFAMethods;
