import React, { useMemo } from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { InboxStatus } from 'core/api/inboxNotification';
import { ContentCard } from 'components/card';
import CircularLoading from 'components/CircularLoading';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { useInboxNotificationQuery, useUpdateInboxNotificationMutation } from 'hooks';
import relativeDateFormat from 'utils/relativeDateFormat';
import StatusChip, { StatusChipLegend } from 'components/StatusChip';
import Markdown from 'components/Markdown/Markdown';
import breakpoints from 'theme/breakpoints';

export default function InboxMessagePage() {
	const navigate = useNavigate();
	const params = useParams();
	const messageId = Number(params.messageId);
	const { data, isLoading } = useInboxNotificationQuery({ id: messageId });
	const { mutate: updateInboxNotification } = useUpdateInboxNotificationMutation();
	const { subject, createdAt, body, statusId } = data || {};
	const markdownContent = `${body}`.trim();
	const handleClickDelete = () =>
		updateInboxNotification(
			{ id: messageId, statusId: InboxStatus.Deleted },
			{ onSuccess: () => navigate(routes.inbox.list) }
		);
	const handleClickMoveToInbox = () => updateInboxNotification({ id: messageId, statusId: InboxStatus.Inbox });
	const isDeleted = statusId === InboxStatus.Deleted;
	const statusLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: InboxStatus.Inbox,
				label: 'Inbox',
				color: 'primary',
			},
			{
				id: InboxStatus.Deleted,
				label: 'Deleted',
				color: 'error',
			},
		],
		[]
	);

	if (isLoading) {
		return <CircularLoading />;
	}

	return (
		<ContentCard
			sx={{
				[breakpoints.down('sm')]: {
					background: (theme) => theme.palette.background.default,
				},
			}}
		>
			<Stack
				direction="row"
				gap={1}
				justifyContent="space-between"
				alignItems="flex-end"
				sx={{
					display: { xs: 'none', sm: 'flex' },
				}}
			>
				<Link
					component={RouterLink}
					to={routes.inbox.list}
					fontWeight="bold"
					underline="none"
					variant="body1"
					color="tertiary.main"
					alignItems="center"
					sx={{ display: 'flex' }}
				>
					<ArrowBackIosIcon sx={{ fontSize: (theme) => theme.typography.body1.fontSize }} />
					Back to Messages
				</Link>
				{!isDeleted ? (
					<Button
						onClick={handleClickDelete}
						startIcon={<DeleteOutlinedIcon />}
						variant="text"
						size="small"
						sx={{ color: (theme) => theme.palette.text.primary }}
					>
						Delete Message
					</Button>
				) : (
					<Button onClick={handleClickMoveToInbox} variant="text" color="primary" size="small">
						Move to Inbox
					</Button>
				)}
			</Stack>
			<Stack direction="column" gap={1} alignItems="stretch">
				<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="stretch" gap={2} justifyContent="space-between">
					<Typography variant="h1" fontWeight={700} color="text.secondary">
						{subject}
					</Typography>
					<Stack direction="row" alignItems="center" gap={1}>
						<Typography variant="caption">{relativeDateFormat(createdAt || '')}</Typography>
						{statusId != null && (
							<StatusChip legend={statusLegend} statusId={statusId} variant="outlined" size="small" />
						)}
						<Stack
							direction="row"
							gap={1}
							justifyContent="space-between"
							alignItems="flex-end"
							sx={{
								display: { xs: 'flex', sm: 'none' },
							}}
						>
							{!isDeleted ? (
								<Button
									onClick={handleClickDelete}
									startIcon={<DeleteOutlinedIcon />}
									variant="text"
									size="small"
									sx={{ color: (theme) => theme.palette.text.primary }}
								>
									Delete Message
								</Button>
							) : (
								<Button onClick={handleClickMoveToInbox} variant="text" color="primary" size="small">
									Move to Inbox
								</Button>
							)}
						</Stack>
					</Stack>
				</Stack>
				<Box mt={2}>
					<Markdown>{markdownContent}</Markdown>
				</Box>
			</Stack>
		</ContentCard>
	);
}
