import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function MorningIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 37 37" {...props}>
			<path
				d="M16.95 11.325C16.95 9.75 16.95 8.4 16.95 6.9375C16.95 5.8125 17.625 5.25 18.6375 5.25C19.3125 5.25 19.875 5.5875 20.1 6.2625C20.2125 6.7125 20.2125 7.05 20.2125 7.5C20.2125 8.7375 20.2125 9.975 20.2125 11.325C21.9 11.775 23.5875 12.3375 25.05 13.4625C25.95 12.5625 26.85 11.775 27.6375 10.875C27.8625 10.65 28.0875 10.3125 28.425 10.2C29.1 9.75 29.8875 9.75 30.45 10.3125C31.0125 10.875 31.125 11.6625 30.675 12.3375C30.45 12.5625 30.225 12.7875 30 13.0125C29.1 13.9125 28.2 14.7 27.3 15.7125C28.2 17.0625 28.7625 18.525 29.1 20.1C30.1125 20.2125 31.0125 20.1 31.9125 20.2125C32.5875 20.2125 33.2625 20.2125 34.05 20.2125C35.175 20.325 35.7375 21 35.7375 22.0125C35.7375 22.8 35.175 23.475 34.275 23.475C34.05 23.475 33.825 23.475 33.6 23.475C23.7 23.475 13.9125 23.475 4.0125 23.475C3.9 23.475 3.7875 23.475 3.675 23.475C2.55 23.475 1.875 22.9125 1.875 21.9C1.875 20.8875 2.6625 20.1 3.675 20.1C4.8 20.1 5.925 20.1 7.1625 20.1H8.0625C8.4 18.4125 9.075 16.8375 10.0875 15.4875C9.1875 14.5875 8.4 13.8 7.5 12.9C7.275 12.675 7.1625 12.5625 6.9375 12.3375C6.375 11.6625 6.2625 10.7625 6.9375 10.0875C7.5 9.525 8.5125 9.4125 9.1875 10.0875C9.975 10.7625 10.65 11.55 11.4375 12.3375C11.775 12.675 12 13.0125 12.45 13.2375C13.8 12.225 15.2625 11.6625 16.95 11.325ZM25.725 20.1C25.1625 16.6125 21.5625 14.1375 17.7375 14.475C14.1375 14.8125 11.55 18.1875 11.55 20.1H25.725Z"
				fill="currentColor"
			/>
			<path
				d="M26.9624 26.625C27.7499 26.625 28.3124 27.075 28.5374 27.8625C28.7624 28.5375 28.4249 29.2125 27.8624 29.6625C27.6374 29.8875 27.2999 29.8875 27.0749 29.8875C26.7374 29.8875 26.3999 30 26.0624 30C21.2249 30 16.4999 30 11.6624 30C11.2124 30 10.8749 30 10.4249 29.8875C9.5249 29.6625 9.0749 28.9875 9.1874 28.0875C9.1874 27.1875 9.7499 26.625 10.6499 26.625H26.9624Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
