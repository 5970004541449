import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	updateEnterprise as updateEnterpriseApi,
	deleteEnterprise as deleteEnterpriseApi,
} from 'core/api/admin/enterprise.api';

export function useEnterpriseMutation() {
	const queryClient = useQueryClient();

	const updateEnterprise = useMutation({
		mutationFn: updateEnterpriseApi,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'enterprise'],
			});
		},
	});

	const deleteEnterprise = useMutation({
		mutationFn: deleteEnterpriseApi,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'enterprise'],
			});
		},
	});

	return {
		updateEnterprise,
		deleteEnterprise,
	};
}

export default useEnterpriseMutation;
