import React from 'react';
import InfoCardWidget from 'components/InfoCardWidget';
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { SidebarListItem } from 'components/sidebarList';
import { useUserContext } from 'contexts/UserProvider';
import routes from 'routes';
import { BillingIcon } from 'components/icons/BillingIcon';

export function CurrentBalanceWidget() {
	const { balance } = useUserContext();

	return (
		<SidebarListItem to={routes.account.paymentsAndBilling} component={RouterLink}>
			<InfoCardWidget title="Current Balance" icon={<BillingIcon />}>
				<Typography variant="body1" color="grey.600">
					{balance}
				</Typography>
			</InfoCardWidget>
		</SidebarListItem>
	);
}

export default CurrentBalanceWidget;
