import { useQuery } from '@tanstack/react-query';
import { patientFilterList } from 'core/api/enterprise/patient.api';
import { PatientFilter } from 'core/api/enterprise/patient.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export function usePatientFiltersQuery() {
	return useQuery({
		queryKey: ['enterprise', 'patient', 'filters'],
		queryFn: () => patientFilterList(),
		select: normalizeHydraCollection<PatientFilter>,
	});
}

export default usePatientFiltersQuery;
