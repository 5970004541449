import React from 'react';
import { styled } from '@mui/material/styles';
import { Box as MuiBox, Chip as MuiChip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';
import { PatientStatus } from 'core/models/admin/patients.models';

const ChipContainer = styled(MuiChip)<{ component: React.ElementType }>(() => ({
	fontWeight: '700',
	textTransform: 'capitalize',
}));

const BoxContainer = styled(MuiBox)(() => ({
	fontWeight: '700',
	textTransform: 'capitalize',
}));

export interface IPatientStatusChipProps extends Omit<ChipProps, 'label' | 'size' | 'color'> {
	status: PatientStatus | string;
}

function PatientStatusChip({ status }: IPatientStatusChipProps) {
	switch (status) {
		case PatientStatus.ACTIVE:
			return <ChipContainer component="span" label={status} size="small" color="success" />;
		case PatientStatus.PRE_REGISTER:
			return <ChipContainer component="span" label={status} size="small" color="warning" />;
		case PatientStatus.SUSPENDED:
			return <ChipContainer component="span" label={status} size="small" color="info" />;
		case PatientStatus.BLOCKED:
			return <ChipContainer component="span" label={status} size="small" color="error" />;
		case PatientStatus.DELETED:
		default:
			return <BoxContainer>{status}</BoxContainer>;
	}
}

export default PatientStatusChip;
