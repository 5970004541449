export interface IApiError {
	status: number;
	name: string;
	message: string;
	validationErrors?: {
		[key: string]: string;
	};
}

export interface IViolation {
	propertyPath: string;
	message: string;
}

export class ApiError extends Error implements IApiError {
	public status;

	public message;

	public validationErrors;

	constructor(status: number, message: string, violations?: IViolation[]) {
		super(message);
		this.name = 'ApiError';
		this.status = status;
		this.message = message;

		if (violations) {
			this.validationErrors = violations.reduce(
				(acc, violation) => {
					const newAcc = { ...acc };
					if (!newAcc[violation.propertyPath]) {
						newAcc[violation.propertyPath] = violation.message;
					} else {
						newAcc[violation.propertyPath] = newAcc[violation.propertyPath].concat(violation.message);
					}

					return newAcc;
				},
				{} as IApiError['validationErrors']
			);
		}

		Object.setPrototypeOf(this, ApiError.prototype);
	}
}
