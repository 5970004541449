import React, { useState } from 'react';
import { Avatar, Typography } from '@mui/material';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import eventService from 'services/eventService';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import Suspense from 'components/Suspense';
import CircularLoading from 'components/CircularLoading';
import { RefillAlertsWidget } from 'components/inbox';
import InfoCardWidget from 'components/InfoCardWidget';
import ContentCard from '../../../card/ContentCard';
import SidebarList from '../../../sidebarList/SidebarList';
import SidebarListItem from '../../../sidebarList/SidebarListItem';
import { IMedsGrouped } from '../../../../core/models/medications/medications.models';
import { getPdfMedications } from '../../../../core/api/medications/medications.api';

interface IMedicationSidebarProps {
	medicationsIsLoading: boolean;
	medsGroupedByPacks: IMedsGrouped | null;
	activeSfid?: string;
	onDownloadPdfError?: (error: string) => void;
}

export function MedicationSidebar({
	medicationsIsLoading,
	medsGroupedByPacks,
	activeSfid,
	onDownloadPdfError,
}: IMedicationSidebarProps) {
	const [downloadPdfIsLoading, setDownloadPdfIsLoading] = useState(false);

	const handleDownloadPdf = () => {
		eventService.emitEvent({ category: 'button', action: 'medicationListDownloadPdf' });

		return getPdfMedications();
	};

	return (
		<ContentCard
			title="Medication"
			actions={
				<DownloadPdfButton
					onDownloadPdf={handleDownloadPdf}
					onError={onDownloadPdfError}
					onIsLoading={setDownloadPdfIsLoading}
					variant="text"
					size="small"
					sx={{ color: 'text.primary' }}
				>
					{!downloadPdfIsLoading ? 'Print Medication List' : 'Loading...'}
				</DownloadPdfButton>
			}
		>
			<Suspense isLoading={medicationsIsLoading} fallback={<CircularLoading />}>
				<SidebarList>
					<RefillAlertsWidget />
				</SidebarList>
				{Object.entries(medsGroupedByPacks || {}).map(([title, medications]) => (
					<SidebarList key={title}>
						<Typography component="h2" variant="h1" fontWeight="bold" color="text.secondary" sx={{ pt: '5px' }}>
							{title}
						</Typography>
						{medications.map(({ sfid, name, imageUrl, refillAlert, onboardingAlerts }) => (
							<SidebarListItem
								key={sfid}
								to={generatePath(routes.medication, {
									sfid,
								})}
								state={{ showSidebarNavigation: false }}
								component={RouterLink}
								className={activeSfid && activeSfid === sfid ? 'active' : ''}
							>
								<InfoCardWidget
									icon={
										imageUrl ? (
											<Avatar src={imageUrl} alt={name || ''} variant="rounded" sx={{ width: 48, height: 48 }} />
										) : undefined
									}
									title={name || ''}
									alert={!!refillAlert || !!onboardingAlerts?.length}
								/>
							</SidebarListItem>
						))}
					</SidebarList>
				))}
			</Suspense>
		</ContentCard>
	);
}

export default MedicationSidebar;
