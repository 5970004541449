import React, { useCallback, useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
	GridActionsCellItem,
	GridColDef,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { PaperTitle, Paper } from 'components/dashboardLayout';
import { FiltersBar, SearchFilter } from 'components/common/inputs/filters';
import DataGrid from 'components/dataGrid';
import ConfirmationDialog from 'components/confirmationDialog';
import LockResetIcon from '@mui/icons-material/LockReset';
import SaveIcon from '@mui/icons-material/Save';
import { useAdminActions } from 'hooks/admin';
import { useDialog } from 'hooks';
import Dialog from 'components/dialog';
import { IChangePasswordAdminRequest } from 'core/api/admin/admins.models';
import { AdminChangePassword, AdminCreate } from 'components/admin';
import { ICreateAdminsRequestActionPayload } from 'core/models/admin/admins.models';
import withPageContext from 'hoc/withPageContext';

function AdminsPage() {
	const {
		adminsListQuery,
		createAdminMutation,
		updateAdminMutation,
		deleteAdminMutation,
		changeAdminPasswordMutation,
	} = useAdminActions();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const [search, setSearch] = useState<string | undefined>(undefined);

	const { data: admins, isLoading } = adminsListQuery({
		search,
		page: paginationModel.page,
		itemsPerPage: paginationModel.pageSize,
	});
	const [isOpenDeleteConfirmationDialog, openDeleteConfirmationDialog, closeDeleteConfirmationDialog, adminIdToDelete] =
		useDialog<number>();
	const [isOpenChangePasswordDialog, openChangePasswordDialog, closeChangePasswordDialog, adminIdToChangePassword] =
		useDialog<number>();
	const [isOpenCreateAdminDialog, openCreateAdminDialog, closeCreateAdminDialog] = useDialog();

	const handleChangeAdminPassword = useCallback((props: IChangePasswordAdminRequest) => {
		changeAdminPasswordMutation.mutate(props, { onSuccess: closeChangePasswordDialog });
	}, []);

	const handleDeleteAdmin = useCallback(() => {
		if (adminIdToDelete) {
			deleteAdminMutation.mutate({ userId: adminIdToDelete.toString() }, { onSuccess: closeDeleteConfirmationDialog });
		}
	}, [adminIdToDelete]);

	const handleCreateAdmin = useCallback((props: ICreateAdminsRequestActionPayload) => {
		createAdminMutation.mutate(props, { onSuccess: closeCreateAdminDialog });
	}, []);

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const handleEditClick = useCallback(
		(id: GridRowId) => {
			setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
		},
		[rowModesModel]
	);

	const handleSaveClick = useCallback(
		(id: GridRowId) => {
			setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
		},
		[rowModesModel]
	);

	const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
		if (event && params.reason === GridRowEditStopReasons.rowFocusOut) {
			// eslint-disable-next-line no-param-reassign
			event.defaultMuiPrevented = true;
		}
	}, []);

	const handleProcessRowUpdate = useCallback((newRow: GridRowModel, originalRow: GridRowModel) => {
		if (
			(newRow?.id && newRow?.email && newRow?.email !== originalRow?.email) ||
			(newRow?.id && newRow?.phoneNumber && newRow?.phoneNumber !== originalRow?.phoneNumber)
		) {
			updateAdminMutation.mutate({ userId: newRow.id, email: newRow.email, phoneNumber: newRow.phoneNumber });
		}

		return newRow;
	}, []);

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', sortable: false, flex: 1 },
		{
			field: 'email',
			headerName: 'Email',
			sortable: false,
			flex: 4,
			editable: true,
		},
		{
			field: 'phoneNumber',
			headerName: 'Phone number',
			sortable: false,
			flex: 3,
			editable: true,
		},
		{
			field: 'createdAt',
			headerName: 'Created at',
			sortable: false,
			flex: 2,
		},
		{
			field: 'updatedAt',
			headerName: 'Updated at',
			sortable: false,
			flex: 2,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			cellClassName: 'actions',
			getActions: ({ id, row }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

				const editAction = isInEditMode ? (
					<GridActionsCellItem
						data-testid="save-button"
						icon={<SaveIcon />}
						label="Save"
						title="Save"
						sx={{
							color: 'primary.main',
						}}
						onClick={() => handleSaveClick(id)}
					/>
				) : (
					<GridActionsCellItem
						data-testid="edit-button"
						icon={<EditIcon />}
						label="Edit"
						title="Edit"
						className="textPrimary"
						color="inherit"
						onClick={() => handleEditClick(id)}
					/>
				);

				return [
					editAction,
					<GridActionsCellItem
						icon={<LockResetIcon />}
						label="Change Password"
						title="Change Password"
						color="inherit"
						onClick={() => openChangePasswordDialog(row.id)}
					/>,
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Delete"
						title="Delete"
						color="inherit"
						onClick={() => openDeleteConfirmationDialog(row.id)}
					/>,
				];
			},
		},
	];

	const handleClearFilters = () => {
		setSearch(undefined);
	};

	return (
		<Grid item xs={12}>
			<Paper>
				<PaperTitle>Admins list</PaperTitle>
				<FiltersBar onClearFilters={handleClearFilters}>
					<SearchFilter sx={{ flex: 4 }} name="search" label="Search admin" value={search} onChange={setSearch} />
					<Button sx={{ alignContent: 'right' }} onClick={() => openCreateAdminDialog()}>
						New Admin
					</Button>
				</FiltersBar>
				<DataGrid
					autoHeight
					rows={admins?.items || []}
					columns={columns}
					loading={isLoading}
					rowCount={admins?.totalItems || 0}
					pageSizeOptions={[5, 10, 25]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowModesModelChange={handleRowModesModelChange}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={handleProcessRowUpdate}
					disableRowSelectionOnClick
					disableColumnSelector
					disableColumnMenu
					disableColumnFilter
				/>
				<ConfirmationDialog
					isLoading={deleteAdminMutation.isPending}
					title="Delete Admin"
					content="Are you sure you want to delete this admin?"
					open={isOpenDeleteConfirmationDialog}
					onConfirm={handleDeleteAdmin}
					onCancel={closeDeleteConfirmationDialog}
				/>
				<Dialog
					open={isOpenChangePasswordDialog}
					scroll="paper"
					fullWidth
					maxWidth="xs"
					title="Change Admin's Password"
					onClose={closeChangePasswordDialog}
				>
					<AdminChangePassword
						isLoading={changeAdminPasswordMutation.isPending}
						onSubmit={handleChangeAdminPassword}
						userId={adminIdToChangePassword?.toString()}
					/>
				</Dialog>
				<Dialog
					open={isOpenCreateAdminDialog}
					scroll="paper"
					fullWidth
					maxWidth="xs"
					title="New Admin"
					onClose={closeCreateAdminDialog}
				>
					<AdminCreate
						isLoading={createAdminMutation.isPending}
						error={createAdminMutation.error}
						onSubmit={handleCreateAdmin}
					/>
				</Dialog>
			</Paper>
		</Grid>
	);
}

export default withPageContext(AdminsPage);
