import React, { useMemo } from 'react';
import InfoCardWidget from 'components/InfoCardWidget';
import { Stack, PaletteOptions } from '@mui/material';
import { usePatientDetailsQuery } from 'hooks/enterprise';
import { CalendarWeek, CalendarWeekDay, CalendarWeekLegend } from 'components/calendarWeek';
import useWeekDays, { IDayDetails } from 'hooks/useWeekDays';
import { Paper } from 'components/dashboardLayout';

type MedsLoggedThisWeek = IDayDetails & { color?: keyof PaletteOptions };

interface MedicationLoggedThisWeekWidgetProps {
	patientId?: string;
}

export function MedicationLoggedThisWeekWidget({ patientId }: MedicationLoggedThisWeekWidgetProps) {
	const { data } = usePatientDetailsQuery({ id: patientId });
	const medsThisWeek = data?.homepage?.medsThisWeek || [];
	const weekDays = useWeekDays();

	const medsLoggedThisWeek: MedsLoggedThisWeek[] = useMemo(() => {
		return weekDays.map((day) => {
			const medThisWeek = medsThisWeek.find((med) => med.day === day.day.format('YYYY-MM-DD'));
			let color: keyof PaletteOptions | undefined;

			if (medThisWeek?.status === 'logged') {
				color = 'primary';
			} else if (medThisWeek?.status === 'incomplete') {
				color = 'warning';
			}

			return {
				...day,
				color,
			};
		});
	}, [weekDays, medsThisWeek]);

	return (
		<Paper>
			<InfoCardWidget
				title="Medication Logged This Week"
				disableArrow
				sx={{
					marginTop: 0,
					marginBottom: 0,
				}}
			>
				<Stack direction="column" gap="12px" py="12px">
					<CalendarWeek>
						{medsLoggedThisWeek.map(({ day, today, color }) => (
							<CalendarWeekDay
								key={day.format('ddd')}
								day={day.date()}
								dayStr={day.format('ddd')}
								{...{ today, color }}
							/>
						))}
					</CalendarWeek>
					<CalendarWeekLegend
						items={[
							{ label: 'Logged', color: 'primary' },
							{ label: 'Incomplete', color: 'warning' },
						]}
					/>
				</Stack>
			</InfoCardWidget>
		</Paper>
	);
}

export default MedicationLoggedThisWeekWidget;
