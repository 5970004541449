import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changePasswordRequest } from 'core/api/userAccount/userAccount.api';

export function useChangePasswordMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['changePassword'],
		mutationFn: changePasswordRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
	});
}

export default useChangePasswordMutation;
