import React from 'react';
import InfoCardWidget from 'components/InfoCardWidget';
import { Typography } from '@mui/material';
import { generatePath, Link as RouterLink, useLocation } from 'react-router-dom';
import { useUserContext } from 'contexts/UserProvider';
import routes from 'routes';
import { RefillIcon } from 'components/icons/RefillIcon';
import { SidebarListItem } from 'components/sidebarList';

export function RefillAlertsWidget() {
	const cardTitle = 'Refill Alerts';

	const { homepage } = useUserContext();
	const refillAlerts = homepage?.refillAlerts || 0;
	const location = useLocation();
	const activeClassName = location.pathname === generatePath(routes.medication) ? 'active' : '';

	return (
		<SidebarListItem
			to={generatePath(routes.medication)}
			state={{ showSidebarNavigation: false }}
			component={RouterLink}
			className={activeClassName}
		>
			<InfoCardWidget title={cardTitle} icon={<RefillIcon />} alert={refillAlerts > 0}>
				<Typography variant="body1" color="grey.600">
					{refillAlerts > 0 ? 'Action Required' : 'None'}
				</Typography>
			</InfoCardWidget>
		</SidebarListItem>
	);
}

export default RefillAlertsWidget;
