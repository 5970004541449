export const grey = {
	50: '#FCFCFC',
	100: '#F5F5F7',
	200: '#EEEEEE',
	300: '#E0E0E0',
	400: '#BDBDBD',
	500: '#9E9E9E',
	600: '#757575',
	700: '#616161',
	800: '#424242',
	900: '#160022',
};
