import React, { useCallback } from 'react';
import { Button, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ScheduledMedStatus } from 'core/models/mySchedule/mySchedule.models';
import { ArrowIcon } from 'components/icons/ArrowIcon';
import { ScheduleIcon, ScheduleTitle, ScheduleTime, ScheduleActions } from 'components/scheduleList';
import { NormalizedHydraCollection } from 'utils';
import { UseMutateFunction } from '@tanstack/react-query';
import {
	IRecordTakenRequestParams,
	IRecordTakenResponse,
	IScheduledMedDetails,
} from 'core/api/mySchedule/mySchedule.models';
import { SidebarList, SidebarListItem } from 'components/sidebarList';

interface MedScheduleListByDayProps {
	data?: NormalizedHydraCollection<IScheduledMedDetails>;
	isPending: boolean;
	onLogChange: UseMutateFunction<IRecordTakenResponse, Error, IRecordTakenRequestParams, unknown>;
	onShowScheduleDetails: (scheduleDetails: IScheduledMedDetails) => void;
}

export function MedScheduleListByDay({
	data,
	isPending,
	onLogChange,
	onShowScheduleDetails,
}: MedScheduleListByDayProps) {
	const { items: scheduleList } = data ?? {};

	const handleLogAllAsTaken = useCallback(
		(scheduleItem: IScheduledMedDetails) => () => {
			const takenIds = [...scheduleItem.exactPackMedication, ...scheduleItem.additionalMedication].map((med) => med.id);
			onLogChange({ takenIds, skippedIds: [] });
		},
		[onLogChange]
	);

	return (
		<SidebarList>
			{scheduleList &&
				scheduleList.map((scheduleItem) => {
					const { type, title, status, scheduleInfo, timeOfDaySuffix } = scheduleItem;

					return (
						<SidebarListItem
							key={title}
							onClick={() => (status !== ScheduledMedStatus.Active ? onShowScheduleDetails(scheduleItem) : null)}
							className={status === ScheduledMedStatus.Active ? 'active' : ''}
						>
							<Stack direction="row" paddingY="12px" gap="12px" alignItems="center">
								<ListItemIcon
									sx={{
										minWidth: 'unset',
										'& .MuiSvgIcon-root': {
											fontSize: { xs: '1.875rem', sm: '2.25rem' },
										},
									}}
								>
									<ScheduleIcon {...{ type, status }} />
								</ListItemIcon>

								<ListItemText
									sx={{
										display: 'flex',
										flexDirection: 'column',
										margin: 0,
									}}
									disableTypography
									primary={
										<ScheduleTitle color={status === ScheduledMedStatus.Active ? 'text.secondary' : 'text.primary'}>
											{title}
										</ScheduleTitle>
									}
									secondary={
										<ScheduleTime color={status === ScheduledMedStatus.Active ? 'text.secondary' : 'text.primary'}>
											{scheduleInfo}{' '}
											{timeOfDaySuffix && (
												<Tooltip
													placement="right"
													title={
														<>
															<b>Time-sensitive.</b>
															<br />
															Follow the schedule exactly as prescribed.
														</>
													}
												>
													<ScheduleRoundedIcon color="warning" />
												</Tooltip>
											)}
										</ScheduleTime>
									}
								/>
								<Stack
									direction="row"
									gap={2}
									color={status === ScheduledMedStatus.Active ? 'text.secondary' : 'text.primary'}
								>
									{status === ScheduledMedStatus.Logged && (
										<Typography variant="caption" display="inline-flex" gap="5px" alignItems="center" textAlign="right">
											Logged <CheckCircleIcon color="success" />
										</Typography>
									)}
									{status === ScheduledMedStatus.Passed && (
										<Typography variant="caption" display="inline-flex" gap="5px" alignItems="center" textAlign="right">
											Log Your Medication <ErrorIcon color="warning" />
										</Typography>
									)}
								</Stack>
								{status === ScheduledMedStatus.Active && (
									<ScheduleActions sx={{ display: { xs: 'none', sm: 'flex' } }}>
										<Button
											onClick={handleLogAllAsTaken(scheduleItem)}
											disabled={isPending}
											variant="contained"
											color="primary"
										>
											Log All as Taken
										</Button>
										<Button onClick={() => onShowScheduleDetails(scheduleItem)} variant="outlined" color="inherit">
											Skip a Medication
										</Button>
									</ScheduleActions>
								)}
								<ListItemIcon sx={{ minWidth: 'unset' }}>
									<ArrowIcon />
								</ListItemIcon>
							</Stack>
							{status === ScheduledMedStatus.Active && (
								<ScheduleActions
									sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', alignItems: 'stretch' }}
								>
									<Button
										onClick={handleLogAllAsTaken(scheduleItem)}
										disabled={isPending}
										variant="contained"
										color="primary"
									>
										Log All as Taken
									</Button>
									<Button onClick={() => onShowScheduleDetails(scheduleItem)} variant="outlined" color="inherit">
										Skip a Medication
									</Button>
								</ScheduleActions>
							)}
						</SidebarListItem>
					);
				})}
		</SidebarList>
	);
}

export default MedScheduleListByDay;
