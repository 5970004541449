import { DeliveryRecordType, ITrackingButton } from '../../models/delivery/delivery.models';
import { MedicationRefillsStatus, MedicationRefillsStatusAlertLevel } from '../medications/medications.models';

export enum SFOrderStatus {
	SUBMITTED_TO_PRODUCTION = 'Submitted to Production',
	IN_PRODUCTION = 'In Production',
	AWAITING_DRIVER = 'Awaiting Driver',
	IN_TRANSIT = 'In Transit',
	DELIVERED = 'Delivered',
	RETURNED = 'Returned',
}

interface IAPIResponse {
	'@context': string;
	'@id': string;
	'@type': string;
}

export interface IAPIDeliveryMedication {
	sfid: string | null;
	name: string | null;
	imageUrl: string | null;
	rxNumber: string | null;
	refills: number | null;
	dispensed: number | null;
	reason: string | null;
	action: string | null;
	prescriberName: string | null;
	prescriberPhone: string | null;
	refillsStatus: MedicationRefillsStatus | null;
	refillsStatusAlertLevel: MedicationRefillsStatusAlertLevel | null;
}

export interface IAPIDeliveryResponse extends IAPIResponse {
	sfid: string | null;
	orderName: string | null;
	orderNumber: string | null;
	orderStatus: SFOrderStatus | null;
	trackingButton: ITrackingButton | null;
	orderStartDate: string | null;
	orderEndDate: string | null;
	shipTo: string | null;
	recordType: DeliveryRecordType | null;
	notIncludedMedication: IAPIDeliveryMedication[];
	exactPackMedication: IAPIDeliveryMedication[];
	refrigerateMedication: IAPIDeliveryMedication[];
	additionalMedication: IAPIDeliveryMedication[];
	firstExactPack: boolean | null;
	newPatientDelivery: boolean | null;
}

export interface IAPIUpcomingDelivery
	extends Pick<IAPIDeliveryResponse, 'sfid' | 'orderName' | 'orderNumber' | 'orderStatus' | 'recordType'> {
	displayStatus: string | null;
}

export interface IAPIPastDelivery extends IAPIUpcomingDelivery {
	deliveryDate: string | null;
}

export interface IGetDeliveriesListResponse extends IAPIResponse {
	upcoming: IAPIUpcomingDelivery[];
	past: IAPIPastDelivery[];
	shippingAddress: string | null;
	firstExactPackReceived: boolean;
	anyDeliveryReceived: boolean;
}

export interface IGetDeliveriesSummaryRequestParams {
	sfid: string;
}

export interface IGetDeliveriesSummaryResponse extends IAPIDeliveryResponse {}
