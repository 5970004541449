import { styled } from '@mui/material/styles';
import { DataGrid as MuiDataGrid, GridActionsCellItem as MuiGridActionsCellItem } from '@mui/x-data-grid';

export const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
	borderColor: theme.palette.grey[200],
	'--DataGrid-rowBorderColor': theme.palette.grey[200],

	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: theme.palette.grey[100],
	},
	'& .row-status--blocked': {
		backgroundColor: theme.palette.error.light,
	},
	'& .row-status--disabled': {
		backgroundColor: theme.palette.background.secondary,
	},
}));

export const GridActionsCellItem = styled(MuiGridActionsCellItem)<{ to?: string }>(() => ({}));
