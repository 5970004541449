import React, { useMemo } from 'react';
import DataGrid from 'components/dataGrid';
import { Paper, PaperTitle } from 'components/dashboardLayout';
import { GridColDef } from '@mui/x-data-grid';
import { Button, Stack, Typography } from '@mui/material';
import { PillExactPackIcon } from 'components/icons/PillExactPackIcon';
import { PillBottleIcon } from 'components/icons/PillBottleIcon';
import { usePatientDeliveriesQuery } from 'hooks/enterprise';
import { SummaryDelivery } from 'core/api/enterprise/patient.models';
import { SFOrderStatus } from 'core/api/delivery/delivery.models';
import { DeliveryRecordType } from 'core/models/delivery/delivery.models';
import StatusChip, { StatusChipLegend } from 'components/StatusChip';

interface DeliveriesListProps {
	patientId: string;
	onClickDelivery: (sfid: string) => void;
}

export function DeliveriesList({ patientId, onClickDelivery }: DeliveriesListProps) {
	const { data, isLoading } = usePatientDeliveriesQuery({
		patientId,
	});

	const items: SummaryDelivery[] = [...(data?.upcoming || []), ...(data?.past || [])] as SummaryDelivery[];
	const totalItems = items.length;

	const orderStatusLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: SFOrderStatus.SUBMITTED_TO_PRODUCTION,
				label: SFOrderStatus.SUBMITTED_TO_PRODUCTION,
				color: 'primary',
			},
			{
				id: SFOrderStatus.IN_PRODUCTION,
				label: SFOrderStatus.IN_PRODUCTION,
				color: 'primary',
			},
			{
				id: SFOrderStatus.AWAITING_DRIVER,
				label: SFOrderStatus.AWAITING_DRIVER,
				color: 'warning',
			},
			{
				id: SFOrderStatus.IN_TRANSIT,
				label: SFOrderStatus.IN_TRANSIT,
				color: 'warning',
			},
			{
				id: SFOrderStatus.DELIVERED,
				label: SFOrderStatus.DELIVERED,
				color: 'success',
			},
			{
				id: SFOrderStatus.RETURNED,
				label: SFOrderStatus.RETURNED,
				color: 'error',
			},
		],
		[]
	);

	const columns: GridColDef[] = [
		{ field: 'sfid', headerName: 'ID', sortable: false, width: 80 },
		{
			field: 'orderName',
			headerName: 'Name',
			sortable: false,
			flex: 3,
			renderCell: ({ row, value }) => (
				<Stack gap={2} alignItems="center" direction="row">
					{row.recordType === DeliveryRecordType.ROUTINE ? (
						<PillExactPackIcon titleAccess={row.recordType} />
					) : (
						<PillBottleIcon titleAccess={row.recordType} />
					)}
					<Typography component="span" variant="body2">
						{value}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'orderNumber',
			headerName: 'Number',
			sortable: false,
			flex: 3,
			renderCell: ({ row, value }) => (
				<Button variant="text" onClick={() => onClickDelivery(row.sfid)}>
					{value}
				</Button>
			),
		},
		{
			field: 'orderStatus',
			headerName: 'Status',
			sortable: false,
			flex: 3,
			renderCell: ({ value }) => <StatusChip legend={orderStatusLegend} statusId={value} />,
		},
		{
			field: 'displayStatus',
			headerName: 'Display status',
			sortable: false,
			flex: 3,
		},
		{
			field: 'deliveryDate',
			headerName: 'Delivery date',
			sortable: false,
			flex: 3,
		},
	];

	return (
		<Paper>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
				<PaperTitle>Deliveries ({totalItems})</PaperTitle>
			</Stack>
			<DataGrid
				autoHeight
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 25,
						},
					},
				}}
				rows={items || []}
				getRowId={(row) => row.sfid}
				columns={columns}
				loading={isLoading}
				pageSizeOptions={[5, 10, 25]}
				paginationMode="client"
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
				getRowClassName={(params) => (params.row.orderStatus === SFOrderStatus.DELIVERED ? 'row-status--disabled' : '')}
			/>
		</Paper>
	);
}

export default DeliveriesList;
