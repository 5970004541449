import { queryOptions } from '@tanstack/react-query';
import {
	getCareTeamMember,
	getCareTeamMembersList,
	getEnterprise,
	getEnterprisesListN,
} from 'core/api/admin/enterprise.api';
import {
	ICareTeamMemberRequestActionPayload,
	ICareTeamMembersListRequest,
	IEnterprise,
	IEnterpriseRequest,
	IEnterprisesListRequest,
} from 'core/api/admin/enterprise.models';
import { ICareTeamMember } from 'core/models/admin/enterprise.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export const adminEnterpriseQueries = {
	enterprisesList: ({ page, ...restRequest }: IEnterprisesListRequest) =>
		queryOptions({
			queryKey: ['admin', 'enterprise', 'list', restRequest, page],
			queryFn: () => getEnterprisesListN({ page, ...restRequest }),
			select: normalizeHydraCollection<IEnterprise>,
		}),
	enterprise: (request: IEnterpriseRequest) =>
		queryOptions({
			queryKey: ['admin', 'enterprise', request.id],
			queryFn: () => getEnterprise(request),
		}),
	careTeamMembers: ({ enterpriseId, page, ...restRequest }: ICareTeamMembersListRequest) =>
		queryOptions({
			queryKey: ['admin', 'careTeamMember', 'list', enterpriseId, restRequest, page],
			queryFn: () => getCareTeamMembersList({ enterpriseId, page, ...restRequest }),
			select: normalizeHydraCollection<ICareTeamMember>,
		}),
	careTeamMember: ({ id }: Partial<ICareTeamMemberRequestActionPayload>) =>
		queryOptions({
			queryKey: ['admin', 'careTeamMember', id],
			queryFn: () => getCareTeamMember({ id: id || '' }),
			enabled: !!id,
		}),
};
