import React from 'react';
import { DialogActions } from '@mui/material';
import { PatientMedication } from 'core/api/enterprise/patient.models';
import Dialog, { IDialogProps } from 'components/dialog';
import MedicationDetails from 'components/patient/medication/MedicationDetails';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { MedicationAction } from 'core/api/medications/medications.models';
import { refillRequestFormPdf } from 'core/api/enterprise/patient.api';
import { EnterpriseRequestRefillButton } from '../EnterpriseRequestRefillButton';

interface MedicationViewDialogProps extends Omit<IDialogProps, 'children'> {
	medicationData: PatientMedication;
	patientId: string;
}

export function MedicationViewDialog({ medicationData, patientId, ...restDialogProps }: MedicationViewDialogProps) {
	const { actions, sfid, prescriber, name } = medicationData || {};
	const handleDownloadPdf = () =>
		refillRequestFormPdf({
			patientId,
			prescriberSfid: prescriber || '',
			currentMedicationSfid: sfid || undefined,
		});

	return (
		<Dialog scroll="paper" fullWidth maxWidth="md" title={name} {...restDialogProps}>
			<MedicationDetails {...medicationData} />
			<DialogActions>
				{actions?.includes(MedicationAction.REQUEST_REFILL_FROM_ECP) && (
					<EnterpriseRequestRefillButton sfid={sfid} patientId={patientId} variant="contained" color="primary" />
				)}
				{prescriber && actions?.includes(MedicationAction.GET_PRESCRIBER_REFILL_REQUEST_FORM) && (
					<DownloadPdfButton onDownloadPdf={handleDownloadPdf} variant="contained" color="primary">
						Download Refill Request Form
					</DownloadPdfButton>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default MedicationViewDialog;
