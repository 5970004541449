import { Shadows } from '@mui/material';

export default [
	'none',
	'0px 0px 1px rgba(97, 0, 138, 0.10), 0px 1px 3px rgba(97, 0, 138, 0.10)',
	'0px 0px 1px rgba(97, 0, 138, 0.10), 0px 2px 4px rgba(97, 0, 138, 0.10)',
	'0px 0px 1px rgba(97, 0, 138, 0.10), 0px 4px 8px rgba(97, 0, 138, 0.10)',
	'0px 0px 1px rgba(97, 0, 138, 0.10), 0px 8px 16px rgba(97, 0, 138, 0.10)',
	'0px 0px 1px rgba(97, 0, 138, 0.10), 0px 16px 24px rgba(97, 0, 138, 0.10)',
	'0px 0px 8px 0px rgba(34, 34, 34, 0.6)',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
	'none',
] as Shadows;
