import React, { useCallback } from 'react';
import DynamicForm, { IDynamicFormElement } from 'components/common/inputs/dynamicForm/DynamicForm';
import Joi from 'joi';
import {
	CareTeamMemberStatus,
	ICreateCareTeamMemberRequestActionPayload,
	IUpdateCareTeamMemberRequestActionPayload,
} from 'core/models/admin/enterprise.models';
import { Box, CircularProgress } from '@mui/material';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useCareTeamMemberMutation, useCareTeamMemberQuery, useEnterpriseQuery } from 'hooks/admin';
import Dialog, { IDialogProps } from 'components/dialog';

type FormData = ICreateCareTeamMemberRequestActionPayload | IUpdateCareTeamMemberRequestActionPayload;

interface EnterpriseCaregiverFormDialogProps extends Omit<IDialogProps, 'children'> {
	enterpriseId: string;
	careTeamMemberId?: string;
}

export function EnterpriseCaregiverFormDialog({
	enterpriseId,
	careTeamMemberId,
	...restDialogProps
}: EnterpriseCaregiverFormDialogProps) {
	const { data: enterprise, isLoading: isLoadingEnterprise } = useEnterpriseQuery({ id: enterpriseId });
	const { data: careTeamMember, isLoading: isLoadingCareTeamMember } = useCareTeamMemberQuery({ id: careTeamMemberId });
	const { createCareTeamMember, updateCareTeamMember } = useCareTeamMemberMutation();
	const formError = createCareTeamMember.error || updateCareTeamMember.error;
	const formIsLoading =
		createCareTeamMember.isPending || updateCareTeamMember.isPending || isLoadingCareTeamMember || isLoadingEnterprise;

	const disabledAllExcept = useCallback((watch: UseFormWatch<FieldValues>, elementName: string): boolean => {
		const fields = {
			patientsAccessIDs: watch('patientsAccessIDs')?.length > 0,
			patientsAccessLocationSfid: !!watch('patientsAccessLocationSfid'),
			patientsAccessStateSfid: !!watch('patientsAccessStateSfid'),
			patientsAccessAll: watch('patientsAccessAll'),
		};

		return Object.entries(fields).some(([key, value]) => key !== elementName && value);
	}, []);

	const formElements: Map<string, IDynamicFormElement> = new Map();

	const handleSubmitForm = (data: FormData) => {
		if (enterprise?.id) {
			const formData = {
				...data,
				enterpriseId: enterprise.id,
			};

			if (careTeamMember?.id) {
				updateCareTeamMember.mutate(
					{
						...formData,
						id: careTeamMember.id.toString(),
					},
					{
						onSuccess: () => {
							if (restDialogProps?.onClose) {
								restDialogProps.onClose();
							}
						},
					}
				);
			} else {
				createCareTeamMember.mutate(formData, {
					onSuccess: () => {
						if (restDialogProps?.onClose) {
							restDialogProps.onClose();
						}
					},
				});
			}
		}
	};

	formElements
		.set('email', {
			label: 'Email',
			type: 'email',
			value: careTeamMember?.email || '',
			validation: Joi.string()
				.email({ tlds: { allow: false } })
				.required(),
		})
		.set('name', {
			label: 'Name',
			type: 'text',
			value: careTeamMember?.name || '',
			validation: Joi.string().required(),
		})
		.set('supervisor', {
			label: 'Supervisor',
			type: 'switch',
			value: careTeamMember?.supervisor || false,
			validation: Joi.boolean(),
		})
		.set('status', {
			label: 'Status',
			type: 'select',
			value: careTeamMember?.status || '',
			options: [
				{
					label: 'Pending',
					value: CareTeamMemberStatus.PENDING,
				},
				{
					label: 'Approved',
					value: CareTeamMemberStatus.APPROVED,
				},
				{
					label: 'Registered',
					value: CareTeamMemberStatus.REGISTERED,
				},
			],
			validation: Joi.number()
				.valid(CareTeamMemberStatus.PENDING, CareTeamMemberStatus.APPROVED, CareTeamMemberStatus.REGISTERED)
				.required(),
		})
		.set('patientsAccessAll', {
			label: 'Patients Access All',
			type: 'switch',
			value: careTeamMember?.patientsAccessAll || false,
			validation: Joi.boolean(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessAll'),
		})
		.set('patientsAccessStateSfid', {
			label: 'Patients Access State Sfid',
			type: 'select',
			value: careTeamMember?.patientsAccessStateSfid || '',
			options:
				((enterprise?.states &&
					enterprise.states.map(({ sfid, name }) => ({
						value: sfid,
						label: name,
					}))) as { label: string; value: string }[]) || [],
			validation: Joi.string().allow('').optional(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessStateSfid'),
		})
		.set('patientsAccessLocationSfid', {
			label: 'Patients Access Location Sfid',
			type: 'select',
			value: careTeamMember?.patientsAccessLocationSfid || '',
			options:
				(enterprise?.locations &&
					Object.entries(enterprise.locations).reduce(
						(accumulator, [key, locationsArray]) => {
							// eslint-disable-next-line no-param-reassign
							accumulator[key] = locationsArray.map(({ sfid, name }) => ({
								value: sfid,
								label: name,
							})) as { label: string; value: string }[];
							return accumulator;
						},
						{} as Record<string, { label: string; value: string }[]>
					)) ||
				undefined,
			validation: Joi.string().allow('').optional(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessLocationSfid'),
		})
		.set('patientsAccessIDs', {
			label: 'Patients Access IDs',
			type: 'multi-select',
			value: careTeamMember?.patientsAccessIDs || [],
			options:
				(enterprise?.patients &&
					Object.entries(enterprise.patients).reduce(
						(accumulator, [key, patientsArray]) => {
							// eslint-disable-next-line no-param-reassign
							accumulator[key] = patientsArray.map(({ id, name }) => ({
								value: id,
								label: name,
							})) as { label: string; value: number }[];
							return accumulator;
						},
						{} as Record<string, { label: string; value: number }[]>
					)) ||
				undefined,
			validation: Joi.array().optional(),
			disabled: (watch) => disabledAllExcept(watch, 'patientsAccessIDs'),
		});

	return (
		<Dialog
			scroll="paper"
			fullWidth
			maxWidth="xs"
			title={careTeamMember ? 'Edit Care Team Member' : 'Create Care Team Member'}
			{...restDialogProps}
		>
			{formIsLoading && (
				<Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress />
				</Box>
			)}
			{!formIsLoading && (
				<DynamicForm
					formElements={formElements}
					formRequestIsLoading={formIsLoading}
					formRequestError={formError}
					onSubmitFormHandler={handleSubmitForm}
				/>
			)}
		</Dialog>
	);
}

export default EnterpriseCaregiverFormDialog;
