import React from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';
import { CareTeamMemberStatus } from 'core/models/admin/enterprise.models';

const ChipContainer = styled(MuiChip)<{ component: React.ElementType }>(() => ({
	fontWeight: '700',
	textTransform: 'capitalize',
}));

export interface ICaregiverStatusChipProps extends Omit<ChipProps, 'label' | 'size' | 'color'> {
	status: CareTeamMemberStatus;
}

function CaregiverStatusChip({ status }: ICaregiverStatusChipProps) {
	switch (status) {
		case CareTeamMemberStatus.APPROVED:
			return <ChipContainer component="span" label="Approved" size="small" color="success" />;
		case CareTeamMemberStatus.PENDING:
			return <ChipContainer component="span" label="Pending" size="small" color="warning" />;
		case CareTeamMemberStatus.REGISTERED:
			return <ChipContainer component="span" label="Registered" size="small" color="info" />;
		default:
			return null;
	}
}

export default CaregiverStatusChip;
