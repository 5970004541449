import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function HomeMobIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 19 20" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.12 8.17995L10.41 0.299949V0.299949C10.0211 -0.0921734 9.38792 -0.0947694 8.9958 0.29415C8.99386 0.296075 8.99193 0.298008 8.99001 0.299949L1.28001 8.18995H1.28001C0.903161 8.56983 0.694259 9.08489 0.700006 9.61995V17.9999L0.700006 18C0.698406 19.063 1.52863 19.9415 2.59003 19.9999H16.81C17.8714 19.9415 18.7016 19.0629 18.7 17.9999V9.61995V9.61992C18.7008 9.08286 18.4928 8.56651 18.12 8.17993L18.12 8.17995ZM7.7 17.9999V11.9999H11.7V17.9999H7.7ZM16.7 17.9999H13.7V10.9999C13.7 10.4477 13.2523 9.99995 12.7 9.99995H6.7C6.14772 9.99995 5.7 10.4477 5.7 10.9999V17.9999H2.7V9.57995L9.7 2.42995L16.7 9.61995V17.9999Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
