import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import googleAnalyticsService from 'services/googleAnalyticsService';

export function GoogleAnalytics() {
	const location = useLocation();

	useEffect(() => {
		googleAnalyticsService.init();

		return () => {
			googleAnalyticsService.cleanup();
		};
	}, []);

	useEffect(() => {
		googleAnalyticsService.logPageView(location.pathname + location.search);
	}, [location]);

	return null;
}

export default GoogleAnalytics;
