import React, { useEffect } from 'react';
import {
	Box,
	Link,
	Grid,
	Toolbar,
	Typography,
	IconButton,
	Container,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	AppBar as MuiAppBar,
	AppBarProps as MuiAppBarProps,
	Drawer as MuiDrawer,
	styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import routes from 'routes';
import { useUserContext } from 'contexts/UserProvider';
import isActiveRoute from 'utils/isActiveRoute';
import { useDisclosure, useAuth } from 'hooks';
import Breadcrumbs from 'components/Breadcrumbs';
import AnewHealthExactCareLightLogo from 'components/logo/AnewHealthExactCareLightLogo';

function Copyright() {
	return (
		<Typography variant="body2" color="text.primary" align="center" sx={{ pt: 4 }}>
			{'Copyright © '}
			<Link color="inherit" to={routes.admin.home} component={RouterLink}>
				ExactCare Pharmacy
			</Link>{' '}
			{new Date().getFullYear()}.
		</Typography>
	);
}

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	background: 'linear-gradient(195deg, rgba(105,0,145,1) 0%, rgba(22,122,201,1) 100%)',
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

interface DashboardLayoutProps {
	children: React.ReactNode;
	pageTitle: string;
	mainMenu: { to: string; activeRoutes: string[]; label: string; icon: React.ReactElement }[];
	className?: string;
}

export function DashboardLayout({ children, pageTitle, mainMenu, className }: DashboardLayoutProps) {
	const { email } = useUserContext();
	const { logout } = useAuth();
	const location = useLocation();
	const { isOpen: isOpenDrawer, onToggle: onToggleDrawer } = useDisclosure({ isOpen: true });
	const handleLogout = () => {
		logout.mutate();
	};

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	return (
		<Box sx={{ display: 'flex', position: 'relative' }} className={className}>
			<AppBar position="absolute" open={isOpenDrawer}>
				<Toolbar
					sx={{
						pr: '24px',
					}}
				>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={onToggleDrawer}
						sx={{
							marginRight: '36px',
							...(isOpenDrawer && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
						{pageTitle}
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
						<Typography>Hi! {email}</Typography>
						<IconButton onClick={handleLogout} color="inherit" title="Logout">
							<LogoutIcon />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={isOpenDrawer}>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						px: [1],
					}}
				>
					<Link to={routes.home} component={RouterLink}>
						<AnewHealthExactCareLightLogo sx={{ width: 190, height: 'auto', display: 'block' }} />
					</Link>
					<IconButton onClick={onToggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				<List component="nav">
					{mainMenu.map((item) => (
						<ListItemButton
							key={item.label}
							to={item.to}
							component={RouterLink}
							selected={isActiveRoute(location.pathname, item.activeRoutes)}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					))}
				</List>
			</Drawer>
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
				}}
			>
				<Toolbar />
				<Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sx={{ paddingTop: '6px !important' }}>
							<Breadcrumbs />
						</Grid>
						{children}
					</Grid>
					<Copyright />
				</Container>
			</Box>
		</Box>
	);
}

export default DashboardLayout;
