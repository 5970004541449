import { queryOptions } from '@tanstack/react-query';
import { IGetDeliveriesSummaryRequestParams } from 'core/api/delivery/delivery.models';
import { getMedications, getMedicationSummary } from 'core/api/medications/medications.api';
import { IMedication } from 'core/api/medications/medications.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export const medicationQueries = {
	getMedications: () =>
		queryOptions({
			queryKey: ['medications', 'list'],
			queryFn: getMedications,
			select: normalizeHydraCollection<IMedication>,
		}),
	getMedication: ({ sfid }: Partial<IGetDeliveriesSummaryRequestParams>) =>
		queryOptions({
			queryKey: ['medications', 'item', sfid],
			queryFn: () => getMedicationSummary({ sfid: sfid || '' }),
			enabled: !!sfid,
		}),
};
