import React from 'react';
import InfoCardWidget from 'components/InfoCardWidget';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { MedicationScheduleIcon } from 'components/icons/MedicationScheduleIcon';
import { SidebarListItem } from 'components/sidebarList';

export function LogTodaysMedicationWidget() {
	const cardTitle = 'Log Today’s Medication';
	return (
		<SidebarListItem to={routes.mySchedule.logTodaysMed} component={RouterLink}>
			<InfoCardWidget title={cardTitle} icon={<MedicationScheduleIcon />} />
		</SidebarListItem>
	);
}

export default LogTodaysMedicationWidget;
