import React from 'react';
import { Select, SelectProps, MenuItem } from '@mui/material';

interface TimeZoneSelectProps extends SelectProps<string> {}

export function TimeZoneSelect({ ...rest }: TimeZoneSelectProps) {
	const timeZone: { [key: string]: string } = {
		'America/Puerto_Rico': 'Atlantic Standard Time',
		'America/New_York': 'Eastern Standard Time',
		'America/Chicago': 'Central Standard Time',
		'America/Denver': 'Mountain Standard Time',
		'America/Phoenix': 'Mountain - Arizona',
		'America/Los_Angeles': 'Pacific Standard Time',
		'America/Anchorage': 'Alaska Standard Time',
		'Pacific/Honolulu': 'Hawaii Standard Time',
	};

	return (
		<Select {...rest}>
			{Object.keys(timeZone).map((zone) => (
				<MenuItem key={zone} value={zone}>
					{timeZone[zone]}
				</MenuItem>
			))}
		</Select>
	);
}

export default TimeZoneSelect;
