import React, { useState, useEffect } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface DownloadPdfButtonProps extends Omit<ButtonProps, 'children' | 'onError'> {
	children: React.ReactNode;
	onDownloadPdf: () => Promise<Blob>;
	onError?: (error: ERROR) => void;
	onIsLoading?: (state: boolean) => void;
}

export enum ERROR {
	POPUP_BLOCKER_ERROR = 'Please disable your popup blocker to view the PDF.',
	PDF_DOWNLOAD_ERROR = 'An error occurred while downloading the PDF.',
}

function DownloadPdfButton({ children, onDownloadPdf, onError, onIsLoading, ...buttonArgs }: DownloadPdfButtonProps) {
	const [isLoading, setIsLoading] = useState(false);

	const handleIsLoading = (state: boolean) => onIsLoading && onIsLoading(state);

	const handleDownloadPdf = async () => {
		setIsLoading(true);

		try {
			const pdfData = await onDownloadPdf();
			const blob = new Blob([pdfData], { type: 'application/pdf' });
			const blobUrl = URL.createObjectURL(blob);
			const newWindow = window.open(blobUrl);

			if (newWindow === null) {
				if (onError) onError(ERROR.POPUP_BLOCKER_ERROR);
			} else {
				newWindow.addEventListener('unload', () => URL.revokeObjectURL(blobUrl));
			}
		} catch (error) {
			if (onError) onError(ERROR.PDF_DOWNLOAD_ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		handleIsLoading(isLoading);
	}, [isLoading]);

	return (
		<Button disabled={isLoading} onClick={handleDownloadPdf} {...buttonArgs}>
			{isLoading && !onIsLoading ? 'Loading...' : children}
		</Button>
	);
}

export default DownloadPdfButton;
