import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import QueryProvider from 'contexts/QueryProvider';
import ThemeProvider from 'theme';
import Router from 'Router';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<ThemeProvider>
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<QueryProvider>
				<React.StrictMode>
					<Router />
				</React.StrictMode>
			</QueryProvider>
		</LocalizationProvider>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
