import React from 'react';
import ContentCard from 'components/card/ContentCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useScheduledMedsQuery, useTakeMedMutation } from 'hooks/patient';
import { IScheduledMedDetails } from 'core/api/mySchedule/mySchedule.models';
import { MedScheduleDetails } from 'components/patient/mySchedule';
import CircularLoading from 'components/CircularLoading';
import Suspense from 'components/Suspense';
import routes from 'routes';
import { Theme, useMediaQuery } from '@mui/material';

export default function TodaysMedDetailsPage() {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { data: scheduledMedsData } = useScheduledMedsQuery({});
	const scheduleDetails = scheduledMedsData?.items.find((item: IScheduledMedDetails) => item.id === Number(id));
	const { mutate: handleLogChange } = useTakeMedMutation();
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	return (
		<ContentCard>
			<Suspense fallback={<CircularLoading />} isLoading={!scheduleDetails}>
				{scheduleDetails && (
					<MedScheduleDetails
						{...scheduleDetails}
						onLogChange={handleLogChange}
						onBackButton={upSmallScreen ? () => navigate(routes.mySchedule.logTodaysMed) : undefined}
					/>
				)}
			</Suspense>
		</ContentCard>
	);
}
