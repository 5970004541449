import { useMemo } from 'react';
import Joi from 'joi';
import { Operator } from 'core/api/enterprise/patient.models';

export function usePatientFilterSchema() {
	const schema = useMemo(() => {
		const stringSchema = Joi.string();
		const numberSchema = Joi.number();

		const buildFilterCriterionSchema = (typeSchema: Joi.Schema, operators: Operator[]) =>
			Joi.object({
				operator: Joi.string()
					.valid(...operators)
					.optional()
					.allow(null, ''),
				value: typeSchema.optional().allow(null, ''),
			}).optional();

		return Joi.object({
			locationName: buildFilterCriterionSchema(stringSchema, ['eq', 'neq', 'startswith', 'endswith', 'contains']),
			territory: buildFilterCriterionSchema(stringSchema, ['eq', 'neq', 'startswith', 'endswith', 'contains']),
			state: buildFilterCriterionSchema(stringSchema, ['eq', 'neq', 'startswith', 'endswith', 'contains']),
			status: buildFilterCriterionSchema(stringSchema, ['eq', 'neq', 'startswith', 'endswith', 'contains']),
			onboardingStatus: buildFilterCriterionSchema(stringSchema, ['eq', 'neq', 'startswith', 'endswith', 'contains']),
			postalCode: buildFilterCriterionSchema(stringSchema, ['eq', 'neq', 'startswith', 'endswith', 'contains']),
			medsWithoutRefills: buildFilterCriterionSchema(numberSchema, ['eq', 'neq', 'gt', 'lt']),
			dateOfBirth: buildFilterCriterionSchema(stringSchema, ['eq', 'gte', 'lte']),
			name: Joi.string().optional().allow(null, ''),
		});
	}, []);

	return schema;
}

export default usePatientFilterSchema;
