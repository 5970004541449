import { useMutation } from '@tanstack/react-query';
import { changePassword } from 'core/api/resetPassword/resetPassword.api';

export function useResetPasswordMutation() {
	return useMutation({
		mutationKey: ['resetPassword'],
		mutationFn: changePassword,
	});
}

export default useResetPasswordMutation;
