import { useState, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';

export const useMonthRange = (): [Dayjs, Dayjs, (date: Dayjs) => void] => {
	const [fromDate, setFromDate] = useState<Dayjs>(dayjs().startOf('month'));
	const [toDate, setToDate] = useState<Dayjs>(dayjs().endOf('month'));

	const setMonthRange = useCallback((date: Dayjs) => {
		setFromDate(date.startOf('month'));
		setToDate(date.endOf('month'));
	}, []);

	return [fromDate, toDate, setMonthRange];
};

export default useMonthRange;
