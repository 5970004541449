import React from 'react';
import { styled } from '@mui/material/styles';
import { Box as MuiBox, Chip as MuiChip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';
import { MedicationRefillsStatusAlertLevel } from 'core/api/medications/medications.models';

const ChipContainer = styled(MuiChip)<{ component: React.ElementType }>(() => ({
	fontWeight: '700',
}));

const BoxContainer = styled(MuiBox)(() => ({
	fontWeight: '700',
}));

export interface IRefillsStatusChipProps extends Omit<ChipProps, 'label' | 'size' | 'color'> {
	refillsStatusAlertLevel: string | null | undefined;
	refillsStatus: string | null | undefined;
}

function RefillsStatusChip({ refillsStatusAlertLevel, refillsStatus }: IRefillsStatusChipProps) {
	switch (refillsStatusAlertLevel) {
		case MedicationRefillsStatusAlertLevel.REFILLS_ALERT_LEVEL_DANGER:
			return (
				<ChipContainer component="span" label={refillsStatus} size="small" color="error" className="refillsChip" />
			);
		case MedicationRefillsStatusAlertLevel.REFILLS_ALERT_LEVEL_WARNING:
			return (
				<ChipContainer component="span" label={refillsStatus} size="small" color="warning" className="refillsChip" />
			);
		default:
			return (
				<BoxContainer component="span" className="refillsChip">
					{refillsStatus}
				</BoxContainer>
			);
	}
}

export default RefillsStatusChip;
