import * as React from 'react';
import { TableHead, TableRow, TableBody, TableCell, Table } from '@mui/material';
import { AnyObject } from 'shared/types';
import { TableContainer } from './TableContainer';
import { MedRow } from './MedRow';
import { IMedColumn } from './MedColumn';

interface IMedsGridProps<ROW> {
	columns: IMedColumn<ROW>[];
	rows: ROW[];
	getRowId: (row: ROW) => string;
}

export function MedsGrid<ROW extends AnyObject>({ rows, columns, getRowId }: IMedsGridProps<ROW>) {
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{columns.map(({ renderCell, field, headerName, hide, ...restColumnProps }) =>
							!hide ? (
								<TableCell key={field} {...restColumnProps}>
									{headerName}
								</TableCell>
							) : null
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<MedRow<ROW> key={getRowId(row)} columns={columns} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default MedsGrid;
