import React from 'react';
import { Grid } from '@mui/material';
import { PatientMedSyncList, PatientNotificationList } from 'components/admin';
import { adminPatientQueries } from 'queries/admin';
import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import withPageContext from 'hoc/withPageContext';
import { PatientDetails } from 'core/api/admin/patients.models';

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		const { patientId } = params;
		const query = adminPatientQueries.patient({ id: patientId });
		return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
	};

function PatientsViewPage() {
	const { userId } = useLoaderData() as PatientDetails;

	return (
		<>
			<Grid item xs={12}>
				<PatientMedSyncList userId={userId} />
			</Grid>
			<Grid item xs={12}>
				<PatientNotificationList userId={userId} />
			</Grid>
		</>
	);
}

export default withPageContext(PatientsViewPage);
