import { useMutation } from '@tanstack/react-query';
import { makeAPaymentRequest } from 'core/api/userAccount/userAccount.api';

export function useMakePaymentMutation() {
	return useMutation({
		mutationKey: ['payment-url'],
		mutationFn: makeAPaymentRequest,
	});
}

export default useMakePaymentMutation;
