import { ListItemText, styled } from '@mui/material';

export const MessageSummary = styled(ListItemText)(({ theme }) => ({
	margin: 0,

	'& .MuiListItemText-primary': {
		textAlign: 'left',
		fontWeight: 600,
		fontSize: theme.typography.h2.fontSize,
		lineHeight: theme.typography.h2.lineHeight,
		color: theme.palette.text.secondary,
	},
	'& .MuiListItemText-secondary': {
		textAlign: 'left',
		fontWeight: 400,
		fontSize: theme.typography.body1.fontSize,
		lineHeight: theme.typography.body1.lineHeight,
	},
}));

export default MessageSummary;
