import { Subject, Observable } from 'rxjs';

interface Event {
	category: string;
	action: string;
	body?: string | number | boolean | Record<string, unknown>;
}

class EventService {
	private eventSubject = new Subject<Event>();

	emitEvent(event: Event) {
		this.eventSubject.next(event);
	}

	get events$(): Observable<Event> {
		return this.eventSubject.asObservable();
	}

	onEvent(category: Event['category'], callback: (event: Event) => void) {
		return this.events$.subscribe((event) => {
			if (event.category === category) {
				callback(event);
			}
		});
	}

	onAnyEvent(callback: (event: Event) => void) {
		return this.events$.subscribe(callback);
	}
}

export const eventService = new EventService();

export default eventService;
