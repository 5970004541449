import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function NoonIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M16.45 17.85C15.45 18.6 14.4 19 13.15 19.25C13.15 19.8 13.15 20.3 13.15 20.85C13.15 21.3 13.15 21.8 13.15 22.25C13.1 22.85 12.6 23.3 12 23.25C11.45 23.25 11 22.8 11 22.2C11 21.55 11 20.95 11 20.3V19.25C9.89995 19 8.84996 18.65 7.94996 18C7.44996 18.35 7.09996 18.8 6.64996 19.2C6.34996 19.45 6.14996 19.75 5.84996 20C5.29996 20.5 4.64996 20.55 4.19996 20.1C3.69996 19.6 3.69996 19 4.29996 18.4C4.94996 17.75 5.59996 17.1 6.29996 16.45C5.49996 15.4 5.04996 14.25 4.79996 12.95H2.39996C2.14996 12.95 1.94996 12.95 1.69996 12.95C1.14996 12.9 0.749956 12.5 0.699956 11.95C0.649956 11.35 0.949956 10.9 1.54996 10.75C1.94996 10.65 2.34996 10.7 2.74996 10.65C3.44996 10.65 4.09996 10.7 4.84996 10.6C5.09996 9.45 5.49996 8.45 6.24996 7.45C5.64996 6.9 5.04996 6.35 4.49996 5.8C4.34996 5.65 4.14996 5.5 3.99996 5.3C3.59996 4.8 3.59996 4.25 3.99996 3.8C4.34996 3.4 4.94996 3.35 5.44996 3.7C5.59996 3.8 5.79996 3.95 5.94996 4.1C6.54996 4.7 7.14996 5.3 7.74996 5.95C8.74996 5.3 9.79996 4.85 11 4.65V2.15C11 1.95 11 1.75 11 1.6C11 0.9 11.35 0.5 12.05 0.5C12.75 0.5 13.15 0.9 13.15 1.65C13.15 2.45 13.15 3.3 13.15 4.1V4.65C14.35 4.9 15.5 5.35 16.55 6.1C17.1 5.55 17.65 5 18.2 4.45C18.35 4.3 18.55 4.1 18.7 3.95C19.2 3.55 19.85 3.55 20.25 3.95C20.7 4.4 20.7 4.95 20.3 5.5C20.15 5.65 20 5.8 19.85 5.95C19.25 6.5 18.65 7.1 18.05 7.7C18.65 8.65 19.05 9.55 19.25 10.55C19.55 10.75 19.85 10.7 20.1 10.7C20.85 10.75 21.6 10.65 22.35 10.75C22.9 10.85 23.3 11.2 23.4 11.65C23.5 12.35 23.05 12.9 22.25 12.95C21.5 12.95 20.8 12.95 20.05 12.95H19.3C19 14.1 18.7 15.2 18 16.15C18.2 16.5 18.5 16.75 18.75 17C19.15 17.45 19.65 17.85 20.05 18.3C20.5 18.85 20.45 19.45 20 19.9C19.55 20.35 18.95 20.3 18.4 19.85C17.9 19.35 17.4 18.85 16.9 18.35C16.85 18.2 16.65 18.05 16.45 17.85ZM12.05 6.85C9.54996 6.75 6.94996 8.95 6.94996 11.95C6.94996 14.75 9.24996 17.05 12.1 17C14.9 17 17.25 14.7 17.2 11.9C17.1 8.6 14.3 6.75 12.05 6.85Z"
				fill="currentColor"
			/>
			<path
				d="M13.1 13.3H15.2C15.4 13.6 15.4 13.8 15.3 14.05C14.8 14.2 12.9 14.25 12.1 14.1C11.85 13.4 12 12.7 12.65 12.15C13 11.85 13.35 11.6 13.7 11.35C14.2 10.95 14.3 10.6 14.05 10.3C13.8 9.95 13.35 10 12.85 10.35C12.8 10.4 12.7 10.45 12.6 10.55C12.35 10.45 12.1 10.3 11.95 10.1C12.45 9.1 13.65 8.75 14.55 9.3C15.25 9.75 15.45 10.75 14.9 11.45C14.65 11.8 14.25 12.05 13.9 12.3C13.65 12.65 13.35 12.8 13.1 13.3Z"
				fill="currentColor"
			/>
			<path
				d="M8.59992 14.15C8.44992 13.85 8.44992 13.65 8.54992 13.4C8.84992 13.15 9.19992 13.4 9.54992 13.2C9.64992 12.4 9.54992 11.55 9.59992 10.7C9.34992 10.7 9.24992 10.95 8.99992 10.95C8.79992 10.85 8.59992 10.7 8.49992 10.4C8.79992 10 9.19992 9.70005 9.54992 9.35005C9.79992 9.10005 9.89992 9.15005 10.5999 9.30005V13.15C10.9499 13.4 11.2499 13.2 11.4999 13.35C11.6499 13.55 11.5999 13.8 11.4999 14.1H8.59992V14.15Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
