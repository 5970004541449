enum RegistrationStatus {
	CreateUser = 0,
	RegisterPhoneNumber = 1,
	VerifyPhoneNumber = 2,
	Completed = 3,
}

export enum RegistrationStep {
	Step1 = 0,
	Step2 = 1,
	Step3 = 2,
	Step4 = 3,
	Finish = 4,
}

type RegistrationStatusToStepMap = Record<RegistrationStatus, RegistrationStep>;

const verifyIdentityStatusToStepMap: RegistrationStatusToStepMap = {
	[RegistrationStatus.CreateUser]: RegistrationStep.Step2,
	[RegistrationStatus.RegisterPhoneNumber]: RegistrationStep.Step3,
	[RegistrationStatus.VerifyPhoneNumber]: RegistrationStep.Step3,
	[RegistrationStatus.Completed]: RegistrationStep.Step4,
};

export const getStepByStatus = (status: RegistrationStatus | null): RegistrationStep => {
	return status !== null ? verifyIdentityStatusToStepMap[status] : RegistrationStep.Step1;
};

export const authErrorStatusMap = {
	accountNotFound: 'Account not found.',
	accountAlreadyRegistered: 'Already registered.',
	invalidPhoneVerificationCode: 'Invalid phone verification code.',
};
