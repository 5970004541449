import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function EarlyMorningIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M7.30006 6.09994C7.20006 5.29994 7.60006 4.49994 8.00006 3.69994C8.50006 2.69994 8.30006 1.49994 7.45006 0.749938C7.25006 0.549938 7.05006 0.349938 6.75006 0.449938C6.45006 0.599938 6.50006 0.949939 6.50006 1.19994C6.65006 2.19994 6.35006 3.04994 5.90006 3.89994C5.80006 4.09994 5.70006 4.34994 5.60006 4.54994C5.40006 5.49994 5.65006 6.34994 6.30006 7.04994C6.50006 7.24994 6.75006 7.39994 7.00006 7.29994C7.30006 7.19994 7.30006 6.89994 7.30006 6.59994C7.35006 6.49994 7.35006 6.39994 7.30006 6.09994Z"
				fill="currentColor"
			/>
			<path
				d="M10.15 7.24986C10.45 6.99986 10.45 6.69986 10.35 6.39986C10.25 5.89986 10.35 5.49986 10.55 5.04986C10.7 4.74986 10.85 4.44986 10.95 4.14986C11.3 3.34986 11.1 2.39986 10.45 1.84986C10.2 1.64986 9.95005 1.49986 9.70004 1.74986C9.65004 2.24986 9.80004 2.74986 9.65004 3.19986C9.50004 3.64986 9.25005 4.09986 9.10005 4.54986C9.05005 4.59986 9.05005 4.69986 9.00005 4.79986C8.70004 5.84986 9.20004 6.94985 10.15 7.24986Z"
				fill="currentColor"
			/>
			<path
				d="M20.55 13.1C20.45 11.3 19.05 9.74995 17.2 9.49995C16.95 9.44995 16.65 9.49995 16.45 9.29995C16.05 8.24995 15.7 7.99995 14.6 7.94995C13.8 7.94995 13.05 7.94995 12.25 7.94995C8.85003 7.94995 5.45002 7.94995 2.05002 7.94995C0.600025 7.94995 0.100024 8.44995 0.100024 9.89995C0.100024 12 0.0500245 14.05 0.150025 16.15C0.250025 18.6 1.55002 20.35 3.65002 20.95C3.75002 21 3.85002 21.05 4.00002 21.1C4.00002 21.55 4.00002 22 4.00002 22.45C4.00002 23.1 4.20002 23.3 4.90002 23.35C7.30002 23.35 9.70003 23.35 12.1 23.35C12.8 23.35 12.95 23.15 13 22.4C13 21.9 12.95 21.45 13.05 20.95C14.85 20.15 15.45 19.7 16.35 17.35C16.75 17.3 17.15 17.25 17.55 17.15C19.05 16.85 20.65 15.3 20.55 13.1ZM14.15 17.05C13.7 18.4 12.9 19.05 11.45 19.05C9.25002 19.05 7.10002 19.05 4.90002 19.05C3.40002 19.05 2.40002 18.15 2.15002 16.7C2.10002 16.4 2.10002 16.05 2.10002 15.75C2.10002 14.1 2.10002 12.4 2.15002 10.75C2.15002 10.55 2.20002 10.4 2.20002 10.1H14.15C14.35 10.3 14.3 10.5 14.3 10.7C14.3 12.45 14.3 14.2 14.3 15.95C14.3 16.3 14.3 16.65 14.15 17.05ZM17.95 14.1C17.65 14.65 17.2 14.95 16.45 14.8V11.85C17.1 11.75 17.55 12 17.85 12.5C18.25 13 18.25 13.55 17.95 14.1Z"
				fill="currentColor"
			/>
			<path
				d="M7.29997 6.09999C7.29997 6.34999 7.34997 6.49999 7.34997 6.64999C7.34997 6.89999 7.39997 7.24999 7.04997 7.34999C6.74997 7.44999 6.49997 7.29999 6.34997 7.09999C5.64997 6.39999 5.39997 5.54999 5.64997 4.59999C5.69997 4.34999 5.79997 4.14999 5.94997 3.94999C6.39997 3.09999 6.69997 2.24999 6.54997 1.24999C6.49997 0.949987 6.44997 0.599987 6.74997 0.499987C7.04997 0.399987 7.24997 0.599987 7.44997 0.799987C8.29997 1.54999 8.49997 2.69999 7.99997 3.69999C7.59997 4.49999 7.19997 5.29999 7.29997 6.09999Z"
				fill="currentColor"
			/>
			<path
				d="M10.15 7.25001C9.15003 6.95001 8.65003 5.85001 9.00003 4.85001C9.05003 4.75001 9.05003 4.70001 9.10003 4.60001C9.30003 4.15001 9.50003 3.70001 9.65003 3.25001C9.80003 2.80001 9.65003 2.30001 9.70003 1.80001C10.05 1.50001 10.25 1.65001 10.5 1.85001C11.15 2.40001 11.35 3.35001 11 4.15001C10.85 4.45001 10.7 4.75001 10.6 5.05001C10.4 5.45001 10.3 5.90001 10.4 6.40001C10.45 6.65001 10.45 6.95001 10.15 7.25001Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
