import { useMutation } from '@tanstack/react-query';
import { requestRefill } from 'core/api/enterprise/patient.api';

export function useRequestRefillMutation() {
	return useMutation({
		mutationKey: ['enterprise', 'patient', 'requestRefill'],
		mutationFn: requestRefill,
	});
}

export default useRequestRefillMutation;
