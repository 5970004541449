import React, { useMemo } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
	Button,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent';

interface LinkComponentProps {
	href?: string;
	title?: string;
	children?: React.ReactNode;
}

function LinkComponent({ href, title, children }: LinkComponentProps) {
	if (title && title.startsWith('btn-')) {
		return (
			<Button
				component={Link}
				href={href}
				variant="contained"
				color={title.slice(4) as 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'}
			>
				{children}
			</Button>
		);
	}

	return (
		<Link href={href} underline="always" title={title}>
			{children}
		</Link>
	);
}

function createMuiComponent(Component: React.ElementType) {
	return function MuiComponent({ children, ...props }: OverrideProps<OverridableTypeMap, typeof Component>) {
		return <Component {...props}>{children}</Component>;
	};
}

interface MarkdownProps {
	children?: string | null;
}

export default function Markdown({ children: markdown }: MarkdownProps) {
	const components: Components = useMemo(
		() => ({
			a: (props) => LinkComponent(props),
			p: ({ children }) => createMuiComponent(Typography)({ children, mt: 1 }),
			del: ({ children }) =>
				createMuiComponent(Typography)({ sx: { mt: 1, textDecoration: 'line-through' }, children }),
			em: ({ children }) => createMuiComponent(Typography)({ sx: { mt: 1, fontStyle: 'italic' }, children }),
			strong: ({ children }) =>
				createMuiComponent(Typography)({ component: 'strong', sx: { mt: 1, fontWeight: 'bold' }, children }),
			b: ({ children }) =>
				createMuiComponent(Typography)({ component: 'b', sx: { mt: 1, fontWeight: 'bold' }, children }),
			h1: ({ children }) => createMuiComponent(Typography)({ component: 'h1', variant: 'h1', sx: { mt: 2 }, children }),
			h2: ({ children }) => createMuiComponent(Typography)({ component: 'h2', variant: 'h2', sx: { mt: 2 }, children }),
			h3: ({ children }) => createMuiComponent(Typography)({ component: 'h3', variant: 'h3', sx: { mt: 2 }, children }),
			h4: ({ children }) => createMuiComponent(Typography)({ component: 'h4', variant: 'h4', sx: { mt: 2 }, children }),
			h5: ({ children }) => createMuiComponent(Typography)({ component: 'h5', variant: 'h5', sx: { mt: 2 }, children }),
			h6: ({ children }) => createMuiComponent(Typography)({ component: 'h6', variant: 'h6', sx: { mt: 2 }, children }),
			table: ({ children }) =>
				createMuiComponent(TableContainer)({
					component: Paper,
					sx: { mt: 2 },
					children: createMuiComponent(Table)({ size: 'small', children }),
				}),
			tbody: ({ children }) => createMuiComponent(TableBody)({ children }),
			th: ({ children }) => createMuiComponent(TableHead)({ children }),
			tr: ({ children }) => createMuiComponent(TableRow)({ children }),
			td: ({ children }) => createMuiComponent(TableCell)({ children }),
		}),
		[]
	);

	return (
		<ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
			{markdown}
		</ReactMarkdown>
	);
}
