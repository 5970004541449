import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ManageMedicationRemindersIcon(props: SvgIconProps) {
	return (
		<SvgIcon sx={{ fill: 'none', color: 'text.secondary' }} viewBox="0 0 45 48" {...props}>
			<g id="Group">
				<path
					id="Vector"
					d="M10.7001 13.8C9.80006 11.1 6.35006 10.5 5.45006 11.1C4.40006 11.7 6.80006 15.9 4.10006 23.85C3.05006 27 2.00006 30.15 1.85006 32.85C1.10006 43.2 11.0001 48.3 17.6001 45.6L27.2001 41.4"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path
					id="Vector_2"
					d="M36.0501 29.1C35.7501 28.2 34.8501 27.6 33.9501 27.6C33.6501 27.6 33.3501 27.6 33.0501 27.75L30.5001 28.95C29.3001 29.55 28.7001 30.9 29.3001 31.95C29.6001 32.85 30.5001 33.45 31.4001 33.45C31.7001 33.45 32.0001 33.45 32.3001 33.3L34.8501 32.25C35.9001 31.65 36.5001 30.3 36.0501 29.1Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path
					id="Vector_3"
					d="M32.3 38.7L34.55 37.8C35.6 37.35 36.05 36.15 35.6 35.1C35.3 34.35 34.55 33.75 33.65 33.75C33.35 33.75 33.05 33.75 32.9 33.9L30.65 34.8C29.6 35.25 29.15 36.45 29.6 37.5C29.9 38.25 30.65 38.85 31.55 38.85C31.85 38.85 32.15 38.85 32.3 38.7Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path
					id="Vector_4"
					d="M29.4501 26.1C29.7501 27 30.6501 27.6 31.5501 27.45C31.8501 27.45 32.1501 27.45 32.4501 27.3L34.1001 26.55C35.3001 26.1 35.7501 24.6 35.3001 23.55C35.0001 22.65 34.1001 22.05 33.2001 22.2C32.9001 22.2 32.6001 22.2 32.3001 22.35L30.6501 23.1C29.4501 23.55 28.8501 24.9 29.4501 26.1Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path id="Vector_5" d="M18.65 38.1H23.6" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path id="Vector_6" d="M10.7001 35.1H29.9001" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path id="Vector_7" d="M10.7001 10.95H18.2001" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path
					id="Vector_8"
					d="M27.5001 22.5C28.1001 22.35 44.0001 18.9 44.0001 10.65C44.0001 8.7 43.2501 6.9 41.7501 5.4C39.3501 3 35.3001 1.5 31.1001 1.5C24.0501 1.5 18.2001 5.55 18.2001 10.65C18.2001 12 18.6501 15.3 23.1501 16.35C26.6001 17.1 27.6501 18.3 27.8001 19.05C28.1001 20.25 26.9001 21.45 26.9001 21.45C26.7501 21.6 26.6001 22.05 26.7501 22.2C26.9001 22.35 27.2001 22.5 27.5001 22.5Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
				<path id="Vector_9" d="M24.3501 7.95001H37.5501" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path id="Vector_10" d="M24.3501 12.45H37.5501" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" />
				<path
					id="Vector_11"
					d="M20.3001 5.70001C18.3501 5.70001 15.3501 5.85001 13.1001 6.15001C11.7501 6.30001 10.8501 7.50001 10.8501 8.85001V38.4C10.8501 39.75 11.9001 40.95 13.1001 41.1C15.9501 41.4 18.8001 41.55 21.6501 41.55C24.0501 41.55 26.6001 41.4 29.0001 41.25C30.3501 41.1 31.7001 40.2 31.7001 38.85"
					stroke="currentColor"
					strokeWidth="2"
					strokeMiterlimit="8"
				/>
			</g>
		</SvgIcon>
	);
}
