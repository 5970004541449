import React from 'react';
import { IMaskInput } from 'react-imask';
import { Masked } from 'imask';

interface MaskedInputProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
	value: string;
}

export const PHONE_NUMBER_MASK_OPTIONS = {
	mask: '###-###-####',
	definitions: {
		'#': /[0-9]/,
	},
	placeholderChar: '#',
};

export const PhoneNumberMaskInput = React.forwardRef<HTMLElement, MaskedInputProps>((props, ref) => {
	const { onChange, ...other } = props;

	return (
		<IMaskInput
			{...other}
			{...PHONE_NUMBER_MASK_OPTIONS}
			lazy={false}
			inputRef={ref}
			onAccept={(value: string, masked: Masked<string>) =>
				onChange({ target: { name: props.name, value: masked.unmaskedValue } })
			}
		/>
	);
});

export const ZIPCodeMaskInput = React.forwardRef<HTMLElement, MaskedInputProps>((props, ref) => {
	const { onChange, ...other } = props;

	return (
		<IMaskInput
			{...other}
			mask="#####"
			definitions={{
				'#': /[0-9]/,
			}}
			lazy={false}
			placeholderChar="#"
			inputRef={ref}
			onAccept={(value: string, masked: Masked<string>) =>
				onChange({ target: { name: props.name, value: masked.unmaskedValue } })
			}
		/>
	);
});

export const SSNNumberMaskInput = React.forwardRef<HTMLElement, MaskedInputProps>((props, ref) => {
	const { onChange, ...other } = props;

	return (
		<IMaskInput
			{...other}
			mask="xxx-xx-####"
			definitions={{
				'#': /[0-9]/,
			}}
			lazy={false}
			placeholderChar="#"
			inputRef={ref}
			onAccept={(value: string, masked: Masked<string>) =>
				onChange({ target: { name: props.name, value: masked.unmaskedValue } })
			}
		/>
	);
});
