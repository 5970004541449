import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type WithValidationProps<T> = T & {
	name: string;
	hideErrorMessage?: boolean;
};

export function withValidation<T>(
	Component: React.ComponentType<T>,
	defaultProps?: Partial<T>
): React.ComponentType<WithValidationProps<T>> {
	return function WrappedComponent({ name, hideErrorMessage, ...restProps }: WithValidationProps<T>) {
		const { control } = useFormContext();

		return (
			<Controller
				name={name}
				control={control}
				render={({ field: { value, ...restField }, fieldState: { error } }) => (
					<Component
						{...({ ...defaultProps, ...restProps } as T)}
						error={!!error}
						helperText={error && !hideErrorMessage ? error.message : ''}
						value={value || ''}
						{...restField}
					/>
				)}
			/>
		);
	};
}

export default withValidation;
