import React, { Suspense } from 'react';
import { Typography } from '@mui/material';
import ContentCard from 'components/card/ContentCard';
import CircularLoading from 'components/CircularLoading';
import { AsNeededMedList } from 'components/patient/mySchedule';

export function LogAsNeededMedPage() {
	return (
		<ContentCard title="Log As-Needed Medication">
			<Typography variant="body1" component="p">
				Track the medications you are taking only when needed or when symptoms appear.
			</Typography>
			<Suspense fallback={<CircularLoading />}>
				<AsNeededMedList />
			</Suspense>
		</ContentCard>
	);
}
