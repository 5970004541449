import ReactGA from 'react-ga4';
import eventService from 'services/eventService';
import { Subscription } from 'rxjs';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

class GoogleAnalyticsService {
	private initialized = false;

	private subscriptions: Subscription[] = [];

	init() {
		if (!this.initialized) {
			ReactGA.initialize(GA_MEASUREMENT_ID as string);
			this.initialized = true;
		}

		this.setupEventListeners();
	}

	// eslint-disable-next-line class-methods-use-this
	logPageView(page: string) {
		ReactGA.send({ hitType: 'pageview', page });
	}

	// eslint-disable-next-line class-methods-use-this
	logEvent(action: string, category?: string, label?: string) {
		if (category) {
			ReactGA.event({
				action,
				category,
				label,
			});
		} else {
			ReactGA.event(action);
		}
	}

	private setupEventListeners() {
		this.subscriptions.push(
			eventService.onEvent('button', ({ action }) => this.logEvent(action)),
			eventService.onEvent('imageWithPreview', ({ action }) => this.logEvent(action)),
			eventService.onEvent('tooltip', ({ action }) => this.logEvent(action))
		);
	}

	cleanup() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}
}

const googleAnalyticsService = new GoogleAnalyticsService();
export default googleAnalyticsService;
