import { styled } from '@mui/material/styles';
import { Paper as MuiPaper } from '@mui/material';

export const Paper = styled(MuiPaper)(({ theme }) => ({
	padding: 16,
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: theme.palette.background.default,
}));

export default Paper;
