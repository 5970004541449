import React, { useMemo } from 'react';
import Dialog, { IDialogProps } from 'components/dialog';
import { Stack, Typography } from '@mui/material';
import { usePatientInboxNotificationQuery } from 'hooks/enterprise';
import CircularLoading from 'components/CircularLoading';
import Markdown from 'components/Markdown/Markdown';
import { InboxStatus } from 'core/api/inboxNotification';
import StatusChip, { StatusChipLegend } from 'components/StatusChip';
import relativeDateFormat from 'utils/relativeDateFormat';

interface InboxNotificationDialogProps extends Omit<IDialogProps, 'children'> {
	notificationId: string;
	patientId: string;
}

export function InboxNotificationDialog({
	notificationId,
	patientId,
	...restDialogProps
}: InboxNotificationDialogProps) {
	const { data, isLoading } = usePatientInboxNotificationQuery({
		patientId,
		notificationId,
	});
	const { subject, body, createdAt, statusId } = data || {};
	const markdownContent = `${body}`.trim();

	const statusLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: InboxStatus.Inbox,
				label: 'Inbox',
				color: 'primary',
			},
			{
				id: InboxStatus.Deleted,
				label: 'Deleted',
				color: 'error',
			},
		],
		[]
	);

	return (
		<Dialog scroll="paper" fullWidth maxWidth="md" title={subject} {...restDialogProps}>
			{isLoading && <CircularLoading />}
			{!isLoading && (
				<>
					<Stack direction="row" alignItems="center" gap={1}>
						<Typography variant="caption">{relativeDateFormat(createdAt || '')}</Typography>
						{statusId != null && (
							<StatusChip legend={statusLegend} statusId={statusId} variant="outlined" size="small" />
						)}
					</Stack>
					<Markdown>{markdownContent}</Markdown>
				</>
			)}
		</Dialog>
	);
}

export default InboxNotificationDialog;
