import { IScheduledTimeReminderSettings } from 'core/models/mySchedule/mySchedule.models';
import { deleteRequest, getBlobRequest, getRequest, patchRequest, postRequest } from 'core/api/api-service';
import { endpoints } from 'core/api/endpoints';
import { ISuccessResponse } from 'core/api/api-service.models';
import {
	IAsNeededMedsParams,
	IAsNeededMedsResponse,
	ICreateScheduledTimeReminderSettingsParams,
	ICreateScheduledTimeReminderSettingsResponse,
	IDeleteRecordTakenAsNeededMedRequestParams,
	MedHistoryParams,
	MedHistoryResponse,
	IRecordTakenAsNeededMedRequestParams,
	IRecordTakenRequestParams,
	IRecordTakenResponse,
	IScheduledMedsRequestParams,
	IScheduledMedsResponse,
	IScheduledTimeReminderSettingsResponse,
	IUpdateScheduledTimeReminderSettingsParams,
	IUpdateScheduledTimeReminderSettingsResponse,
	IAsNeededMedication,
} from './mySchedule.models';

function mapScheduledTimeReminderSettings(
	result: IScheduledTimeReminderSettingsResponse
): IScheduledTimeReminderSettings[] {
	return result['hydra:member'].map((item) => {
		return {
			id: item.id,
			scheduledTimeHour: item.scheduledTimeHour,
			scheduledTimeMinute: item.scheduledTimeMinute,
			scheduledTimeMeridiem: item.scheduledTimeMeridiem,
			reminderTimeHour: item.reminderTimeHour,
			reminderTimeMinute: item.reminderTimeMinute,
			reminderTimeMeridiem: item.reminderTimeMeridiem,
			enabled: item.enabled,
			scheduleTimeOfDayName: item.scheduleTimeOfDayName,
		};
	});
}

export function getScheduledMeds(request: IScheduledMedsRequestParams): Promise<IScheduledMedsResponse> {
	const body = {
		day: request.day ?? '',
	};

	return getRequest<IScheduledMedsResponse>(endpoints.mySchedule.scheduledMeds, body, {});
}

export function recordTaken(request: IRecordTakenRequestParams): Promise<IRecordTakenResponse> {
	const body = { ...request };
	return postRequest<IRecordTakenResponse>(endpoints.mySchedule.recordTaken, body, {});
}

export function getAsNeededMeds(request: IAsNeededMedsParams): Promise<IAsNeededMedsResponse> {
	const body = {
		day: request.day ?? '',
	};

	return getRequest<IAsNeededMedsResponse>(endpoints.mySchedule.asNeededMeds, body, {});
}

export async function recordTakenAsNeededMed({
	id,
	scheduledMedId,
	scheduledTimeId,
	hourTaken,
	minuteTaken,
	meridiem,
}: IRecordTakenAsNeededMedRequestParams): Promise<ISuccessResponse> {
	let body;

	if (id) {
		body = {
			hourTaken,
			minuteTaken,
			meridiem,
		};

		return patchRequest<ISuccessResponse>(endpoints.mySchedule.editRecordTakenAsNeededMed(id.toString()), body, {});
	}

	body = {
		scheduledMedId,
		scheduledTimeId,
		hourTaken,
		minuteTaken,
		meridiem,
	};

	return postRequest<ISuccessResponse>(endpoints.mySchedule.recordTakenAsNeededMed, body, {});
}

export function deleteRecordTakenAsNeededMed(
	request: IDeleteRecordTakenAsNeededMedRequestParams
): Promise<ISuccessResponse> {
	return deleteRequest<ISuccessResponse>(
		endpoints.mySchedule.deleteRecordTakenAsNeededMed(request.id.toString()),
		{},
		{}
	);
}

export async function getScheduledTimeReminderSettings(): Promise<IScheduledTimeReminderSettings[]> {
	const result = await getRequest<IScheduledTimeReminderSettingsResponse>(
		endpoints.mySchedule.getScheduledTimeReminderSettings,
		{},
		{}
	);

	return mapScheduledTimeReminderSettings(result);
}

export async function getScheduledTimeReminderSettingsN(): Promise<IScheduledTimeReminderSettingsResponse> {
	return getRequest<IScheduledTimeReminderSettingsResponse>(
		endpoints.mySchedule.getScheduledTimeReminderSettings,
		{},
		{}
	);
}

export function createScheduledTimeReminderSettings(
	request: ICreateScheduledTimeReminderSettingsParams
): Promise<ICreateScheduledTimeReminderSettingsResponse> {
	const body = {
		scheduledTimeHour: request.scheduledTimeHour,
		scheduledTimeMinute: request.scheduledTimeMinute,
		scheduledTimeMeridiem: request.scheduledTimeMeridiem,
		reminderTimeHour: request.reminderTimeHour,
		reminderTimeMinute: request.reminderTimeMinute,
		reminderTimeMeridiem: request.reminderTimeMeridiem,
		enabled: request.enabled,
	};

	return postRequest<ICreateScheduledTimeReminderSettingsResponse>(
		endpoints.mySchedule.createScheduledTimeReminderSettings,
		body,
		{}
	);
}

export function updateScheduledTimeReminderSettings(
	request: IUpdateScheduledTimeReminderSettingsParams
): Promise<IUpdateScheduledTimeReminderSettingsResponse> {
	const body = {
		reminderTimeHour: request.reminderTimeHour,
		reminderTimeMinute: request.reminderTimeMinute,
		reminderTimeMeridiem: request.reminderTimeMeridiem,
		enabled: request.enabled,
	};

	return patchRequest<IUpdateScheduledTimeReminderSettingsResponse>(
		endpoints.mySchedule.updateScheduledTimeReminderSettings(request?.id?.toString() ?? ''),
		body,
		{}
	);
}

export function resetScheduledTimeReminderSettings(): Promise<ISuccessResponse> {
	return postRequest<ISuccessResponse>(endpoints.mySchedule.resetScheduledTimeReminderSettings, {}, {});
}

export async function getMedHistory(request: MedHistoryParams): Promise<MedHistoryResponse> {
	const body = {
		from: request.from,
		to: request.to,
	};

	return getRequest<MedHistoryResponse>(endpoints.mySchedule.medHistory, body, {});
}

export function getPdfScheduledMeds(request: IScheduledMedsRequestParams): Promise<Blob> {
	const body = {
		day: request.day ?? '',
	};

	return getBlobRequest(endpoints.mySchedule.scheduledMedsPdf, body, {});
}

export function getPdfAsNeededMedsUsageHistory({
	scheduledMedId,
}: Pick<IAsNeededMedication, 'scheduledMedId'>): Promise<Blob> {
	return getBlobRequest(endpoints.mySchedule.asNeededMedsUsageHistoryPdf(scheduledMedId), {}, {});
}
