import { useQuery } from '@tanstack/react-query';
import { patientAccessesList } from 'core/api/enterprise/supervisor.api';

export function usePatientAccessesListQuery() {
	return useQuery({
		queryKey: ['enterprise', 'patientAccesses', 'list'],
		queryFn: patientAccessesList,
	});
}

export default usePatientAccessesListQuery;
