import React from 'react';
import { Box, Stack, Theme, Typography, styled } from '@mui/material';
import { PaletteColor, PaletteOptions } from '@mui/material/styles/createPalette';

export const CalendarWeekDayStyled = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'today',
})<{
	color?: keyof PaletteOptions;
	today: boolean;
}>`
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme, color, today }: { theme: Theme; color?: keyof PaletteOptions; today: boolean }) => {
		if (color && theme.palette?.[color]) return (theme.palette[color] as PaletteColor)?.main;
		if (today) return theme.palette.primary.main;
		return 'transparent';
	}};
	color: ${({ theme, color, today }: { theme: Theme; color?: keyof PaletteOptions; today: boolean }) => {
		if (color && theme.palette?.[color]) return (theme.palette[color] as PaletteColor)?.contrastText;
		if (today) return theme.palette.primary.contrastText;
		return theme.palette.text.primary;
	}};
	font-weight: ${({ today }) => (today ? 700 : 400)};
`;

interface ICalendarWeekDayProps {
	dayStr: string;
	day: number;
	today: boolean;
	todayStr?: string;
	color?: keyof PaletteOptions;
}

export function CalendarWeekDay({ dayStr, day, color, today, todayStr = 'Today' }: ICalendarWeekDayProps) {
	return (
		<Stack direction="column" alignItems="center" gap="4px">
			<Typography
				fontSize={12}
				fontWeight={today ? 700 : 400}
				color={today ? 'text.secondary' : 'text.primary'}
				textTransform="uppercase"
			>
				{today ? todayStr : dayStr}
			</Typography>
			<CalendarWeekDayStyled {...{ color, today }}>{day}</CalendarWeekDayStyled>
		</Stack>
	);
}
